import { SetStateAction, useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  IconEye,
  IconEyeClosed,
  IconInfo,
  IconInfoCircleFilled,
  IconInfoDarkI,
  IconPen,
} from "../../../Components/Common/Icons";
import { isAppointmentFeatureEnabled, toastr } from "../../../services/Common.services";
import { LoaderBar } from "../../../Components/Common/LoaderBar";
import {
  getTrackingCredentials,
  updateTrackingCredentials,
  validateTrackingCredentials,
} from "./actionCreators";
import { diff } from "deep-diff";
import ReactTooltip from "react-tooltip";
import _ from "lodash";
import { LOGIN_TYPE } from "./constant";
import { aliasMap } from "./portProfileLabelAlias";
import { replaceTerminalAlias } from "./utils";

const systemCredentialList = ["APM TERMINAL", "TRAPAC","APM","PNCT","GCT BAYONNE","TRAPAC JA X","TRAPAC OAK","SFCT TERMINAL"];

const PortLogin = (props) => {
  const {
    showModal,
    hideModal,
    terminalCode,
    ports,
    label,
    updateStats,
    isAlertView,
    singleTerminal,
  } = props;
  const [selectedPort, setSelectedPort] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  // const [showPortCred, setShowPortCred] = useState(1);
  const [portCredFields, setPortCredFields] = useState([]);
  const [validatedCredentials, setValidatedCredentials] = useState([]);
  const [modalState, setModalState] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [initialPortCreds, setInitialPortCreds] = useState([]);
  const loadingSet = useRef(new Set());
  const [selectedLoading, setSelectedLoading] = useState(Array.from(loadingSet.current));
  const [enabledInputList, setEnabledInputList] = useState(new Set());
  const recentEditedCredential = useRef([]);

  const enableInput = (code, type) => {
    const newSet = new Set(Array.from(enabledInputList));
    if ([LOGIN_TYPE.APPOINTMENT, LOGIN_TYPE.SSAPPOINTMENT ].includes(type) ) {
      newSet.add(`${code} ${type}`);
    } else {
      newSet.add(code);
    }
    setEnabledInputList(newSet);
  }

  const disableInput = (code, type) => {
    const newSet = new Set(Array.from(enabledInputList));
    if ([LOGIN_TYPE.APPOINTMENT, LOGIN_TYPE.SSAPPOINTMENT ].includes(type) ) {
      newSet.delete(`${code} ${type}`);
    } else {
      newSet.delete(code);
    }
    setEnabledInputList(newSet);
  }

  const usesSystemCredentials = (code) => {
    return systemCredentialList.includes(code);
  }

  const isInputDisabled = useCallback((code, status) => {
    if(status === 'warn') {
      return false;
    }

    return !Array.from(enabledInputList ?? [])?.includes(code);
  },[enabledInputList]);

  const handleSelectedPort = () => {
    setSelectedPort(!selectedPort);
  };
  const togglePasswordVisibility = (code, type) => {
    setShowPassword(showPassword ? false : true);
    const fields = portCredFields.map((field) => {
      if (field.code === code) {
        if (type === LOGIN_TYPE.TRACKING) {
          return {
            ...field,
            showPass: !field.showPass,
          };
        } else if (type === LOGIN_TYPE.APPOINTMENT && field.hasAppointment) {
          return {
            ...field,
            showAppointmentPass: !field.showAppointmentPass,
          };
        }
        else if (type === LOGIN_TYPE.SSAPPOINTMENT && field.hasSSAppointment) {
          return {
            ...field,
            showSSAppointmentPass: !field.showSSAppointmentPass,
          };
        }
      }
      return field;
    });
    setPortCredFields(fields);
  };

  const handleFormChange = (index, event) => {
    let data = [...portCredFields];
    data[index][event.target.name] = event.target.value;

    if (data[index][event.target.name] === "" && ["username", "password"].includes(event.target.name)) data[index]["status"] = "warn";
    if (data[index][event.target.name] === "" && ["appointmentUsername", "appointmentPassword"].includes(event.target.name)) data[index]["appointmentloginStatus"] = "warn";
    if (data[index][event.target.name] === "" && ["ssappointmentUsername", "ssappointmentPassword"].includes(event.target.name)) data[index]["ssappointmentloginStatus"] = "warn";

    // else if (data[index]["username"] !== "" && data[index]["password"] !== "")
    //   data[index]["status"] = "pending";

    setPortCredFields(data);
  };

  useEffect(() => {
    setIsLoading(true);
    getTrackingCredentials({
      portMarket: [terminalCode],
    }).then((res) => {
      let fields = portCredFields;
      if (res?.ports?.length > 0) {
        const initial = res.ports.map(
          ({ portId, portPassword, portUsername, isConnected, appointmentUsername, appointmentPassword, hasAppointment, isAppointmentConnected, ssappointmentUsername, ssappointmentPassword, isSSAppointmentConnected, hasSSAppointment  }, index) => {
            return {
              portId,
              portUsername,
              portPassword,
              appointmentUsername,
              appointmentPassword,
              hasAppointment,
              isConnected,
              isAppointmentConnected,
              ssappointmentUsername,
              ssappointmentPassword,
              isSSAppointmentConnected,
              hasSSAppointment
            };
          }
        );

        setInitialPortCreds(initial);

        fields = res.ports.map(
          ({ portId, portPassword, portUsername, isConnected, isLogin, appointmentUsername, appointmentPassword, hasAppointment, isAppointmentConnected, ssappointmentUsername, ssappointmentPassword, isSSAppointmentConnected, hasSSAppointment }, index) => {
            return {
              label: portId,
              code: portId,
              username: portUsername,
              password: portPassword,
              isLogin: isLogin,
              isConnected: isConnected,
              isAppointmentConnected: isAppointmentConnected,
              showPass: false,
              showAppointmentPass: false,
              status:
                portUsername && portPassword ?
                 typeof isConnected === "undefined" ? "error" 
                  : isConnected === "processing" ? "processing"
                  : (isConnected == true || isConnected === "true") ? "success" 
                  : "credError" 
                  : isConnected == true || isConnected === "true" ? "pending"
                  :"warn",
              appointmentUsername,
              appointmentPassword,
              hasAppointment,
              appointmentloginStatus: 
                appointmentUsername && appointmentPassword ?
                 typeof isAppointmentConnected === "undefined" ? "error" 
                  : isAppointmentConnected === "processing" ? "processing"
                  : (isAppointmentConnected == true || isAppointmentConnected === "true") ? "success" 
                  : "credError" 
                  : isAppointmentConnected == true || isAppointmentConnected === "true" ? "pending"
                  :"warn",

              showSSAppointmentPass: false,
              ssappointmentUsername,
              ssappointmentPassword,
              hasSSAppointment,
              ssappointmentloginStatus: 
              ssappointmentUsername && ssappointmentPassword ?
                  typeof isSSAppointmentConnected === "undefined" ? "error" 
                  : isSSAppointmentConnected === "processing" ? "processing"
                  : (isSSAppointmentConnected == true || isSSAppointmentConnected === "true") ? "success" 
                  : "credError" 
                  : isSSAppointmentConnected == true || isSSAppointmentConnected === "true" ? "pending"
                  :"warn",


            
            };
          }
        );
      }
      if (singleTerminal) {
        const updatedSingleTerminal = replaceTerminalAlias(singleTerminal, aliasMap)
        fields = fields.filter(obj => obj?.label === updatedSingleTerminal);
      }
      setPortCredFields(fields);
      setIsLoading(false);
    });
  }, []);

  const handleUpdate = async (loadingData, type) => {
    try {
      const key = type === LOGIN_TYPE.APPOINTMENT 
      ? `${loadingData} ${LOGIN_TYPE.APPOINTMENT}` 
      : type === LOGIN_TYPE.SSAPPOINTMENT 
      ? `${loadingData} ${LOGIN_TYPE.SSAPPOINTMENT}` 
      :  loadingData;

      loadingSet.current.add(key);
    setSelectedLoading(Array.from(loadingSet.current));

    // setIsLoading(true);
    const data = [];
    portCredFields?.map((cred) => {
      if(cred?.code === loadingData){
        const updatedData = {
          portId: cred.code,
          portUsername: cred.username,
          portPassword: cred.password,
        };

        if (type === LOGIN_TYPE.APPOINTMENT) {
          updatedData.portUsername = cred.appointmentUsername;
          updatedData.portPassword = cred.appointmentPassword;
          updatedData.hasAppointment = true;
        }
        if (type === LOGIN_TYPE.SSAPPOINTMENT) {
          updatedData.portUsername = cred.ssappointmentUsername;
          updatedData.portPassword = cred.ssappointmentPassword;
          updatedData.hasSSAppointment = true;
        }
        data.push(updatedData);
        // const initial = initialPortCreds.find(
        //   (cred) => cred?.portId === updatedData?.portId
        // );
        // const changes = diff(updatedData, initial);
        // if (
        //   changes ||
        //   (typeof cred.isConnected === "undefined" &&
        //     cred.username &&
        //     cred.password)
        // ) {
        //   data.push(updatedData);
        // }
      }
    });


    if (data.length === 0) {
      handleStatUpdate();
      // setIsLoading(false);
      loadingSet.current.delete(loadingData);
      setSelectedLoading(Array.from(loadingSet.current));
      return;
    }

    const response = await validateTrackingCredentials({
      credentials: data,
    });

    setPortCredFields((prevPortCredFields) => {
      const fields = prevPortCredFields?.map((field) => {
        const resData = response?.find(({ portId }) => portId === field.code);
        const newData = {
          ...field,
        };
        if (resData) {
          if (resData.hasAppointment) {
            newData.appointmentloginStatus =
            (field.appointmentUsername && field.appointmentPassword)
              ? resData?.type && resData?.type === "isInvalid"
                ? "credError"
                : resData?.type && resData?.type === "isConnected"
                ? "success"
                : resData?.type && resData?.type === "processing"
                ? "processing"
                : "error"
              :resData?.type && resData?.type === "isConnected"? "pending"
              : "warn";
          } else if (resData.hasSSAppointment) {
            newData.ssappointmentloginStatus =
              (field.ssappointmentUsername && field.ssappointmentPassword)
                ? resData?.type && resData?.type === "isInvalid"
                  ? "credError"
                  : resData?.type && resData?.type === "isConnected"
                  ? "success"
                  : resData?.type && resData?.type === "processing"
                  ? "processing"
                  : "error"
                :resData?.type && resData?.type === "isConnected"? "pending"
                : "warn";
          } else {
            newData.status =
            (field.username && field.password)
              ? resData?.type && resData?.type === "isInvalid"
                ? "credError"
                : resData?.type && resData?.type === "isConnected"
                ? "success"
                : resData?.type && resData?.type === "processing"
                ? "processing"
                : "error"
              :resData?.type && resData?.type === "isConnected"? "pending"
              : "warn";
            
          }
        }
        return newData;
      });
  
      const updatedInital = fields?.map((field) => {
        return {
          portId: field.code,
          portUsername: field.username,
          portPassword: field.password,
          appointmentUsername: field.appointmentUsername,
          appointmentPassword: field.appointmentPassword,
          ssappointmentUsername: field.ssappointmentUsername,
          ssappointmentPassword: field.ssappointmentPassword,
        };
      });
      recentEditedCredential.current.push(loadingData);
      recentEditedCredential.current = _.uniq(recentEditedCredential.current);
  
      setInitialPortCreds(updatedInital);
      setPortCredFields(fields);
      setValidatedCredentials((prev) => {
        let temp = [...prev, ...response];
        if(response?.length === 0 || !response){
          const latest = portCredFields?.find((field) => field?.code === loadingData);
          latest.portId = data[0]?.portId;
          temp = [...temp, ...[latest]];
        }
        return temp ?? [];
      });
      const status = fields?.find(field => field?.code === loadingData)?.status;
      if(status?.includes("success")) toastr.show("Credential Verification Completed", "success");
      
      loadingSet.current.delete(loadingData);
      loadingSet.current.delete(`${loadingData} ${LOGIN_TYPE.APPOINTMENT}`);
      loadingSet.current.delete(`${loadingData} ${LOGIN_TYPE.SSAPPOINTMENT}`);
      setSelectedLoading(Array.from(loadingSet.current));
    })


  }catch(e){
    loadingSet.current.delete(loadingData);
    setSelectedLoading(Array.from(loadingSet.current));
    console.error("Error:", e)
  }
  }

  const handleSaveClick = async () => {
    setIsLoading(true);
    await updateTrackingCredentials({
      credentials: validatedCredentials,
    }).catch((err) => {
      toastr.show("Credential Update Failed", "error");
      console.error(err);
    });
    toastr.show("Credential Update Success", "success");
    handleStatUpdate();
    setIsLoading(false);
    hideModal();
  };

  const handleStatUpdate = () => {
    // Update Stats
    const stats = {
      connected: 0,
      errors: 0,
      missing: 0,
      processing: 0
    };
    portCredFields?.map((cred) => {
      if (cred.status === "error" || cred.status === "credError") {
        stats["errors"] += 1;
      } 

      if ((cred.hasAppointment || cred.hasSSAppointment)  && ([cred.appointmentloginStatus, cred.ssappointmentloginStatus].includes("error") ||  [cred.appointmentloginStatus, cred.ssappointmentloginStatus].includes("credError"))) {
        stats["errors"] += 1;
      }
      if (cred.status === "warn") {
        stats["missing"] += 1;
      }

      if ((cred.hasAppointment || cred.hasSSAppointment) && [cred.appointmentloginStatus, cred.ssappointmentloginStatus].includes("warn")) {
        stats["missing"] += 1;
      }
    
      if (cred.status === "success" || cred.status === "pending") {
        stats["connected"] += 1;
      } 
    
      if ((cred.hasAppointment || cred.hasSSAppointment) && [cred.appointmentloginStatus, cred.ssappointmentloginStatus].includes("success")) {
        stats["connected"] += 1;
      } 
    
      if (cred.status === "processing") {
        stats["processing"] += 1;
        stats["errors"] += 1;
      }
    });
    updateStats(terminalCode, stats);
  };
  const handleBackClick = async () => {
    hideModal();
  };

  const STATUS_MESSAGE = {
    success: {
      colorBorder: "",
      colorText: "text-defaultbrand-500",
      color: "bg-brand-100",
      message: "Connected!",
    },
    warn: {
      colorBorder: "border-orange",
      colorText: "text-warning-500",
      color: "bg-warning-50",
      message: "Credentials Required",
    },

    processing: {
      colorBorder: "border-orange",
      colorText: "text-warning-500",
      color: "bg-warning-50",
      message: "Processing Credentials",
    },
    error: {
      colorBorder: "border-error-500",
      colorText: "text-error-500",
      color: "bg-red-soft",
      message: "Credentials cannot be validated",
    },
    credError: {
      colorBorder: "border-error-500",
      colorText: "text-error-500",
      color: "bg-red-soft",
      message: "Login or Password is wrong",
    },
    pending: {
      colorBorder: "",
      colorText: "text-defaultbrand-500",
      color: "bg-brand-50",
      message: "",
    },
  };

  const getStatusClass = (status, appointmentStatus, hasAppointment) => {
    if (status === 'success' && appointmentStatus !== 'success' && hasAppointment) {
      return STATUS_MESSAGE[appointmentStatus].color;
    } else if (status !== 'success' && appointmentStatus === 'success' && hasAppointment) {
      return STATUS_MESSAGE[status].color;
    } else {
      return STATUS_MESSAGE[status].color;
    }
  };

  return (
    <Modal show={showModal} centered animation={false} dialogClassName="modal-w900 modal-dialog-centered modal-dialog-scrollable">
      {isLoading && <LoaderBar />}
      <Modal.Header className="">
        <Modal.Title>{label}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <form>
          {portCredFields?.length > 0 ?
            portCredFields?.map((input, index) => {
              {/* if (
                ["success", "pending"].includes(input.status) &&
                isAlertView && !recentEditedCredential.current.includes(input.code)
              ) {
                return;
              } */}
              return (
                <div
                  key={index}
                  className={
                    "form-row align-items-center " +
                    getStatusClass(input.status, input.appointmentloginStatus, input.hasAppointment) +
                    " py-2 rounded-3 mb-1"
                  }
                >
                  <div className="col-3">
                    <div className="pl-1">
                      <label className="font-14 font-weight-500 text-gray-900">
                        {input.label}
                      </label>
                      <p
                        className={
                          STATUS_MESSAGE[input.status].colorText + " mb-0"
                        }
                      >
                        {selectedLoading?.includes(input.code) ? 'Connecting...' : STATUS_MESSAGE[input.status].message}
                        { usesSystemCredentials(input.code) && <>
                            { input.status === 'pending' && 'Connected' }
                            <span data-tip data-for="connected-info">
                              <IconInfoCircleFilled className="mr-2 text-muted"/>
                            </span> 
                            <ReactTooltip
                              place="top"
                              effect="solid"
                              id="connected-info"
                              clasName="react-tooltip-new"
                            >
                              <span>
                                Credentials are optional.
                              </span>
                            </ReactTooltip>
                          </> }
                      </p>
                      { isAppointmentFeatureEnabled() && input?.hasAppointment &&
                        <p className={
                          STATUS_MESSAGE[input.appointmentloginStatus].colorText + " mb-0 mt-40"
                        }>
                          {selectedLoading?.includes(`${input.code} ${LOGIN_TYPE.APPOINTMENT}`) ? 'Connecting...' : STATUS_MESSAGE[input.appointmentloginStatus].message}
                        </p>
                      }
                      { isAppointmentFeatureEnabled() && input?.hasSSAppointment &&
                        <p className={
                          STATUS_MESSAGE[input.ssappointmentloginStatus].colorText + " mb-0 mt-40"
                        }>
                          {selectedLoading?.includes(`${input.code} ${LOGIN_TYPE.SSAPPOINTMENT}`) ? 'Connecting...' : STATUS_MESSAGE[input.ssappointmentloginStatus].message}
                        </p>
                      }
                    </div>
                  </div>
                 
                    <div className="col-9">
                        {isAppointmentFeatureEnabled() && input.hasAppointment &&
                          <div className="row no-gutters text-gray-900 font-12 mb-1 font-weight-500">Container Tracking</div>
                        }
                      <div className="row no-gutters gap-10">
                        <div className="col-5">
                          <div className="form-group mb-0">
                            <input
                              disabled={isInputDisabled(input.code, input.status)}
                              // disabled={modalState === 1 ? false : true}
                              name="username"
                              value={input.username}
                              onChange={(event) => handleFormChange(index, event)}
                              className={
                                "form-control " +
                                STATUS_MESSAGE[input.status].colorBorder
                              }
                              placeholder={usesSystemCredentials(input.code) && isInputDisabled(input.code, input.status) ? "System Default" : "Enter login"}
                            />
                          </div>
                        </div>
                        <div className="col-5">
                          <div className="input-wrapper">
                            <input
                              disabled={isInputDisabled(input.code, input.status)}
                              // disabled={modalState === 1 ? false : true}
                              name="password"
                              value={input.password}
                              onChange={(event) => handleFormChange(index, event)}
                              type={input.showPass ? "text" : "password"}
                              className={
                                "form-control " +
                                STATUS_MESSAGE[input.status].colorBorder
                              }
                              placeholder={usesSystemCredentials(input.code) && isInputDisabled(input.code, input.status) ? "System Default" : "Enter Password"}
                            />
                            <div
                              className="input-icon"
                              onClick={() => togglePasswordVisibility(input.code, LOGIN_TYPE.TRACKING)}
                            >
                              {input.showPass ? <IconEye /> : <IconEyeClosed />}
                            </div>
                          </div>
                        </div>
                        <div className="col-2" style={{maxWidth: 80}}>
                          {selectedLoading?.includes(input.code) && (
                            <button onClick={(e) => e.preventDefault()} className="btn bg-blue-500 text-white btn-block"><span className="spinner-border text-white spinner-border-sm"role="status" aria-hidden="true"></span></button>
                          )}
                          {/* {(!selectedLoading?.includes(input.code) && input.username && input.password && input.status === 'success') && (
                            <button 
                              onClick={(e) => {
                                e.preventDefault();
                                handleUpdate(input.code);
                              }} 
                              className="btn bg-white btn-block">
                                Edit
                            </button>
                          )} */}
                          {(!selectedLoading?.includes(input.code) && isInputDisabled(input.code, input.status)) && (
                            <button 
                              onClick={(e) => {
                                e.preventDefault();
                                enableInput(input.code);
                              }} 
                              className="btn bg-white btn-block">
                                Edit
                            </button>
                          )}
                          {((!selectedLoading?.includes(input.code) && !isInputDisabled(input.code, input.status))) && (
                            <button 
                              onClick={(e) => {
                                e.preventDefault();
                                // if(input.username && input.password) {
                                //   disableInput(input.code);
                                //   handleUpdate(input.code);
                                // }
                                disableInput(input.code);
                                handleUpdate(input.code);
                              }} 
                              className="btn bg-blue-500 text-white btn-block">
                                Connect
                            </button>
                          )}
                          {/* //open comment for other button */}
                          {/* <button className="btn bg-gray-50">Connect</button>
                          <button className="btn bg-blue-500 text-white">Connect</button>
                          <button className="btn bg-blue-500 text-white"><span className="spinner-border text-white spinner-border-sm mr-2"role="status" aria-hidden="true"></span></button> */}
                        </div>
                      </div>
                      {/* Appointment */}
                      { isAppointmentFeatureEnabled() && input?.hasAppointment &&
                        <>
                          <div className="row no-gutters text-gray-900 font-12 mb-1 mt-10 font-weight-500">Appointment Scheduling</div>
                          <div className="row no-gutters gap-10">
                            <div className="col-5">
                              <div className="form-group mb-0">
                                <input
                                  disabled={isInputDisabled(`${input.code} ${LOGIN_TYPE.APPOINTMENT}`, input.appointmentloginStatus)}
                                  name="appointmentUsername"
                                  value={input.appointmentUsername}
                                  onChange={(event) => handleFormChange(index, event)}
                                  className={
                                    "form-control " +
                                    STATUS_MESSAGE[input.appointmentloginStatus].colorBorder
                                  }
                                  placeholder={usesSystemCredentials(`${input.code} ${LOGIN_TYPE.APPOINTMENT}`) && isInputDisabled(`${input.code} ${LOGIN_TYPE.APPOINTMENT}`, input.appointmentloginStatus) ? "System Default" : "Enter login"}
                                />
                              </div>
                            </div>
                            <div className="col-5">
                              <div className="input-wrapper">
                                <input
                                  disabled={isInputDisabled(`${input.code} ${LOGIN_TYPE.APPOINTMENT}`, input.appointmentloginStatus)}
                                  name="appointmentPassword"
                                  value={input.appointmentPassword}
                                  onChange={(event) => handleFormChange(index, event)}
                                  type={input.showAppointmentPass ? "text" : "password"}
                                  className={
                                    "form-control " +
                                    STATUS_MESSAGE[input.appointmentloginStatus].colorBorder
                                  }
                                  placeholder={usesSystemCredentials(`${input.code} ${LOGIN_TYPE.APPOINTMENT}`) && isInputDisabled(`${input.code} ${LOGIN_TYPE.APPOINTMENT}`, input.appointmentloginStatus) ? "System Default" : "Enter Password"}
                                />
                                <div
                                  className="input-icon"
                                  onClick={() => togglePasswordVisibility(input.code, LOGIN_TYPE.APPOINTMENT)}
                                >
                                  {input.showAppointmentPass ? <IconEye /> : <IconEyeClosed />}
                                </div>
                              </div>
                            </div>
                            <div className="col-2" style={{maxWidth: 80}}>
                              {selectedLoading?.includes(`${input.code} ${LOGIN_TYPE.APPOINTMENT}`) && (
                                <button onClick={(e) => e.preventDefault()} className="btn bg-blue-500 text-white btn-block"><span className="spinner-border text-white spinner-border-sm"role="status" aria-hidden="true"></span></button>
                              )}
                              {(!selectedLoading?.includes(`${input.code} ${LOGIN_TYPE.APPOINTMENT}`) && isInputDisabled(`${input.code} ${LOGIN_TYPE.APPOINTMENT}`, input.appointmentloginStatus)) && (
                                <button 
                                  onClick={(e) => {
                                    e.preventDefault();
                                    enableInput(input.code, LOGIN_TYPE.APPOINTMENT);
                                  }} 
                                  className="btn bg-white btn-block">
                                    Edit
                                </button>
                              )}
                              {((!selectedLoading?.includes(`${input.code} ${LOGIN_TYPE.APPOINTMENT}`) && !isInputDisabled(`${input.code} ${LOGIN_TYPE.APPOINTMENT}`, input.appointmentloginStatus))) && (
                                <button 
                                  onClick={(e) => {
                                    e.preventDefault();
                                    disableInput(input.code, LOGIN_TYPE.APPOINTMENT);
                                    handleUpdate(input.code, LOGIN_TYPE.APPOINTMENT);
                                  }} 
                                  className="btn bg-blue-500 text-white btn-block">
                                    Connect
                                </button>
                              )}
                            </div>
                          </div>
                        </>
                      }

                    {isAppointmentFeatureEnabled() && input?.hasSSAppointment &&
                      <>
                        <div className="row no-gutters text-gray-900 font-12 mb-1 mt-10 font-weight-500">Appointment ScreenShot</div>
                        <div className="row no-gutters gap-10">
                          <div className="col-5">
                            <div className="form-group mb-0">
                              <input
                                disabled={isInputDisabled(`${input.code} ${LOGIN_TYPE.SSAPPOINTMENT}`, input.ssappointmentloginStatus)}
                                name="ssappointmentUsername"
                                value={input.ssappointmentUsername}
                                onChange={(event) => handleFormChange(index, event)}
                                className={
                                  "form-control " +
                                  STATUS_MESSAGE[input.ssappointmentloginStatus].colorBorder
                                }
                                placeholder={usesSystemCredentials(`${input.code} ${LOGIN_TYPE.SSAPPOINTMENT}`) && isInputDisabled(`${input.code} ${LOGIN_TYPE.SSAPPOINTMENT}`, input.ssappointmentloginStatus) ? "System Default" : "Enter login"}
                              />
                            </div>
                          </div>
                          <div className="col-5">
                            <div className="input-wrapper">
                              <input
                                disabled={isInputDisabled(`${input.code} ${LOGIN_TYPE.SSAPPOINTMENT}`, input.ssappointmentloginStatus)}
                                name="ssappointmentPassword"
                                value={input.ssappointmentPassword}
                                onChange={(event) => handleFormChange(index, event)}
                                type={input.showSSAppointmentPass ? "text" : "password"}
                                className={
                                  "form-control " +
                                  STATUS_MESSAGE[input.ssappointmentloginStatus].colorBorder
                                }
                                placeholder={usesSystemCredentials(`${input.code} ${LOGIN_TYPE.SSAPPOINTMENT}`) && isInputDisabled(`${input.code} ${LOGIN_TYPE.SSAPPOINTMENT}`, input.ssappointmentloginStatus) ? "System Default" : "Enter Password"}
                              />
                              <div
                                className="input-icon"
                                onClick={() => togglePasswordVisibility(input.code, LOGIN_TYPE.SSAPPOINTMENT)}
                              >
                                {input.showSSAppointmentPass ? <IconEye /> : <IconEyeClosed />}
                              </div>
                            </div>
                          </div>
                          <div className="col-2" style={{ maxWidth: 80 }}>
                            {selectedLoading?.includes(`${input.code} ${LOGIN_TYPE.SSAPPOINTMENT}`) && (
                              <button onClick={(e) => e.preventDefault()} className="btn bg-blue-500 text-white btn-block"><span className="spinner-border text-white spinner-border-sm" role="status" aria-hidden="true"></span></button>
                            )}
                            {(!selectedLoading?.includes(`${input.code} ${LOGIN_TYPE.SSAPPOINTMENT}`) && isInputDisabled(`${input.code} ${LOGIN_TYPE.SSAPPOINTMENT}`, input.ssappointmentloginStatus)) && (
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  enableInput(input.code, LOGIN_TYPE.SSAPPOINTMENT);
                                }}
                                className="btn bg-white btn-block">
                                Edit
                              </button>
                            )}
                            {((!selectedLoading?.includes(`${input.code} ${LOGIN_TYPE.SSAPPOINTMENT}`) && !isInputDisabled(`${input.code} ${LOGIN_TYPE.SSAPPOINTMENT}`, input.ssappointmentloginStatus))) && (
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  disableInput(input.code, LOGIN_TYPE.SSAPPOINTMENT);
                                  handleUpdate(input.code, LOGIN_TYPE.SSAPPOINTMENT);
                                }}
                                className="btn bg-blue-500 text-white btn-block">
                                Connect
                              </button>
                            )}
                          </div>
                        </div>
                      </>
                    }

                    </div>
                  {/* )}  */}
                </div>
              );
            })
            : 
            <div
            className={
              "row align-items-center " +
              STATUS_MESSAGE["error"].color +
              " py-2 rounded-3 no-gutters mb-1"
            }
          >
                  <div className="col-12">
                      <span >
                        <IconInfoDarkI />
                      </span>
                      <h5 className="font-14 d-inline-block mb-0">
                      No ports added in Customer Profile.
                      </h5>
                    </div>
            </div>
          }
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button
          className=" btn btn-outline-light"
          onClick={handleBackClick}
          disabled={isLoading}
        >
          {/* {modalState === 1 ? "Cancel" : "Back"} */}
          Back
        </button>
        <button
          className="btn btn-primary"
          onClick={handleSaveClick}
          disabled={isLoading}
        >
          {isLoading && <LoaderBar />}
          {/* {modalState === 1 ? "Next" : "Apply & Save"} */}
          Save
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default PortLogin;
