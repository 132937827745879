import { LoaderBar } from 'Components/Common/LoaderBar';
import { useHover } from 'hooks';
import { debounce } from 'lodash';
import moment from 'moment';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PopperTooltip from 'pages/tms/NewDispatcher/DispatcherTable/Components/portals/PopperTooltip';
import React, { memo, useCallback, useMemo, useState } from 'react'
import { DateTimeFormatUtils, getStorage, isCreditMemo } from 'services';
import { getInvoicedDetail, getBillingChargeLists } from '../../actionCreator';
import { getInvoiceDetail } from 'pages/tms/carrier/InvoiceRecivable/actionCreators';
import InvoiceModal from 'pages/tms/InvoiceModal/InvoiceModal';

const HoveredContent = (props) => {
    const { invoicedData, isInvoiceDataLoading, billingRow } = props;
    const isVatEnabled = JSON.parse(getStorage("userBasicSettings"))?.isVAT;
    const isCalculateTax = JSON.parse(getStorage("userBasicSettings"))?.isCalculateTax;
    return (
        <div className="card w-100 z-1 shadow-5 rounded-3 mb-0 overflow-hidden" style={{ Width: "317px" }}>
            {isInvoiceDataLoading && <LoaderBar></LoaderBar>}
            <>
                <div className="p-10">
                    <h5 className="font-14 font-medium">{invoicedData?.invoiceNumber}</h5>
                    <div className="d-flex gap-10 mb-1">
                        <div className="text-muted">Invoice Date</div>
                        <div className="ml-auto font-medium text-dark font-12">{moment(invoicedData?.invoiceDate).format(DateTimeFormatUtils.fullDateFormat())}</div>
                    </div>
                    <div className="d-flex gap-10 mb-1">
                        <div className="text-muted">Invoiced By</div>
                        <div className="ml-auto font-medium text-dark font-12">{invoicedData?.invoiceCreatedBy}</div>
                    </div>
                    <div className="d-flex gap-10 mb-1">
                        <div className="text-muted">Number of Loads Included</div>
                        <div className="ml-auto font-medium text-dark font-12">{invoicedData?.numberOfLoads}</div>
                    </div>
                    <div className="hr-light"></div>
                    <h5 className="font-12 font-medium">This Load - {invoicedData?.currentLoad?.charge_reference_number}</h5>
                    <div className="d-flex gap-10 mb-1">
                        <div className="text-muted">Total Charges</div>
                        <div className="ml-auto font-medium text-dark font-12">{(invoicedData?.currentLoad?.totalAmount ?? 0).toCurrency(billingRow?.billToDetail?.currency)}</div>
                    </div>
                    {((isVatEnabled && billingRow?.taxDetail?.taxType) || isCalculateTax) && <div className="d-flex gap-10 mb-1">
                        <div className="text-muted">Tax</div>
                        <div className="ml-auto font-medium text-dark font-12">{(invoicedData?.currentLoad?.taxAmount ?? 0).toCurrency(billingRow?.billToDetail?.currency)}</div>
                    </div>}
                    <div className="d-flex gap-10 mb-1">
                        <div className="text-muted">Billing Total</div>
                        <div className="ml-auto font-medium text-dark font-12">{(invoicedData?.currentLoad?.totalAmountWithTax ?? 0).toCurrency(billingRow?.billToDetail?.currency)}</div>
                    </div>
                    <div className="hr-light"></div>
                    <h5 className="font-12 font-medium">Other loads on this invoice</h5>
                    <div className="d-flex gap-10 mb-1">
                        <div className="text-muted">Total Charges</div>
                        <div className="ml-auto font-medium text-dark font-12">{(invoicedData?.otherLoad?.totalAmount ?? 0).toCurrency(billingRow?.billToDetail?.currency)}</div>
                    </div>
                    {((isVatEnabled && billingRow?.taxDetail?.taxType) || isCalculateTax) && <div className="d-flex gap-10 mb-1">
                        <div className="text-muted">Total Tax</div>
                        <div className="ml-auto font-medium text-dark font-12">{(invoicedData?.otherLoad?.totalTaxAmount ?? 0).toCurrency(billingRow?.billToDetail?.currency)}</div>
                    </div>}
                    <div className="hr-light"></div>
                    <div className="d-flex gap-10 mb-1">
                        <div className="font-14 font-weight-500 text-dark">Total</div>
                        <div className="fomt-14 ml-auto font-medium text-dark">{(invoicedData?.totalAmount ?? 0).toCurrency(billingRow?.billToDetail?.currency)}</div>
                    </div>
                </div>
                <div className="bg-gray-50 p-10">
                    {isCreditMemo() && <div className="d-flex gap-10">
                        <div className="text-muted mb-1 font-weight-normal font-12">Credit Applied</div>
                        <div className="ml-auto text-dark font-weight-500 font-12">{(invoicedData?.totalAppliedCreditAmount ?? 0).toCurrency(billingRow?.billToDetail?.currency)}</div>
                    </div>}
                    <div className="d-flex gap-10">
                        <div className="text-muted mb-1 font-weight-normal font-12">Payment Received</div>
                        <div className="ml-auto text-dark font-weight-500 font-12">{(invoicedData?.totalPaymentAmount ?? 0).toCurrency(billingRow?.billToDetail?.currency)}</div>
                    </div>
                    <div className="d-flex gap-10">
                        <div className="text-muted mb-1 font-weight-normal font-12">Amount Due</div>
                        <div className="ml-auto text-dark font-weight-500 font-12">{(invoicedData?.totalRemainAmount ?? 0).toCurrency(billingRow?.billToDetail?.currency)}</div>
                    </div>
                </div>
            </>
        </div>
    )
}

const InvoiceHoveredContent = (props) => {
    const { invoiceNumber, chargeId, invoiceId, invoicedData, setInvoicedData, billingRow, isInvoiceModel, setBillingCards } = props
    const [isHovered, setIsHovered] = useState(false);
    const [isInvoiceDataLoading, setIsInvoiceDataLoading] = useState(false);
    const [referenceElementRef, referenceElement] = useHover();
    const [showInvoiceModel, setShowInvoiceModel] = useState(false);
    const [invoiceDetail, setInvoiceDetail] = useState({});
    const [isLoadingBarShow, setIsLoadingBarShow] = useState(false);

    const getInvoiceData = async (invoiceId) => {
        try {
            if(!isInvoiceModel || !invoiceId) return;

            let param = {
                invoiceId: invoiceId
            }
            setIsLoadingBarShow(true);
            const invoiceData = await getInvoiceDetail(param);
            if (Object.keys(invoiceData)?.length) {
                setInvoiceDetail(invoiceData);
                setShowInvoiceModel(true);
            }
            setIsLoadingBarShow(false);
        } catch (error) {
            setIsLoadingBarShow(false);
        }
    }

    const getUpdatedChargeList = ()=>{
        props.actions.getBillingChargeLists({ "loadId": billingRow?.loadId?._id }).then((res) => {
            setBillingCards(res?.data?.data);
        }).catch()
    }
    const closeModel = () => {
    setShowInvoiceModel(false);
    setInvoiceDetail({});
    getUpdatedChargeList();
  }

    // Memoized function for fetching invoice data
    const fetchInvoicedData = useMemo(
        () =>
            debounce((invoiceId, chargeId) => {
                if (!invoicedData) {
                    setIsInvoiceDataLoading(true);
                    getInvoicedDetail({ inVoiceId: invoiceId, chargeId })
                        .then((res) => {
                            setInvoicedData(res?.data);
                            setIsInvoiceDataLoading(false);
                        })
                        .catch(() => {
                            setIsInvoiceDataLoading(false);
                        });
                }

            }, 300), // Debounce for 300 milliseconds
        [invoicedData]
    );

    const handleMouseEnter = useCallback(() => {
        if (invoiceNumber) {
            setIsHovered(true);
            fetchInvoicedData(invoiceId, chargeId);
        } else {
            setIsHovered(false);
        }
    }, [invoiceNumber, fetchInvoicedData])

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return ( 
    <div>        
        <div
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={() => getInvoiceData(invoiceId)}
            className='position-relative'
        >
            <div ref={referenceElementRef} className={`font-medium ${invoiceNumber && "text-primary"}`}>
                {invoiceNumber ? invoiceNumber : "-"}
            </div>

            {isHovered && referenceElement && <PopperTooltip
                isArrow={false}
                isOpen={referenceElement}
                refNo={referenceElementRef?.current}
                name={<HoveredContent isInvoiceDataLoading={isInvoiceDataLoading} invoicedData={invoicedData} billingRow={billingRow} />}
                color={'gray-700'}
                cssClass={"invoice_detail o-1 p-0 w-300"} />}
            <div className='position-absolute' style={{top: "-8px", left: "-25px"}}>
                {isInvoiceModel && isLoadingBarShow &&
                    <span class="spinner-border text-black spinner-border-sm" role="status" aria-hidden="true" />
                }
            </div>
        </div>

        {isInvoiceModel && showInvoiceModel && invoiceNumber &&
            <InvoiceModal
                showModel={showInvoiceModel}
                closeModel={closeModel}
                invoiceDetail={invoiceDetail}
                getInvoiceData={() => getInvoiceData(invoiceId)}
                apisToCall={()=>{}}
            />
        }
    </div>
    )
}

const mapStateToProps = (state) => {
    return {
      ...state,
    };
  };
  
  
  function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators({ getBillingChargeLists }, dispatch)
    };
  }

export default connect(mapStateToProps, mapDispatchToProps)(memo(InvoiceHoveredContent));

