import axios from 'axios';
import _, { isArray } from 'lodash';
import moment  from 'moment';

import { addressFormatEnum, checkIfNumber, defaultAddressFormat, getStorage, isTerminalTrackingEnabled, parsedAddressIntoLocality } from '../../../services/Common.services';
import { HTTP } from '../../../services/Http.service';
import * as ToQuery from '../../../Components/Common/jsonToQueryParams'
import jsonToQueryParams, { objToQueryParams } from '../../../Components/Common/jsonToQueryParams'
import { LOCATION_PRICE } from '../constant';
import configuration from '../../../config';
import config from '../../../config';
import { ALL_DISPATCHER_STATUS } from './utility';
import { lorem } from "../../../temp.data";
import { convertResultToSelectWithStatus } from '../Load/DriverOrderUtility';


export function getCustomers(params, limit, customerType, isWithOutAddress) {
  let obj = {}
  if(limit && typeof(limit)==='number'){
    if(params){
      obj = { ...params, limit: limit} 
    }else{
      obj = {limit: limit}
    }
  } else {
    obj = { nameSearch: params, limit: 10}
  }
  if (customerType) {
  obj.customerType=customerType
}
  let url = 'carrier/getTMSCustomers';
  url = obj ? url + "?" + ToQuery.objToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        let allDatas = result.data.data;
        if(limit && typeof(limit)==='number'){
            resolve(allDatas);
        }
        let customers = []
        allDatas.forEach((obj) => {
          let labelData = [];
          const defaultAddressFormat = JSON.parse(getStorage("userAddressFormat") ?? null)?.userAddressFormat ?? addressFormatEnum.US;
          if (isWithOutAddress) {
            customers.push({ value: obj._id, label: `${obj.company_name}`, allInfos: obj });
          } else if (defaultAddressFormat !== addressFormatEnum.US) {
            euAddressFormate(obj, labelData)
            customers.push({ value: obj._id, label: `${obj.company_name}, ${labelData.join(', ')}`, allInfos: obj });
          } else {
          customers.push({ value: obj._id, label: `${obj.company_name}, ${obj.address ? parsedAddressIntoLocality(obj.address) : ''}`, allInfos: obj });
          }
        });
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getSubCustomer(params) {
  if (!params) {
    return
  }

  const obj = { ...params } 

  let url = 'carrier/getTMSSubCustomer';
  return new Promise((resolve, reject) => {
    HTTP('post', url, obj, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        let allDatas = result.data.data;
        
        resolve(allDatas);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getDroppedCustomers(params) {
  const obj = { searchTerm: params }
  let url = 'carrier/getTMSCustomers';
  url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const customers = []
        result.data.data.forEach((obj) => {
          let labelData = [];
          const defaultAddressFormat = JSON.parse(getStorage("userAddressFormat") ?? null)?.userAddressFormat ?? addressFormatEnum.US;
          if (defaultAddressFormat !== addressFormatEnum.US) {
            euAddressFormate(obj, labelData)
            customers.push({ value: obj._id, label: `${obj.company_name}, ${labelData.join(', ')}`, allInfos: obj });
          } else {
          customers.push({ value: obj._id, label: `${obj.company_name}, ${obj.address ? obj.address.address : ''}`, allInfos: obj });
          }
        })
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export function getDefaultAddress(params) {
  const obj = params;
  let url = 'carrier/getTMSCustomers';
  url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data[0]);
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export function getCallers(params, isLoadCreation = false) {    
  let obj = { limit: 10, customerType:JSON.stringify(['caller', 'ALL'])} 
  const isCreation = typeof isLoadCreation === 'boolean' && !!isLoadCreation
  
  if (isCreation) {
    obj = { ...params }; 
  } else if (params) {
    obj.nameSearch = params; 
  }

  let url = 'carrier/getTMSCustomers';
  url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const customers = []
        const fetchCallers = result?.data?.data;
        fetchCallers?.forEach((obj) => {
            let labelData = [];
            const defaultAddressFormat = JSON.parse(getStorage("userAddressFormat") ?? null)?.userAddressFormat ?? addressFormatEnum.US;
            if (defaultAddressFormat !== addressFormatEnum.US) {
              euAddressFormate(obj, labelData)
              customers.push({ value: obj._id, label: `${obj.company_name}, ${labelData.join(', ')}`, allInfos: obj });
            } else {
            if (obj.company_name) {
              labelData.push(obj.company_name)
            }
            if (obj.address && obj.address.address) {
              let street = obj.address.address.split(',');
              if (street && street.length > 0) {
                labelData.push(street[0]);
              }
            }
            if (obj.city) {
              labelData.push(obj.city)
            }
            if (obj.state) {
              labelData.push(obj.state)
            }
            if (obj.zip_code) {
              labelData.push(obj.zip_code)
            }
            customers.push({ value: obj._id, label: `${labelData.join(', ')}`, allInfos: obj });
            }
          })
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getEmployeeCallers(params) {
  const obj = { limit: 10 }
  if (params)
    obj.nameSearch = params
  obj.isFleetCustomer = true
  let url = 'carrier/getTMSCustomers';
  url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const customers = []
        result?.data?.data.filter((customer) => { return customer.customerType && (customer.customerType.indexOf('ALL') > -1 || customer.customerType.indexOf('caller') > -1) })
          .forEach((obj) => {
            let labelData = [];
            const defaultAddressFormat = JSON.parse(getStorage("userAddressFormat") ?? null)?.userAddressFormat ?? addressFormatEnum.US;
            if (defaultAddressFormat !== addressFormatEnum.US) {
              euAddressFormate(obj, labelData)
              customers.push({ value: obj._id, label: `${obj.company_name}, ${labelData.join(', ')}`, allInfos: obj });
            } else {
            if (obj.company_name) {
              labelData.push(obj.company_name)
            }
            if (obj.address && obj.address.address) {
              let street = obj.address.address.split(',');
              if (street && street.length > 0) {
                labelData.push(street[0]);
              }
            }
            if (obj.city) {
              labelData.push(obj.city)
            }
            if (obj.state) {
              labelData.push(obj.state)
            }
            if (obj.zip_code) {
              labelData.push(obj.zip_code)
            }
            customers.push({ value: obj._id, label: `${labelData.join(', ')}`, allInfos: obj });
            }
          })
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getShippers(params, isCustomerLogin = false) {
  const obj = { limit: 10 }
  obj.isCustomerLogin = isCustomerLogin === true ? true : false
  if (params)
    obj.nameSearch = params
  let url = 'carrier/getTMSCustomers';
  url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const customers = []
        result.data.data.filter((customer) => { return customer.customerType && (customer.customerType.indexOf('ALL') > -1 || customer.customerType.indexOf('shipper') > -1) })
          .forEach((obj) => {
            let labelData = [];
            const userAddressFormat = defaultAddressFormat();
            if (userAddressFormat !== addressFormatEnum.US) {
              euAddressFormate(obj, labelData)
              customers.push({ value: obj._id, label: `${obj.company_name}, ${labelData.join(', ')}`, allInfos: obj });
            } else {
            if (obj.company_name) {
              labelData.push(obj.company_name)
            }
            if (obj.address && obj.address.address) {
              let street = obj.address.address.split(',');
              if (street && street.length > 0) {
                labelData.push(street[0]);
              }
            }
            if (obj.city) {
              labelData.push(obj.city)
            }
            if (obj.state) {
              labelData.push(obj.state)
            }
            if (obj.zip_code) {
              labelData.push(obj.zip_code)
            }
            customers.push({ value: obj._id, label: `${labelData.join(', ')}`, allInfos: obj });
            }
          })
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getConsignees(params) {
  const obj = { limit: 10 }
  if (params)
    obj.nameSearch = params
  let url = 'carrier/getTMSCustomers';
  url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const customers = []
        result.data.data.filter((customer) => { return customer.customerType && (customer.customerType.indexOf('ALL') > -1 || customer.customerType.indexOf('consignee') > -1) })
          .forEach((obj) => {
            let labelData = [];
            const defaultAddressFormat = JSON.parse(getStorage("userAddressFormat") ?? null)?.userAddressFormat ?? addressFormatEnum.US;
            if (defaultAddressFormat !== addressFormatEnum.US) {
              euAddressFormate(obj, labelData)
              customers.push({ value: obj._id, label: `${obj.company_name}, ${labelData.join(', ')}`, allInfos: obj });
            } else {
            if (obj.company_name) {
              labelData.push(obj.company_name)
            }
            if (obj.address && obj.address.address) {
              let street = obj.address.address.split(',');
              if (street && street.length > 0) {
                labelData.push(street[0]);
              }
            }
            if (obj.city) {
              labelData.push(obj.city)
            }
            if (obj.state) {
              labelData.push(obj.state)
            }
            if (obj.zip_code) {
              labelData.push(obj.zip_code)
            }
            customers.push({ value: obj._id, label: `${labelData.join(', ')}`, allInfos: obj });
            }
          })
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export async function getShippersWithStatus(params, isCustomerLogin = false) {
  try {
    const r = await getShippers(params, isCustomerLogin);
    return convertResultToSelectWithStatus(r);
  } catch (error) {
    console.error(error);
  }
}

export async function getConsigneesWithStatus(params) {
  try {
    const r = await getConsignees(params);
    return convertResultToSelectWithStatus(r);
  } catch (error) {
    console.error(error);
  }
}


export function getCustomerCityOptionsDispatcher(params, limit) {
  let obj = { searchTerm: params, limit: limit ? limit : 5 };

  let url = 'carrier/getCustomerCityOptions';
  url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        let allDatas = result.data.data;
        const customers = [];
        allDatas.forEach((obj) => { 
          let labelData = [];
            const defaultAddressFormat = JSON.parse(getStorage("userAddressFormat") ?? null)?.userAddressFormat ?? addressFormatEnum.US;
            if (defaultAddressFormat !== addressFormatEnum.US) {
              euAddressFormate(obj, labelData)
              customers.push({ value: obj._id, label: `${obj.name}${labelData.length>0 ? `, ${labelData.join(', ')}` : ''}`, allInfos: obj });
            } else {
            if (obj.name) {
              labelData.push(obj.name)
            }
            if (obj.address && obj.address.address) {
              let street = obj.address.address.split(',');
              if (street && street.length > 0) {
                labelData.push(street[0]);
              }
            }
            if (obj.city) {
              labelData.push(obj.city)
            }
            if (obj.state) {
              labelData.push(obj.state)
            }
            if (obj.zip_code) {
              labelData.push(obj.zip_code)
            }
            customers.push({ value: obj._id, label: `${labelData.length>0 ? labelData.join(', ') : ''}`, allInfos: obj });
            }
        })
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getChassisPickCustomers(params) {
  const obj = { limit: 10 }
  if (params) {
    obj.nameSearch = params
    obj.customerType = ['ALL', 'chassisPick']
  }

  let url = 'carrier/getTMSCustomers';
  // url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  url = obj ? url + "?" + ToQuery.objToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const customers = []
        result.data.data
          .forEach((obj) => {
            let labelData = [];
            const defaultAddressFormat = JSON.parse(getStorage("userAddressFormat") ?? null)?.userAddressFormat ?? addressFormatEnum.US;
            if (defaultAddressFormat !== addressFormatEnum.US) {
              euAddressFormate(obj, labelData)
              customers.push({ value: obj._id, label: `${obj.company_name} | ${labelData.join(', ')}`, allInfos: obj });
            } else {
            customers.push({ value: obj._id, label: `${obj.company_name} | ${obj.address ? obj.address.address : ''}`, allInfos: obj });
            }
          })
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export function getTMScaller(params, _id) {
  const obj = { limit: 10 }
  if (params) {
    obj.nameSearch = params
    obj.customerType = ['ALL', 'caller']
  }
  if (_id && _id !== "undefined"  && typeof _id !== 'function') {
    obj._id = _id;
    obj.limit = 1;
  }

  let url = 'carrier/getTMSCustomers';
  // url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  url = obj ? url + "?" + ToQuery.objToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const customers = []
        result.data.data
          .forEach((obj) => {
            customers.push({ value: obj._id, label: obj.company_name, allInfos: obj });
          })
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}


export function getTMScallerFormatted(params, _id) {
  const obj = { limit: 10 }
  if (params) {
    obj.nameSearch = params
    obj.customerType = ['ALL', 'caller']
  }
  if (_id && _id !== "undefined"  && typeof _id !== 'function') {
    obj._id = _id;
    obj.limit = 1;
  }

  let url = 'carrier/getTMSCustomers';
  // url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  url = obj ? url + "?" + ToQuery.objToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const customers = []
        result.data.data
          .forEach((obj) => {
            const rawAddress ={
              address1: obj?.address1,
              zip_code:  obj?.zipCode,
              city: obj?.city,
              country: obj?.country,
              address: obj?.address?.address,
            }
            const formattedAddress = parsedAddressIntoLocality(rawAddress)
            customers.push({ value: obj._id, label:`${obj.company_name}  | ${formattedAddress}`, allInfos: obj });
          })
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getTMSshipper(params, isLoadCreation = false) {
  let obj = { limit: 10 }

  if (isLoadCreation) {
    obj = { ...params }; 
  } else if (params) {
    obj.nameSearch = params; 
    obj.customerType = ['ALL', 'shipper']
  }

  let url = 'carrier/getTMSCustomers';
  // url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  url = obj ? url + "?" + ToQuery.objToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const customers = []
        result.data.data
          .forEach((obj) => {
            let labelData = [];
            const defaultAddressFormat = JSON.parse(getStorage("userAddressFormat") ?? null)?.userAddressFormat ?? addressFormatEnum.US;
            if (defaultAddressFormat !== addressFormatEnum.US) {
              euAddressFormate(obj, labelData)
              customers.push({ value: obj._id, label: `${obj.company_name} | ${labelData.join(', ')}`, allInfos: obj });
            } else {
            customers.push({ value: obj._id, label: `${obj.company_name} | ${obj.address ? obj.address.address : ''}`, allInfos: obj });
            }
          })
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export function getTMSconsignee(params,isLoadCreation = false) {
  let obj = { limit: 10 }

  if (isLoadCreation) {
    obj = { ...params }; 
  } else if (params) {
    obj.nameSearch = params; 
    obj.customerType = ['ALL', 'consignee']
  }

  let url = 'carrier/getTMSCustomers';
  // url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  url = obj ? url + "?" + ToQuery.objToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const customers = []
        result.data.data
          .forEach((obj) => {
            let labelData = [];
            const defaultAddressFormat = JSON.parse(getStorage("userAddressFormat") ?? null)?.userAddressFormat ?? addressFormatEnum.US;
            if (defaultAddressFormat !== addressFormatEnum.US) {
              euAddressFormate(obj, labelData)
              customers.push({ value: obj._id, label: `${obj.company_name} | ${labelData.join(', ')}`, allInfos: obj });
            } else {
            customers.push({ value: obj._id, label: `${obj.company_name} | ${obj.address ? obj.address.address : ''}`, allInfos: obj });
            }
          })
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export function getEmptyOriginCustomers(params, isLoadCreation = false) {
  let obj = { limit: 10 }
 
  if (isLoadCreation) {
    obj = { ...params }; 
  } else if (params) {
    obj.nameSearch = params; 
    obj.customerType = ['ALL', 'containerReturn','shipper']
  }

  let url = 'carrier/getTMSCustomers';
  // url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  url = obj ? url + "?" + ToQuery.objToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const customers = []
        result.data.data
          .forEach((obj) => {
            let labelData = [];
            const defaultAddressFormat = JSON.parse(getStorage("userAddressFormat") ?? null)?.userAddressFormat ?? addressFormatEnum.US;
            if (defaultAddressFormat !== addressFormatEnum.US) {
              euAddressFormate(obj, labelData)
              customers.push({ value: obj._id, label: `${obj.company_name} | ${labelData.join(', ')}`, allInfos: obj });
            } else {
            customers.push({ value: obj._id, label: `${obj.company_name} | ${obj.address ? obj.address.address : ''}`, allInfos: obj });
            }
          })
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export function getChassisTerminationCustomers(params) {
  const obj = { limit: 10 }
  if (params) {
    obj.nameSearch = params
    obj.customerType = ['ALL', 'chassisTermination']
  }

  let url = 'carrier/getTMSCustomers';
  // url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  url = obj ? url + "?" + ToQuery.objToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const customers = []
        result.data.data
          .forEach((obj) => {
            let labelData = [];
            const defaultAddressFormat = JSON.parse(getStorage("userAddressFormat") ?? null)?.userAddressFormat ?? addressFormatEnum.US;
            if (defaultAddressFormat !== addressFormatEnum.US) {
              euAddressFormate(obj, labelData)
              customers.push({ value: obj._id, label: `${obj.company_name} | ${labelData.join(', ')}`, allInfos: obj });
            } else {
            customers.push({ value: obj._id, label: `${obj.company_name} | ${obj.address ? obj.address.address : ''}`, allInfos: obj });
            }
          })
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export function getTerminal(params) {
  let obj;
  if (params) {
    obj = { searchTerm: params }
  }
  let url = 'getTerminal';
  url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        let datas = []
        if (result.data.data.length > 0) {
          result.data.data.forEach((obj) => {
            datas.push({ value: obj._id, label: obj.name, allInfos: obj });
          })
        }
        if (getStorage('currentUserRole') == 'fleetmanager' && (configuration.idForTerminal.indexOf(JSON.parse(getStorage('loggedInUser')).fleetManager.carrier) != -1)) {
          let fleetTerminal = JSON.parse(getStorage('loggedInUser')).fleetManager.terminals;
          datas = datas.filter((D) => fleetTerminal.indexOf(D.value) > -1);
        }
        resolve(datas);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getTypeOfLoads(params) {
  return new Promise((resolve, reject) => {
    const types = [
      { value: 'IMPORT', label: 'IMPORT' },
      { value: 'EXPORT', label: 'EXPORT' },
      { value: 'ROAD', label: 'ROAD' },
      { value: 'BILL_ONLY', label: 'BILL_ONLY' }
    ]
    resolve(types);
  })
}

export function getTypeOfLoadsForUnassignedLoads(params) {
  return new Promise((resolve, reject) => {
    const types = [
      { value: 'IMPORT', label: 'IMPORT' },
      { value: 'EXPORT', label: 'EXPORT' },
      { value: 'ROAD', label: 'ROAD' }
    ]

    resolve(types);
  })
}

export function getDirectStatuses(params) {
  return new Promise((resolve, reject) => {
    const types = [
      { value: 'PENDING', label: 'PENDING' },
      { value: 'AVAILABLE', label: 'AVAILABLE' },
      { value: 'DEPARTED', label: 'DEPARTED' },
    ]
    resolve(types);
  })
}

export function getCountryCode() {
  let url = 'tms/getCountryCode';
    return new Promise((resolve, reject) => {
    HTTP('GET', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getFiterLoads(params) {
  const obj = { searchTerm: params, limit: 10, skip: 0 }
  let url = 'tms/getLoads';
  // url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('POST', url, obj, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const loads = []
        result.data.data.forEach((obj) => {
          loads.push({ value: obj.reference_number, label: obj.reference_number, allInfos: obj });
        })
        resolve(loads);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getAllDriver(params) {
  let url = 'carrier/getAllDriver';
  url = params ? url + "?" + jsonToQueryParams(params) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function getAllDriverFilter(params) {
  const obj = {}
  if (params !== "")
    obj.name = params
  let url = 'carrier/getAllDriver';
  url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const drivers = []
        result.data.data.data.forEach((obj) => {
          drivers.push({ value: obj._id, label: obj.name + ' ' + obj.lastName });
        })
        resolve(drivers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getAllStatusFilter(params) {
  let url = 'tms/allLoadStatus';
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const statuses = []
        result.data.data.forEach((obj) => {
          statuses.push({ value: obj, label: obj });
        })
        resolve(statuses);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function allTrucks(params) {
  let url = 'tms/allTrucks';
  url = params ? url + "?" + ToQuery(params) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function getLoads(params) {
  let url = 'tms/getLoads';
  // url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function getLoadsV2(params) {
  let url = 'tms/getLoads';
  // url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  
}

export function getStreetTurnLoadCounts(params) {
  let url = 'tms/getStreetTurnLoadCounts';
  return new Promise((resolve, reject) => {
    HTTP('post', url, params, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getTMSLoadsByDates(params) {
  let url = 'tms/getTMSLoadsByDates';
  url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function getLoadDetail(reference_number) {
  let url = 'tms/getLoadDetail?reference_number=' + encodeURIComponent(reference_number);
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function getNewLoadDetail(reference_number) {
  let url = 'tms/getLoadDetail?reference_number=' + encodeURIComponent(reference_number);
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
}

export function funGetLoadDetail(reference_number) {
  let url = 'tms/getLoadDetail?reference_number=' + encodeURIComponent(reference_number);
  return new Promise((resolve, reject) => {
    HTTP('GET', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function allLoadStatus() {
  let url = 'tms/allLoadStatus';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null,{
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function getTMSChassis(params) {
  let url = 'carrier/getTMSChassis';
  url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function assignLoadToDriver(params) {
  let url = 'tms/assignLoadToDriver';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function assignLoadToEquipment(params) {
  let url = 'tms/assignLoadToEquipment';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function updateTMSLoadStatus(params) {
  let url = 'tms/updateTMSLoadStatus';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function unCompleteStatus(params) {
  let url = 'tms/unCompleteStatus';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function changeLoadStatus(params) {
  let url = 'tms/changeLoadStatus';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function unAssignLoadToEquipment(params) {
  let url = 'tms/unAssignLoadToEquipment';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function editLoad(params) {
  let url = 'tms/editLoad';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function fetchLocationProfiles(url, params) {
  url = params ? url + "?" + Object.entries(params).map(e => e.join('=')).join('&') : url;

  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('get', url, {}, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function updateStatusOrder(params) {
  let url = 'tms/updateStatusOrder';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function updateStopOffType(params) {
  let url = 'routing/update-stop-off-type';
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result?.data?.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
export function getLocationName(lat, lng) {
  let url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=pk.eyJ1IjoiaWFuamVubmluZ3MiLCJhIjoiZExwb0p5WSJ9.XLi48h-NOyJOCJuu1-h-Jg`;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      const query = {
        method: 'get',
        url: url
      }
      axios(query).then(function (response) {
        let address;
        let lat;
        let lng;

        if (response && response.data && response.data.features) {
          response.data.features.forEach((feature) => {
            if (feature.id.indexOf('place') === 0) {
              address = feature.place_name;
              lat = feature.center[1];
              lng = feature.center[0];
            }
          });
          resolve(address);
        } else {
          reject(response);
        }
      })
        .catch((error) => {
          reject(error)
        })

    })
  }
}

export function updateReverseAddress(params) {
  let url = 'driver/updateReverseAddress';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function hardRemoveDriver(params) {
  let url = 'tms/hardRemoveDriver';
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
}
export function makeLoadStartabelToDriver(params) {
  let url = 'tms/makeLoadStartabelToDriver';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function makeLoadReadyForPickup(params) {
  let url = 'tms/makeLoadReadyForPickup';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function makeLoadConfirm(params) {
  let url = 'tms/makeLoadConfirm';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}
export function getPricingForLocation(params) {
  let url = 'tms/driver-payment';
  url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('get', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          dispatch({
            type: LOCATION_PRICE,
            payload: result.data.data

          })
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function auditByLoad(params) {
  let url = 'tms/auditByLoad';
  url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('get', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function getTimescaleAuditByLoad(params) {
  let url = 'tms/v2/auditByLoad';
  url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('get', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function changeStatusCompleteDate(params) {
  let url = 'tms/changeStatusCompleteDate';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function resetLoad(params) {
  let url = 'tms/resetLoad';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function addBobTail(params) {
  let url = 'tms/addBobTail';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function updateBobTail(params) {
  let url = 'tms/updateBobTail';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function assignBobTailToLoad(params) {
  let url = 'tms/assignBobTailToLoad';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function changeBobTailStatus(params) {
  let url = 'tms/changeBobTailStatus';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function removeBobTail(params) {
  let url = 'tms/removeBobTail';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function assignStreetTurn(params) {
  let url = 'tms/assignStreetTurn';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function assignDomesticMove(params) {
  let url = 'routing/assign-domestic-move';
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          if (result)
            resolve(result)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function getContainerSize(params = '') {
  const obj = { searchTerm: params, limit: 10 }
  let url = 'admin/getContainerSize';
  url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const containerSize = []
        result.data.data.forEach((obj) => {
          containerSize.push({ value: obj._id, label: obj.name, allInfos: obj });
        })
        resolve(containerSize);
      })
      .catch((error) => {
        reject(error);
      });
  })
}


export function getCommodityProfile() {

  const url = "tms/getCommodityProfile";
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      authorization: getStorage("token")
    }).then(result => {
      resolve(result)
    })
      .catch(error => {
        reject(error)
      })

  })
}


export function getContainerType(params) {
  const obj = { searchTerm: params, limit: 10 }
  let url = 'admin/getContainerType';
  url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const containerType = []
        result.data.data.forEach((obj) => {
          containerType.push({ value: obj._id, label: obj.name, allInfos: obj });
        })
        resolve(containerType);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getISOEquipmentType(params) {
  const obj = { limit: 10 }
  if(params){
    obj.searchTerm = params
  }
  let url = 'iso-equipment-type';
  url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const containerType = []
        result.data.data.forEach((obj) => {
          containerType.push({ value: obj._id, label: obj.name, allInfos: obj });
        })
        resolve(containerType);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getContainerOwner(params) {
  const obj = { searchTerm: params, limit: 10 }
  let url = 'carrier/getTMSContainerOwner';
  url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const containerOwner = []
        result.data.data.forEach((obj) => {
          containerOwner.push({ value: obj._id, label: obj.company_name, allInfos: obj });
        })
        resolve(containerOwner);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getDrivers(searchTerm) {
  let url = 'carrier/getAllDriver';
  let data = {
    searchTerm,
    limit: 10
  }

  url = data ? url + "?" + jsonToQueryParams(data) : url;
  return new Promise((resolve, reject) => {
    HTTP('GET', url, data, {
      'authorization': getStorage('token'),
    })
      .then((result) => {

        if (result.data && result.data && result.data.data && result.data.data.data) {
          const { data } = result.data.data;
          const allDrivers = data.map(d => ({ value: d._id, label: `${d.name} ${d.lastName || ''}`.trim(), allInfo: d }))
          resolve(allDrivers)
        }
        reject();
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getDriverItinerary(params) {
  let url = 'tms/getDriverItinerary';
  url = params ? url + "?" + objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP('GET', url, {}, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const data = result.data.data;
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export function getDriverItineraryNew(params) {
  let url = 'tms/driver-itinerary/paginate';
  return new Promise((resolve, reject) => {
    HTTP('post', url, params, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const data = result.data.data;
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getTMScustomerForMap(params, cType = 'caller', isPdfMap = false,mappingId = null) {
  const obj = { limit: 10 }
  if(JSON.parse(getStorage("userBasicSettings"))?.isNewTenderMapping && mappingId) {
    obj._id = mappingId;
  } else {
  if (params) {
    obj.nameSearch = params
  }
  if (cType) {
    obj.customerType = ['ALL', ...(isArray(cType) ? cType : [cType])]
  }
  if (isPdfMap) {
    obj.fieldName = 'pdfMap';
    obj.limit = 10;
  }
}

  let url = 'carrier/getTMSCustomers';
  // url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  url = obj ? url + "?" + ToQuery.objToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const customers = []
        result.data.data
          .forEach((obj) => {
            customers.push({ 
              ...(isTerminalTrackingEnabled() ? obj : {}),
              value: obj._id, 
              label: `${obj.company_name}, ${obj.address ? obj.address.address : ''}`, 
              pdfMap: obj.pdfMap 
            });
          })
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}


export function getCSVLoads(params) {
  let url = 'tms/getTMSLoadsByAppointment';
  // url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  // url = params ? url + "?" + jsonToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP('post', url, params, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function addMapping(data) {
  const url = 'v1/mapping/add';

  return new Promise((resolve, reject) => {
    HTTP('post', url, data, {
      'authorization': getStorage('token'),
    })
      .then(result => {
        resolve(result.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function getMapping(params) {
  let url = 'v1/mapping/get-mapping';
  url = params ? url + "?" + jsonToQueryParams(params) : url;

  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then(result => {
        resolve(result?.data?.data);
      })
      .catch(error => {
        reject(error);
      });
  });
}

export function getAllFleetManagers(params) {
  let url = 'carrier/getFleetManagers';
  url = params ? url + "?" + jsonToQueryParams(params) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('GET', url, null, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}


export function handleColumnMove(tableColumns) {
  let tbColumn=JSON.stringify(tableColumns)
  // setStorage('tbColumn', tbColumn);
  let url = 'user/updateUserDispatcherSorting';
  let params ={
    dispatcherColumnSorting:tbColumn
  }
  return new Promise((resolve, reject) => {
    HTTP("post", url, params, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function getUserColumn() {
  let url = 'user/getUserDispatcherSorting';
  return new Promise((resolve, reject) => {
    HTTP("get", url, null, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}


export function getLoadByReference(reference_number) {
  let url = 'tms/getLoadDetail?reference_number=' + encodeURIComponent(reference_number);
  return new Promise((resolve, reject) => {
    HTTP("get", url, null, {
      'authorization': getStorage('token'),
    })
    .then((result) => {
      if (result) resolve(result.data);
    })
    .catch((error) => {
      reject(error);
    });
  })
}



export function getConsigneeListByCaller(params) {
  let url = 'tms/getConsigneeListByCaller';
  url = params ? url + "?" + jsonToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        let allDatas = result.data.data;
        const options = []
        allDatas
          .forEach((obj) => {
            options.push({ value: obj._id, label: obj.company_name });
          })
        resolve(options);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

// get Loads with lfd and Cuttoff 
export function getCarrierProblemsInfo(params) {
  const obj ={
    skip: params.skip,
    limit: params.limit,
    status: params.status
  };
  if(params.searchTerm) {
    obj.searchTerm = params.searchTerm
  }
  if(params?.assignedCSRId) obj.assignedCSRId = params?.assignedCSRId;
  if(params?.terminalId) obj.terminalId = params?.terminalId;

  let url = params.type==='lfd' ?'carrier/getCarrierLFDInfo':'carrier/getCarrierReturnContainerInfo';
  return new Promise((resolve, reject) => {
    HTTP('post', url, obj, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export function getUnMatchedContainersLoads(params) {
  const obj ={
    skip: params.skip,
    limit: params.limit,
    status: params.status
  };
  if(params.searchTerm) {
    obj.searchTerm = params.searchTerm
  }
  if(params?.assignedCSRId) obj.assignedCSRId = params?.assignedCSRId;
  if(params?.terminalId) obj.terminalId = params?.terminalId;

  let url = 'carrier/getUnMatchedContainers';
  return new Promise((resolve, reject) => {
    HTTP('post', url, obj, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

// get Loads with Empties Problems 
export function getEmptiesProblemLoads(params) {
  let userSelectedPortMarket = JSON.parse(getStorage("carrierDetail"))?.carrier?.portMarket;
  if (typeof userSelectedPortMarket === "string") {
    userSelectedPortMarket = [userSelectedPortMarket];
  }
  if (Array.isArray(userSelectedPortMarket) && userSelectedPortMarket?.length > 0){
    const obj ={
      portMarket : userSelectedPortMarket,
      skip: params.skip,
      limit: params.limit,
    };
    if(params.searchTerm) {
      obj.searchTerm = params.searchTerm
    }
    if(params?.assignedCSRId) obj.assignedCSRId = params?.assignedCSRId;
    if(params?.terminalId) obj.terminalId = params?.terminalId;
    let url = 'myapp/getProblemContainers';
    return new Promise((resolve, reject) => {
      HTTP('post', url, obj, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

// Get Loads(problem) Counts
export function getContainerInformationCount() {
  const params = {
    "status": ALL_DISPATCHER_STATUS,
    "isCount":true,
    // "limit":0,
    // "skip":0  
  }
  let url = 'tms/getContainerInformationCount';
  return new Promise((resolve, reject) => {
    HTTP("post", url, params, {
      'authorization': getStorage('token'),
    })
    .then((result) => {
      if (result) resolve(result.data);
    })
    .catch((error) => {
      reject(error);
    });
  })
}

export function updateUserItinerarySorting(params) {
  let url = 'user/updateUserItinerarySorting';
  return new Promise((resolve, reject) => {
    HTTP("post", url, {itineraryColumnSorting:params}, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getUserItinerarySorting(params) {
  let url = 'user/getUserItinerarySorting';
  return new Promise((resolve, reject) => {
    HTTP("get", url, null, {
      authorization: getStorage("token"),
    })
      .then((result) => {
        if (result) resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function getBobtail(params) {
  let url = 'tms/getBobTail';
  url = params ? url + "?" + objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, {}, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const data = result.data.data;
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getDesyncedQbLoadsCount(params) {
  let url = "tms/getDesyncedQbLoads";
  return new Promise((resolve, reject) => {
    HTTP('post', url, params, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}
export function getProblemInvoiceCount() {
  let url = "v1/problem-invoice/count";
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export const getTimeSlots = (params) => (dispatch) => {
  let url = `tms/apptSlots`;
  url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;

  return new Promise((resolve, reject) => {
    dispatch({
      type: "GET_TIMESLOTS_START"
    });
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((response) => {
        dispatch({
          type: "GET_TIMESLOTS_SUCCESS",
          payload: response.data
        })
        resolve("success")
      })
      .catch((error) => {
        dispatch({
          type: "GET_TIMESLOTS_FAILED"
        })
        console.log(error, "error")
        reject(error);
      });
  })
}

export const bookPickupAppointment = (payload) => (dispatch) => {
  let appointmentType = "bookAppointment";
  let url = `tms/${appointmentType}`;

  return new Promise((resolve, reject) => {
    dispatch({
      type: "BOOK_APPOINTMENT_START"
    });
    HTTP('post', url, payload, {
      'authorization': getStorage('token'),
    })
      .then((response) => {
        dispatch({
          type: "BOOK_APPOINTMENT_SUCCESS",
          payload: response.data
        })
        resolve("success")
      })
      .catch((error) => {
        dispatch({
          type: "BOOK_APPOINTMENT_FAILED"
        });
        console.log(error, "error")
        reject(error);
      });
  })
}

export const getApptSlots = (params) => (dispatch) => {
  let url = `tms/totalApptSlots`;
  url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return new Promise((resolve, reject) => {
    dispatch({
      type: "GET_TOTAL_APPT_SLOTS_START"
    });
    HTTP('get', url, null, {
      'authorization': getStorage('token')
    })
      .then((response) => {
        dispatch({
          type: "GET_TOTAL_APPT_SLOTS_SUCCESS",
          payload: response.data
        })
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getAppSettings = ()  => (dispatch) =>  {
  let url = `myapp/getSetting`;
  return new Promise((resolve, reject) => {
    HTTP('post', url, null, {
      'authorization': getStorage('token'),
    })
      .then((response) => {
        let payload= response && response.data && response.data.data && response.data.data.message && response.data.data.message.myappFeature?response.data.data.message.myappFeature:[];
        dispatch({
          type: "GET_APPSETTINGS_SUCCESS",
          payload: payload
        })
        resolve(response)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getAllCustomers(params) {
  let obj = {}
  if (params) {
    obj = { _id: params }
  }
  let url = 'carrier/getTMSCustomers';
  url = obj ? url + "?" + ToQuery.objToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        let allDatas = result.data.data;
        let customers = []
        allDatas.forEach((obj) => {
          customers.push({ value: obj._id, label: `${obj.company_name}, ${obj.address ? obj.address.address : ''}`, allInfos: obj });
        });
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}



export function getDoubleMoveLoadCardCount(params) {
  let url = 'tms/getDoubleMoveLoadCardCount';
  // url = params ? url + "?" + ToQuery.objToQueryParams(params) : url;
  return function (dispatch) {
    return new Promise((resolve, reject) => {
      HTTP('post', url, params, {
        'authorization': getStorage('token'),
      })
        .then((result) => {
          resolve(result.data)
        })
        .catch((error) => {
          reject(error);
        });
    })
  }
}

export function getTaxCodes(params) {
  let url = 'tax/get-tax-codes';
  let limit = 10
  if(params?.limit)
    limit = params.limit
  url += `?limit=${limit}`
  if(params?.nameSearch)
    url += `&nameSearch=${params?.nameSearch}`
  if (params?.accountID)
    url += `&accountID=${params?.accountID}`
  return new Promise((resolve, reject) => {
    HTTP('get', url, {}, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result.data?.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}


export const euAddressFormate = (obj, labelData) => {
  if (obj.address && obj.address.address) {
    if (/\d/.test(obj?.address1) && isNaN(obj?.address1?.at(-1))) {
      const streetNumber = obj?.address1?.trim()?.split(" ")?.[0];
      const streetName = obj?.address1?.trim()?.split(streetNumber)?.[1];
      if (streetName) labelData.push(streetName);
      if (streetNumber) labelData.push(streetNumber);
    } else {
      const splittedStreet = obj?.address1?.split(" ")
      if (checkIfNumber(splittedStreet?.[0])) {
        const streetNumber = splittedStreet?.[0];
        const streetName = obj?.address1?.trim()?.split(splittedStreet?.[0])?.[1];
       if (streetName) labelData.push(streetName);
       if (streetNumber) labelData.push(streetNumber);
      } 
    }
  }
  if (obj.zip_code) {
    labelData.push(obj.zip_code)
  }
  if (obj.city) {
    labelData.push(obj.city)
  }
  if (obj.country) {
    labelData.push(obj.country)
  }
  return labelData
}

export function generateSummaryPdf(payload,timeZone) {
  let url = `${config.newDocUrl}report/getSummary?approvedDateFrom=${moment(payload.approvedDateFrom).toISOString()}&approvedDateTo=${moment(payload.approvedDateTo).toISOString()}&timeZone=${timeZone}`
  console.log(url)
  return new Promise((resolve, reject) => {
    HTTP('get', null, null, {
      'authorization': getStorage('token'),
    },url)
      .then((result) => {
        resolve(result.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function newDocUpload(data, payload = {}) {
  let url = `${config.newDocUrl}document/upload/docs`
  if(payload?.isGenerateSignUrl) url += `?isGenerateSignUrl=${payload.isGenerateSignUrl}`
  return new Promise((resolve, reject) => {
    try {
      data?.append("paddedData", lorem);
    } catch(e) {
      //
    }
    HTTP('post', null, data, {
      "Content-Type": `multipart/form-data`, 
      'authorization': getStorage('token'),
    },url)
      .then((result) => {
        resolve(result?.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function newImageUpload(data, payload = {}) {
  let url = `${config.newDocUrl}document/upload/image`
  if(payload?.isGenerateSignUrl) url += `?isGenerateSignUrl=${payload.isGenerateSignUrl}`
  return new Promise((resolve, reject) => {
    HTTP('post', null, data, {
      "Content-Type": `multipart/form-data`, 
      'authorization': getStorage('token'),
    },url)
      .then((result) => {
        resolve(result?.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function oldDocUpload(data) {
  let url = `${config.docUrl}driver/uploadDoctoAWS`
  return new Promise((resolve, reject) => {
    HTTP('put', null, data, {
      "Content-Type": `multipart/form-data`,
    },url)
      .then((result) => {
        resolve(result?.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function oldImageUpload(data) {
  let url = `${config.docUrl}driver/uploadProofOfDelivery`
  return new Promise((resolve, reject) => {
    HTTP('put', null, data, {
      "Content-Type": `multipart/form-data`,
    },url)
      .then((result) => {
        resolve(result.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getDriverTags(params) {
  let url = 'tms/getDriverTags';
  if(params)
  url+="?"+jsonToQueryParams(params)
  return new Promise((resolve, reject) => {
    HTTP('get', url, {},{
      'authorization': getStorage('token'),
    })
      .then((result) => {
        if (result)
          resolve(result.data.data)
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function getSyncRequestCount(params) {
  let url = `load-sync-requests/count`;
  return new Promise((resolve, reject) => {
  HTTP('get', url, null, {
    'authorization': getStorage('token'),
  })
    .then((result) => {
      resolve(result?.data?.data)
    })
    .catch((error) => {
      reject(error);
    });
})
}


export function getTMSShipperConsigneeChassis(params, isLoadCreation=false) {
  let obj = { limit: 10 }

  if (isLoadCreation) {
    obj = { ...params }; 
  } else if (params) {
    obj.nameSearch = params
    obj.customerType = ['ALL', 'shipper', 'consignee','chassisPick', 'chassisTermination']
  }

  let url = 'carrier/getTMSCustomers';
  // url = obj ? url + "?" + jsonToQueryParams(obj) : url;
  url = obj ? url + "?" + ToQuery.objToQueryParams(obj) : url;
  return new Promise((resolve, reject) => {
    HTTP('get', url, null, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        const customers = []
        result.data.data
          .forEach((obj) => {
            let labelData = [];
            const defaultAddressFormat = JSON.parse(getStorage("userAddressFormat") ?? null)?.userAddressFormat ?? addressFormatEnum.US;
            if (defaultAddressFormat !== addressFormatEnum.US) {
              euAddressFormate(obj, labelData)
              customers.push({ value: obj._id, label: `${obj.company_name} | ${labelData.join(', ')}`, allInfos: obj });
            } else {
            customers.push({ value: obj._id, label: `${obj.company_name} | ${obj.address ? obj.address.address : ''}`, allInfos: obj });
            }
          })
        resolve(customers);
      })
      .catch((error) => {
        reject(error);
      });
  })
}

export function driverPlannerOptimizerPlan(params) {
  
  let url = 'driver-planner-optimizer/optimizer-plan';
  return new Promise((resolve, reject) => {
    HTTP('post', url, params, {
      'authorization': getStorage('token'),
    })
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject(error);
      });
  })
}