import { useCurrentUserSettings } from "hooks"
import { checkIfAllowedForManualBooking } from "pages/tms/NewDispatcher/utility"
import { createPortal } from "react-dom"
import { useSelector } from "react-redux"
import ReactTooltip from "react-tooltip"
import { getStorage } from "services"
import ButtonFormGroup from './ButtonFormGroup'
import { useMemo } from "react"
import { checkForDisable, getScheduleButtonColor } from "pages/tms/NewDispatcher/DispatcherTable/Functions"

const APPT_TYPES = {
    "PICKUP": "pick",
    "EMPTY": "return",
}

const ManualBookingButton = ({ openManualBooking, btnClass = "", load = {}, wrapWithFormGroup = false, isPortAccepted = false, moveType="", loadStatus="" }) => {
    const { isAppointmentFeatureEnabled, isManualBookingFeatureEnabled } = useCurrentUserSettings() || {};
    const { standard_ports } = useSelector((state) => state.TmsReducer) ?? {};
    const { allowedManualBookingPorts } = useSelector((state) => state.appointmentReducer) ?? {};

    const showButton = isAppointmentFeatureEnabled && isManualBookingFeatureEnabled && !JSON.parse(getStorage("userBasicSettings"))?.myappFeature?.includes("PORTBASE") && isPortAccepted && checkIfAllowedForManualBooking(allowedManualBookingPorts, load, moveType, standard_ports);

    const btnId = new Date().valueOf()?.toString()
    const isDisabled =
      useMemo(() => {
        return checkForDisable(loadStatus, moveType)
      }, [loadStatus, moveType])
  
    const className = useMemo(() => {
      return `${btnClass ?? ""} ${getScheduleButtonColor(loadStatus, moveType)}`
    }, [btnClass, loadStatus, moveType])

    const button = <> <button
        className={`btn btn-sm text-nowrap ${className}`}
        onClick={openManualBooking}
        data-tip
        data-for={btnId}
        disabled={isDisabled}
    >
        Manual Booking
    </button>
        {isDisabled && createPortal(
            <ReactTooltip id={btnId} place="top" multiline>
                <span>You cannot request {moveType?.toLowerCase()} apt because <br />the load status arrived {APPT_TYPES[moveType]} container is <br /> already completed.</span>
            </ReactTooltip>, document.getElementById("poper-dest")
        )
        }
    </>
    if (showButton)
        return wrapWithFormGroup ? <ButtonFormGroup>{button}</ButtonFormGroup> : button
    return null
}

export default ManualBookingButton;