import { IconHomeSmall } from "Components/Common/Icons";
import { getStorage, isDriverTariffEnabled } from "services";

import { allCompanySettingsTabs } from "pages/tms/NewSettings/CompanySettings/constant";

export const dispatchItems = [
  {
    id: 1,
    title: "Dispatcher",
    path: "/tms/Dispatcher",
    permission: "dispatcher",
  },
  {
    id: 2,
    title: "Driver Itinerary",
    path: "/tms/customer-service/driver-itinerary",
    permission: "dispatcher_driver_itinerary",
  },
  {
    id: 7,
    title: "Scheduler",
    path: "/tms/Scheduling",
    permission: "dispatcher_scheduling",
  },
  {
    id: 6,
    title: "Planner",
    path: "/tms/customer-service/driver-planner",
    permission: "dispatcher_driver_itinerary",
  },
  {
    id: 3,
    title: JSON.parse(getStorage("userBasicSettings"))?.isDualTransactionEnabled ? "Dual Transactions" : "Double Moves",
    path: "/tms/customer-service/dropped-dispatch-yard-container",
    permission: "dispatcher_double_moves",
  },
  {
    id: 4,
    title: "Street Turns",
    path: "/tms/customer-service/street-turns",
    permission: "dispatcher_street_turns",
  },
  {
    id: 5,
    title: "Problem Containers",
    path: "/tms/customer-service/problem-container",
    permission: "dispatcher_problem_container",
  },
  {
    id: 7,
    title: "Trips",
    path: "/tms/trips",
    permission: "load_free_flow",
  },

 {
    id: 8,
    title: "Connect Notices",
    path: "/tms/connect-notices",
    permission: "dispatcher_connect_notices",
 },
 {
  id: 9,
  title: "Rail Order",
  path: "/tms/RailOrder",
  permission: "load_rail_order",
},
];

export const customerServiceItems = [
  {
    id: 1,
    title: "Dashboard",
    path: "/tms/customer-dashboard",
    permission: "customer_service_dashboard",
  },
  {
    id: 2,
    title: "Quotes",
    path: "/tms/load-quote-settings",
    permission: "customer_service_quotes",
  },
  {
    id: 3,
    title: "Yard Check",
    path: "/tms/customer-service/at-yard-container-loads",
    permission: "customer_service_dropped_container",
  },
  {
    id: 4,
    title: "Tendered Loads",
    path: "/tendered",
    permission: "customer_service_tendered_loads",
  },
  {
    id: 5,
    title: "Load Calendar",
    path: "/tms/customer-service/calendar",
    permission: "customer_service_load_calendar",
  },
  {
    id: 6,
    title: "Bulk Uploads",
    path: "/tms/customer-service/bulk-upload1",
    permission: "customer_service_bulk_upload",
  },
  {
    id: 7,
    title: "Messaging",
    path: "/driver-messaging",
    permission: ["customer_service_drive_messaging", "customer_service_employee_messaging", "customer_service_group_messaging", "customer_service_customer_messaging", "customer_service_driver_group_messaging"],
  },
  {
    id: 8,
    title: "Deleted Loads",
    path: "/tms/deleted-loads",
    permission: "customer_service_deleted_load",
  },
]

export const accRecievableItems = [
  {
    id: 1,
    title: "Accounts Receivable Dashboard",
    path: "/tms/billing/account-receivable",
    permission: "billing_account_receivable",
  },
  {
    id: 9,
    title: "Invoices",
    path: "/tms/billing/invoices",
    permission: "invoice_account_receivable"
  },
  {
    id: 2,
    title: "Apply Payments",
    path: "/tms/billing/payment",
    permission: "billing_batch_apply_payment",
  },
  {
    id: 3,
    title: "Subaccounts Receivable",
    path: "/tms/billing/sub-customer-account-receivable",
    permission: "billing_subaccount_receivable",
  },
  {
    id: 4,
    title: "Aging",
    path: "/tms/billing/aging1",
    permission: "reports_aging",
  },
  {
    id: 5,
    title: "Chassis Audit",
    path: "/tms/customer-service/chassis-validation",
    permission: "billing_chassis_validation",
  },
  {
    id: 6,
    title: "Credit Memos",
    path: "/tms/credit-memo",
    permission: "credit_memo"
  },
  {
    id: 7,
    title: "Problem Invoices",
    path: "/tms/desyncedQBLoads",
    permission: "billing_problem_invoices",
  },
  {
    id: 8,
    title: "Per Diem Audit",
    path: "/tms/billing/per-diem",
    permission: "per_diem",
  },
  {
    id: 9,
    title: "Waiting Time Audit",
    path: "/tms/billing/waiting-time-audit",
    permission: "waiting-time-audit",
  },
];

export const accPayableItems = [
  {
    id: 1,
    title: "Driver Pay",
    path: "/tms/billing/driver-pay",
    permission: "billing_driver_pay"
  },
  {
    id: 2,
    title: "Vendor Pay",
    path: "/tms/vendor-pay",
    permission: "billing_vendor_pay"
  },
  {
    id: 3,
    title: "Vendor Bills",
    path: "/tms/vendor-bills",
    permission: "billing_vendor_bills"
  },
  {
    id: 4,
    title: "Driver Settlements",
    path: "/tms/billing/settlements",
    permission: "billing_driver_settlement"
  },
  {
    id: 5,
    title: "Dynamic Expenses",
    path: "/tms/dynamic-expense",
    permission: "dynamic_dynamic_expense"
  },
  {
    id: 6,
    title: "Carrier Pay Dashboard",
    path: "/tms/billing/carrier-pay",
    permission: "billing_driver_pay"
  },
  {
    id: 7,
    title: "Apply Carrier Payment",
    path: "/tms/billing/apply-carrier-payment",
    permission: "billing_driver_pay"
  },
  {
    id: 8,
    title: "Apply Vendor Payment",
    path: "/tms/billing/apply-vendor-payment",
    permission: "billing_driver_pay"
  },
  {
    id: 9,
    title: "Problem Sync",
    path: "/tms/account-payable/problem-sync",
    permission: "vendor_problem_bills"
  },
];

export const DrayosCarrier = [
  {
    id: 1,
    title: "Vendors",
    path: "/tms/drayosCarrierProfile",
    permission: "other_profile_driver_shipos",
  },
  { 
    id: 2,
    title: "Vendor Tariff",
    path: "/tms/carrier-tariffs",
    permission: "other_driver_pay_rate",
  },
  {
    id: 2,
    title: "Carrier Rates",
    path: "/tms/drayos-carrier-rates",
    permission: "other_driver_pay_rate",
  },
];

export const driverItems = [
  {
    id: 1,
    title: "Driver Profiles",
    path: "/tms/Driver",
    permission: "other_profile_driver",
  },
  {
    id: 2,
    title: "Truck Assignments",
    path: "/tms/AssignTruck",
    permission: "other_assign_truck",
  },
  {
    id: 3,
    title: "Truck Owners",
    path: "/tms/fleetTruck",
    permission: "safety_truck_owner",
  },
  {
    id: 4,
    title: "Driver Pay Rates",
    path: "/tms/payment-setting",
    permission: "other_driver_pay_rate",
  },
  {
    id: 5,
    title: "Settlement Settings",
    path: "/tms/billing/settings",
    permission: "billing_settlement_setting",
  },
  !isDriverTariffEnabled() ?
    {
      id: 6,
      title: "Dynamic Driver Pay",
      path: "/dynamic-driverPay",
      permission: "other_dynamic_driver_pay",
    } : {},
];

export const equipmentItems = [
  {
    id: 1,
    title: "Truck Profiles",
    path: "/tms/Truck",
    permission: "other_profile_truck",
  },
  {
    id: 2,
    title: "Chassis Profiles",
    path: "/tms/AddChassis",
    permission: "other_profile_chassis",
  },
  {
    id: 3,
    title: "Trailer Profiles",
    path: "/tms/Trailer",
    permission: "other_profile_trailer",
  },
];

export const customerItems = [
  {
    id: 1,
    title: "Customer Profile",
    path: "/tms/customer",
    permission: "other_profile_customer",
  },
  {
    id: 5,
    title: "People",
    path: "/tms/people",
    permission: "other_people",
  },
  {
    id: 2,
    title: "Customer Rates",
    path: "/tms/load-price-settings",
    permission: "other_customer_rate",
  },
  {
    id: 3,
    title: "Group Customer",
    path: "/tms/FleetCustomer",
    permission: "other_group_customer",
  },
  {
    id: 4,
    title: "Tariffs",
    path: "/tms/load-tariffs",
    permission: "other_customer_rate",
  },
];

export const reportItems = [
  {
    id: 1,
    title: "Aging",
    path: "/tms/report/aging1",
    permission: "reports_aging",
  },
  {
    id: 2,
    title: "Load Master",
    path: "/tms/report/load-master-report",
    permission: "reports_master_load-master-report",
  },
  {
    id: 3,
    title: "Admin Dashboard",
    path: "/tms/report/agingChart",
    permission: "reports_admin_dashboard",
  },
  {
    id: 30,
    title: "Admin Dashboard 2",
    path: "/tms/report/reportDashboard",
    permission: "reports_admin_dashboard_2",
  },
  {
    id: 4,
    title: "Clock",
    path: "/tms/report/driverclockinoutreport",
    permission: "reports_clock_report",
  },
  {
    id: 5,
    title: "Charge",
    path: "/tms/report/report1",
    permission: "reports_charge_report",
  },
  {
    id: 6,
    title: "Charge 2",
    path: "/tms/report/charge-report-2",
    permission: "reports_charge_report_2",
  },
  {
    id: 32,
    title: "HWB Import Report",
    path: "/tms/report/HWB-report",
    permission: "report_HWB",
  },
  {
    id: 7,
    title: "Charge 3",
    path: "/tms/report/charge-3",
    permission: "reports_charge_report_3",
  },
  {
    id: 33,
    title: "Charge 4",
    path: "/tms/report/charge-4",
    permission: "reports_charge_report_4",
  },
  {
    id: 35,
    title: "Charge 5",
    path: "/tms/report/charge-5",
    permission: "reports_charge_report_4",
  },
  {
    id: 36,
    title: "Expense Report",
    path: "/tms/report/expense",
    permission: "report_expense",
  },
  {
    id: 8,
    title: "Customer Revenue",
    path: "/tms/report/revenue",
    permission: "reports_customer_revenue",
  },
  {
    id: 9,
    title: "Driver Pay",
    path: "/tms/report/driver-pay-report",
    permission: "reports_driver_pay",
  },
  {
    id: 29,
    title: "Driver Pay Summary",
    path: "/tms/report/driver-pay-summary-report",
    permission: "reports_driver_pay_summary",
  },
  {
    id: 10,
    title: "Driver Revenue",
    path: "/tms/report/driverrevenue",
    permission: "reports_driver_revenue",
  },
  {
    id: 11,
    title: "Forecast Report",
    path: "/tms/report/ForcastReport",
    permission: "reports_forecast_report",
  },
  {
    id: 12,
    title: "New Driver Revenue",
    path: "/tms/report/newdriverrevenue",
    permission: "reports_new_driver_revenue",
  },
  {
    id: 13,
    title: "Leg Type",
    path: "/tms/report/leg-type-report",
    permission: "reports_leg_type",
  },
  {
    id: 14,
    title: "LFD",
    path: "/tms/report/lfd-report",
    permission: "reports_lfd_report",
  },
  {
    id: 15,
    title: "Loads",
    path: "/tms/report/report2",
    permission: "reports_loads_report",
  },
  {
    id: 16,
    title: "Load Pricing",
    path: "/tms/report/pricing-load-report",
    permission: "reports_loads_report_pricing",
  },
  {
    id: 18,
    title: "Pricing Breakdown",
    path: "/tms/report/report5",
    permission: "reports_pricing_breakdown",
  },
  {
    id: 28,
    title: "Invoice Breakdown",
    path: "/tms/report/invoice-breakdown",
    permission: "reports_pricing_breakdown",
  },
  {
    id: 19,
    title: "Productivity",
    path: "/tms/report/productivityReport",
    permission: "reports_productivity_report",
  },
  {
    id: 34,
    title: "Productivity 2",
    path: "/tms/report/productivityReport2",
    permission: "reports_productivity_report_2",
  },
  {
    id: 20,
    title: "Return",
    path: "/tms/report/return-report",
    permission: "reports_return_report",
  },
  {
    id: 21,
    title: "Revenue",
    path: "/tms/report/revenue-report",
    permission: "reports_revenue_report",
  },
  {
    id: 22,
    title: "Status Driver",
    path: "/tms/report/driverstatusreport",
    permission: "reports_status_driver",
  },
  {
    id: 23,
    title: "Summary",
    path: "/tms/report/summary-report",
    permission: "reports_summary_report",
  },
  {
    id: 35,
    title: "Support Move",
    path: "/tms/report/support-move-report",
    permission: "support-move-report",
  },
  {
    id: 36,
    title: "Deduction Report",
    path: "/tms/report/deduction-report",
    permission: "deduction-report",
  },
  {
    id: 24,
    title: "Vessel",
    path: "/tms/report/report4",
    permission: "reports_vessel_report",
  },
  {
    id: 25,
    title: "Profitability Report",
    path: "/tms/report/profitability-report",
    permission: "reports_profitability_report",
  },
  {
    id: 26,
    title: "Chassis History",
    path: "/tms/report/chassishistory",
    permission: "reports_chassis_history",
  },
  {
    id: 27,
    title: "ClockIn-ClockOut Report",
    path: "/tms/report/clock-in-clock-out-report",
    permission: "reports_clock_in_out",
  },
  {
    id: 28,
    title: "Import Charge Report",
    path: "/tms/report/import-charge-report",
    permission: "reports_import_charge",
  },
  {
    id: 29,
    title: "Export Charge Report",
    path: "/tms/report/export-charge-report",
    permission: "reports_export_charge",
  },
  {
    id: 31,
    title: "External Charge",
    path: "/tms/report/external-charge",
    permission: "reports_external_charge_report",
  },
  {
    id: 32,
    title: "Email Audit Report",
    path: "/tms/report/email-audit",
    permission: "email_audit_report",
  },
  {
    id: 34,
    title: "Carrier Transaction Report",
    path: "/tms/report/carrier-transaction-report",
    permission: "carrier_transaction_report",
  },
  {
    id: 35,
    title: "On-Time Performance Report",
    path: "/tms/report/on-time-performance-report",
    permission: "on_time_performance-report",
  },
  {
    id: 36,
    title: "Credit Memo",
    path: "/tms/report/credit-memo-report",
    permission: "reports_credit_memo_report",
  },
  {
    id:79,
    title: "Vendor Pay Breakdown",
    path:"/tms/report/vendorpay-breakdown",
    permission: "vendorpay_breakdown_report"
  },
  {
    id:79,
    title: "Equipment Issues",
    path:"/tms/report/equipment-issues",
    permission: "equipment_issues"
  },
];

export const settingItems = [
  {
    id: 1,
    title: "General Settings",
    path: "/settings/general-setting",
    permission: "settings_general",
  },
  {
    id: 2,
    title: "Users & Permissions",
    path: "/carrieraccessmanagement",
    permission: "other_permission",
  },
  {
    id: 3,
    title: "Role Management",
    path: "/roleaccessmanagement",
    permission: "other_role_management",
  },
  {
    id: 4,
    title: "Expense",
    path: "/settings/expense-setting",
    permission: "settings_expense",
  },
  {
    id: 5,
    title: "Container Settings",
    path: "/tms/Container/Size",
    permission: ["other_container_owner",
      "other_container_size",
      "other_container_type",
      "other_container_free_day"
    ],
  },
  {
    id: 6,
    title: "Chassis Settings",
    path: "/tms/Chassis/Size",
    permission: ["other_chassis_owner",
      "other_chassis_size",
      "other_chassis_type"
    ],
  },
  {
    id: 7,
    title: "Commodity Settings",
    path: "/settings/Commodity",
    permission: "settings_commodity",
  },
  {
    id: 8,
    title: "Terminal Settings",
    path: "/tms/terminals",
    permission: "settings_terminal",
  },
  {
    id: 9,
    title: "Dispatch Color Settings",
    path: "/settings/loadStatusColors",
    permission: "settings_load_status_colors",
  },
  {
    id: 10,
    title: "Group Settings",
    path: "/settings/group-setting",
    permission: "settings_group_settings",
  },
  {
    id: 11,
    title: "General Info",
    path: "/settings/general-info",
    permission: "settings_general_info",
  },
  {
    id: 23,
    title: "Connect Configuration",
    path: "/settings/connect-configuration",
    permission: "settings_connect_configuration",
  },
  {
    id: 22,
    title: "Notification Settings",
    path: "/settings/notifications",
    permission: "notification_settings",
  },
  {
    id: 12,
    title: "Equipment Settings",
    path: "/account/equipment-parameters",
    permission: "settings_equipment_params",
  },
  {
    id: 20,
    title: "Expiry Notification",
    path: "/settings/expiry-notifications",
    permission: "expiry_notifications",
  },
  {
    id: 13,
    title: "Document Configuration",
    path: "/account/document-setup",
    permission: "settings_invoice_setup",
  },
  {
    id: 14,
    title: "Email Configuration",
    path: "/account/email-configuration/domain-configuration",
    permission: "settings_email_configuration",
  },
  {
    id: 15,
    title: "Billing Configuration",
    path: "/account/billing-configuration",
    permission: "settings_billing_configuration",
  },
  {
    id: 16,
    title: "Developer Setting",
    path: "/settings/developer-settings",
    permission: "settings_developer",
  },
  {
    id: 17,
    title: "My Apps",
    path: "/settings/my-apps",
    permission: "settings_my_apps",
  },
  {
    id: 22,
    title: "Embedded Email",
    path: "/settings/embedded-email/mail-box",
    permission: "embedded_email"
  },
  {
    id: 18,
    title: "Appointment List",
    path: "/tms/AppointmentList",
    permission: "appointment_list",
  },
  {
    id: 19,
    title: "Container Tracking",
    path: "/container-tracking",
    permission: "container-tracking",
  },
  {
    id: 20,
    title: "Dynamic Tax",
    path: "/settings/dynamicTax",
    permission: "dynamic-tax"
  },
  {
    id: 21,
    title: "Enterprise Connection Setup",
    path: "/settings/auth0-connect",
  },
  {
    id: 22,
    title: "Cabotage",
    path: "/settings/cabotage",
    permission: "settings_general_info"
  },
  {
    id: 23,
    title: "Slots Availability",
    path: "/settings/slots-availability",
    permission: "slots-availability"
  },
];

export const groupSettings = [
  {
    id: 1,
    title: "Carrier Group Settings",
    path: "/settings/group-setting",
  },
  {
    id: 2,
    title: "Carrier Cities Settings",
    path: "/settings/group-setting/cities",
  },
];

export const qbServiceHeaderItems = [
  {
    id: 1,
    title: "Loads",
    path: "/admin/quickbookServices/loads",
  },
  {
    id: 2,
    title: "Customers",
    path: "/admin/quickbookServices/Customers",
  },
  {
    id: 3,
    title: "Payments",
    path: "/admin/quickbookServices/Payments",
  },
  {
    id: 4,
    title: "Carrier ChargeCodes",
    path: "/admin/quickbookServices/updateChargeCode",
  },
  {
    id: 5,
    title: "Terminals(Branches)",
    path: "/admin/quickbookServices/updateTerminals",
  },
  {
    id: 6,
    title: "QBD Carriers",
    path: "/admin/quickbookServices/qbDesktopCarriers"
  }
];

export const customerNewBillingTabs = [
  {
    id: 1,
    title: "Account Payable",
    path: "/tms/account-receivable",
    permission: "account_payable"
  },
  {
    id: 2,
    title: "Account payable Invoices",
    path: "/tms/customer/bills",
    permission: "account_payable"
  },
];
export const emailTabs = [
  {
    id: 1,
    title: "Mailbox",
    path: "/tms/email",
    icon:<IconHomeSmall className="mr-1"/>
  },
];

export const reportV2Items = [
  {
    id: 1,
    title: "Orders & Loads",
    path: "/tms/report-v2/orders-and-loads",
  },
  {
    id: 2,
    title: "Load/Move Completion",
    path: "/tms/report-v2/load-or-move-completion",
  },
  {
    id: 3,
    title: "Loads by CSR",
    path: "/tms/report-v2/loads-by-csr",
  },
  {
    id: 4,
    title: "Loads by Dispatcher",
    path: "/tms/report-v2/loads-by-dispatcher",
  },
  // {
  //   id: 10,
  //   title: "Revenue by Yard",
  //   path: "/tms/report-v2/revenue-by-yard",
  // },
  {
    id: 11,
    title: "Revenue",
    path: "/tms/report-v2/revenue",
  },
  {
    id: 12,
    title: "Load invoicing",
    path: "/tms/report-v2/load-invoicing",
  },
  {
    id: 5,
    title: "Driver Performance",
    path: "/tms/report-v2/driver-performance",
  },
  {
    id: 6,
    title: "Load Revenue",
    path: "/tms/report-v2/load-revenue",
  },
  {
    id: 7,
    title: "Invoices By Charge Type",
    path: "/tms/report-v2/invoices-by-charge-type",
  },
  {
    id: 8,
    title: "Load Timing",
    path: "/tms/report-v2/load-timing",
  },
  // {
  //   id: 9,
  //   title: "Payable Legs & Charge Legs",
  //   path: "/tms/report-v2/payable-legs-and-charge-legs",
  // },
  {
    id: 13,
    title: "Equipment",
    path: "/tms/report-v2/equipment",
  },
  {
    id: 14,
    title: "Highest Drivers And Leg Types",
    path: "/tms/report-v2/accounts_payable",
  },
  {
    id: 15,
    title: "Auto Populated Fields By TrackOS",
    path: "/tms/report-v2/autopopulatedFieldbytrack",
  },
  {
    id: 16,
    title: "Cron Reports",
    path: "/tms/report-v2/cronReports",
  },
  {
    id: 17,
    title: "Document Uploads",
    path: "/tms/report-v2/documentUpoads",
  },
  {
    id: 18,
    title: "Load Status",
    path: "/tms/report-v2/loadStatus",
  },
  {
    id: 19,
    title: "Automatic Vs Manual Charges",
    path: "/tms/report-v2/automatic-vs-manual-charges",
  },
  {
    id: 20,
    title: "Automatic Vs Manual DriverPay",
    path: "/tms/report-v2/automatic-vs-manual-driverPay",
  },
];

export const companySettings = () => (allCompanySettingsTabs()?.map((val, i) => {
  return {
    id: i,
    title: val.title,
    path: val.path
  }
}))

export const adminUsersTab = [
  {
    id: 1,
    title: "Users",
    path: "/admin/users",
  },
  {
    id: 2,
    title: "Departments",
    path: "/admin/departments",
  },
]

export const employeeAuditTab = [
  {
    id: 1,
    title: "Logged In",
    path: "/admin/login-history",
  },
]

export const dashboardsTabs = [
  {
    id: 1,
    title: "Dashboards",
    path: "/tms/report/dashboards",
  },
  {
    id: 2,
    title: "Custom Report",
    path: "/tms/report/custom-reports",
  },
]
