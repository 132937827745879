import React, { useState, useMemo } from "react";
import { useCurrentUserSettings } from "hooks";

const FilterAuditLoadList = ({ auditList, handleFilterAuditList }) => {
  const { isAppointmentFeatureEnabled } = useCurrentUserSettings() || {};

  const [selectedTypes, setSelectedTypes] = useState([]);

  const countAuditTypes = useMemo(() => {
    let audits = auditList ?? [];

    const apptCount = audits.reduce((count, each) => {
      return count + (["APPOINTMENT_LOAD_EDIT", "AUTO_APPOINTMENT_EDIT"].includes(each?.type) ? 1 : 0);
    }, 0);

    const trackingCount = audits.reduce((count, each) => {
      return count + (each?.type === "MYAPP_UPDATE" && Object.keys(each?.data).length > 0 ? 1 : 0);
    }, 0);

    return {
      apptCount,
      trackingCount,
    };
  },[auditList]);

  const handleCheckboxChange = (types) => {
    let newSelectedTypes = [...selectedTypes];

    const typeArray = Array.isArray(types) ? types : [types];

    typeArray.forEach((type) => {
      if (newSelectedTypes.includes(type)) {
        newSelectedTypes = newSelectedTypes.filter(
          (selectedType) => selectedType !== type
        );
      } else {
        newSelectedTypes.push(type);
      }
    });

    setSelectedTypes(newSelectedTypes);
    handleFilterAuditList(newSelectedTypes);
  };

  const isAppointmentChecked = useMemo(
    () =>
      selectedTypes.includes("APPOINTMENT_LOAD_EDIT") ||
      selectedTypes.includes("AUTO_APPOINTMENT_EDIT"),
    [selectedTypes]
  );

  return (
    isAppointmentFeatureEnabled && (
      <div className="d-flex ml-auto">
        <div className="form-check form-check--gray pr-30">
          <input
            className="form-check-input"
            type="checkbox"
            id="appointment-check"
            checked={isAppointmentChecked}
            onChange={() => {
              handleCheckboxChange(["APPOINTMENT_LOAD_EDIT", "AUTO_APPOINTMENT_EDIT"])
            }}
          />
          <label className="form-check-label" htmlFor="appointment-check">
            Appointment
            <span className="badge badge-gray-100 font-12 ml-2 badge-pill">{countAuditTypes?.apptCount || 0}</span>
          </label>
        </div>
        <div className="form-check form-check--gray">
          <input
            className="form-check-input"
            type="checkbox"
            id="tracking-check"
            checked={selectedTypes?.includes("MYAPP_UPDATE")}
            onChange={() => {
              handleCheckboxChange("MYAPP_UPDATE");
            }}
          />
          <label className="form-check-label" htmlFor="tracking-check">
            Tracking
            <span className="badge badge-gray-100 font-12 ml-2 badge-pill">{countAuditTypes?.trackingCount || 0}</span>
          </label>
        </div>
      </div>
    )
  );
};

export default FilterAuditLoadList;
