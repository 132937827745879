import React, { useEffect } from "react";
import { createPortal } from "react-dom";
import DateTimePicker from "../CustomDatePicker/DateTimePicker";

const DatePickerPortal = (props) => {
  const cellRef = document.querySelector(`.${props?.refClassName}`);

  useEffect(() => {
    if (window.document.getElementById("tab-loads")) {
      window.document.getElementById("tab-loads").style.pointerEvents = "none";
      return () => {
        window.document.getElementById("tab-loads").style.pointerEvents = "auto";
      };
    }
  }, []);

  return createPortal(<DateTimePicker {...props} datePickerCellRef={{ current: cellRef }} />, document.body);
};

export default DatePickerPortal;