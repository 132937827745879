import moment from "moment";
import { getTimeZone } from "pages/tms/NewDispatcher/constants";
import { amplitudeTrack, getStorage, makeOptionsForSelect, showForTerminal, toastr } from "services";
import { getAdminChartCounts, getBrokerChartCounts, getCustomerChartCounts, getCustomerServiceChartCounts, getEmployeeChartCounts, getFinancialsChartCounts, getOperatinsChartCounts, getSystemChartCounts, getTrackChartCounts, getEquipmentChartCounts } from "./actionCreator";
import { ADMIN_CHARTS, BROKER_CHARTS, CUSTOMER_CHARTS, CUSTOMER_SERVICE_CHARTS, EMPLOYEE_CHARTS, FINANCIALS_CHARTS, OPERATIONS_CHARTS, REPORT_TYPES, SYSTEM_CHARTS, TRACK_CHARTS, EQUIPMENT_CHARTS, DASHBOARDS_CHARTS_LIST, REPORT_NAMES, DASHBOARD_CHARTS_FILTER_NAME, DASHBOARDS } from "./constants";
import numbro from "numbro";
import _ from "lodash";
import { LOAD_TYPES } from "pages/tms/carrier/AccountReceivable/constants";
import { getCustomerAsyncSearch } from "Components/DynamicPricing/utils";
import { createContext } from "react";


// Function to define date ranges
export const DATE_RANGE = () => {
  const tz = getStorage("timeZone")
  return {
    "Yesterday": [moment().tz(tz).subtract(1, 'day').startOf('day'), moment().tz(tz).subtract(1, 'day').endOf('day')],
    "Last Week": [moment().tz(tz).subtract(1, 'week').startOf('week'), moment().tz(tz).subtract(1, 'week').endOf('week')],
    "Last 7 Days": [moment().tz(tz).subtract(7, "days").startOf('day'), moment().tz(tz).subtract(1, 'day').endOf('day')],
    "Last 15 Days": [moment().tz(tz).subtract(15, "days").startOf('day'), moment().tz(tz).subtract(1, 'day').endOf('day')],
    "Last 30 Days": [moment().tz(tz).subtract(30, "days").startOf('day'), moment().tz(tz).subtract(1, 'day').endOf('day')],
    "Last Month": [moment().tz(tz).subtract(1, "month").startOf("month"), moment().tz(tz).subtract(1, "month").endOf("month")],
    "Last 3 Month": [
      moment().tz(tz).subtract(3, "month").startOf("month"),
      moment().tz(tz).subtract(1, "month").endOf("month"),
    ],
  }
}

// Function to convert data for chart
export const convertDataForChart = (data, reportType, isTableData) => {
  const desiredOrder = ["Import", "Export", "Road", "Bill"];

  if(isTableData && reportType === REPORT_TYPES.FINANCIAL_PAST_DUE_INVOCES){
    return data?.map(item => ({ name: item?.["Invoice #"], count: item?.["Date Invoiced"], status: item?.["Payment Status"] }))
  }

  if(isTableData && reportType === REPORT_TYPES.CUSTOMER_CUSTOMER_PORTAL_USAGE_PERCENTAGE){
    return data?.map(item => ({ name: item?.company_name, count: item?.loadCount}))
  }
  const transformers = {
    // Admin
    [REPORT_TYPES.LOADS_BY_TYPE]: () => {
      const chartData = typeOfLoadDataMapper(data, "Load Type", "Count", "name", "count");
      return chartData?.length > 0 ? chartData.sort((a, b) => desiredOrder.indexOf(a.name) - desiredOrder.indexOf(b.name)) : data;
    },

    [REPORT_TYPES.LOADS_BY_CUSTOMER]: () => 
      data.map(item => ({ company_name: item?.["Customer"], count: item?.["Load Count"], ...item })),

    [REPORT_TYPES.TOP_DELIVERY_LOCATIONS]: () => 
      data.map(item => ({ company_name: item?.["Delivery Location"], count: item?.["Count"], ...item })),

    [REPORT_TYPES.TOP_PICK_UP_LOCATIONS]: () => 
      data.map(item => ({ company_name: item?.["Pick Up Location"], count: item?.["Count"], ...item })),

    [REPORT_TYPES.TOP_RETURN_LOCATIONS]: () => 
      data.map(item => ({ company_name: item?.["Return Location"], count: item?.["Count"], ...item })),
    
    // Broker
    [REPORT_TYPES.BROKER_TENDERS_SENT_BY_CARRIER]: () => 
      data.map(item => ({ company_name: item?.["Carrier"], count: item?.["# of Tenders Sent"], ...item })),

    [REPORT_TYPES.BROKER_TENDERS_ACCEPTED_BY_CARRIER]: () => 
      data.map(item => ({ company_name: item?.["Carrier"], count: item?.["# of Tenders Accepted"], ...item })),

    [REPORT_TYPES.BROKER_INVOICES_RECEIVED_BY_CARRIER]: () => 
      data.map(item => ({ company_name: item?.["Carrier"], count: item?.["# of Invoices Receives"], ...item })),

    // Financials
    [REPORT_TYPES.FINANCIAL_REVENUE_BY_LOAD_TYPE]: () =>{
      const chartData = typeOfLoadDataMapper(data, "Load Type", "Revenue", "company_name");
      return chartData?.length > 0 ? chartData.sort((a, b) => desiredOrder.indexOf(a.name) - desiredOrder.indexOf(b.name)) : data;
    },

    [REPORT_TYPES.FINANCIAL_REVENUE_BY_CHARGE_NAME]: () =>
      data.map(item => ({ chargeName: item?.["Charge Name"], count: item?.["Revenue"], percentage: item?.["% of Total Revenue"] })),

    [REPORT_TYPES.FINANCIAL_MOST_PROFITABLE_CUSTOMERS_PERCENTAGE]: () => 
      data.map(item => ({ name: item?.["Customer"], count: item?.["Profit ($ amount)"], percentage: item?.["Profit (% of Total Profit)"] })),
    
    [REPORT_TYPES.FINANCIAL_PERCENTAGE_OF_MARGIN]: () => 
      data.map(item => ({ company_name: item?.["Margin Badge"], count: item?.["% of Loads"] })),

    [REPORT_TYPES.FINANCIAL_MOST_PROFITABLE_CUSTOMERS]: () => 
      data.map(item => ({ company_name: item?.["Customer"], count: item?.Profit })),

    [REPORT_TYPES.FINANCIAL_REVENUE_BY_DELIVERY_LOCATION]: () => 
      data.map(item => ({ company_name: item?.["Delivery Location"], count: item?.Revenue })),

    [REPORT_TYPES.FINANCIAL_TOP_EARNING_DRIVERS]: () =>
      data.map(item => ({ company_name: item?.["Driver Name"], count: item?.["Net Pay"] })),
    
    [REPORT_TYPES.FINANCIAL_REVENUE_BY_CUSTOMER]: () =>
      data.map(item => ({ company_name: item?.["Customer"], count: item?.Revenue })),

    [REPORT_TYPES.FINANCIAL_MOST_PROFITABLE_DELIVERY_LOCATIONS]: () =>
      data.map(item => ({ company_name: item?.["Delivery Location"], count: item?.Profit })),

    // Operation
    [REPORT_TYPES.OPERATION_DAILY_AVERAGE_MOVES_BY_DRIVER]: () =>
      data.map(item => ({ company_name: item?.["Driver"], count: item?.["Average number of Moves"] })),
    
    [REPORT_TYPES.OPERATION_MOVES_COMPLETED_BY_DRIVER]: () =>
      data.map(item => ({ company_name: item?.["Driver"], count: item?.["Moves Completed"] })),
    
    [REPORT_TYPES.OPERATION_LOADS_COMPLETED_BY_WEEK]: () =>
      data.map(item => ({ company_name: item?.["Day of Week"], count: item?.["Loads Completed"] })),
    
    [REPORT_TYPES.OPERATION_DISPATCHES_BY_EMPLOYEE]: () =>
      data.map(item => ({ company_name: item?.["Employee"], count: item?.["Number of Dispatches"] })),
    
    [REPORT_TYPES.OPERATION_EVENTS_BY_DRIVER]: () =>
      data.map(item => ({ name: item?.["Driver"], ...item })),

    // Customer Service
    [REPORT_TYPES.CUSTOMER_SERVICE_LOADS_CREATED]: () =>
      data.map(item => ({ company_name: item?.name, count: item?.loads })),

    [REPORT_TYPES.CUSTOMER_SERVICE_LOADS_CREATED_BY_DAY]: () =>
      data.map(item => ({ company_name: item?.day, count: item?.loads, ...item })),

    // Empployee
    [REPORT_TYPES.EMPLOYEE_DRIVER_PAY_APPROVED]: () =>
      data.map(item => ({ company_name: item?.["Employee Name"], count: item?.["Count of Approved Line Items"] })),

    [REPORT_TYPES.EMPLOYEE_SETTLMENT_APPROVED]: () =>
      data.map(item => ({ company_name: item?.["Employee Name"], count: item?.["Count of Settlements Approved"] })),

    [REPORT_TYPES.CUSTOMER_CUSTOMER_PORTAL_USAGE_PERCENTAGE]: () =>
      data.map(item => ({ name: item?.name, count: parseFloat(item?.percentage || 0) })),  

    // Equipment
    [REPORT_TYPES.EQUIPMENT_CHASSIS_USAGE]: () =>
      data.map(item => ({ company_name: item?.["Chassis #"], count: item?.["Count of Completed Moves"] })),

    [REPORT_TYPES.EQUIPMENT_TRUCK_USAGE]: () =>
      data.map(item => ({ company_name: `${item?.["Driver Name"]} ( ${item?.["Truck #"]} )`, count: item?.["Count of Completed Moves"], truckNo: item?.["Truck #"], driverName: item?.["Driver Name"] })),

    [REPORT_TYPES.EQUIPMENT_TRAILER_USAGE]: () =>
      data.map(item => ({ company_name: item?.["Trailer #"], count: item?.["Count of Completed Moves"] })),
    
    [REPORT_TYPES.EQUIPMENT_CHASSIS_LAST_USED]: () =>
      data.map(item => ({ chargeName: item?.["Chassis #"], count: item?.["Date Last Used"], percentage: item?.["Days Since Last Use"] })),

    [REPORT_TYPES.EQUIPMENT_TRUCK_LAST_USED]: () =>
      data.map(item => ({ chargeName: item?.["Truck #"], count: item?.["Date Last Used"], percentage: item?.["Days Since Last Use"] })),

    [REPORT_TYPES.EQUIPMENT_TRAILER_LAST_USED]: () =>
      data.map(item => ({ chargeName: item?.["Trailer #"], count: item?.["Date Last Used"], percentage: item?.["Days Since Last Use"] })),
    [REPORT_TYPES.TRACK_PER_DIEM_PERCENTAGE]: () => 
      data.map(item => ({ ...item, name: item?.name, count: Math.round(item?.percentage) })),

    [REPORT_TYPES.TRACK_LAST_FREE_DAY_PERCENTAGE]: () => 
      data.map(item => ({ ...item, name: item?.name, count: Math.round(item?.percentage) })),
};

  return transformers[reportType] ? transformers[reportType]() : data;
};

// Function to check date range
export function checkDateRange(startDate, endDate, isDefultLabel = 'Custom Range') {
  const start = moment(startDate);
  const end = moment(endDate);
  const userTimeZone = getTimeZone();
  const dateRange = DATE_RANGE() || {}
  for (const [rangeName, [rangeStart, rangeEnd]] of Object.entries(dateRange)) {
    if (moment(start).tz(userTimeZone).format("DD/MM/YYYY") === moment(rangeStart).format("DD/MM/YYYY") && moment(end).tz(userTimeZone).format("DD/MM/YYYY") === moment(rangeEnd).format("DD/MM/YYYY")) {
      return rangeName;
    }
  }
  return isDefultLabel;
}

// Function to download CSV from buffer
export const downloadCsvFromBuffer = (csvBuffer = '', fileName = 'fileName') => {
  if (!csvBuffer) {
    return toastr.show('No data to download', 'error')
  };

  try {
    // Convert the buffer to a Blob
    const csvBlob = new Blob([csvBuffer], { type: 'text/csv' });

    // Create a URL for the Blob and set up a download link
    const link = document.createElement('a');
    link.href = URL.createObjectURL(csvBlob);
    link.download = `${fileName}.csv`;

    // Append to body, trigger download, and remove link immediately after
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Revoke the object URL to free up memory
    setTimeout(() => URL.revokeObjectURL(link.href), 100);
  } catch (error) {
    console.error('Error downloading CSV:', error);
  }
};

// Function to get data for chart from DB
export const getDataForChartFromDB = async (payload, reportType) => {
  let responseData = {}
  const timeZone = getTimeZone();

  payload.fromDate = moment(payload.fromDate).tz(timeZone).format('MM/DD/YYYY')
  payload.toDate = moment(payload.toDate).tz(timeZone).format('MM/DD/YYYY')

  if([...TRACK_CHARTS, ...EQUIPMENT_CHARTS, ...SYSTEM_CHARTS, ...EMPLOYEE_CHARTS]?.includes(reportType)) {
    delete payload.filter
  }

  /**
   * Tracks the download of a report using Amplitude.
   * 
   * @param {Object} payload - The payload containing report details.
   * @param {String} reportType - The type of report being downloaded.
   */
  if (payload?.isDownload) {
    // Find the dashboard name based on the report type
    const dashboardName = Object.keys(DASHBOARDS_CHARTS_LIST).find((dashboard) => DASHBOARDS_CHARTS_LIST[dashboard]?.includes(reportType));
    // Prepare event properties for Amplitude tracking
    const eventProperties = {
      dashboard_name: dashboardName,
      chart_name: reportType,
      // Include filters if payload has any keys (excluding 'isDownload' and 'filter')
      ...(Object.keys(payload).filter(key => key !== 'isDownload' && key !== 'filter').length && {filters: JSON.stringify(payload)}),
      // Include filter properties directly if 'filter' is present in payload
      ...payload.filter,
      // Include dates if present in payload
      ...(payload?.fromDate && {fromDate: payload?.fromDate}),
      ...(payload?.toDate && {toDate: payload?.toDate})
    };
    // Track the download event
    amplitudeTrack("DOWNLOAD_REPORT", eventProperties);
  }

  

  if (ADMIN_CHARTS?.includes(reportType)) { 
    responseData = await getAdminChartCounts(payload)
  }

  if (FINANCIALS_CHARTS?.includes(reportType)) {
    responseData = await getFinancialsChartCounts(payload)
  }

  if (OPERATIONS_CHARTS?.includes(reportType)) {
    responseData = await getOperatinsChartCounts(payload)
  }

  if (CUSTOMER_SERVICE_CHARTS?.includes(reportType)) {
    responseData = await getCustomerServiceChartCounts(payload)
  }

  if (SYSTEM_CHARTS?.includes(reportType)) {
    responseData = await getSystemChartCounts(payload)
  }

  if (BROKER_CHARTS?.includes(reportType)) {
    responseData = await getBrokerChartCounts(payload)
  }
  
  if (EMPLOYEE_CHARTS?.includes(reportType)) {
    responseData = await getEmployeeChartCounts(payload)
  }

  if (CUSTOMER_CHARTS?.includes(reportType)) {
    responseData = await getCustomerChartCounts(payload)
  }

  if (EQUIPMENT_CHARTS?.includes(reportType)) {
    responseData = await getEquipmentChartCounts(payload)
  }
  if (TRACK_CHARTS?.includes(reportType)) {
    responseData = await getTrackChartCounts(payload)
  }

  if (responseData && !payload?.isDownload && Object.keys(responseData)?.length > 0) {
    responseData.reportData = convertDataForChart(responseData?.reportData, reportType, payload?.isTableData)
    if(!payload?.isTableData) responseData = {
      ...responseData,
      reportData: responseData?.reportData?.filter(item => item && ( !_.isNumber(item?.count || 0) || item?.count > 0 ))
  }
  }
  return responseData
}

// Function to map type of load data
export const typeOfLoadDataMapper = (data, payloadObjKey1, payloadObjKey2, responseObjKey3, responseObjKey4 = "count") => {
  const transformedData = []
  data.forEach(item => {
    const transformedObj = {}
    let type;
    switch (item[payloadObjKey1]) {
      case 'IMPORT':
        type = 'Import';
        break;
      case 'EXPORT':
        type = 'Export';
        break;
      case 'ROAD':
        type = 'Road';
        break;
      case 'BILL_ONLY':
        type = 'Bill';
        break;
      default:
        type = item.type;
    }
    transformedObj[responseObjKey3] = type
    transformedObj[responseObjKey4] = item[payloadObjKey2]
    transformedData?.push(transformedObj);
  })
  return transformedData
}

// Function to get rows of IndexDB
export const getRowsOfIndexDB = (inputObj, regex) => {
  const financialKeys = [];
  for (const key in inputObj) {
    if (key?.startsWith(regex)) {
      financialKeys.push(key);
    }
  }

  return financialKeys;
}

// Function to format number
export const formatNumber = (number) => {
  return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

// Function to get data keys for stacked bar chart
export const getDataKeysForStackedBarChart = (data, keysForStackedBarChart) => {
  if (!data || !Array.isArray(data) || data.length === 0 || !data[0]) {
      return [];
  }
  const keys = Object.keys(data[0]);
  return keys.filter(key => keysForStackedBarChart.includes(key));
};

// Function to format time zone string
export const formatTimeZoneString = (str) => {
  return str
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
}

// Function to fix number
export const fixedNumer = (number = 0) => {
  let average = true
  if (number < 1000) {
    average = false
  }
  return numbro(number).format({
    average: average,
    mantissa: 2,
    optionalMantissa: false
  })?.replace('k', 'K').replace('m', 'M').replace('b', 'B').replace('t', 'T')
}

// Function to convert filter data to string array
export const convertFilterDataToStringArray = (filterData) => {
  const result = {};
  Object.keys(filterData || {}).forEach((key) => {
    if (filterData[key]?.length > 0) {
      result[key] = filterData[key]?.map(item => item?.value);
    }
  });
  return result;
}

// Function to convert chart filter data to string array
export const convertChartFilterDataToStringArray = (filterData, chartLevelFilterData = {}) => {
  const result = {};
  Object.keys({ ...filterData, ...chartLevelFilterData } || {}).forEach((key) => {
    const data = [...(filterData?.[key] || []), ...(chartLevelFilterData?.[key] || [])].filter(val => val !== null && val !== undefined);
    result[key] =  _.uniqBy(data, 'value');
  });
  return result;
}

// Function to get dashboard filter options
export const getDashboardFilterOptions = async (props) => {
  const {
    filterTitle,
    customers,
    terminals,
    consignees,
    shippers,
    driverList,
    customerActions,
  } = props;
  let options = [];

  switch (filterTitle) {
    case DASHBOARD_CHARTS_FILTER_NAME?.customer:
      if (customers?.length > 0) {
        options = makeOptionsForSelect(customers, "company_name", "address.address", "_id");
      } else {
        options = [];
      }
      break;

    case DASHBOARD_CHARTS_FILTER_NAME?.terminal:
      if (terminals?.length > 0) {
        options = makeOptionsForSelect(terminals, "name", null, "_id");
      } else {
        options = [];
      }
      break;
    case DASHBOARD_CHARTS_FILTER_NAME?.consignee:
      if (consignees?.length > 0) {
        options = makeOptionsForSelect(consignees, "company_name", "address.address", "_id");
      } else {
        options = [];
      }
      break;
    case DASHBOARD_CHARTS_FILTER_NAME?.shipper:
    case DASHBOARD_CHARTS_FILTER_NAME?.empty_origin:
      if (consignees?.length > 0) {
        options = makeOptionsForSelect(shippers, "company_name", "address.address", "_id");
      } else {
        options = [];
      }
      break;
    case DASHBOARD_CHARTS_FILTER_NAME?.type_of_load:
      options = LOAD_TYPES
      break;
    case DASHBOARD_CHARTS_FILTER_NAME?.route:
      options = [
        { label: "Local", value: "Local" },
        { label: "Highway", value: "Highway" }
      ];
      break;
    case DASHBOARD_CHARTS_FILTER_NAME?.driver:
      options = driverList?.map((d) => ({
        value: d._id,
        label: `${d.name} ${d.lastName}`.trim(),
        allInfo: d,
      }));
      break;
    case DASHBOARD_CHARTS_FILTER_NAME.employee:
      if (customerActions) {
        let data = { active: true };
        await customerActions.listAllFleetManagers(data, 'fromCsr').then((options2) => {
          options = (options2 || []).map((obj) => ({
            value: obj.value,
            label: obj.allInfos.name + " " + obj.allInfos.lastName,
            allInfos: obj,
          }));
        });
      } else {
        options = [];
      }
      break;
    default:
      options = [];
      break;
  }

  return options;
};

export const getDashboardFilterLoadOptions = async (filterTitle, e, filterOptions) => {
  if ([
    DASHBOARD_CHARTS_FILTER_NAME?.customer,
    DASHBOARD_CHARTS_FILTER_NAME?.consignee,
    DASHBOARD_CHARTS_FILTER_NAME?.shipper,
    DASHBOARD_CHARTS_FILTER_NAME?.empty_origin,
  ].includes(filterTitle)) {
    return await getCustomerAsyncSearch(e);
  } else {
    return filterOptions?.filter((i) => i?.label?.toLowerCase()?.includes(e?.toLowerCase())) || [];
  }
};

export const getDashboardFilters = (dashboardName) => {
  const FILTER_FOR_DASHBOARD_CHARTS = {
    [DASHBOARDS.FINANCIALS]: [
      "customer",
      "consignee",
      "shipper",
      "empty_origin",
      "route",
      "type_of_load",
      ...(showForTerminal() ? ["terminal"] : []),
      "driver",
    ],
    [DASHBOARDS.BROKER]: [
      "route",
      "type_of_load",
      ...(showForTerminal() ? ["terminal"] : []),
    ],
    [DASHBOARDS.CUSTOMER]: [
      "customer"
    ],
    [DASHBOARDS.CUSTOMER_SERVICE]: [
      "customer",
      "consignee",
      "shipper",
      "empty_origin",
      "route",
      "type_of_load",
      ...(showForTerminal() ? ["terminal"] : []),
      "driver",
      "employee"
    ],
    // [DASHBOARDS.EQUIPMENT]: [
    //     "terminals",
    //     "trailer_size",
    //     "year",
    //     "trailer_type",
    //     "reg_exp",
    //     "inspection_exp",
    //     "hut_exp",
    // ],
    [DASHBOARDS.OPERATIONS]: [
      "customer",
      "consignee",
      "shipper",
      "empty_origin",
      "route",
      "type_of_load",
      ...(showForTerminal() ? ["terminal"] : []),
      "driver",
    ],
    // [DASHBOARDS.SYSTEM]: [
    //     "terminals",
    //     "trailer_size",
    //     "year",
    //     "trailer_type",
    //     "reg_exp",
    //     "inspection_exp",
    //     "hut_exp",
    // ],
    // [DASHBOARDS.TRACK]: [
    //     "terminals",
    //     "trailer_size",
    //     "year",
    //     "trailer_type",
    //     "reg_exp",
    //     "inspection_exp",
    //     "hut_exp",
    // ],
    [DASHBOARDS.ADMIN]: [
      "customer",
      "consignee",
      "shipper",
      "empty_origin",
      "route",
      "type_of_load",
      ...(showForTerminal() ? ["terminal"] : []),
    ],
    // [DASHBOARDS.EMPLOYEES]: [
    //     "terminals",
    //     "trailer_size",
    //     "year",
    //     "trailer_type",
    //     "reg_exp",
    //     "inspection_exp",
    //     "hut_exp",
    // ],
  };
  return FILTER_FOR_DASHBOARD_CHARTS?.[dashboardName] || []
}

export const TypeDashBoardContext = createContext();