import { useMemo } from "react";
import useLocalStorage from "../helpers/useLocalStorage";

/**
 * useCurrentUser hooks gets the currently loggedin user with other useful data.
 * check the returned values for the use cases.
 */
const useCurrentUser = () => {
  const [currentUser] = useLocalStorage("loggedInUser", null, true);
  const [currentCarrier] = useLocalStorage("carrierDetail", null, true);

  const [timeZone] = useLocalStorage("timeZone");

  const currentUserId = useMemo(() => currentUser?._id, [currentUser]);
  const currentCarrierId = useMemo(() => currentCarrier?._id, [currentCarrier]);
  const currentUserRole = useMemo(() => currentUser?.role, [currentUser]);

  return {
    currentUser,
    currentCarrier,
    currentCarrierId,
    currentUserId,
    currentUserTimeZone: timeZone,
    currentUserRole,
  };
};

export default useCurrentUser;
