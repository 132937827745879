import { useState } from "react";
import _ from "lodash";
import { archiveEmail } from "../actionCreator";
import { getStorage, toastr } from "../../../../services";
import { EMAIL_MSG, EMAIL_PROVIDER, EMAIL_TABS, OUTLOOK_PROVIDER } from "../constant";

export const useArchiveEmail = (activeNav, allEmails, setAllEmails, callback) => {
  const [data, setData] = useState(null);
  const [archiveLoaderIds, setArchiveLoaderIds] = useState([]);
  const embeddedEmailAccount = JSON.parse(getStorage('embeddedEmailAccount'));
  const { provider } = embeddedEmailAccount ?? {}
  const _archiveEmail = async (params) => {
    setArchiveLoaderIds(params.ids);
    try {
      const data = await archiveEmail(params);
      setArchiveLoaderIds([]);
      if(data?.failed?.length>0) {
        setArchiveLoaderIds([]);
        return toastr.show('Something went wrong!', "error");
      }
      setData(data);
      const uniqueFolders = _.uniq(_.flatMap(data.success, 'folders'));
      if(provider===EMAIL_PROVIDER.GMAIL)uniqueFolders.push("INBOX")
      callback(uniqueFolders);
      if (activeNav === EMAIL_TABS.INBOX) {
        const successfullyArchived = _.cloneDeep(data?.success);
        const archivedMailIds = successfullyArchived?.map((email) => email?.id);
        const newMails = allEmails?.filter((email) => !archivedMailIds?.includes(email?.id));
        setAllEmails(newMails);
      } else {
        const successfullyArchived = _.cloneDeep(data?.success);
        const archivedMailIds = successfullyArchived?.map((email) => email?.id);
        const oldMails = _.cloneDeep(allEmails);
        const newMails = oldMails?.map((email) => {
          let data
          if(archivedMailIds?.includes(email?.id)) {
            data = {...email, folders:  email?.folders.filter((d) => d.name !== "INBOX")}
          } else {
            data = email
          }
          return data
        });
        setAllEmails(newMails);
      }
        toastr.show(EMAIL_MSG.EMAIL_ARCHIVED, "success");
    } catch (err) {
      setArchiveLoaderIds([]);
      console.log(err);
      toastr.show(EMAIL_MSG.SOMETHING_WENT_WRONG, "error");
    }
  };
  return { data, archiveLoaderIds, _archiveEmail };
};
