import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form'

import {
  HomeReducer,
  LoginReducer,
  ProfileReducer,
} from '../Components/';

import {
  filterUsers,
  changeNav,
  changeEquipemt
} from './headerReducers';

import {
  problemContainers
} from './problemContainerReducers';

import 
  driverPayGroupsReducer
  from './driverPayGroupsReducer';
 
  import 
  carrierGroupsReducer
 from './carrierGroupsReducer';

// Tms reducers
import TmsReducer from '../pages/tms/TmsReducer';
import accountingServiceReducer from './accountingServiceReducer';
import containerReducer from './containerReducer';
import chassisReducer from './chassisReducer';
import onboardingReducer from './onboardingReducer';
import adminReducer from './adminReducer';
import StatesReducer from '../Components/DynamicPricing/StatesReducer';
import chargeCodeReducer from './chargeCodeReducer';
import dispatcherTableReducer from './dispatcherTableReducer';
import qbReducer from './qbReducers';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'
import tariffChargeProfilesFilterReducer from './tariffChargeProfilesFilterReducer';
import emailTagsReducer from './emailTagsReducer';
import emailCountReducer from "./emailCountReducer"

import appointmentReducer from 'pages/tms/AppointmentModal/store/reducer'
import emailConfigurationReducer from './emailConfigurationReducer'; 
import measureUnitsReducer from './measureUnitsReducer';
import trackingReducer from "./trackingReducer";

export default combineReducers({
  HomeReducer: persistReducer({key: "HomeReducer", blacklist: ["groups", "unreadMessages"], storage}, HomeReducer),
  LoginReducer,
  form: formReducer,
  HeaderReducers: filterUsers,
  changeNav,
  changeEquipemt,
  TmsReducer: persistReducer({key: "TmsReducer", blacklist: ["apptParams"], storage}, TmsReducer),
  ProfileReducer,
  problemContainers,
  containerReducer,
  chassisReducer,
  onboardingReducer,
  adminReducer,
  StatesReducer,
  chargeCodeReducer,
  driverPayGroupsReducer,
  dispatcherTableReducer,
  qbReducer,
  appointmentReducer,
  carrierGroupsReducer,
  accountingServiceReducer,
  tariffChargeProfilesFilterReducer,
  emailTagsReducer,
  emailConfigurationReducer,
  emailCountReducer,
  measureUnitsReducer,
  trackingReducer,
});
