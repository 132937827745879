import React from "react";
import { confirmAlert } from "react-confirm-alert";
import { CustomIconAlert } from "../CustomIcons/Index";

const confirm = (title, message, callback, options) => {
  confirmAlert({
    title,
    message,
    onClickOutside: () => {
      callback(false);
    },
    customUI: ({ title, message, onClose }) => {
      const showInputText =
        options && options.showInputText ? options.showInputText : false;
      const showCheckBox =
        options && options.showCheckBox ? options.showCheckBox : false;
      const showCheckBoxForNegativeDriverSettlement =
        options && options.showCheckBoxForNegativeDriverSettlement ? options.showCheckBoxForNegativeDriverSettlement : "";
      const defaultValue =
        options && options.defaultValue ? options.defaultValue : null;
      const duplicateTemplate = 
        options?.isDuplicateTemplate ? options?.isDuplicateTemplate : false;
      return (
        <div className="custom-ui card">
          <div className={`${options?.justifyContentCenter ? 'justify-content-center' : ''} ${(!options?.isEmailTemplate || !options?.isViewRule || options?.isDeleteTrip) && `modal-header ${(showInputText || options?.isDeleteTrip || options?.isDualTransaction) && 'd-none'}`}`}>
            <h4 className="modal-title" id="myModalLabel">
              {title}
            </h4>
          </div>

          <div className="modal-body">
            <div className={`${!showInputText && 'text-center'}`}>
              {!duplicateTemplate && (<div className={`my-30 ${(options?.isEmailTemplate || options?.isViewRule) && ""} ${options?.isDeleteTrip && "mt-30"}`}>
              {!showInputText && (
               <CustomIconAlert />
              )}
                
              </div>)}
              
              {(showInputText || options?.isDeleteTrip || options?.isDualTransaction) &&
              <h4 className="modal-title mb-2">{title}</h4>
              }
              <p className={`${options?.isNewCreateLoad ? 'font-16' : options?.isOverwrite ? 'font-16' : (options?.isEmailTemplate || options?.isViewRule) ? 'text-dark font-weight-500 px-20 font-20 line-height-25' : 'text-muted font-14 line-height-22'} ${ options?.removeInvoice && 'px-30 ml-60 mr-60 text-muted font-14' }`}>{message}</p>
              {options?.warningMessage && options?.warningMessage}
              {showInputText && (
                <div className="form-group w-150 mt-20">
                  <input
                    type="number"
                    className="form-control"
                    defaultValue="1"
                    min="1"
                    max="50"
                    id="count"
                    style={{ width: 150 }}
                  />
                </div>
               
              )}
              {options?.isEmailTemplate && <h6 className="font-14 text-muted font-weight-normal">You won’t be able to recover once deleted</h6>}
              {showCheckBox && (
                <>
                <div className="form-check mb-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="carryOverPricing"
                  />
                  <label
                    className="form-check-label"
                    style={{ overflowWrap: "break-word" }}
                    htmlFor="carryOverPricing"
                  >
                   Do Not Carry Over Pricing
                  </label>
                </div>
                  <div className="form-check mb-2">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="carryOverDriverAndBillingNotes"
                      defaultChecked = {true}
                    />
                    <label
                      className="form-check-label"
                      style={{ overflowWrap: "break-word" }}
                      htmlFor="carryOverDriverAndBillingNotes"
                    >
                    Do Not Carry Over Driver & Billing Notes
                    </label>
                  </div>
                  <div className="form-check mb-2">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="createWithExistingLoadRouting"
                      defaultChecked = {true}
                    />
                    <label
                      className="form-check-label"
                      style={{ overflowWrap: "break-word" }}
                      htmlFor="createWithExistingLoadRouting"
                    >
                    Create with routing template
                    </label>
                  </div>
                </>
              )}
              {
                showCheckBoxForNegativeDriverSettlement && (
                  <>
                    <div className="form-check mb-2">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="negativeDriverSettlement"
                      />
                      <label
                        className="form-check-label"
                        style={{ overflowWrap: "break-word" }}
                        htmlFor="negativeDriverSettlement"
                      >
                        Transfer the negative net pay amount as deduction to next settlement
                      </label>
                    </div>
                  </>
                )
              }
            </div>
          </div>

          <div className="modal-footer">
            <button
              className="btn btn-close"
              onClick={() => {
                callback(false);
                onClose();
              }}
            >
              Cancel
            </button>
            <button
              className={`btn ${(options?.isEmailTemplate || options?.isViewRule || options?.isDeleteTrip || options?.isDualTransaction) ? 'btn-danger' : 'btn-primary'}`}
              onClick={() => {
                callback(
                  true,
                  showInputText ? document.getElementById("count").value : null,
                  showCheckBox
                    ? !document.getElementById("carryOverPricing").checked
                    : null,
                  showCheckBox
                    ? !document.getElementById("carryOverDriverAndBillingNotes").checked 
                    : null,
                  showCheckBoxForNegativeDriverSettlement 
                    ? document.getElementById("negativeDriverSettlement").checked 
                    : null,
                  false,
                  showCheckBox
                    ? !document.getElementById("createWithExistingLoadRouting").checked 
                    : null,
                );
                if(options?.disableClose) return;
                onClose();
              }}
            >
              {options?.isDualTransaction ? 'Disconnect' : (showInputText || options?.isVendorPay) ? 'Confirm' : options?.isOverwrite ? 'Yes, Overwrite' : options?.isNewCreateLoad ? 'Yes, Create a New Load' : options?.isCreateLoad ? 'Create Load' : options?.isEmailTemplate ? `Delete ${options?.isEmailGroup ? 'Group' : options?.isEmailRecord ? 'Record' : 'Template'}` : options?.isViewRule ? 'Proceed' : options?.isDeleteTrip ? 'Delete Trip' : 'Yes' }
            </button>
          </div>
        </div>
      );
    },
  });
};

export default confirm;
