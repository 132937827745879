const ACTIONS = {
  SET_SELECTED_DRIVER: "SET_SELECTED_DRIVER",
  SET_SEARCHED_DRIVER: "SET_SEARCHED_DRIVER",
  SET_UNSELECTED_DRIVER: "SET_UNSELECTED_DRIVER",
  SET_DRIVERS: "SET_DRIVERS",
  SET_LOADING: "SET_LOADING",
  UPDATE_TRACKING_DRIVER: "UPDATE_TRACKING_DRIVER",
  ASSIGN_LOAD: "ASSIGN_LOAD",
  UPDATE_LOAD_DOCS: "UPDATE_LOAD_DOCS",
  SET_FORMIK_VALUES: "SET_FORMIK_VALUES",
  UPDATE_DRIVERS: "UPDATE_DRIVERS",
};

export default ACTIONS;
