export const truckHistoryCoordinates = (state) => {
  const geoPoints = state?.geoPoints || [];

  if (!geoPoints) return [];

  const lines = geoPoints?.map((points) => {
    return points?.map((point) => {
      return {
        lat: point?.lat,
        lng: point?.lng,
        devicetime: point?.devicetime,
        speed: point?.speed, // mph
        tsp_source: point?.tsp_source
      }
      // [point?.lat, point?.lng, point?.devicetime];
    });
  });

  return lines ?? [];
};

export const truckHistoryCombinedCoordinates = (state) => {
  const polyline = truckHistoryCoordinates(state);

  const boundPoints = [];

  polyline?.forEach((points) => {
    points?.forEach((point) => {
      boundPoints.push(point);
    });
  });

  return boundPoints;
};

export const isTrackingHistoryLoading = (state) => {
  return state?.isLoading;
};
