import React, { useEffect, useRef, useState } from "react"
import { useDispatch, shallowEqual, useSelector } from "react-redux"
import _ from "lodash"
import moment from "moment"
import { LoaderBar } from "../../../Components/Common/LoaderBar"
import config from "../../../config"
import {
  amplitudeTrack,
  getCarrierId,
  getNewTranscation,
  getStorage,
  isCabotageEnabled,
  isConnectedWithOutSource,
  isDualTransactionEnabled,
  isManageFleetOrOutSource,
  isNewSupportMovesEnabled,
  supportMoveEnabled,
  toastr
} from "../../../services"

import DriverOrderSidebar from "../Load/DriverOrderSidebar"
import { getSupportMoves } from "../Load/actionCreator"
import { statusType, SUPPORT_MOVE_LEG_EVENTS, SUPPORT_MOVE_TO_ROUTING } from "../LoadRouting/constants"

import { isValidDriverOrder } from "../LoadRouting/utilities"
import { createSource, DUAL_TRANSACTION_MESSAGES, loadEvents, SUPPORT_MOVE_EVENTS, tmsDrops, tmsEndMoveEvents, tmsHooks, tmsStartMoveEvents } from "../constant"
import DriverOrder from "./DriverOrder"
import RoutingHelperSection from "./RoutingHelperSection"
import { addManualSupportMove, cabotagePointCalculate, manageEventOrder, updateLoadOptions } from "./actionCreator"
import { getDefaultDropLocation, manualSupportMoveValidation, routingEventsOnDropValidation, validateOrderStatus } from "./helpers"

import { IconCancel } from "Components/Common/Icons"
import { getLoadDetailFromRedis, lastUpdatedLoadChannel, getCabotageDetailFromRedis } from "../../../services/common"
import SingleLoadSelection from "../ConnectNotices/LoadInfoChanges/SingleLoadSelection"
import { REQUEST_TYPES, STATUS } from "../ConnectNotices/constant"
import { VENDOR_TYPES } from "../DrayosCarrier/constants"
import { getLoadSyncRequests } from "../Load/actionCreator"
import ChassisValidationModal from "./ChassisValidationModal"
import { CABOTAGE_CODE_STATUS, completedStatus } from './constant'
import { AmplitudeSourceName, AmplitudeSupportMoveEvent } from "utils/AmplitudeEventConstant"
import { CabotageViolationModal } from 'pages/tms/LoadRoutingV2/CabotageViolationModal'
import AlertBreakDualTransaction from "Components/Common/AlertBreakDualTransaction"
import { isCombineTripEnabled } from "../Dispatcher/NewDriverItinerary/constants"
const firebase = config.firebase;
const NewFirebase = config.getNewFirebaseInstance;
const db = NewFirebase('load-routing-v2').database();

const NewLoadDriverOrderV2 = (props) => {
  const {
    selectedLoads,
    originalLoad,
    showNotesModel,
    changeTopTab,
    dispatcherModal,
    closeStatusModal,
    showEvent,
    newSyncRequests,
    _setOpenModalRefNumber,
    isLoadWithSingleVendor,
    updateLoadInfo,
    updateLoadRef
  } = props
  const dispatch = useDispatch();
  const { drivers } = useSelector((state) => state?.TmsReducer, shallowEqual);
  const [loadDetails, setLoadDetails] = useState(selectedLoads)
  const [driverOrder, setDriverOrder] = useState();
  const [supportMoves, setSupportMoves] = useState([])
  const [droppedIndex, setDroppedIndex] = useState()

  const oldLoadDetails = useRef(selectedLoads);

  const [customer, setCustomer] = useState({
    CHASSISPICKCUSTOMER: loadDetails?.chassisPick || null,
    PULLCONTAINERCUSTOMER: loadDetails?.shipper?.[0] || null,
    DELIVERLOADCUSTOMER: loadDetails?.consignee?.[0] || null,
    RETURNCONTAINERCUSTOMER: loadDetails?.emptyOrigin || null,
    DROPCONTAINERCUSTOMER: null,
    LIFTOFFCUSTOMER: null,
  })

  const [loading, setLoading] = useState()

  const [selectedMoveFilter, setSelectedMoveFilter] = useState(isNewSupportMovesEnabled() ? null : "containerMove")
  const [allowDriverCompletion, setAllowDriverCompletion] = useState(loadDetails.allowDriverCompletion)
  const [isDragging, setIsDragging] = useState(false)
  const currentUserDetails = JSON.parse(getStorage("loggedInUser"));
  const [loadCompletedAt, setLoadCompletedAt] = useState(loadDetails.loadCompletedAt);

  const [isShowTripModal, setIsShowTripModal] = useState(false);
  const [driverPays, setDriverPays] = useState()
  const [isDriverPayLoading, setIsDriverPayLoading] = useState(false)
  const [chassisValidation, setChassisValidation] = useState(false);
  const [invalidDriverOrder, setInvalidDriverOrder] = useState(null);
  const [isInvalidDriverOrder, setIsInvalidDriverOrder] = useState(false);
  
  const [routingSyncRequests, setRoutingSyncRequests] = useState([]);
  const [loadSyncRequestsData, setLoadSyncRequestsData] = useState([]);
  const [requestBanner, setRequestBanner] = useState(true);
  const [isReviewModalVisible, setIsReviewModalVisible] = useState(false);
  const [routingSentSyncRequests, setRoutingSentSyncRequests] = useState([]);
  const shiposOrConnectCarrier = isConnectedWithOutSource();
  const [receivedRoutingSyncRequests, setReceivedRoutingSyncRequests] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState(null)
  const [showViolatedModal, setShowViolatedModal] = useState(false);

  const savedFunction = useRef();
  const supportMovesDataRef = useRef();
  const driversDataRef = useRef();
  const isBrokerTender = loadDetails?.createSource === createSource.BROKER_TENDER;
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));

  let carrierId = currentUserDetails._id
  if (currentUserDetails.role === "fleetmanager") {
    carrierId = currentUserDetails.fleetManager.carrier
  }

  // TODO: Get Driver Pay and Support Move Func.
  const _getDriverPayAndSupportMoves = async () => {
    if (loadDetails && supportMoveEnabled()) {
      await getSupportMoves({ reference_number: loadDetails.reference_number })
        .then((result) => {
          setSupportMoves(result)
          _getDriverPays(result)
        })
        .catch((err) => {
          _getDriverPays(supportMoves)
          console.log(err)
        })
    } else {
      _getDriverPays(supportMoves)
    }
  }
  // TODO: Get Driver Pays Func.
  const _getDriverPays = (supportMove, load) => {
    const loadDetailData = load ? load : loadDetails
    const isDriverExists = loadDetailData?.driverOrder.find((d) => d.driver && !d.isVoidOut)
    if (isDriverExists) {
      setIsDriverPayLoading(true);
      const payload = { reference_number: selectedLoads.reference_number }
      const supportMoves = supportMove?.map((x) => x?._id + "")
      if (supportMoves?.length) {
        payload.supportMoves = supportMoves
      }
      getNewTranscation(payload)
        .then((response) => {
          if (response?.data?.data?.result) {
            setDriverPays(response.data.data.result);
          }
          setIsDriverPayLoading(false);
        })
        .catch((err) => {
          setIsDriverPayLoading(false);
          console.log(err)
        })
    } else {
      setDriverPays([]);
    }
    }

  // TODO: Drag & Drop all Func.
  const handleDragStart = (e) => {
    setIsDragging(true)
    e.dataTransfer.setData("loadStatusType", e.target.id)
    e.stopPropagation()
  }
  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }
  const handleDragEnter = (index) => {
    if (isDragging && droppedIndex !== index) setDroppedIndex(index)
  }
  const handleOnDrop = async (e, lst, skipValidation) => {
    const isCompleted =
      completedStatus.indexOf(
        loadDetails.status
      ) > -1
    if (isCompleted){
      handleDragOver(e)
      toastr.show("The load is now completed!", "error");
      return 
    }

    const loadStatusType = e.dataTransfer.getData("loadStatusType") || lst;
    let currentIndex = droppedIndex;
    let _driverOrder = _.cloneDeep(driverOrder)

    if (currentIndex >= 100) {
      currentIndex -= 101
    }
    currentIndex += 1;

    const allowAddEventAfterDrop = isLoadWithSingleVendor == undefined ? isBrokerTender : !isLoadWithSingleVendor;
    
    if(allowAddEventAfterDrop && ((currentIndex === _driverOrder?.length && tmsDrops.includes(_driverOrder[_driverOrder?.length - 1]?.type)) || (currentIndex === 0 && tmsHooks.includes(_driverOrder[0]?.type)))) {
      handleDragOver(e)
      return
    }

    if (isCombineTripEnabled() || isDualTransactionEnabled()) {
      const currentEventIndex = droppedIndex >= 100 ? droppedIndex - 100 : droppedIndex;
      const currentEvent = driverOrder[currentEventIndex];
      const currentMove = driverOrder.filter(({ isVoidOut, moveId }) => !isVoidOut && moveId + "" === currentEvent.moveId + "");
      if (currentEvent?.combineTripId || currentEvent?.isDualTransaction) {
        if (currentEvent?.isDualTransaction) {
          if ((currentEvent?.dualTransactionFrom && tmsStartMoveEvents.includes(loadStatusType)) ||
            (currentEvent?.dualTransactionTo && ['CHASSISTERMINATION'].includes(loadStatusType))) {
            await AlertBreakDualTransaction(loadDetails, DUAL_TRANSACTION_MESSAGES.ADD_NEW_EVENT, (val) => { setLoading(val) });
          }
        }
        if (currentMove[currentEventIndex + 1]?.arrived) {
          handleDragOver(e);
          toastr.show(`Cannot add ${loadStatusType} because the current move has inprogress.`, "error");
          return;
        } 
      }
    }

    if (currentIndex === _driverOrder?.length && _driverOrder?.[currentIndex-1]?.vendorType === VENDOR_TYPES.RAIL) {
      handleDragOver(e) 
       return
    }

    if (_driverOrder?.[currentIndex]?.arrived && !_driverOrder?.[currentIndex]?.isVoidOut) {
      handleDragOver(e) 
       return
    } 
    if (_driverOrder?.[currentIndex]?.tenderId?.drayOSTenderRefNo && _driverOrder?.[currentIndex]?.tenderId?.status !== "REJECTED") {
      handleDragOver(e) 
       return
    }

    if (_driverOrder?.[currentIndex]?.vendorType === VENDOR_TYPES.RAIL) {
      handleDragOver(e) 
       return
    }
    const isDeliveryBefore =
      driverOrder.findIndex((x, i) => x?.type === loadEvents.DELIVERLOAD && !x?.isVoidOut && i < currentIndex) !== -1
    if (isDeliveryBefore && loadStatusType === loadEvents.PULLCONTAINER) {
      handleDragOver(e)
      toastr.show("Pickup Container cannot be after Load Delivery", "error")
    }
    const beforeLiftOnAndNotChassisEvent =
      _driverOrder?.[currentIndex]?.type === loadEvents.LIFTON &&
      ![loadEvents.CHASSISTERMINATION, loadEvents.CHASSISPICK].includes(loadStatusType)
    if (_driverOrder?.[currentIndex]?.type === loadEvents.HOOKCONTAINER || beforeLiftOnAndNotChassisEvent) {
      if (!_driverOrder?.[currentIndex].isVoidOut) currentIndex += 1
      else {
        const validNextEvent = driverOrder.findIndex((x, i) => !x?.isVoidOut && i > currentIndex)
        if (validNextEvent >= 0) currentIndex += validNextEvent - currentIndex + 1
      }
    }
    savedFunction.current = () => handleOnDrop(e, loadStatusType, true)

    if (isNewSupportMovesEnabled() && SUPPORT_MOVE_LEG_EVENTS.includes(loadStatusType)) {
      const [currentMove, validEvent, err] = manualSupportMoveValidation(droppedIndex, driverOrder, loadStatusType);
      if (err) {
        toastr.show(err, 'error');
        handleDragOver(e)
        setIsDragging(false)
        return;
      }
      let payloadData = {
        supportMoveType: loadStatusType,
        moves: [],
        ...(loadDetails?.chassisNo && { chassisNo: loadDetails.chassisNo }),
        ...(loadDetails?.chassisId && { chassisId: loadDetails.chassisId }),
        ...(loadDetails?.chassisSize?._id && { chassisSize: loadDetails.chassisSize._id }),
        ...(loadDetails?.chassisType?._id && { chassisType: loadDetails.chassisType._id }),
        ...(loadDetails?.chassisOwner?._id && { chassisOwner: loadDetails.chassisOwner._id })
      };
      if ([SUPPORT_MOVE_EVENTS.CHASSISPICK, SUPPORT_MOVE_EVENTS.CHASSISTERMINATION, SUPPORT_MOVE_TO_ROUTING.DROPCHASSIS].includes(loadStatusType)) {
        const moveEvent = {
          loadId: loadDetails?._id,
          customerId: validEvent?.customerId?._id ?? validEvent?.customerId,
          eventId: validEvent?._id,
          eventType: validEvent?.type
        }
        payloadData.loadAssignedDate = validEvent?.loadAssignedDate;
        payloadData.priority = validEvent?.priority;
        payloadData.moves.push(moveEvent);
        const newEvent = { loadId: loadDetails?._id, eventType: loadStatusType }
        if ([loadEvents.PULLCONTAINER, loadEvents.LIFTON].includes(validEvent.type)) payloadData.moves.unshift(newEvent)
        else payloadData.moves.push(newEvent)
      } else if ([SUPPORT_MOVE_EVENTS.BOBTAILTRANSFER].includes(loadStatusType)) {
        toastr.show("Invaid support move Type", 'error');
        handleDragOver(e)
        setIsDragging(false)
        return;
      } else {
        toastr.show("Something went wrong!!!", 'error');
        handleDragOver(e)
        setIsDragging(false)
        return;
      }
      setLoading(true)
      payloadData.driverId = validEvent?.driver?._id ?? validEvent?.driver;
      addManualSupportMove(payloadData).then((data) => {
        if (data?.isChassisv3) {
          setChassisValidation(data?.isChassisv3)
          return
        }
        let eventProperties = {
          source: AmplitudeSourceName.ROUTING_TAB,
          supportMoveType: loadStatusType
        }
        amplitudeTrack(AmplitudeSupportMoveEvent.CREATE_MANUAL_SUPPORT_MOVE,eventProperties);
        setSupportMoves([...supportMoves, data]);
        setSelectedMoveFilter(null)
        setLoading(false)
      })
        .catch((err) => {
          setLoading(false)
          console.log("🚀 ~ handleOnDrop ~ err:", err)
        })
      handleDragOver(e)
      setIsDragging(false)
      return;
    }

    if (loadStatusType === "COMPLETED") {
      if (currentIndex === driverOrder.length) {
        updateAllowDriverCompletion(true);
        handleDragOver(e)
        return
      } else {
        toastr.show("Completed status should be at end Only", "error")
        handleDragOver(e)
        return
      }
    }

    const modifiedDriverOrder = routingEventsOnDropValidation(
      _driverOrder,
      loadDetails,
      currentIndex,
      loadStatusType,
      customer
    )

    if (!modifiedDriverOrder) {
      handleDragOver(e)
      return
    };

    const driverOrderValidation = isValidDriverOrder(modifiedDriverOrder)
    if (!driverOrderValidation?.isValid) {
      setDriverOrder(modifiedDriverOrder)
      setIsInvalidDriverOrder(true)
      handleDragOver(e)
      return toastr.show(driverOrderValidation.message, "error")
    }
    setDriverOrder(modifiedDriverOrder)


    // TODO: Add New API
    setIsDragging(false)
    setDroppedIndex(null)
    if (!validateOrderStatus(modifiedDriverOrder, loadDetails)) {
			setIsInvalidDriverOrder(true)
			setDriverOrder(modifiedDriverOrder)
      handleDragOver(e)
			return;
		};
    const isDeliveryInLoad = loadDetails?.consignee?.length
    if (!isDeliveryInLoad) {
      const isDeliveryEvent = loadStatusType === loadEvents.DELIVERLOAD
      const isDriverInMove = driverOrder?.[currentIndex]?.driver
      if (isDeliveryEvent && isDriverInMove) {
        handleDragOver(e)
        return
      }
    }
    handleDragOver(e)
    let newPayload = [];
    modifiedDriverOrder?.forEach((event) => {
      newPayload.push({
        ...(event?._id ? { _id: event._id } : {}),
        type: event.type,
        ...(event?.customerId?._id ? { customerId: event.customerId._id } : {}),
        ...((loadDetails?.preSetOrderStatus !== selectedRoute) ? { isGrayPool: event.isGrayPool } : {}),
      })
    })
    let payloadData = {
      loadId: loadDetails?._id,
      driverOrder: newPayload
    };

    if (loadDetails?.preSetOrderStatus !== selectedRoute) 
			payloadData.preSetOrderStatus = selectedRoute

    setLoading(true)
            
    if (isCabotageEnabled() && selectedLoads?.driver) {
      cabotagePointCalculate({ ...payloadData, reference_number: loadDetails?.reference_number }).then(res => {
        if (res?.code === CABOTAGE_CODE_STATUS.VIOLATED) {
          setLoading(false);
          return setShowViolatedModal({ message: res?.message ?? '', response: res, payloadData, loadDetails });
        }
        handleManageEventOrder({ payloadData, loadDetails });
      }).catch((err) => {
        handleManageEventOrder({ payloadData, loadDetails });
        console.warn(err);
      });
    } else {
      handleManageEventOrder({ payloadData, loadDetails });
    }
  }

  function handleManageEventOrder({ payloadData, loadDetails }) {
    setShowViolatedModal(false);
    setLoading(true);
    manageEventOrder(payloadData).then(() => {
      setLoading(false)
      setIsInvalidDriverOrder(false)
      toastr.show("Update Successfully!", 'success')
    })
      .catch((err) => {
        console.log("🚀 ~ file: index.jsx:250 ~ handleOnDrop ~ err:", err)
        setDriverOrder(loadDetails?.driverOrder)
        setLoading(false)
      })
  }

  const hadleonDragEnd = () => {
    setDroppedIndex(null);
    setIsDragging(false);
  }

  const updateSupportMovesInLocal= (newSupportMove) => {
    setSupportMoves([...supportMoves, newSupportMove]);
    setSelectedMoveFilter(null)
  }

  // TODO: Update View Move Filter
  const handleSelectMoveFilter = (value) => {
    if (selectedMoveFilter === value) {
      setSelectedMoveFilter(null);
    } else {
      setSelectedMoveFilter(value)
    }
  }

  // TODO: Update Allow Driver Completion
  const updateAllowDriverCompletion = (value) => {
    setAllowDriverCompletion(value)
    let payload = {
      loadId: loadDetails?._id,
      allowDriverCompletion: value
    }
    setLoading(true);
    updateLoadOptions(payload)
      .then((result) => {
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log("🚀 ~ file: index.jsx:728 ~ updateAllowDriverCompletion ~ err:", err)
      })
  }

  const onChassisEventSuccess = (result) => {
    setLoading(false)
    setDroppedIndex(null)
    if(result?.driverOrder) setDriverOrder(result?.driverOrder)
    if (savedFunction.current) {
        savedFunction.current()
        savedFunction.current = null
    }
  }

  const onChassisEventFailure = () => {
    if (props && props.setOrderForCheck) props.setOrderForCheck(loadDetails.driverOrder)
    setDroppedIndex(null)
    setLoading(false)
    if(loadDetails?.driverOrder) setDriverOrder(loadDetails?.driverOrder)
  }

  useEffect(() => {
    setDriverOrder(loadDetails.driverOrder)
    fetchSyncChanges()
    if(isManageFleetOrOutSource()?.isManageFleet) _getDriverPayAndSupportMoves()
    setCustomers()

    let socketupdatedSupportMoves = firebase.database().ref(`${carrierId}/lastUpdatedMove`);
    let socketDriverPayUpdateRef = firebase.database().ref(`${carrierId}/reloadDriverPays`);
    let socketEditSupportMovesTrip = db.ref(`${getCarrierId()}/trip-edit`);
    let socketDeleteSupportMovesTrip = db.ref(`${getCarrierId()}/deletedTrip`);
    let socketLastUpdateLoad = db.ref(lastUpdatedLoadChannel());
    let cabotagePointUpdate = db.ref(`${getCarrierId()}/cabotage-point-updates`);
    // for load sync

    // Listen Load Updates
    let load
    socketLastUpdateLoad.on("value", (snapshot) => {
      try {
        const data = snapshot.val()
        const loadInfo = JSON.parse(data);
        if (loadDetails?.reference_number === loadInfo?.reference_number
          && moment().diff(moment(loadInfo.updatedAt), "seconds") <= 10) {

            getLoadDetailFromRedis(loadInfo.reference_number, loadInfo).then((loadData) => {
            if(loadData){
              if (!_.isEqual(oldLoadDetails.current?.driverOrder, loadData.driverOrder)) {
                setDriverOrder(loadData.driverOrder);
              }
              setLoadDetails(loadData);
              oldLoadDetails.current = loadData;
            }
            
        });
        }
      } catch (e) {
        console.log("Tracking exception: ", e);
      }
    });

    socketupdatedSupportMoves.on("value", (snapshot) => {
      const data = snapshot.val();
      const moveDetails = JSON.parse(data);
      if (moveDetails && moment().diff(moment(moveDetails.updatedAt), "seconds") <= 10) {
          const _supportMoves = _.cloneDeep(supportMoves);
          const sMoveIndex = _supportMoves.findIndex((sm) => sm._id === moveDetails._id);

          if (sMoveIndex > -1) {
              if (moveDetails.isDeleted) _supportMoves.splice(sMoveIndex, 1);
              else _supportMoves[sMoveIndex] = moveDetails;
          }
          else if (moveDetails.prevReferenceNo === selectedLoads?.reference_number ||
              (moveDetails.nextReferenceNo && moveDetails.nextReferenceNo === selectedLoads?.reference_number)
          ) {
              _supportMoves.push(moveDetails);
          }
          setSupportMoves(_supportMoves)
      }
    });

    if(isManageFleetOrOutSource()?.isManageFleet) {
      // Listen Driver Pay Updates
      socketDriverPayUpdateRef.on("value", (snapshot) => {
        const data = snapshot.val();
        const loadDetail = JSON.parse(data);
        if (loadDetail?.reference_number === loadDetails?.reference_number
          && moment().diff(moment(loadDetail.updatedAt), "seconds") <= 10) {
          _getDriverPays(null, load);
        }
      });
    }
    if (isNewSupportMovesEnabled()) {
      socketEditSupportMovesTrip.on('value', (snapshot) => {
        const data = snapshot.val();
        const moveDetails = JSON.parse(data);
        if (moveDetails && moment().diff(moment(moveDetails.updatedAt), "seconds") <= 10) {
          const connectedLoadIDs = moveDetails?.tripOrder?.map(p => p.loadId + "");
          if (!connectedLoadIDs?.includes(loadDetails?._id)) return; 
          let _supportMoves = _.cloneDeep(supportMovesDataRef.current);
          const _findIndex = _supportMoves.findIndex((p) => p?._id === moveDetails?._id);
          if (_findIndex !== -1) {
              if (moveDetails.isDeleted) {
                _supportMoves.splice(_findIndex, 1);
              } else {
                _supportMoves[_findIndex] = moveDetails;
                setSupportMoves(_supportMoves)
              }
          } else {
            _supportMoves = [..._supportMoves, moveDetails];
            setSupportMoves(_supportMoves)
          }
        }
      })
      socketDeleteSupportMovesTrip.on('value', (snapshot) => {
        const data = snapshot.val();
        const moveDetails = JSON.parse(data);
        if (moveDetails && moment().diff(moment(moveDetails.updatedAt), "seconds") <= 10) {
          const _supportMoves = _.cloneDeep(supportMovesDataRef.current);
          const connectedLoadIDs = moveDetails?.tripOrder?.map(p => p.loadId + "");
          const isSupportMove = _supportMoves?.find((s) => connectedLoadIDs.includes(s.loadId + ""))
          if (isSupportMove) {
            if (_supportMoves?.length) {
              const sMoveIndex = _supportMoves?.findIndex((SM) => SM._id + "" === moveDetails._id + "")
              if (sMoveIndex > -1) {
                if (moveDetails?.isDeleted) _supportMoves.splice(sMoveIndex, 1)
              }
              setSupportMoves(_supportMoves)
            }
          }
        }
      })
    }

    if (isCabotageEnabled()) {
      try {
        cabotagePointUpdate.on("value", async (snapshot) => {
          const data = snapshot.val();
          let cabotage = data;
          if (typeof data === "string") {
            cabotage = JSON.parse(data);
          }
          if (
            cabotage &&
            !cabotage.processed &&
            cabotage?.updatedAt &&
            cabotage?.token
          ) {
            const response = await getCabotageDetailFromRedis(cabotage?.token);
            if (response) {
              const driversCopy = driversDataRef.current;
              const matchIndex = driversCopy?.findIndex((driver) => driver?._id+'' === response?.driver+'' && driver?.driver?.truck?._id+'' === response?.truck+'');
              
              if (matchIndex !== -1) {
                driversCopy[matchIndex] = {
                  ...driversCopy?.[matchIndex],
                  driver: {
                    ...driversCopy?.[matchIndex]?.driver,
                    truck: {
                      ...driversCopy?.[matchIndex]?.driver?.truck,
                      cabotage: response,
                    },
                  },
                };
                await snapshot.ref.update({ processed: true });
                dispatch({
                  type: "TMS_DRIVER", 
                  payload: [...driversCopy],
                });
              }
            }
          }
        });
      } catch (error) {
        console.error("Error in cabotage listener:", error);
      }
    }
     

    return () => {
      // Cleanup code here
      if (socketupdatedSupportMoves) {
        socketupdatedSupportMoves.off("value");
      }
      if (socketEditSupportMovesTrip) {
        socketEditSupportMovesTrip.off('value')
      }
      if (socketDeleteSupportMovesTrip) {
        socketDeleteSupportMovesTrip.off('value')
      }
      if (socketDriverPayUpdateRef) {
        socketDriverPayUpdateRef.off("value");
      }
      if (socketLastUpdateLoad) {
        socketLastUpdateLoad.off("value");
      }
      if (cabotagePointUpdate) {
        cabotagePointUpdate.off("value");
      }
    };
  }, [])
  useEffect(() => {

   supportMovesDataRef.current = supportMoves
   driversDataRef.current = drivers;
  }, [supportMoves, drivers]) 

  useEffect(()=>{
    if(newSyncRequests?.loadId+"" !== loadDetails?._id+"") return;
      
      const routingSyncRequests = newSyncRequests?.syncRequests?.filter((request) => request?.status === STATUS.PENDING && request?.requestType === REQUEST_TYPES.RECIEVED && request?.fieldName === 'loadRoutingLocation')
      const routingSentSyncRequests = newSyncRequests?.syncRequests?.filter((request) => request?.status === STATUS.PENDING && request?.requestType === REQUEST_TYPES.SENT && request?.fieldName === 'loadRoutingLocation')
      const receivedRoutingSyncRequests = newSyncRequests?.syncRequests?.filter((request) => request?.requestType === REQUEST_TYPES.RECIEVED && request?.fieldName === 'loadRoutingLocation')
      setRoutingSyncRequests(routingSyncRequests)
      setRoutingSentSyncRequests(routingSentSyncRequests)
      setLoadSyncRequestsData(newSyncRequests)
      setReceivedRoutingSyncRequests(receivedRoutingSyncRequests)
  },[newSyncRequests])

  const fetchSyncChanges = () => {
    if(shiposOrConnectCarrier){
      getLoadSyncRequests({ load_reference_number: loadDetails?.reference_number }).then((data)=> {
        const routingSyncRequests = data?.syncRequests?.filter((request) => request?.status === STATUS.PENDING && request?.requestType === REQUEST_TYPES.RECIEVED && request?.fieldName === 'loadRoutingLocation')
        const routingSentSyncRequests = data?.syncRequests?.filter((request) => request?.status === STATUS.PENDING && request?.requestType === REQUEST_TYPES.SENT && request?.fieldName === 'loadRoutingLocation')
        const receivedRoutingSyncRequests = data?.syncRequests?.filter((request) => request?.requestType === REQUEST_TYPES.RECIEVED && request?.fieldName === 'loadRoutingLocation')
        setRoutingSyncRequests(routingSyncRequests)
        setRoutingSentSyncRequests(routingSentSyncRequests)
        setLoadSyncRequestsData(data)
        setReceivedRoutingSyncRequests(receivedRoutingSyncRequests)
      })
    }
  }


  const setCustomers = async () => {
    const _customer = await getDefaultDropLocation(carrierId, customer, loadDetails);
    setCustomer(_customer)
  }

	const isTenderHaveLoadRef = (driverOrder?.some(el=>el?.tenderId?.drayOSTenderRefNo && el?.tenderId?.status !== "REJECTED")) || false;
  
  const renderReturn = () => {
    return(
    <React.Fragment>
      <div className="tab position-relative h-100 d-flex flex-column">
        {dispatcherModal && (
          <div className="d-flex align-items-center my-10">
            {showEvent ? (
              <h5 className="font-20 mb-0">Add {statusType?.[showEvent] || "Event"} in Routing</h5>
            ) : (
              <h5 className="font-20 mb-0">Load #: {loadDetails && loadDetails.reference_number}</h5>
            )}
            <div className="d-flex ml-auto">
              <button className="btn btn-outline-light bg-white" onClick={() => closeStatusModal()}>
                Close
              </button>
            </div>
          </div>
        )}
        {receivedRoutingSyncRequests?.length > 0 && shiposOrConnectCarrier && requestBanner && (
          <div className="toast-container-relative position-relative mb-10 mt-10">
            <div className="toast toast-warning mw-100 text-left mb-0 py-2 pr-0">
              <div className="toast-message d-flex align-items-center">
                {receivedRoutingSyncRequests.length} Received Changes
                <button className="btn btn-sm btn-white ml-auto" onClick={() => setIsReviewModalVisible(true)}>
                  Review Changes
                </button>
                <button className="btn btn-sm btn-link" onClick={() => setRequestBanner(false)}>
                  <IconCancel />
                </button>
              </div>
            </div>
          </div>
        )}
        {isReviewModalVisible && (
          <SingleLoadSelection
            isShow={isReviewModalVisible}
            handleClose={() => setIsReviewModalVisible(false)}
            selectionData={[loadSyncRequestsData]}
            fetchSyncLoad={fetchSyncChanges}
            isFromLoadInfo={true}
            handleProcessingRequest={() => {
              setTimeout(() => {
                setIsReviewModalVisible(false);
              }, 5000);
            }}
          />
        )}
        <div className="form-row-md mt-10 flex-grow-1 h-100 overflow-hidden mx-0 gap-10">
          {loading && <LoaderBar />}
          {dispatcherModal && <DriverOrderSidebar {...props} />}
          {loadDetails.type_of_load === "BILL_ONLY" && (
            <div className="routing_info--card p-15 card w-100">This load doesn't have routing</div>
          )}
          {driverOrder?.length > 0 && (
            <RoutingHelperSection
              loadDetails={loadDetails}
              driverOrder={driverOrder}
              selectedMoveFilter={selectedMoveFilter}
              loadCompletedAt={loadCompletedAt}
              setLoadCompletedAt={setLoadCompletedAt}
              handleDragStart={handleDragStart}
              handleDragOver={handleDragOver}
              handleOnDrop={handleOnDrop}
              handleSelectMoveFilter={handleSelectMoveFilter}
              hadleonDragEnd={hadleonDragEnd}
              showEvent={showEvent}
              supportMoves={supportMoves}
              originalLoad={originalLoad}
              customer={customer}
              setDriverOrder={setDriverOrder}
              setLoading={setLoading}
              allowDriverCompletion={allowDriverCompletion}
              isTenderHaveLoadRef={isTenderHaveLoadRef}
              isInvalidDriverOrder={isInvalidDriverOrder}
              setIsInvalidDriverOrder={setIsInvalidDriverOrder}
              selectedRoute={selectedRoute}
              setSelectedRoute={setSelectedRoute}
              _setOpenModalRefNumber={_setOpenModalRefNumber}
            />
          )}
          {driverOrder?.length > 0 && (
            <DriverOrder
              driverOrder={driverOrder}
              selectedMoveFilter={selectedMoveFilter}
              loadDetails={loadDetails}
              droppedIndex={droppedIndex}
              allowDriverCompletion={allowDriverCompletion}
              updateAllowDriverCompletion={updateAllowDriverCompletion}
              loadCompletedAt={loadCompletedAt}
              handleDragStart={handleDragStart}
              handleDragOver={handleDragOver}
              handleOnDrop={handleOnDrop}
              isDragging={isDragging}
              handleDragEnter={handleDragEnter}
              loading={loading}
              setLoading={setLoading}
              showNotesModel={showNotesModel}
              changeTopTab={changeTopTab}
              supportMoves={supportMoves}
              showEvent={showEvent}
              isShowTripModal={isShowTripModal}
              setIsShowTripModal={setIsShowTripModal}
              setDriverOrder={setDriverOrder}
              savedFunction={savedFunction}
              isDriverPayLoading={isDriverPayLoading}
              setDriverPays={setDriverPays}
              driverPays={driverPays}
              isTenderHaveLoadRef={isTenderHaveLoadRef}
              invalidDriverOrder={invalidDriverOrder}
              setInvalidDriverOrder={setInvalidDriverOrder}
              isInvalidDriverOrder={isInvalidDriverOrder}
              setIsInvalidDriverOrder={setIsInvalidDriverOrder}
              routingSyncRequests={routingSyncRequests}
              routingSentSyncRequests={routingSentSyncRequests}
              fetchSyncChanges={fetchSyncChanges}
              setIsReviewModalVisible={setIsReviewModalVisible}
              selectedRoute={selectedRoute}
              updateLoadInfo={updateLoadInfo}
              updateLoadRef={updateLoadRef}
              updateSupportMovesInLocal={updateSupportMovesInLocal}
            />
          )}

          {chassisValidation && <ChassisValidationModal
            validations={chassisValidation}
            loadDetails={loadDetails}
            onChassisEventSuccess={onChassisEventSuccess}
            onChassisEventFailure={onChassisEventFailure}
            updateSupportMovesInLocal={updateSupportMovesInLocal}
          />}
        </div>
      </div>


      {isCabotageEnabled() && showViolatedModal &&
				<CabotageViolationModal
					isWarning
					response={showViolatedModal?.response ?? {}}
					contentBody={showViolatedModal?.message ?? ''}
					showModal={showViolatedModal}
					setShowModal={setShowViolatedModal}
					removeDriver={() => handleManageEventOrder(showViolatedModal)}
					confirmText='Continue'
				/>
			}  
    </React.Fragment>
  )}
  if (loggedInUser) {
    if (loggedInUser.role === "carrier" || loggedInUser.role === "customer" ||loggedInUser.role === "fleetcustomer" ) {
        return renderReturn();
    } else if (loggedInUser.permissions.length !== 0) {
        if (
            loggedInUser.permissions.includes("customer_service_order_status")
        ) {
            return renderReturn();
        } else {
            return (
                <div className="nopermission">
                    <h1>You do not have permission to view this page</h1>
                </div>
            );
        }
    } else {
        return (
            <div className="nopermission">
                <h1>You do not have permission to view this page</h1>
            </div>
        );
    }
  }
}

export default NewLoadDriverOrderV2
