const TSP_HISTORY_SEGMENTATION_MAPPER = Object.freeze({
  MOTIVE: {
    segmentTimeThreshold: "2.5", // minutes
    segmentDistanceThreshold: "2.5", // miles
  },
  // SAMSARA: {
  //   segmentTimeThreshold: "1", // minutes
  //   segmentDistanceThreshold: "1.5", // miles
  // },
});

export { TSP_HISTORY_SEGMENTATION_MAPPER };
