import { newSmSelectStyle } from 'assets/js/select-style';
import _ from 'lodash';
import { ROUTING_ADDRESS_FIELDS } from 'pages/tms/Load/DriverOrderUtility';
import { addEventInCombineTrip } from 'pages/tms/LoadRoutingV2/actionCreator';
import { getAddressdefaultOptions } from 'pages/tms/LoadRoutingV2/helpers';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { shallowEqual, useSelector } from 'react-redux';
import AsyncSelect from "react-select/async";
import { makeOptionsForSelect, toastr } from 'services';
import { legTypeToHuman } from '../functions';
import CellSpinner from '../Spinner/CellSpinner';

const AddEventConfirm = ({ loadStatusType, setShowModal, orderDetails, setIsLoading, dropIndex, updateTripDetails }) => {

    const addressComp = ROUTING_ADDRESS_FIELDS[loadStatusType];
    const tripOrder = orderDetails?.tripOrder || orderDetails?.driverOrder;
    const allLoad = _.uniqBy(tripOrder, "loadId");
    const eventName = legTypeToHuman(loadStatusType);
    const [selectedLoad, setSelectedLoad] = useState(allLoad[0]);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [isSpinnerLoading, setIsSpinnerLoading] = useState(false);

    const { customers, shippers, consignees, chassisTerms } = useSelector(
        (state) => state.TmsReducer,
        shallowEqual
    )

    const handleAddEvent = () => {
        setIsSpinnerLoading(true);
        const tripOrderClone = _.cloneDeep(orderDetails);
        const tripOrder = _.cloneDeep(tripOrderClone.tripOrder || tripOrderClone.driverOrder);
        let currentIndex = dropIndex;
        if (currentIndex >= 100) {
            currentIndex -= 101
        }
        currentIndex += 1;
        if ((orderDetails?.driverId || orderDetails?.driver) && !selectedAddress && !['COMPLETED'].includes(loadStatusType)) {
            toastr.show("Please select address", "error");
            setIsSpinnerLoading(false);
            return;
        }
        const findNextEvent = tripOrder?.find((item, index) => item.loadId === selectedLoad.loadId && index >= currentIndex);
        const findCurrentEvent = tripOrder?.find((item, index) => item.loadId === selectedLoad.loadId && index === currentIndex - 1);
        if (findNextEvent && (findNextEvent.arrived || findNextEvent.departed)) {
            toastr.show("Cannot add event as the next event is already arrived or departed", "error");
            setIsSpinnerLoading(false);
            return;
        }
        
        const payloadData = {
            eventType: loadStatusType,
            loadId: selectedLoad.loadId,
            ...(selectedAddress ? { customerId: selectedAddress.value } : {}),
            tripId: tripOrderClone?._id,
            ...(findNextEvent ? { attachEventId: findNextEvent.eventId } : {}),
            currentEventIndex: currentIndex
        }


        addEventInCombineTrip(payloadData).then(() => {
            toastr.show("Update Successfully!", 'success');
            setShowModal(null);
            setIsLoading(false);
        })
            .catch((err) => {
                updateTripDetails && updateTripDetails(orderDetails);
                console.log("🚀 ~ file: index.jsx:250 ~ handleOnDrop ~ err:", err)
                setShowModal(null);
                setIsLoading(false);
            })
    }


    return (
        <Modal dialogClassName="modal-dialog-centered modal-sm" backdropClassName="z-1050" show={true} centered animation={false}>
            <Modal.Header className="pb-10">
                <Modal.Title>{`Add “${eventName}” event`}<div className="text-muted font-12 font-weight-normal">{`Select a Load to which you want to add the “${eventName}” event`}</div></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="form-wrapper">
                    <div className="form-row">
                        {allLoad?.map((load, index) => {
                            return (
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label className="btn btn-outline-light w-100 justify-content-start align-items-center mb-0" htmlFor={`${load.reference_number}`} onChange={(e) => {
                                            setSelectedLoad(load)
                                        }}>
                                            <input
                                                type="radio"
                                                className="form-check-input"
                                                value={load.reference_number}
                                                id={`${load.reference_number}`}
                                                checked={selectedLoad?.reference_number === load.reference_number}

                                            />
                                            <span className='ml-2'>{load.reference_number}</span></label>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    {!['COMPLETED'].includes(loadStatusType) && <div className="form-row">
                        <div className="col-lg-12">
                            <label className="col-form-label">
                                {(orderDetails?.driverId || orderDetails?.driver) && <span className="text-danger">*</span>} {eventName} Address
                            </label>
                            <div className="form-group">
                                <AsyncSelect
                                    defaultOptions={makeOptionsForSelect(
                                        getAddressdefaultOptions({ type: loadStatusType }, customers, chassisTerms, shippers, consignees),
                                        "company_name",
                                        "address.address",
                                        "_id"
                                    )}
                                    isClearable
                                    cacheOptions
                                    styles={newSmSelectStyle}
                                    loadOptions={addressComp.loadOptions}
                                    onChange={(value) => {
                                        setSelectedAddress(value)
                                    }}
                                    value={selectedAddress}
                                />
                            </div>
                        </div>
                    </div>}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="btn btn-close"
                    onClick={() => {
                        setShowModal(null);
                        setIsLoading(false)
                    }}
                    disabled={isSpinnerLoading}
                >
                    Cancel
                </button>
                <button
                    className="btn btn-primary"
                    disabled={isSpinnerLoading || !selectedLoad || ((orderDetails?.driverId || orderDetails?.driver) && !selectedAddress && !['COMPLETED'].includes(loadStatusType))}
                    onClick={handleAddEvent}
                >
                    {isSpinnerLoading && <CellSpinner size="sm" className="mr-1" />}
                    Confirm
                </button>

            </Modal.Footer>
        </Modal>
    )

}

export default AddEventConfirm;