import React from "react";
import Select, { components } from "react-select";
import { smallSelectStyle } from "assets/js/select-style";
import { statusLabels } from "../../constant";
import { useUpdateStatus } from "./useUpdateStatus";
import { amplitudeTrack } from "../../../../../services";

const StatusSelect = ({ threadId, accountId, setShowStatus, event, eventProperties, isFromBottomBar, handleHideBatchMenu,setupdateStatusData,
  setupdateStatusDataFlag }) => {
  const { status, handleChange, loading } = useUpdateStatus(threadId, accountId, () => {
    if(setShowStatus) setShowStatus(false); 
    if(handleHideBatchMenu) handleHideBatchMenu()
  },setupdateStatusData, setupdateStatusDataFlag);
  const { Option, SingleValue } = components;

  const BadgeOption = (props) => (
    <Option {...props}>
      <span className={`badge badge-sm ${props.data.badgeClass}`}>{props.data.label}</span>
    </Option>
  );

  const BadgeSingleValue = (props) => (
    <SingleValue {...props}>
      <span className={`badge badge-sm ${props.data.badgeClass}`}>{props.data.label}</span>
    </SingleValue>
  );

  return (
    <div>
      <Select
        styles={smallSelectStyle}
        className="w-150"
        components={{
          Option: BadgeOption,
          SingleValue: BadgeSingleValue,
        }}
        options={statusLabels}
        value={status}
        onChange={(value)=>{
          handleChange(value)
          if(event && eventProperties){
            amplitudeTrack(event, eventProperties)
          }
        }}
        isLoading={loading}
        menuPlacement={isFromBottomBar ? "top" : "auto"}
      />
    </div>
  );
};

export default StatusSelect;
