import { useEffect, useState } from "react"
import _ from "lodash"
import moment from "moment"
import Datetime from "react-datetime"
import { browserHistory } from "react-router"
import { IconTrash } from "../../../Components/Common/Icons"
import { DateTimeFormatUtils, getStorage, IsELDEnabled, isMilitaryTime, isNegativeDriverPayAllowed } from "../../../services"
import { ICONS } from "../Load/DriverOrderUtility"
import SupportMove from "../Load/SupportMove"
import { createManualDriverPay } from "../LoadRouting/utilities"
import DriverMoves from "./DriverMoves"
import { completedStatus } from "./constant"
import { _tempEditDriverOder, createLegsFromDriverOrder } from "./helpers"
import { getDrayosCarriers, getBulkHosAvailability } from "./actionCreator"
import { shallowEqual, useSelector } from "react-redux"

const DriverOrder = (props) => {
  const {
    driverOrder,
    selectedMoveFilter,
    loadDetails,
    droppedIndex,
    allowDriverCompletion,
    updateAllowDriverCompletion,
    loadCompletedAt,
    handleDragOver,
    handleOnDrop,
    isDragging,
    handleDragEnter,
    loading,
    setLoading,
    showNotesModel,
    changeTopTab,
    isDriverPayLoading,
    supportMoves,
    showEvent,
    setDriverOrder,
    savedFunction,
    checkIsChassisV3,
    setDriverPays,
    driverPays,
    invalidDriverOrder,
    setInvalidDriverOrder,
    isInvalidDriverOrder,
    setIsInvalidDriverOrder,
    routingSyncRequests,
    routingSentSyncRequests,
    fetchSyncChanges,
    setIsReviewModalVisible,
    selectedRoute,
    updateLoadInfo,
    updateLoadRef,
    updateSupportMovesInLocal
  } = props
  const [editDriverPayData, setEditDriverPayData] = useState();
  const [carrierData, setCarrierData] = useState([])
  const currencySymbol = loadDetails?.invoiceCurrency?.symbol || "$";
  const userTimeFormat = isMilitaryTime() ? "MM/DD/yyyy HH:mm" : "";
  const [bulkHosAvailability, setBulkHosAvailability] = useState([])
  const { drivers } = useSelector((state) => state.TmsReducer, shallowEqual);


  useEffect(() => {
    if (!IsELDEnabled()) return;
    getBulkHosAvailability({ systemIds: drivers.map((driver) => driver?._id) }).then((data) => {
      setBulkHosAvailability(data);
    });
  }, [drivers]);

  const redirectToRates = (payItem) => {
    if (!payItem?.isManual) {
      if (payItem.driverPayRateId) {
        browserHistory.push("/tms/driver-pay-rates?rateId=" + payItem.driverPayRateId)
      } else if (payItem.dynamicDriverPayId) {
        browserHistory.push("/dynamic-driverPay?rateId=" + payItem.dynamicDriverPayId)
      } else if (payItem.radiusRateId) {
        browserHistory.push("/tms/driver-pay-rates?rateId=" + payItem.radiusRateId + "&rateType=driver_radius_pay_rate")
      }
    }
  }

  const allDriverMoves = createLegsFromDriverOrder(driverOrder, supportMoves)
  const _selectedMoveFilter = selectedMoveFilter

  const driverMoves = allDriverMoves.filter((dl) => {
    if (!_selectedMoveFilter) return true
    else if (_selectedMoveFilter === "containerMove" && !dl[0]?.isSupportMove) return true
    else if (_selectedMoveFilter === "supportMove" && dl[0]?.isSupportMove) return true
    return false
  })

  const isCompleted = completedStatus.indexOf(loadDetails.status) > -1

  // TODO: Update Driver Pay Func.
  const updateDriverPay = (driverPay, value, status, nextValidEvent) => {
    value = value.replace(driverPay?.invoiceCurrency?.symbol || currencySymbol, '');
    if(!isNegativeDriverPayAllowed() && value.includes("-")) return;  
    const minusSignCount = (value.match(/-/g) || []).length;
    if (minusSignCount > 1 || (value.indexOf("-") !== 0 && minusSignCount > 0)) {
      return;
    }
    if (driverPay) {
      const _editDriverPayData = _.cloneDeep(driverPay);
      _editDriverPayData.amount = value;
      setEditDriverPayData(_editDriverPayData);
    }
    else if (status && nextValidEvent) {
      const _editDriverPayData = _.cloneDeep(createManualDriverPay(loadDetails, status, nextValidEvent));
      _editDriverPayData.amount = value;
      setEditDriverPayData(_editDriverPayData)
    }
  }
  useEffect(() => {
    // Re-rendering
  }, [driverOrder])

  return (
    <>
      <div className="col routing_info--card px-0 h-100 overflow-y-auto custom-scrollbar-sm">
        {driverMoves.length > 0 &&
          driverMoves.map((move, moveIndex) => {
            let moveColor = moveIndex % 2 == 0 ? "blue" : "pink"

            let firstStatus = move.find((event) => !event.isVoidOut)
            let isMoveTenderId = (move.find((d) => d?.tenderId?.drayOSTenderRefNo && d?.tenderId?.status !== "REJECTED")) || false
            if (!firstStatus) firstStatus = move[0]
            const dIndex = move[0]?.dIndex
            if(driverOrder[dIndex]?.isGrayPool) moveColor = 'gray';
            if (move.length > 0 && firstStatus?.isSupportMove) {
              return (
                <SupportMove
                key={moveIndex}
                move={move}
                legIndex={moveIndex}
                supportMoves={supportMoves}
                legColor={moveColor}
                userTimeFormat={userTimeFormat}
                reference_number={loadDetails?.reference_number}
                driverPays={driverPays}
                currencySymbol={currencySymbol}
                redirectToRates={redirectToRates}
                setLoading={setLoading}
                updateDriverPay={updateDriverPay}
                setNewDriverPays={(dp) => setDriverPays(dp)}
                isLoading={isDriverPayLoading}
                supportMoveDetail={supportMoves?.find(
                  (supportMove) => supportMove?.move?.[0]?._id + "" === move?.[0]?._id + ""
                )}
                updateLoadInfo={updateLoadInfo}
                updateLoadRef={updateLoadRef}
                loadDetails={loadDetails}
                updateSupportMovesInLocal={updateSupportMovesInLocal}
              />
              )
            }
            if (move.length > 0 && !firstStatus?.isSupportMove) {
              return (
                <DriverMoves
                  key={moveIndex}
                  move={move}
                  moveIndex={moveIndex}
                  driverOrder={driverOrder}
                  loadDetails={loadDetails}
                  dIndex={dIndex}
                  moveColor={moveColor}
                  firstStatus={firstStatus}
                  isCompleted={isCompleted}
                  setLoading={setLoading}
                  showEvent={showEvent}
                  showNotesModel={showNotesModel}
                  changeTopTab={changeTopTab}
                  isDragging={isDragging}
                  handleOnDrop={handleOnDrop}
                  handleDragOver={handleDragOver}
                  handleDragEnter={handleDragEnter}
                  droppedIndex={droppedIndex}
                  driverMoves={driverMoves}
                  isDriverPayLoading={isDriverPayLoading}
                  currencySymbol={currencySymbol}
                  redirectToRates={redirectToRates}
                  loading={loading}
                  loadCompletedAt={loadCompletedAt}
                  editDriverPayData={editDriverPayData}
                  driverPays={driverPays}
                  updateDriverPay={updateDriverPay}
                  setDriverOrder={setDriverOrder}
                  checkIsChassisV3={checkIsChassisV3}
                  savedFunction={savedFunction}
                  setDriverPays={setDriverPays}
		              setEditDriverPayData={setEditDriverPayData}
                  isTenderHaveLoadRef={isMoveTenderId}
                  invalidDriverOrder={invalidDriverOrder}
                  setInvalidDriverOrder={setInvalidDriverOrder}
                  isInvalidDriverOrder={isInvalidDriverOrder}
                  setIsInvalidDriverOrder={setIsInvalidDriverOrder}
                  routingSyncRequests={routingSyncRequests}
                  routingSentSyncRequests={routingSentSyncRequests}
                  fetchSyncChanges={fetchSyncChanges}
                  setIsReviewModalVisible={setIsReviewModalVisible}
                  selectedRoute={selectedRoute}
                  updateLoadInfo={updateLoadInfo}
                  updateSupportMovesInLocal={updateSupportMovesInLocal}
                  bulkHosAvailability={bulkHosAvailability}
                />
              )
            }
          })}
        {allowDriverCompletion && (
          <div className="card p-15 card-blue mb-10">
            <div className={`outline-card outline-card--blue p-10 mb-1`} key={"driverCompletion"}>
              <div className="d-flex align-items-center mb-10">
                {ICONS.COMPLETED}
                <span className="font-14">Completed</span>
                <div className="d-flex ml-auto">
                  {loadCompletedAt && (
                    <Datetime
                      className="mr-2 input-primary"
                      timeFormat={false}
                      dateFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                      inputProps={{ disabled: isCompleted }}
                      displayTimeZone={getStorage("timeZone")}
                      value={moment(loadCompletedAt)}
                    />
                  )}
                  {!isCompleted && (
                    <button
                      className="btn btn-link text-danger"
                      onClick={() => {
                        updateAllowDriverCompletion(false)
                      }}
                    >
                      <IconTrash />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default DriverOrder
