const initialState = {
    driverSearchKeyword: null,
}

export default function trackingReducer(state = initialState, action) {
    switch (action.type) {
        case 'SET_DRIVER_SEARCH_KEYWORD':
            return {
                ...state,
                driverSearchKeyword: action.payload
            };
        default: return { ...state }
    }
};