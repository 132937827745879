import EmailEditor from "Components/Common/NewEmailEditor/EmailEditor";
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { IconAttachment, IconTimes, IconTrash } from "../../../../../Components/Common/Icons";
import { getStorage, toastr } from "../../../../../services";
import { createDraftEmail, getAttachmentsByThread, sendMail, updateDraftEmail } from "../../actionCreator";
import { EMAIL_CONTEXT, EMAIL_MSG, EMAIL_TABS, SEND_EMAIL_TYPE, EMAIL_PROVIDER } from "../../constant";
import { bytesToKB, convertEmailTos, encryptEmbeddedEmailData, generateGmailReply, getSignatureBody, getSubjectForEmail, getThreadId, isExternalEmail, sanitizeSubject } from "../../helper";
import { useDeleteDraftEmail, useForwardMail, useGetMailById, useReplyMail } from "../../hooks";
import ReciptionsInput from "./ReciptionsInput";
import _ from "lodash";
import { v4 as uuidv4 } from 'uuid';
const Buffer = require('buffer/').Buffer

const MessageComposer = ({
  email: selectedMail,
  uploadedDocuments,
  setUploadedDocuments,
  setOpenDocumentUploadModal,
  removeDocument,
  setIsSendEmail,
  sendMailType,
  callback,
  getReply,
  loadId,
  _getEmailByLoad,
  isFromLoad,
  isFromBottomBar,
  allEmailSignature,
  getAllEmailSignatures,
  isScrolledToBottom,
  footerAtTop,
  modalFooterRef,
  currentSelectedThread = {},
  isEmailMode
}) => {
  const embeddedEmailAccount = JSON.parse(getStorage('embeddedEmailAccount')) ?? {};
  const [emailTo, setEmailTo] = useState([]);
  const [emailCc, setEmailCc] = useState([]);
  const [emailBcc, setEmailBcc] = useState([]);
  const [emailSubject, setEmailSubject] = useState("");
  const [mailSubject, setMailSubject] = useState("");
  const [editorState, setEditorState] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const [draftId, setDraftId] = useState("");
  const [isDraftApiCall, setIsDraftApiCall] = useState(false)
  const [isSendingMail, setIsSendingMail] = useState(false);
  const [isDraftLoaded, setIsDraftLoaded] = useState(false);
  const [hasUserEdited, setHasUserEdited] = useState(false);
  const composerInputRef = useRef(null);
  const threadIdRef = useRef('')

  // New ref to store last saved draft data
  const lastSavedDraftRef = useRef({
    to: [],
    cc: [],
    bcc: [], 
    subject: "",
    body: "",
    attachments: []
  });

  const context = useContext(EMAIL_CONTEXT)
  const { resetState: resetMainListState,setAllEmails, allEmails = [], allLabels = [], activeNav } = context

  const useGetMailByIdCb = useCallback(
    ({ result }) => {
      if (!isFromBottomBar) getReply && getReply(true, result);
    },
    [isFromBottomBar]
  );
  const { provider } = embeddedEmailAccount ?? {};
  const { email: EmailById, _getEmailById, loading: getEmailLoading } = useGetMailById(useGetMailByIdCb);
  const { _deleteDraftEmail, data: deleteDraftData } = useDeleteDraftEmail();

  const email = useMemo(()=> {
    if(isFromBottomBar){
      return EmailById?.message || selectedMail
    } else {
      return selectedMail
    }
  }, [isFromBottomBar, EmailById?.message, selectedMail]);

  useEffect(() => {
    if (threadIdRef.current !== email?.threadId) {
      threadIdRef.current = email?.threadId;
      setIsDraftLoaded(false);
    }
  }, [email?.threadId]);

  const isExternalMessage = isExternalEmail(email?.grantId)

  const getEmailById = useCallback(async () => {
    await _getEmailById({ id: getThreadId(selectedMail), isRetrieveFromNylas: selectedMail?.isRetrieveFromNylas ?? false });
  }, [_getEmailById, selectedMail]);

  useEffect(() => {
    if(isFromBottomBar && !draftId){
      getEmailById()
    }
  }, [selectedMail?.id]);

  const handleDocuments = useCallback(async (allFiles) => {
    if(!allFiles?.length) return;
    const files =await Promise.all(allFiles?.filter((f) => f.isInline !== true)?.map(async (file) => {
      const arrayBuffer = new Uint8Array(file.buffer.data).buffer;
      const blob = new Blob([arrayBuffer], { type: file.content_type });
      const fileData = new File([blob], file.filename, {
        type: blob.type, 
        size: file.size
      });
      fileData.id = file.id
    return fileData        
    })) 
    setUploadedDocuments(files)
  }, [setUploadedDocuments])


  useEffect(() => {
    if(!isDraftLoaded || sendMailType !== stateRef?.current?.sendMailType || email?.id !== stateRef?.current?.email?.id){
    stateRef.current.sendMailType = sendMailType;
    const { subject, from, cc = [], bcc = [], body, to,attachments, id, draftId } = email;
    const currentEmail = JSON.parse(getStorage("embeddedEmailAccount") || "{}" )?.email
    const isSendMail = (from || [])?.find(x => x?.email === currentEmail)
    const isReplyAll = sendMailType === SEND_EMAIL_TYPE.REPLY_ALL;
    const isReply = sendMailType === SEND_EMAIL_TYPE.REPLY;
    const defaultSignature = allEmailSignature?.find((p) => p?.useDefaultForReply)
    setEmailSubject(getSubjectForEmail(subject, sendMailType));
    const fetchData = async () => {
      let finalBody = "";
      if (body && sendMailType === SEND_EMAIL_TYPE.FORWARD) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(body, 'text/html');
        if (attachments?.length) {
          const param = {
            accountId: attachments?.[0]?.grantId,
          }
          const images = doc.getElementsByTagName('img');
          const { attachments: allFiles, isSuccess, message: errorMessage } = await getAttachmentsByThread(param, id);
          if (!isSuccess) toastr.show(errorMessage, 'error')

          for (let img of images) {
            const cid = img.getAttribute('src').replace('cid:', '');
            const attachment = allFiles.find(att => att.content_id === cid);
            if (attachment) {
              const base64String = Buffer.from(attachment.buffer.data).toString('base64');
              img.setAttribute('src', `data:${attachment.content_type};base64,${base64String}`);
            }
          }
          if (!isExternalMessage) {
            handleDocuments(allFiles)
           }
        }
      
      const bodyContent = doc.body.innerHTML;
      const threeDotDataDiv = document.createElement('div');
      threeDotDataDiv.setAttribute('class', 'gmail_quote')
      threeDotDataDiv.setAttribute('id', 'dataToRemove')
      threeDotDataDiv.setAttribute('contenteditable', false)
      threeDotDataDiv.style.pointerEvents = 'none'
      threeDotDataDiv.innerHTML = `<p>---------- Forwarded message ---------</p>\n\n${bodyContent?.replace(/<!--[\s\S]*?-->/g, '')}`;

      const userInputDiv = document.createElement('p')
      userInputDiv.innerHTML = '<br/><br/><br/>'
      userInputDiv.setAttribute('id','userInputDiv')
      let forwardBody = `${userInputDiv.outerHTML}${threeDotDataDiv.outerHTML}`;

      if(draftId){
        setDraftId(id?.startsWith('r') ? id : draftId)
        setEditorState(body)
        setEmailBody(body)
        finalBody = body;
      } else {
        if(defaultSignature) forwardBody = forwardBody + `${getSignatureBody(defaultSignature)}`
        setEditorState(forwardBody);
        setEmailBody(forwardBody);
        finalBody = forwardBody;
      }
    } else {
      if (id?.startsWith('r') || draftId) {
          setDraftId(draftId)
          setEditorState(body)
          setEmailBody(body)
          finalBody = body;
        } else {
        if (defaultSignature) {
          finalBody = getInitialState(defaultSignature)
        } else {
          setEditorState("")
          setEmailBody("")
          finalBody = "";
        }
      }
    }
    lastSavedDraftRef.current = { ...lastSavedDraftRef.current, body: finalBody };
  }
    let newEmailTo = [];
    let newEmailCc = [];
    let newEmailBcc = [];
    let newUploadedDocuments = [];

    if ((isReply || isReplyAll) && !draftId) {
      newEmailTo = isSendMail ? convertEmailTos(to || []) : convertEmailTos(from || []);
      newUploadedDocuments = [];

      if(isReplyAll){
        const toEmails = convertEmailTos(to || []);
        const ccEmails = convertEmailTos(cc || []);
        
        const getFilteredEmails = (mails = []) => {
          const emails = isSendMail ? [...mails] : [...toEmails, ...mails];
          return emails?.filter((email) => email?.email?.toLowerCase() !== embeddedEmailAccount?.email?.toLowerCase());
        };
        
        newEmailCc = getFilteredEmails(ccEmails);
      }
    } else {
      newEmailTo = draftId ? to : [];
      newEmailCc = draftId ? cc : [];
      newEmailBcc = draftId ? bcc : [];
    }

    if(draftId) setDraftId(draftId)
    setEmailTo(newEmailTo);
    setEmailCc(newEmailCc);
    setEmailBcc(newEmailBcc);
    setUploadedDocuments(newUploadedDocuments);
    setIsDraftLoaded(true);

    // Set initial saved draft state
    lastSavedDraftRef.current = {
      ...lastSavedDraftRef.current,       
      to: newEmailTo,
      cc: newEmailCc,
      bcc: newEmailBcc,
      subject: emailSubject,
      attachments: newUploadedDocuments
    };

    fetchData()
  }
  }, [sendMailType, email, allEmailSignature, isDraftLoaded]);
  const getInitialState = (defaultValue) => {
    if (defaultValue) {
      let bodyFornewSignatureEmail = getSignatureBody(defaultValue)
      setEditorState(bodyFornewSignatureEmail || "")
      setEmailBody(bodyFornewSignatureEmail || "")
      return bodyFornewSignatureEmail || ""
    } else {
      return ""
    }
  } 

  const onEditorStateChange = async (data) => {
    const tempDiv = document.createElement('div')
    tempDiv.innerHTML = data;
    const userDiv = tempDiv.querySelector('#userInputDiv')
    if(!userDiv && sendMailType === SEND_EMAIL_TYPE.FORWARD){
      const newUserDiv = document.createElement('p')
      newUserDiv.setAttribute('id','userInputDiv')
      newUserDiv.innerHTML = '<br/>'
      tempDiv.prepend(newUserDiv)
    }
    
    const newBody = tempDiv.innerHTML;
    // Skip draft creation if body is empty and no manual changes were made
    if (newBody?.replaceAll('"', "") !== lastSavedDraftRef.current.body?.replaceAll('"', "")) {
      setHasUserEdited(true);
    }
    
    setEditorState(newBody);
    setEmailBody(newBody);
  };

  const resetState = () => {
    setEmailTo([]);
    setEmailCc([]);
    setEmailBcc([]);
    setUploadedDocuments([]);
    setEmailSubject("");
    onEditorStateChange("");
    if(isFromLoad) _getEmailByLoad({skip:0},true);
    callback && callback()
  };

  const forwardCallBack = (value, newMail,threadData) => {
    const currentSelectedThreadId = getThreadId(currentSelectedThread?.message) ?? null
    const newMailThreadId = getThreadId(newMail)
    if((currentSelectedThreadId === newMailThreadId) || !isFromBottomBar){
      getReply && getReply(value,threadData)
    }
    if(isFromBottomBar){
      callback && callback()
    }
    resetMainState();
  }

  const resetMainState = () => {
    resetMainListState({ isClearSelectedEmailData: isEmailMode !== 'card' });
  }

  const useReplyMailCb = useCallback((isReply, data) => {
    resetMainState();
    getReply && getReply(isReply, data);
  }, []);
  
  const { _forwardMail, loading } = useForwardMail(forwardCallBack, setIsSendEmail);
  const { _replyMail, loading: isReplyMailLoad } = useReplyMail(useReplyMailCb, setIsSendEmail);

  const isApiCall = loading || isReplyMailLoad || isSendingMail || isDraftApiCall;

  const processBase64Images = async (htmlContent) => {
    // Function to extract image src attributes using regex
    const extractImageSrcs = (html) => {
      const regex = /<img [^>]*src="data:image\/[^;]+;base64,([^'"]+)/g;
      let matches;
      const srcs = [];
      while ((matches = regex.exec(html)) !== null) {
        srcs.push(matches[0]); // Full match including the base64 data
      }
      return srcs;
    };

    try {
      const imgSrcs = extractImageSrcs(htmlContent);
      let cidHtmlContent = htmlContent;
      const attachments = [];
      imgSrcs.forEach((src, index) => {
        const cid = `${uuidv4()}`;
        const base64Data = src.match(/data:image\/[^;]+;base64,([^'"]+)/)[1];
        const contentType = src.match(/data:image\/([^;]+);base64/)[1];
        const filename = `image${index}.${contentType}`;

        const buffer = Buffer.from(base64Data, 'base64');
        const size = buffer.length;

        cidHtmlContent = cidHtmlContent.replace(src, `<img src="cid:${cid}`);

        // Create attachment object
        attachments.push({
          filename: filename,
          content: base64Data,
          content_type: `image/${contentType}`,
          is_inline: true,
          content_id: cid,
          content_disposition: 'inline',
          size
        });
      });
      return { htmlContent: cidHtmlContent, attachments }
    } catch (error) {
      console.error('Error downloading images:', error);
      toastr.show(`${error}`, 'error');
      setIsSendingMail(false)
    }
  };

  const sendEmail = async () => {
    if (!emailTo?.length) {
      toastr.show("Pls Add email To ", "error");
      return;
    }
    const { subject: originalMailSubject } = email;
    const mainMailSubject = sanitizeSubject(originalMailSubject?.trim()) //original subject
    const sanitizedSubject = sanitizeSubject(emailSubject?.trim()) // new subject
    const subjectChanged = mainMailSubject !== sanitizedSubject
    let eBody = emailBody
    if (!subjectChanged) {
      const tempDiv = document.createElement('div')
      tempDiv.innerHTML = emailBody;
      const gmailQuoteDiv = tempDiv.querySelector('#dataToRemove');
      if (gmailQuoteDiv) {
        gmailQuoteDiv.parentNode.removeChild(gmailQuoteDiv);
      }
      eBody = tempDiv.innerHTML;
    }
    
      // Only append Gmail-style quote for Google provider
    if ([SEND_EMAIL_TYPE.REPLY, SEND_EMAIL_TYPE.REPLY_ALL].includes(sendMailType) &&
      provider === EMAIL_PROVIDER.GMAIL) {
      const tempDiv = document.createElement('div')
      tempDiv.innerHTML = eBody;
      const gmailQuoteDiv = tempDiv.querySelector('.gmail_quote');
      if (gmailQuoteDiv) {
        gmailQuoteDiv.parentNode.removeChild(gmailQuoteDiv);
      }
      eBody = tempDiv.innerHTML;
      const parser = new DOMParser();
      const doc = parser.parseFromString(email.body, 'text/html');
      const bodyContent = doc.body.innerHTML;
      eBody = eBody + generateGmailReply(bodyContent, email.createdAt);
    }

    const { htmlContent: cidHtmlContent, attachments } = await processBase64Images(eBody);
    eBody = cidHtmlContent
    
    const encryptedBody = eBody ? encryptEmbeddedEmailData(eBody) : "";

    const payload = new FormData();
    payload.append("to", JSON.stringify(convertEmailTos(emailTo)));

    const subjectForPayload = subjectChanged ? sanitizedSubject : originalMailSubject
    payload.append("subject", subjectForPayload);
    if (attachments?.length) {
      attachments.forEach((doc) => {
        payload.append("inlineAttachments", JSON.stringify(doc));
      });
    }
    if (uploadedDocuments?.length) {
      uploadedDocuments.forEach((doc) => {
        payload.append("attachments", doc);
      });
    }
    if (encryptedBody) {
      payload.append("body", encryptedBody);
    }
    if (emailCc?.length) {
      payload.append("cc", JSON.stringify(convertEmailTos(emailCc)));
    }
    if (emailBcc?.length) {
      payload.append("bcc", JSON.stringify(convertEmailTos(emailBcc)));
    }

    debouncedHandleCreateUpdateDraft.cancel();
    if (isExternalMessage) {
      if(loadId) payload.append("loadId", loadId)
      payload.append("originalMailThreadId", email?.object === "message" ? email?.threadId : email?.id)
      setIsSendingMail(true)
      sendMail(payload).then(() => {
        toastr.show(EMAIL_MSG.EMAIL_SENT, "success")
        setIsSendingMail(false)
        setIsSendEmail(false)
        resetState()
        stateRef.current.isSaveDraft = false
      }).catch((err) => {
        console.log("err", err)
        setIsSendEmail(false)
        resetState()
        setIsSendingMail(false)
      })
    } else {
      const mailData = {
        messageId: email.id,
        body: payload,
      };
      
      const emailData = new FormData();

      
      emailData.append('messageId', mailData.messageId);
      if(draftId) emailData.append("draftId", draftId);
      if(email?.replyToMessageId) {
        emailData.delete('messageId')
        emailData.append("messageId", email?.replyToMessageId);
      };

      
      for (const [key, value] of mailData.body.entries()) {
        emailData.append(key, value);
      }

        if (sendMailType === SEND_EMAIL_TYPE.FORWARD) {
          if(subjectChanged){
            emailData.delete('messageId')
            emailData.delete('draftId')
            setIsSendingMail(true)

            if(draftId) emailData.append("id", draftId);

            sendMail(emailData).then(() => {
              toastr.show(EMAIL_MSG.EMAIL_FORWARDED, "success")
              setIsSendingMail(false)
              setIsSendEmail(false)
              resetState()
              getEmailById();
              resetMainState();
              stateRef.current.isSaveDraft = false
            }).catch((err) => {
              console.log("err", err)
              // setIsSendEmail(false)
              // resetState()
              setIsSendingMail(false)
            })
          } else {
            _forwardMail(emailData);
            stateRef.current.isSaveDraft = false
          }
        } else {
          if (sendMailType === SEND_EMAIL_TYPE.REPLY_ALL || sendMailType === SEND_EMAIL_TYPE.REPLY) {
            if(sendMailType === SEND_EMAIL_TYPE.REPLY_ALL){
              emailData.append("all", "true");
            }
          }
          _replyMail(emailData);
          stateRef.current.isSaveDraft = false
        }
    }
  };


  const isReplyOrReplyAll = useMemo(() => sendMailType === SEND_EMAIL_TYPE.REPLY || sendMailType === SEND_EMAIL_TYPE.REPLY_ALL, [sendMailType])
  useEffect(() => {
    if (composerInputRef.current) {
      const height = composerInputRef.current.clientHeight;
      document.documentElement.style.setProperty('--composer-input-height', `${height + 20}px`);
    }
  }, []);
  
  const checkDraftDataDifference = useCallback(
    () => {
      const {emailBody, emailTo, emailCc, emailBcc, emailSubject, uploadedDocuments} = stateRef.current;

      const cleanBody = (body) => (body ?? "")
        .replaceAll("<html>", "")
        .replaceAll("</html>", "")
        .replaceAll("<head>", "")
        .replaceAll("</head>", "")
        .replaceAll("<body>", "")
        .replaceAll("</body>", "")
        .replaceAll(`<meta http-equiv="Content-Type" content="text/html; charset=utf-8">`, "")
        .replaceAll(`<div>\n<br></div>`, "")
        .replaceAll(`<div><br></div>`, "")
        .replaceAll(`<div></div>`, "")
        .trim();

      const lastSavedBody = cleanBody(lastSavedDraftRef.current.body);
      const currentBody = cleanBody(emailBody);

      const hasChanged = [
        !_.isEqual(lastSavedDraftRef.current.subject, emailSubject),
        !_.isEqual(lastSavedDraftRef.current.to?.map((e) => ({ email: e.email, name: e.name })), emailTo?.map((e) => ({ email: e.email, name: e.name }))),
        !_.isEqual(lastSavedDraftRef.current.cc?.map((e) => ({ email: e.email, name: e.name })), emailCc?.map((e) => ({ email: e.email, name: e.name }))),
        !_.isEqual(lastSavedDraftRef.current.bcc?.map((e) => ({ email: e.email, name: e.name })), emailBcc?.map((e) => ({ email: e.email, name: e.name }))),
        !_.isEqual(lastSavedDraftRef.current.attachments?.map((val) => val?.id), uploadedDocuments?.map((val) => val?.id)),
        !_.isEqual(lastSavedBody, currentBody)
      ].some(Boolean);

      return hasChanged;
    },
    []
  );
  const preparePayload = () => {
    const payload = new FormData();
    const {emailBody, emailTo, emailCc, emailBcc, emailSubject, uploadedDocuments, draftId} = stateRef.current;
    const encryptedBody = emailBody ? encryptEmbeddedEmailData(emailBody) : "";
    const subject = sendMailType !== SEND_EMAIL_TYPE.FORWARD ? emailSubject.replace(/^(Re: |Fwd: |Fw: )/i, "") : emailSubject.replace("Re: ", "");

    if (emailSubject) payload.append("subject", subject);
    if (emailTo?.length) payload.append("to", JSON.stringify(emailTo.map(({ email, name }) => ({ email, name }))));
    if (emailCc?.length) payload.append("cc", JSON.stringify(emailCc.map(({ email, name }) => ({ email, name }))));
    if (emailBcc?.length) payload.append("bcc", JSON.stringify(emailBcc.map(({ email, name }) => ({ email, name }))));

    const newAttachments = uploadedDocuments.filter((file) => !file.id);
    newAttachments.forEach((file) => payload.append("attachments", file));
    if (encryptedBody) payload.append("body", encryptedBody);
    if (draftId) payload.append("id", draftId);

    const replyToMessageId = draftId ? email?.threadId : email?.id
    payload.append("replyToMessageId", replyToMessageId);

    return payload;
  };

  const createDraft = async (isUnmount = false) => {
    try {
      // Skip draft creation if body is empty and no manual changes were made
      // if ((!emailBody || emailBody === "" || emailBody === "<div><br></div>") && !hasUserEdited) {
      //   return;
      // }

      setIsDraftApiCall(true)
      const payload = preparePayload();
      if (!payload.entries().next().value) return;
      
      const res = await createDraftEmail(payload);


      if (res?.rawData?.id) {
        setDraftId(res?.rawData?.id);
        // Update lastSavedDraft ref with current values
        lastSavedDraftRef.current = {
          to: [...emailTo],
          cc: [...emailCc],
          bcc: [...emailBcc],
          subject: emailSubject,
          body: emailBody,
          attachments: [...uploadedDocuments]
        };

        const data = allEmails.map((e) => {
          if (e.id === res.rawData.threadId) {
            return { ...e, draftIds: [...e.draftIds, res?.rawData?.id] };
          }
          return e;
        });
        setAllEmails(data);
        // getEmailById();
        // resetMainState();
      } else {
        toastr.show(EMAIL_MSG.SOMETHING_WENT_WRONG, "error");
      }
    } catch (error) {
      console.error("createDraft error:", error);
      toastr.show(EMAIL_MSG.SOMETHING_WENT_WRONG, "error");
    } finally {
      setIsDraftApiCall(false)
    }
  };

  const updateDraft = async (isUnmount = false) => {
    try {
      if (!checkDraftDataDifference()) return;
      setIsDraftApiCall(true)

      const payload = preparePayload();

      const res = await updateDraftEmail(payload);
      if (res?.rawData?.id) {
        const {rawData} = res  || {}

        // Update lastSavedDraft ref with current values after successful update
        lastSavedDraftRef.current = {
          to: [...emailTo],
          cc: [...emailCc],
          bcc: [...emailBcc], 
          subject: emailSubject,
          body: emailBody,
          attachments: [...uploadedDocuments]
        };

        const draftFolder = allLabels?.find((x) => x?.name === "Drafts");
        if (activeNav === EMAIL_TABS.DRAFT || activeNav === draftFolder?.id) {
          const data = allEmails?.map((e = {}) => {
            if (e?.id === rawData?.id) {
              return { ...e,  ...rawData};
            }
            return e;
          });
          setAllEmails(data);
        }

        // if(isUnmount) resetMainState();
        // getEmailById();
      } else {
        toastr.show(EMAIL_MSG.SOMETHING_WENT_WRONG, "error");
      }
    } catch (error) {
      console.error("updateDraft error:", error);
      toastr.show(EMAIL_MSG.SOMETHING_WENT_WRONG, "error");
    } finally {
      setIsDraftApiCall(false)
    }
  };

  const handleCreateUpdateDraft = useCallback((isUnmount = false) => {
    if(!stateRef.current.isDraftApiCall && !isExternalMessage){
      if (!stateRef.current.draftId) {
        createDraft(isUnmount);
      } else {
        updateDraft(isUnmount);
      }
    }
  }, []);

  const onDeleteDraft = useCallback(async () => {
    debouncedHandleCreateUpdateDraft.cancel();
    await _deleteDraftEmail({ ids: [draftId] });
    setDraftId("")
    getEmailById()
          
    if (!deleteDraftData?.failed?.length) {
      setIsSendEmail(false);
      resetMainState();
      stateRef.current.isSaveDraft = false;
    }
  }, [_deleteDraftEmail, deleteDraftData?.failed?.length, draftId, setIsSendEmail]);

  const onClickDelete = useCallback(async () => {
    stateRef.current.isSaveDraft = false;
    if (draftId) onDeleteDraft();
    else setIsSendEmail(false);
  }, [draftId, onDeleteDraft, setIsSendEmail]);

  const debouncedHandleCreateUpdateDraft = useMemo(() => {
    const debounced = _.debounce(() => {
      if (isDraftLoaded && hasUserEdited && !isFromBottomBar) {
        handleCreateUpdateDraft();
      }
    }, 500);
  
    return debounced;
  }, [isDraftLoaded, hasUserEdited, isFromBottomBar, handleCreateUpdateDraft]);
  
  useEffect(() => {
    debouncedHandleCreateUpdateDraft();
    return () => {
      debouncedHandleCreateUpdateDraft.cancel();
    };
  }, [emailTo, emailCc, emailBcc, mailSubject, emailBody]);

  const stateRef = useRef({
    email: email,
    hasUserEdited: hasUserEdited,
    isDraftApiCall: isDraftApiCall,
    draftId: draftId,
    emailTo: emailTo,
    emailCc: emailCc,
    emailBcc: emailBcc,
    emailSubject: emailSubject,
    emailBody: emailBody,
    uploadedDocuments: uploadedDocuments,
    isSaveDraft: true,
    sendMailType: sendMailType
  });

  useEffect(() => {
    stateRef.current = {
      ...stateRef.current,
      email: email,
      hasUserEdited: hasUserEdited,
      isDraftApiCall: isDraftApiCall,
      draftId: draftId,
      emailTo: emailTo,
      emailCc: emailCc,
      emailBcc: emailBcc,
      emailSubject: emailSubject,
      emailBody: emailBody,
      uploadedDocuments: uploadedDocuments,
      isSaveDraft: true
    };
  }, [draftId, emailTo, emailCc, emailBcc, emailSubject, emailBody, uploadedDocuments, hasUserEdited, isDraftApiCall]);

  useEffect(() => {
    return () => {
      const hasEmailChanges = checkDraftDataDifference();
      if (stateRef.current.hasUserEdited && stateRef.current.isSaveDraft && hasEmailChanges && !isFromBottomBar) {
        handleCreateUpdateDraft(true);
      }
    };
  }, []);

  return (
    <div className="pt-15 d-flex flex-column gap-10" ref={modalFooterRef}>
      <div 
        className={`d-flex flex-column gap-10 ${footerAtTop ? 'position-sticky top-0 bg-white shadow-1 py-10 px-15 z-4': ''}`} 
        style={footerAtTop ? { margin: "-10px -15px" } : null}
        ref={composerInputRef}
      >
      <div className="d-flex align-items-center">
        <div className="text-muted" style={{ width: 60 }}>
          To
        </div>
        <ReciptionsInput placeholder={"Enter To"} email={emailTo} setEmail={(newTo) => {
          if (!_.isEqual(newTo, lastSavedDraftRef.current.to)) {
            setHasUserEdited(true);
          }
          setEmailTo(newTo);
        }} autoFocus={!isReplyOrReplyAll} />
        {!isFromBottomBar && (<button
          className="btn"
          onClick={onClickDelete}
          disabled={isApiCall}
        >
          <IconTrash className="text-muted" />
        </button>)}
      </div>

      <div className="d-flex align-items-center">
        <div className="text-muted" style={{ width: 60 }}>
          Cc
        </div>
        <ReciptionsInput placeholder={"Enter CC"} email={emailCc} setEmail={(newCc) => {
          if (!_.isEqual(newCc, lastSavedDraftRef.current.cc)) {
            setHasUserEdited(true);
          }
          setEmailCc(newCc);
        }} />
      </div>

      <div className="d-flex align-items-center">
        <div className="text-muted" style={{ width: 60 }}>
          Bcc
        </div>
        <ReciptionsInput placeholder={"Enter BCC"} email={emailBcc} setEmail={(newBcc) => {
          if (!_.isEqual(newBcc, lastSavedDraftRef.current.bcc)) {
            setHasUserEdited(true);
          }
          setEmailBcc(newBcc);
        }} />
      </div>
      {sendMailType === SEND_EMAIL_TYPE.FORWARD && <div>
        <label>Email Subject</label>
        <input
          type="text"
          className="form-control"
          placeholder="Enter Subject.."
          value={emailSubject}
          onChange={(e) => {
            const newSubject = e.target.value;
            if (newSubject !== lastSavedDraftRef.current.subject) {
              setHasUserEdited(true);
            }
            setEmailSubject(newSubject);
          }}
          onBlur={(e) => setMailSubject(e.target.value)}
        />
      </div>}
      </div>
      {sendMailType === SEND_EMAIL_TYPE.REPLY &&<EmailEditor 
        onEditorStateChange={onEditorStateChange} 
        editorState={editorState} 
        allEmailSignature={allEmailSignature} 
        getAllEmailSignatures={getAllEmailSignatures} 
        isEmailSignature={true} 
        fromReply={true}
        // isAutoFocusEnabled={true}
      />}
      {sendMailType === SEND_EMAIL_TYPE.REPLY_ALL &&<EmailEditor 
        onEditorStateChange={onEditorStateChange} 
        editorState={editorState} 
        allEmailSignature={allEmailSignature} 
        getAllEmailSignatures={getAllEmailSignatures} 
        isEmailSignature={true} 
        fromReply={true}
        // isAutoFocusEnabled={true}
      />}
      {sendMailType === SEND_EMAIL_TYPE.FORWARD &&<EmailEditor 
        onEditorStateChange={onEditorStateChange} 
        editorState={editorState} 
        allEmailSignature={allEmailSignature} 
        getAllEmailSignatures={getAllEmailSignatures} 
        isEmailSignature={true} 
        fromReply={true}
      />}
      <div 
        className={`d-flex align-items-start position-sticky bottom-0 py-10 px-15 bg-white ${!isScrolledToBottom ? "shadow-1": ""}`} 
        style={{margin: "-10px -15px"}}>
      <button
            className="btn btn-outline-light wh-32px px-1 mr-10"
            onClick={() => setOpenDocumentUploadModal(true)}
            disabled={isApiCall}
          >
            <IconAttachment />
          </button>
        <div
          className="d-flex flex-wrap align-items-center gap-5 overflow-y-auto custom-scrollbar-sm mr-10"
          style={{ maxHeight: "80px" }}
        >
          {uploadedDocuments?.length > 0 &&
            uploadedDocuments?.map((doc, i) => (
              
              <span key={`doc_${i}`} className="badge border-1 border-gray-100">
                <span className="text-primary">{doc?.name}</span>
                <span className="text-muted ml-10">{`${bytesToKB(doc?.size)} KB`}</span>
                <span className="pointer" onClick={(e) => removeDocument(doc.id)}>
                  <IconTimes />
                </span>
              </span>
            ))}
        </div>
        <div className="d-flex align-items-center gap-10 ml-auto">
          <button
            className="btn btn-primary"
            onClick={() => {
              sendEmail();
            }}
            disabled={isApiCall || (emailTo?.length === 0)}
          >
            Send
          {isApiCall && <span className="spinner-border spinner-border-sm ml-1"></span>}
          </button>
        </div>
      </div>
    </div>
  );
};

export default MessageComposer;
