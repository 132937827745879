import React, { useEffect, useMemo, useRef, useState } from "react"
import { IconAngleArrowDown, IconDotSeperator, IconForward, IconPlus, IconReply, IconReplyAll } from "Components/Common/Icons"
import _ from "lodash"
import { DateTimeFormatUtils, amplitudeTrack, displayName, getSessionStorage, getStorage } from "services"
import moment from "moment"
import { convertLinksToAnchorTags, extractPersonData, getBccParticipantsString, getParticipantsString, getThreeDotDataV2, isEmbeddedEmailConnected, isExternalEmail, isGenAIEnabled } from "../../helper"
import { EMAIL_TOOL_TIPS, SEND_EMAIL_TYPE, AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE, OUTLOOK_PROVIDER, EMAIL_TABS } from "../../constant"
import AttachmentPreview from "./AttachmentPreview"
import ShadowDOMInjectorWithAttachment from "./ShadowDOMInjectorWithAttachment"
import Avatar from "Components/Common/Avatar"
import ExternalMailForwardAlert from  "./ExternalMailForwardAlert"
import ThreeDotsBtn from "Components/Common/Buttons/ThreeDotsBtn"
import DOMPurify from 'dompurify';
import MoreParticipantsWrapper from "./MoreParticipantsWrapper"
import EmailToolTip from "./EmailToolTip"
import LoadDataChanges from "../LoadDataChanges"
import SafeTextDecode from "Components/Common/SafeContent/SafeTextDecode"
import { getTimeZone } from "pages/tms/NewDispatcher/constants"
import { getSinglePeople } from "pages/tms/People/actionCreator"
import PeopleModal from "../../../People/PeopleModal"
import { useGetOrgByDomain } from "./hooks"
import { LoaderBar } from "../../../../../Components/Common/LoaderBar"
import EmailParticipantOrganizationModal from "../../../People/EmailParticipantOrganizationModal.jsx"


// The `fullMessage` prop determines whether to display the full message or a truncated version.
// It is received from the parent page and stays true in two scenarios:
//   1. The message is the last one in the thread.
//   2. The threadCount is one, indicating a single-message thread.
const MessagePreview = ({ 
  fullMessage, 
  emailData, 
  handleSendEmailModal,
  getProfilePicture,
  key,
  handleShowParticipantInsights,
  loadChanges,
  isEmailMode,
  activePage,
  getLoadChanges,
  getOrganizationNameFromEmail,
  emailPeople = [],
  getEmailPeople,
  isExternal,
  isDraft,
}) => {
  const { 
    from, 
    date: emailDate, 
    body: emailBody, 
    attachments: emailAttachment = [], 
    cc: emailCc = [],
    to: emailTo = [],
    bcc: emailBcc = [],
    grantId: accountId,
    id : messageId,
    labels,
    folder,
    threadId
   } = emailData ?? {}
  const body = useMemo(() => DOMPurify.sanitize(emailBody), [emailBody]);
  const [showFullMessage, setShowFullMessage] = useState(fullMessage)
  const [showMailAlert, setShowMailAlert] = useState(false)
  const [sendType, setSendType] = useState("")
  const [isApiCall, setIsApiCall] = useState(false)
  const [activeDot, setActiveDot] = useState(null);
  const [threeDotData, setThreeDotData] = useState(getPrevThreadData(body))
  const [showAddModal, setShowAddModal] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const cellRef = useRef(null)
  const boxRef = useRef(null)
  const canUseGenAI = isGenAIEnabled() 

  const [organizationName, setOrganizationName] = useState('');
  const [selectedPeople, setSelectedPeople] = useState(null);
  const emailFrom = _.first(from || [])
  const isExternalMessage = isExternalEmail(accountId)
  const isEmailConnected = isEmbeddedEmailConnected()
  const people = emailPeople?.find((e)=> e.email === emailFrom.email) || {}
  const [showMultiOrgModal, setShowMultiOrgModal] = useState(false)
  const phoneFormat = JSON.parse(getStorage("userBasicSettings"))?.phoneFormat

  const { organizations, loading, getOrgs } = useGetOrgByDomain();

  const _loadChanges = useMemo(()=> {
    return loadChanges?.filter((load) => load?.messageId + '' === messageId + '')
  },[loadChanges])


  const handleShowMore = () => setShowMore(prevState => !prevState)

  function getPrevThreadData(emailDetail){
    try{ 
      const data = getThreeDotDataV2(emailDetail)
      if(!data) return ''
      const linkedHTML = convertLinksToAnchorTags(data)
      return linkedHTML;
    } catch (error) {
      console.error('Error converting links to anchor tags:', error);
      return emailDetail;
    }
  }

  const handleToggleFullMessage = () => {
    !fullMessage && setShowFullMessage((prevState) => !prevState)
  }
  const handleAlertModal = (value) => {
    setShowMailAlert(value)
  }
  
  const openMailComposser =  () => {
    if(!isEmailConnected) return
    return handleClick(SEND_EMAIL_TYPE.REPLY_ALL, AMPLITUDE_EVENTS.REPLY_ALL, { source: isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_EMAIL_MODEL : AMPLITUDE_EVENTS_SOURCE.GRID_EMAIL_MODEL })
  }

  const handleClick = (emailType, event, eventProperties) => {
    if(isExternalMessage){
      handleAlertModal(true)
      setSendType(emailType)
    }else{
      handleSendEmailModal(emailData, emailType)
    }
    amplitudeTrack(event,eventProperties)
  }

  const onClose = () => {
    setSendType("")
    handleAlertModal(false)
  }

  const onApply = async() => {
    setIsApiCall(true)
    await handleSendEmailModal(emailData, sendType)
    setIsApiCall(false)
    onClose()
  }



  const profilePicture = getProfilePicture(emailFrom?.email)

  const handleActiveDot = () => {
    setActiveDot((prevState) => !prevState)
  }

  const isSendMail = labels?.find(ele => ele?.name === "sent") ?? {}
  const isSendFolder = folder?.name === "sent"

  const embeddedEmailAccount = JSON.parse(getStorage('embeddedEmailAccount'))
  const { provider } = embeddedEmailAccount ?? {};
  const junkEmailOptionDisabled = OUTLOOK_PROVIDER.includes(provider) && [EMAIL_TABS.JUNK_EMAIL].includes(activePage)

  useEffect(() => {
    if(!emailFrom?.email) return;
    const name = getOrganizationNameFromEmail(emailFrom?.email?.trim()?.toLowerCase());
    setOrganizationName(name);
  }, [emailFrom?.email, getOrganizationNameFromEmail]);

  const handleShowAddModal = () => {
      getSinglePeople(people.peopleId, true).then((res) => {
        setSelectedPeople(res)
        setShowAddModal(true)
      }).catch((err) => {
        console.log(err);
      })
    }

  const setPersonDataOnClick = async () => {
    const data = extractPersonData(people, false, phoneFormat);
    const orgs = await getOrgs(people.email);
    setSelectedPeople(data);
    if (orgs.length > 1) {
      setShowMultiOrgModal(true);
    } else {
      setShowAddModal(true);
    }
  };

  const isLoggedInUserEmail = (email) => {
    const flag = (embeddedEmailAccount?.email === email)
    return flag
  }

  return (
    <>
      {loading && <LoaderBar />}
      <div
        key={key}
        className={`d-flex align-items-start gap-15 px-15 `}
      >
        <div className="wh-30px rounded-circle bg-gray-100 d-flex align-items-center justify-content-center flex-shrink-0">
          {profilePicture ? (
            <Avatar src={profilePicture} />
          ) : (
            <Avatar>{displayName(emailFrom?.name??emailFrom?.email)}</Avatar>
          )}
        </div>
        <div className="d-flex flex-column w-100">
          <div className={`d-flex align-items-center gap-10 mb-1 ${!fullMessage ? "pointer" : ""}`} onClick={handleToggleFullMessage}>
          {emailFrom?.name && (
              <>
                {organizationName?.length > 0 && (!people?.carrierFleet && !!people.peopleId) ? (
                  <div
                    className={`text-primary pointer font-14 font-medium line-height-20`}
                    onClick={() => {
                      handleShowAddModal();
                      let eventProperties = {
                        source:
                          isEmailMode === "card"
                            ? AMPLITUDE_EVENTS_SOURCE.CARD_EMAIL_MODEL
                            : AMPLITUDE_EVENTS_SOURCE.GRID_EMAIL_MODEL,
                      };
                      amplitudeTrack(AMPLITUDE_EVENTS.PEOPLE_COUNT_CLICK, eventProperties);
                    }}
                >
                  {emailFrom?.name}
                </div>
                ) : (
                  <div className="font-14 font-medium line-height-20">{emailFrom?.name}</div>
                )}
              </>
            )}
            <div className="text-muted">{emailFrom?.email}</div>
            {organizationName?.length > 0 ? (
              <>
                <IconDotSeperator className="text-muted" />
                <div className="text-muted">{organizationName}</div>
              </>
            ) : (
              <>
              {!people?.carrierFleet && !isLoggedInUserEmail(people?.email) && !people?.peopleId &&
              <div
                className={`text-primary pointer font-14 font-medium line-height-20`}
                onClick={() => {
                  setPersonDataOnClick()
                  let eventProperties = {
                    source:
                      isEmailMode === "card"
                        ? AMPLITUDE_EVENTS_SOURCE.CARD_EMAIL_MODEL
                        : AMPLITUDE_EVENTS_SOURCE.GRID_EMAIL_MODEL,
                  };
                  amplitudeTrack(AMPLITUDE_EVENTS.PEOPLE_COUNT_CLICK, eventProperties);
                }}
              >
                <button className="btn btn-white btn-sm text-primary shadow-none border-0 bg-transparent px-0">
                  <IconPlus className="mr-1" />
                  <span className="font-12">Add Person</span>
                </button>
              </div>
              }
              </>
            )}
          </div>
          { showFullMessage && (
            <>
              <div className="d-flex align-items-center gap-5 mb-1">
                <div onClick={handleToggleFullMessage} className={`${!fullMessage ? "pointer" : ""}`}>
                <span className="text-muted">{`to: ${getParticipantsString([...(emailTo ?? []), ...(emailCc ?? [])])} ${getBccParticipantsString(emailBcc, isSendFolder || Object.keys(isSendMail)?.length)}`}</span>
                </div>
                <div
                data-for={'more-participants'}
                data-tip={'Show details'}
                 className="pointer"
                 ref={cellRef} 
                 onClick={handleShowMore} 
                 >
                  <IconAngleArrowDown className={'text-muted wh-20px'} />
                  <EmailToolTip toolTipId={'more-participants'}/>
                </div>
                <div onClick={handleToggleFullMessage} className={`flex-grow-1 opacity-0 ${!fullMessage ? "pointer" : ""}`}> pointer div</div>
                <MoreParticipantsWrapper
                  showDropdown={showMore}
                  onClose={() => { setShowMore(false) }}
                  boxRef={boxRef}
                  cellRef={cellRef}
                  popUpdata={emailData}
                  handleShowParticipantInsights={handleShowParticipantInsights}
                  handleShowMore={handleShowMore}
                  isEmailMode={isEmailMode}
                />
              </div>
            </>
          )}
          
        </div>
        <div className="d-flex align-items justify-content-end ml-auto w-100 gap-10">
          <div className="text-muted">{moment.unix(emailDate).tz(getTimeZone({preferred: true})).format(DateTimeFormatUtils.fullDateTimeFormat())}</div>
        {isEmailConnected && 
          <div className="d-flex align-items-center gap-5">
            <div
              data-tip={EMAIL_TOOL_TIPS.REPLY}
              data-for={`reply-mail`}
            >
            <button 
              disabled={junkEmailOptionDisabled || isDraft}
              className="btn btn-link hover-gray-50" 
              onClick={() => { handleClick(SEND_EMAIL_TYPE.REPLY, AMPLITUDE_EVENTS.REPLY, { source: isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_EMAIL_MODEL : AMPLITUDE_EVENTS_SOURCE.GRID_EMAIL_MODEL }) }}
            >
              <IconReply />
            </button>
            <EmailToolTip toolTipId={`reply-mail`} />
            </div>
            <div
              data-tip={EMAIL_TOOL_TIPS.REPLY_ALL}
              data-for={`reply-all-mail`}
            >
            <button 
              disabled={junkEmailOptionDisabled || isDraft}
              className="btn btn-link hover-gray-50" 
              onClick={() => { handleClick(SEND_EMAIL_TYPE.REPLY_ALL, AMPLITUDE_EVENTS.REPLY_ALL, { source: isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_EMAIL_MODEL : AMPLITUDE_EVENTS_SOURCE.GRID_EMAIL_MODEL }) }}
            >
              <IconReplyAll />
            </button>
            <EmailToolTip toolTipId={`reply-all-mail`} />
            </div>
            <div
              data-tip={EMAIL_TOOL_TIPS.FORWARD}
              data-for={`forward-mail`}
            >
            <button 
              disabled={isDraft}
              className="btn btn-link hover-gray-50" 
              onClick={() => { handleClick(SEND_EMAIL_TYPE.FORWARD, AMPLITUDE_EVENTS.FORWARD, { source: isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_EMAIL_MODEL : AMPLITUDE_EVENTS_SOURCE.GRID_EMAIL_MODEL  }) }}
            >
              <IconForward />
            </button>
            <EmailToolTip toolTipId={`forward-mail`}/>
            </div>
          </div>}
        </div>
      </div>
      {!showFullMessage && 
        <div className={`clamp-1 pl-60 pr-15 email-content pointer`} onClick={handleToggleFullMessage}>
          <SafeTextDecode text={emailData.snippet} />
        </div>
      }
      {showFullMessage && (
        <>
        <div className="pl-60 pr-15 email-content">
          <div className="overflow-x-auto">
            <ShadowDOMInjectorWithAttachment 
              emailBody={emailBody} 
              uploadedDocuments={emailAttachment} 
              accountId={accountId} 
              messageId={messageId} 
            />
          </div>
        </div>
        <div className="pl-60 pr-15 email-content">
          {threeDotData && <ThreeDotsBtn onClick={handleActiveDot} className={activeDot ? "active" : ""} activeDot={activeDot} />}
          {activeDot &&
            <div className="mt-10 overflow-x-auto">
              <ShadowDOMInjectorWithAttachment 
                emailBody={threeDotData} 
                uploadedDocuments={emailAttachment} 
                accountId={accountId} 
                messageId={messageId} 
                dotDataRemove = {false}
              />
            </div>
          }
          {canUseGenAI && _loadChanges?.length > 0 &&
            <LoadDataChanges
              selectionData={_loadChanges}
              getLoadChanges={getLoadChanges}
              openMailComposser={openMailComposser}
              isEmailMode={isEmailMode}
            />
          }


          <div className="d-flex flex-wrap align-items-center gap-5 overflow-y-auto custom-scrollbar-sm mr-10 mt-10" style={{ maxHeight: "85px" }}>
            {(emailAttachment?.length > 0) &&
              emailAttachment?.map((doc, index) => !doc?.isInline && (
                <AttachmentPreview
                  attachmentData={doc}
                  index={index}
                  accountId={accountId}
                  messageId={messageId}
                  emailAttachment={emailAttachment}
                  emailDate={emailDate}
                />
              ))}
          </div>
        </div>
        </>
      )}
      {
        <ExternalMailForwardAlert
          show={showMailAlert}
          onClose={() => { onClose() }}
          onApply={() => { onApply() }}
          isApiCall={isApiCall}
        />
      }
      {showAddModal && selectedPeople && (
        <PeopleModal
          show={showAddModal}
          hide={()=> {setShowAddModal(false); setShowMultiOrgModal(false);}}
          selectEditPerson={selectedPeople}
          getEmailPeople={getEmailPeople}
          fromAdd={true}
          isExternal={isExternal}
          isEmailConnected={isEmailConnected}
          event={AMPLITUDE_EVENTS.ADD_PERSON}
          eventProperties={{ source: isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_RECIPIENTS_MODAL : AMPLITUDE_EVENTS_SOURCE.GRID_RECIPIENTS_MODAL }}
          threadId={threadId}
        />
      )}
       {showMultiOrgModal && (
        <EmailParticipantOrganizationModal
          people={selectedPeople}
          handlePeopleChange={setSelectedPeople}
          organizations={organizations}
          hide={() => setShowMultiOrgModal(false)}
          showPeople={() => {
            console.log("EmailParticipantOrganizationModal showPeople");
            setShowAddModal(true);
          }}
        />
      )}
    </>
  )
}

export default MessagePreview
