import React from "react";
import { Modal } from "react-bootstrap";
import { IconWarning } from "Components/Common/Icons";

const DisconnectModal = ({ hide, disconnect, isDisconnecting, terminalDriver , title, body }) => {
  return (
    <>
      <Modal show={true} dialogClassName="modal-sm modal-dialog-scrollable" backdropClassName="z-1050">
        <Modal.Body className=" text-center p-30">
          <IconWarning className="wh-60px mb-20 text-gray-300" />
          <div className=" font-20 font-medium line-height-25 mb-10">{title ?? ''}</div>
          <div className="font-14 line-height-20 d-flex flex-column">
            <span>{body ?? ''}</span>
            <strong>{`${terminalDriver ?? ""} ?`}</strong> 
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-outline-light" onClick={hide}>
            Cancel
          </button>
          <button className="btn btn-danger" onClick={disconnect} disabled={isDisconnecting}>
            {isDisconnecting && (
              <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" />
            )}
            Disconnect
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DisconnectModal;