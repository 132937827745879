import { useContext, useState } from "react";
import { replyEmail } from "../actionCreator";
import { EMAIL_CONTEXT, EMAIL_MSG } from "../constant";
import { toastr } from "../../../../services";
import { useEmailStatus } from "./useEmailStatus";
import { useGetMailById } from "./useGetMailById";

export const useReplyMail = (callback, setIsSendEmail) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { allEmailStatus, setAllEmailStatus, setAllEmails } = useContext(EMAIL_CONTEXT);
  const { _updateStatus } = useEmailStatus(allEmailStatus, setAllEmailStatus);
  const { email: EmailById, _getEmailById } = useGetMailById();

  const _replyMail = async (params) => {
    setLoading(true);
    try {
      const data = await replyEmail(params);
      setData(data);
      setAllEmails(_allEmails => _allEmails?.map((d) => {
        if (d?.id && data?.threadId && d.id.toLowerCase() === data.threadId.toLowerCase()) {
          delete d.timeElapsedLow;
          delete d?.timeElapsedMedium;
          delete d?.timeElapsedHigh;
        }
        return d;
      }));
      setIsSendEmail(false);
      toastr.show(EMAIL_MSG.EMAIL_REPLYED, "success");
      const isClosed = allEmailStatus.find((a) => a?.threadId === data.threadId)?.status === "CLOSED";
      if (isClosed) {
        _updateStatus({ threadIds: [data.threadId], status: "OPEN" });
      }
      if (callback) {
        const isGetEmailById = params.get('draftId') && data?.threadId;
        if (isGetEmailById) {
            const email = await _getEmailById({ id: data?.threadId, isRetrieveFromNylas: true });
            callback(true, email);
            return
        }
        callback(true, isGetEmailById ? { ...EmailById, threadId: data?.threadId } : data);
      }
    } catch (err) {
      toastr.show(EMAIL_MSG.SOMETHING_WENT_WRONG, "error");
    }
    
    setLoading(false);
  };
  return { data, loading, _replyMail };
};
