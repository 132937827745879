import React, {useState} from "react"
import { Modal } from "react-bootstrap";
import _ from "lodash"

import {toastr} from "../../../../../services"
import { ImageUploader } from "../../../../../Components/Common/Components/Component"
import { IconTrash, IconFileUpload } from "../../../../../Components/Common/Icons";

import { uploadFiles } from "../../actionCreator";
import { ALLOWED_FILE_TYPES } from "../../constant";
import { LoaderBar } from "Components/Common/LoaderBar";

const DocumentUpload = ({
  setOpenDocumentUploadModal,
  removeDocument,
  uploadedDocuments,
  setUploadedDocuments,
  setHasUserEdited
}) => {

  const [isDocUploading, setIsDocUploading] = useState(false);

  const uploadResult = (file, previewUrl, type) => {
    //previewUrl this can be used in future to preview uploadded doc

    if (file.size > 26214400 && file.name) {
      toastr.show("File too big, please select a file less than 25mb", "warning");
      return
    }

    const fileType = file.type.toLowerCase();
    if (ALLOWED_FILE_TYPES.includes(fileType)) {

      const prevUploadedFile = _.cloneDeep(uploadedDocuments)
        const newUploadedFile = [...prevUploadedFile, file]
        setUploadedDocuments(newUploadedFile)
        setHasUserEdited(true)
    } else {
      toastr.show("Only Pdf, Image or Excel files are allowed.", "error");
    }
  }

  return (
    <>
      <Modal className="backdrop_modal_open" show={true} centered>
        <Modal.Header>
          <Modal.Title>Upload Document</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="bg-gray-50 rounded-6 p-2 mb-2">
            {isDocUploading && <LoaderBar/>}
            <div className="mb-3">
              <ImageUploader
                uploadResult={uploadResult}
                multi={false}
                name="Choose from your device..."
                type={"image"}
                className="custom-file-input "
                isSmallDesign={(uploadedDocuments?.length > 0) ? true : false}
              />
            </div>
            <div>
            <ul className="list-inline document-list" style={{ overflowY: "auto" }}>
              {(uploadedDocuments?.length > 0) &&
                uploadedDocuments.map((document, index) => {
                  return (
                    <li className="rounded-lg bg-white mb_2 py_5 px_6 w-100" key={index}>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <IconFileUpload />
                          <h6 className="text-gray-700 d-flex ml_12 font-weight-500 mb-0">{document?.name}</h6>
                        </div>
                        <div
                          className="upload-icon"
                          onClick={(e) => {
                            removeDocument(document, index);
                          }}
                        >
                          <IconTrash className="text-gray-500 pointer" />
                        </div>
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-close"
            onClick={() => {
                setOpenDocumentUploadModal(false)
            }
            }
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default DocumentUpload
