import React from "react";
import { DEFAULT_TAGS_COLORS } from "./const";

const TagOption = ({ tag }) => {
  return (
    <div className={`badge badge-sm text-white badge-light`} style={{ backgroundColor: DEFAULT_TAGS_COLORS[0] }}>
      # {tag.value}
    </div>
  );
};

export default TagOption;
