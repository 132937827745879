import React, { useState, useCallback } from "react";
import { debounce } from "lodash";
import AsyncSelect from "react-select/async";
import { smallSelectStyle } from "assets/js/select-style";
import { toastr } from "services";
import { connectLicensePlateNo, disconnectLicensePlateNo  } from "../actionCreators";
import DisconnectModal from "../../Driver/Components/DisconnectModal";
import TruckList from "../index";

const API_RESPONSE = Object.freeze({
  DISCONNECTED: "DISCONNECTED",
  CONNECTED: "CONNECTED",
});

const LicensePlateNumber = (props) => {
  const { terminalPlateNumber , truckId, licensePlateNumberOptions, isOptionsLoading } = props;
  const [liscencePlateNo, setLiscencePlateNo] = useState(terminalPlateNumber);
  const [liscencePlateNoValue, setLiscencePlateNoValue] = useState(null);
  const [isDisconnecting, setIsDisconnecting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showConnect, setShowConnect] = useState(false);
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);
  const licensePlateNoRef = React.useRef();
  const loading = [isLoading, isOptionsLoading].some((i) => i);
  const selectStyles = {
    ...smallSelectStyle,
    control: (baseStyles) => ({
      ...baseStyles,
      minHeight: "32px",
      backgroundColor: liscencePlateNoValue && !loading && !liscencePlateNo ? "#FCECEE" : "",
      borderColor: liscencePlateNoValue && !loading && !liscencePlateNo ? "#D67976" : baseStyles.borderColor,
      outlineColor: liscencePlateNoValue && !loading && !liscencePlateNo ? "#D67976" : baseStyles.outlineColor,
    }),
    singleValue: (baseStyles) => ({
      ...baseStyles,
      color: liscencePlateNoValue && !loading && !liscencePlateNo ? "#D67976" : baseStyles.color,
    }),
  };

  const handleConnectButtonClick = () => {
    setShowConnect(true);
  };

  const handleConnectLicensePlateNo = async (option) => {
    setIsLoading(true);
    setLiscencePlateNoValue(option);
    if (!truckId) {
      toastr.show("Truck Id is required", "error");
      setIsLoading(false);
      return;
    }
    const payload = {
      truckId,
      terminalPlateNumber: option?.label,
    };
    try {
      const response = await connectLicensePlateNo(payload);
      if (response?.data === API_RESPONSE?.CONNECTED) {
        toastr.show("License plate no connected successfully", "success");
        setLiscencePlateNo(option?.label);
      }
    } catch (error) {
      console.error("Error connecting license plate no:", error);
      setLiscencePlateNo(null);
      toastr.show(error, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleLiscensePlateNoDisconnect = async () => {
    setIsDisconnecting(true);
    try {
      if (!truckId) {
        toastr.show("Truck Id is required", "error");
        setIsDisconnecting(false);
        return;
      }
      const response = await disconnectLicensePlateNo(truckId);
      if (response?.data === API_RESPONSE?.DISCONNECTED) {
        toastr.show("License plate no disconnected successfully", "success");
        setShowDisconnectModal(false);
        setLiscencePlateNo(null);
        setLiscencePlateNoValue(null);
        setShowConnect(false);
      }
    } catch (error) {
      console.error("Error disconnecting license plate no:", error);
      toastr.show(error, "error");
    } finally {
      setIsDisconnecting(false);
    }
  };

  const handleDisconnectModalClose = () => {
    setShowDisconnectModal(false);
  };

  const debouncedSearch = useCallback(
    debounce(async (searchTerm, callback) => {
      const response = await TruckList.getLicensePlateList(searchTerm);
      callback(response);
    }, 500),
    []
  );
  
  const handleAsyncSearch = useCallback(
    (searchTerm) => {
      return new Promise((resolve) => {
        debouncedSearch(searchTerm, resolve);
      });
    },
    [debouncedSearch]
  );

  React.useEffect(() => {
    const currentProp = terminalPlateNumber;
    const prevProp = licensePlateNoRef?.current;
    
    if (currentProp !== prevProp) {
      if (currentProp) {
        setLiscencePlateNo(currentProp);
        setShowConnect(true);
      } else {
        setLiscencePlateNo(null);
        setShowConnect(false);
      }
    }
    licensePlateNoRef.current = currentProp;

  }, [terminalPlateNumber]); 

  return (
    <>
      <div className="bg-white rounded-5 px-15 py-10">
        <div className="d-flex align-items-center mb-15">
          <div className="font-medium font-12 line-height-20">GCT BAYONNE Plate No</div>
          {liscencePlateNo ? (
            <span className="badge badge-sm badge-brand-50 ml-auto">Connected</span>
          ) : (
            <span className="badge badge-sm badge-light ml-auto">Not Connected</span>
          )}
        </div>
        {liscencePlateNo && (
          <div className="d-flex align-items-center mb-15">
            <div className="text-muted text-nowrap">License Plate No</div>
            <div className="ml-auto text-right">{liscencePlateNo}</div>
          </div>
        )}
        <div className="d-flex flex-column gap-5">
          {!liscencePlateNo && !showConnect && (
            <button
              className="btn btn-sm btn-outline-light w-100 justify-content-center"
              onClick={handleConnectButtonClick}
            >
              Connect
            </button>
          )}
          {!liscencePlateNo && showConnect && (
            <AsyncSelect
              key="license-plate-no"
              cacheOptions
              defaultMenuIsOpen
              value={liscencePlateNoValue}
              defaultOptions={licensePlateNumberOptions}
              className="w-100 pointer"
              styles={selectStyles}
              onChange={handleConnectLicensePlateNo}
              loadOptions={handleAsyncSearch}
              menuPlacement="auto"
              isLoading={loading}
            />
          )}
          {liscencePlateNo && (
            <button
              className="btn btn-sm btn-outline-light w-100 justify-content-center"
              onClick={() => setShowDisconnectModal(true)}
              disabled={isDisconnecting}
            >
              Disconnect
            </button>
          )}
        </div>
      </div>
      {showDisconnectModal && (
        <DisconnectModal
          terminalDriver={liscencePlateNo}
          hide={handleDisconnectModalClose}
          disconnect={handleLiscensePlateNoDisconnect}
          isDisconnecting={isDisconnecting}
          title="Disconnect from License Plate Number ?"
          body="Are you sure that you want to disconnect this truck from"
        />
      )}
    </>
  );
};

export default LicensePlateNumber;
