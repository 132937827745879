import React, { useState, useCallback } from "react";
import { debounce } from "lodash";
import AsyncSelect from "react-select/async";
import { smallSelectStyle } from "assets/js/select-style";
import { toastr } from "services";
import { connectTerminalDriver, disconnectTerminalDriver } from "../actionCreators";
import DisconnectModal from "../Components/DisconnectModal";
import DriverProfileList from "../index";

const API_RESPONSE = Object.freeze({
  DISCONNECTED: "DISCONNECTED",
  CONNECTED: "CONNECTED",
});

const TerminalDriver = (props) => {
  const { terminalDriverDetails , terminalDriverOptions, isOptionsLoading } = props;
  const { terminalDriverName: terminalDriver, _id: driverId } = terminalDriverDetails ?? {};
  const [terminalDriverName, setTerminalDriverName] = useState(terminalDriver);
  const [terminalDriverValue, setTerminalDriverValue] = useState(null);
  const [isDisconnecting, setIsDisconnecting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showConnect, setShowConnect] = useState(false);
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);
  const terminalDriverNameRef = React.useRef();
  const loading = [isLoading, isOptionsLoading].some((i) => i);
  const selectStyles = {
    ...smallSelectStyle,
    control: (baseStyles) => ({
      ...baseStyles,
      minHeight: "32px",
      backgroundColor: terminalDriverValue && !loading && !terminalDriverName ? "#FCECEE" : "",
      borderColor: terminalDriverValue && !loading && !terminalDriverName ? "#D67976" : baseStyles.borderColor,
      outlineColor: terminalDriverValue && !loading && !terminalDriverName ? "#D67976" : baseStyles.outlineColor,
    }),
    singleValue: (baseStyles) => ({
      ...baseStyles,
      color: terminalDriverValue && !loading && !terminalDriverName ? "#D67976" : baseStyles.color,
    }),
  };

  const handleConnectButtonClick = () => {
    setShowConnect(true);
  };

  const handleConnectDriver = async (option) => {
    setIsLoading(true);
    setTerminalDriverValue(option);
    if (!driverId) {
      toastr.show("Driver Id is required", "error");
      setIsLoading(false);
      return;
    }
    const payload = {
      driverId,
      terminalDriverName: option?.label,
    };
    try {
      const response = await connectTerminalDriver(payload);
      if (response?.data === API_RESPONSE?.CONNECTED) {
        toastr.show("Driver connected successfully", "success");
        setTerminalDriverName(option?.label);
      }
    } catch (error) {
      console.error("Error connecting terminal driver:", error);
      setTerminalDriverName(null);
      toastr.show(error, "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleTerminalDriverDisconnect = async () => {
    setIsDisconnecting(true);
    try {
      if (!driverId) {
        toastr.show("Driver Id is required", "error");
        setIsDisconnecting(false);
        return;
      }
      const response = await disconnectTerminalDriver(driverId);
      if (response?.data === API_RESPONSE?.DISCONNECTED) {
        toastr.show("Driver disconnected successfully", "success");
        setShowDisconnectModal(false);
        setTerminalDriverName(null);
        setTerminalDriverValue(null);
        setShowConnect(false);
      }
    } catch (error) {
      console.error("Error disconnecting terminal driver:", error);
      toastr.show(error, "error");
    } finally {
      setIsDisconnecting(false);
    }
  };

  const handleDisconnectModalClose = () => {
    setShowDisconnectModal(false);
  };

  const debouncedSearch = useCallback(
    debounce(async (searchTerm, callback) => {
      const response = await DriverProfileList?.getTerminalDriverStatus(searchTerm);
      callback(response);
    }, 500),
    []
  );
  
  const handleAsyncSearch = useCallback(
    (searchTerm) => {
      return new Promise((resolve) => {
        debouncedSearch(searchTerm, resolve);
      });
    },
    [debouncedSearch]
  );

  React.useEffect(() => {
    const currentProp = terminalDriverDetails?.terminalDriverName;
    const prevProp = terminalDriverNameRef?.current;
    
    if (currentProp !== prevProp) {
      if (currentProp) {
        setTerminalDriverName(currentProp);
        setShowConnect(true);
      } else {
        setTerminalDriverName(null);
        setShowConnect(false);
      }
    }
    terminalDriverNameRef.current = currentProp;

  }, [terminalDriverDetails]); 

  return (
    <>
      <div className="bg-white rounded-5 px-15 py-10">
        <div className="d-flex align-items-center mb-15">
          <div className="font-medium font-14 line-height-20">APM Terminal Driver</div>
          {terminalDriverName ? (
            <span className="badge badge-sm badge-brand-50 ml-auto">Connected</span>
          ) : (
            <span className="badge badge-sm badge-light ml-auto">Not Connected</span>
          )}
        </div>
        {terminalDriverName && (
          <div className="d-flex align-items-center mb-15">
            <div className="text-muted text-nowrap">Driver Name</div>
            <div className="ml-auto text-right">{terminalDriverName}</div>
          </div>
        )}
        <div className="d-flex flex-column gap-5">
          {!terminalDriverName && !showConnect && (
            <button
              className="btn btn-sm btn-outline-light w-100 justify-content-center"
              onClick={handleConnectButtonClick}
            >
              Connect
            </button>
          )}
          {!terminalDriverName && showConnect && (
            <AsyncSelect
              key="terminal-driver"
              cacheOptions
              defaultMenuIsOpen
              value={terminalDriverValue}
              defaultOptions={terminalDriverOptions}
              className="w-100 pointer"
              styles={selectStyles}
              onChange={handleConnectDriver}
              loadOptions={handleAsyncSearch}
              menuPlacement="auto"
              isLoading={loading}
            />
          )}
          {terminalDriverName && (
            <button
              className="btn btn-sm btn-outline-light w-100 justify-content-center"
              onClick={() => setShowDisconnectModal(true)}
              disabled={isDisconnecting}
            >
              Disconnect
            </button>
          )}
        </div>
      </div>
      {showDisconnectModal && (
        <DisconnectModal
          terminalDriver={terminalDriverName}
          hide={handleDisconnectModalClose}
          disconnect={handleTerminalDriverDisconnect}
          isDisconnecting={isDisconnecting}
          title="Disconnect from Terminal Driver ?"
          body="Are you sure that you want to disconnect this driver from"
        />
      )}
    </>
  );
};

export default TerminalDriver;
