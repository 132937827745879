import React, { useState, useCallback } from "react";
import Creatable from 'react-select/creatable';
import { debounce } from 'lodash';
import { isTerminalTrackingEnabled } from "services";
import { convertResultToSelectWithStatus } from "pages/tms/Load/DriverOrderUtility";

const smallSelectStyle = {
  control: (base, state) => ({
    ...base,
    minHeight: 30,
    borderColor: "#BFCCDA",
    borderRadius: "3px",
    boxShadow: "none",
    "&:hover": {
      borderColor: "#687d96",
      boxShadow: "none",
    },
    "&:focus": {
      border: "1px solid var(--color-primary-500)",
      paddingTop: "6.5px",
      paddingBottom: "6.5px",
      boxShadow: "none",
    },
    "&:active": {
      border: "1px solid var(--color-primary-500)",
      boxShadow: "none",
    },
  }),
  menu: (base) => ({
    ...base,
    innerHeight: 30,
    zIndex: 2,
  }),
  menuList: (base) => ({
    ...base,
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: 30,
    color: "#394E66",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    padding: 5,
    color: "#394E66",
    svg: {
      width: 14,
    },
  }),
  indicatorSeparator: (base) => ({
    ...base,
    margin: "0",
    backgroundColor: "#BFCCDA",
  }),
};

const mediumSelectStyle = {
  control: (base, state) => ({
    ...base,
    minHeight: 40,
    borderColor: "#BFCCDA",
    borderRadius: "3px",
  }),
  menu: (base) => ({
    ...base,
    innerHeight: 40,
    zIndex: 2,
  }),
  menuList: (base) => ({
    ...base,
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: 40,
    color: "#394E66",
  }),
  indicatorSeparator: (base) => ({
    ...base,
    margin: "0",
    backgroundColor: "#BFCCDA",
  }),
};

const CustomSelectDropdown = (props) => {
  const {
    name,
    value,
    isMulti,
    isClearable,
    isDisabled,
    size,
    placement,
    placeholder,
    className,
    closeMenuOnSelect,
    onChange,
    isSearchable,
    onBlur,
    style,
    onEmployeeEmailChange,
    createOptionPosition,
    formatCreateLabel,
    onCreateOption,
    components,
    onFocus,
    blurInputOnSelect,
    onCustomerSelect,
    isEmployeeEmail,
    menuPosition,
    dynamicApi,
    dynamicCondition,
    defaulOption,
    customerInputValue,
    setCustomerInputValue,
    setRenderCustomers,
    customerType,
    cacheOptions,
    allowCreateWhileLoading
  } = props;
  const [inputValue, setInputValue] = useState("");
  const [skipCount, setSkipCount] = useState(0);
  const [renderCustomersFilter, setRenderCustomersFilter] = useState([]);
  const [renderCustomerData, setRenderCustomerData] = useState([]);
  const [isLoadingBarShow, setIsLoadingBarShow] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const locationPerReq = 10;

  const debouncedGetApiData = useCallback(
    debounce(async (searchValue, isFlag, isScroll,skip) => {
      const filterParams = {
        limit: locationPerReq,
        skip: skip || skipCount,
        nameSearch: searchValue,
        customerType: JSON.stringify(customerType),
      };
      try {
        let result = await dynamicApi(filterParams, true);

        result = isTerminalTrackingEnabled() && (customerType.some(type => ['ALL', 'shipper', 'consignee', 'chassisPick', 'chassisTermination'].includes(type)) || dynamicCondition) ? convertResultToSelectWithStatus(result) : result;

        if (!isFlag) {
          if (result?.length > 0 && isScroll && searchValue.length > 0) {
            setRenderCustomersFilter((prevState) => [...prevState, ...result]);
            setRenderCustomerData([])
          } else if (!isScroll && searchValue.length === 0) {
            setRenderCustomers(result);
          } else if (result?.length > 0 && searchValue.length > 0) {
            setRenderCustomerData(result)
            setRenderCustomersFilter(result)
            setHasMoreData(true);
          }
          if (isScroll && result.length < locationPerReq) {
            setHasMoreData(false);
          }
        }
        if (searchValue.length <= 0) {
          setRenderCustomersFilter([]);
          setRenderCustomerData([])
        }
      } catch (error) {
        setHasMoreData(false);
        console.error('API call failed:', error);
      } finally {
        setIsLoadingBarShow(false);
      }
    }, 300),
    [skipCount, dynamicApi, setRenderCustomers]
  );

  const handleScroll = useCallback(() => {
    if (hasMoreData && !isLoadingBarShow) {
      let skip ;
      if(renderCustomerData.length > 0){
        setSkipCount(locationPerReq);
        skip = locationPerReq
      }else{
        setSkipCount((prev) => prev + locationPerReq);
        skip = skipCount + locationPerReq
      }    
      setIsLoadingBarShow(true);
      debouncedGetApiData(inputValue, false, true,skip);
    }
  }, [hasMoreData, isLoadingBarShow, skipCount, inputValue, debouncedGetApiData]);

  const handleInputChange = useCallback((newInputValue) => {
    if (onEmployeeEmailChange) {
      if (isEmployeeEmail) {
        onEmployeeEmailChange(newInputValue, true);
      } else {
        setCustomerInputValue(newInputValue || customerInputValue);
      }
    }
    if (newInputValue) {
      setInputValue(newInputValue);
      setIsLoadingBarShow(true);
      debouncedGetApiData(newInputValue, false, false);
    }
    else {
      setSkipCount(0);
      setRenderCustomerData([])
      setRenderCustomersFilter([]);
      setInputValue("");
    }
  }, [isEmployeeEmail, onEmployeeEmailChange, customerInputValue, setCustomerInputValue, debouncedGetApiData]);

  return (
    <Creatable
      name={name}
      classNames={className}
      onEmployeeEmailChange={onEmployeeEmailChange}
      options={renderCustomersFilter.length > 0 ? renderCustomersFilter : defaulOption}
      closeMenuOnSelect={closeMenuOnSelect}
      value={value}
      onChange={onChange}
      styles={smallSelectStyle}
      isMulti={isMulti}
      isClearable={isClearable}
      menuPlacement={placement === "top" ? "top" : placement === "bottom" ? "bottom" : "auto"}
      placeholder={placeholder}
      isDisabled={isDisabled}
      isSearchable={isSearchable}
      onBlur={onBlur}
      onInputChange={handleInputChange}
      onMenuScrollToBottom={inputValue?.length !== 0 ? handleScroll : undefined}
      isLoading={isLoadingBarShow}
      createOptionPosition={createOptionPosition}
      formatCreateLabel={formatCreateLabel}
      onCreateOption={onCreateOption}
      components={components}
      onFocus={onFocus}
      blurInputOnSelect={blurInputOnSelect}
      onCustomerSelect={onCustomerSelect}
      isEmployeeEmail={isEmployeeEmail}
      menuPosition={menuPosition}
      cacheOptions={cacheOptions}
      allowCreateWhileLoading={allowCreateWhileLoading}
    />
  );
};
export default CustomSelectDropdown;