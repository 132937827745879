import React, { Component } from "react";
import AsyncCreatableSelect from "react-select/async-creatable";
import _ from "lodash";
import moment from "moment";
import Datetime from "react-datetime";
import { connect } from "react-redux";
import Select from "react-select";
import AsyncSelect from "react-select/async";

import { bindActionCreators } from "redux";
import * as actionCreators from "../Load/actionCreator";
import {
  newSmSelectStyle,
} from "../../../assets/js/select-style";
import {
  loadEvents,
  tmsDrops,
  tmsEndMoveEvents,
} from "../constant";
import {
  IconCompleted,
  IconDrag,
  IconTimes,
  IconTrash,
  IconPool,
} from "../../../Components/Common/Icons";
import { LoaderBar } from "../../../Components/Common/LoaderBar";
import {
  DateTimeFormatUtils,
  addressFormatEnum,
  addressParser,
  defaultAddressFormat,
  getStorage,
  isMilitaryTime,
  makeOptionsForSelect,
  toastr,
  amplitudeTrack,
  getValidDrivers,
  getDriverAndCarrierName,
  isManageFleetOrOutSource,
  makeOptionsForSelectWithStatus,
  StatusDropdownWrapper,
} from "../../../services/Common.services";
import {
  hardRemoveDriver,
  updateStopOffType,
} from "../Dispatcher/actionCreators";
import {
  removeStatusDriverOrderById,
  setLoadStatusOrder,
  updateDriverOrderById,
} from "../Load/actionCreator";
import "../Load/style.css";
import {
  manualDriverPay,
  UpdatePricing,
  removeValueLoad,
} from "../services";
import RecommendedReturnTerminals from "../Load/RecommendedReturnTerminals";
import ExactDateWrapper from "../NewDispatcher/DispatcherTable/Components/CustomDatePicker/ExactDateWrapper";
import {
  ICONS,
  ROUTING_ADDRESS_FIELDS,
  updateStatusTypeForLoad,
} from "../Load/DriverOrderUtility";
import SupportMove from "../Load/SupportMove";
import config from "../../../config";
import { mappedPayloadSuggestion } from "../ChassisValidation/utils";
import { ActionType } from "../ChassisValidation/utils/constants";
import { checkTrips } from "../FreeFlow/FreeFlow.services";
import { DRIVER_ORDER_VALIDATION, ROUTING_DRAG_LEGS, statusType } from "./constants";
import { createLoadStatus, getDistanceBetweenEvents, getDurationInHHMMFormat, findPrevValidEventIndex, isValidDriverOrder, movesFromDriverOrder } from "./utilities";
import { STOPOFF_TYPES } from "../../../utils";
const firebase = config.firebase;

class AddRoutingEvent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      assignedDriver: null,
      selectedRoute: null,
      driverOrder: [],
      supportMoves: [],
      driverNote: "",
      officeNote: "",
      droppedIndex: null,
      CHASSISPICKCUSTOMER: props.selectedLoads?.chassisPick || null,
      PULLCONTAINERCUSTOMER:(props.selectedLoads?.shipper && props.selectedLoads.shipper.length > 0) ? props.selectedLoads.shipper[0] : null,
      DELIVERLOADCUSTOMER: (props.selectedLoads?.consignee && props.selectedLoads?.consignee.length > 0) ? props.selectedLoads?.consignee[0] : null,
      RETURNCONTAINERCUSTOMER: props.selectedLoads.emptyOrigin || null,
      DROPCONTAINERCUSTOMER: null,
      LIFTOFFCUSTOMER: null,
      CHASSISTERMINATIONCUSTOMER:
        props.selectedLoads.chassisTermination || null,
      isPrePullCharge: props.selectedLoads.isPrePullCharge,
      isNextContainerNeedToPickup:
        props.selectedLoads.isNextContainerNeedToPickup,
      activeNoteTab: "load",
      allowDriverCompletion: props.selectedLoads.allowDriverCompletion,
      changeCompletionDate: null,
      groupTerminalData: [],
      showNote: false,
      selectedLoad: null,
      loading: false,
      isGrayPool: props.selectedLoads.isGrayPool,
      grayChassisNo: props.selectedLoads.grayChassisNo,
      grayContainerNo: props.selectedLoads.grayContainerNo,
      loadCompletedAt: props.selectedLoads.loadCompletedAt,
      currentLoads: props.selectedLoads,
      isLoader: false,
      isShowTripModal: false,
      tripDetails: null,
      isCall: true,
      selectedMoveFilter: "containerMove",
      suggestions: [],
      indexForSelect: null,
      saveLoader: false,
      driverPays: [],
      isDriverPayLoading:false,
      editDriverPayData: null,
      isDragging:false,
      canChangeTemplate: props.selectedLoads.driverOrder?.[0]?.driver
        ? true
        : false,
      editInputs: [],
      combineTripModal: false,
      currentLoadEventType : null,
      prevValidEventId : null
    };

    this.colorIndex = 0;
    this.currentUserDetails = JSON.parse(getStorage("loggedInUser"));
    this.userTimeFormat = isMilitaryTime() ? "MM/DD/yyyy HH:mm" : "";
    this.prevDriverOrder = [];
    this.isChassisV3 = JSON.parse(getStorage("userBasicSettings"))?.isChassisV3;
    this.currencySymbol = this.props.selectedLoads?.invoiceCurrency?.symbol || "$"
    this.listOfStopOff = STOPOFF_TYPES()?.filter((d) => !d.isDeleted)
    this.showEvent = this.props.showEvent
  }

  componentDidMount() {
    this.getLoadDriverOrder();

    this.prevDriverOrder = _.cloneDeep(this.props.selectedLoads.driverOrder);

    const currentUser = JSON.parse(getStorage("loggedInUser"));
    let carrierId = currentUser._id;
    if (currentUser.role === "fleetmanager") {
      carrierId = currentUser.fleetManager.carrier;
    }

    if (this.props.selectedLoads.officeNote) {
      this.setState({ officeNote: this.props.selectedLoads.officeNote });
    }
    if (this.props.selectedLoads.driverNote) {
      this.setState({ driverNote: this.props.selectedLoads.driverNote });
    }

    if (this.props.handleSubmitLoad) {
      this.props.handleSubmitLoad(() => this.handleOrderStatusChange("update"));
    }

    const userBasicSettings = JSON.parse(getStorage("userBasicSettings") ?? '{}');
    let driverValidation = [];
    if (userBasicSettings && userBasicSettings.driverValidation && userBasicSettings.driverValidation?.length > 0) {
			driverValidation = userBasicSettings.driverValidation;
		}
    this.setState({ driverValidation });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!_.isEqual(prevProps.selectedLoads.driverOrder, this.props.selectedLoads.driverOrder)) {
      this.prevDriverOrder = _.cloneDeep(this.props.selectedLoads.driverOrder);
      this.setState({ driverOrder: this.props.selectedLoads.driverOrder });
    }
    if (!_.isEqual(prevProps.selectedLoads.preSetOrderStatus, this.props.selectedLoads.preSetOrderStatus) && _.isEqual(prevProps.selectedLoads.driverOrder, this.props.selectedLoads.driverOrder)) {
      this.setState({ driverOrder: this.props.selectedLoads.driverOrder });
    }
    if (!_.isEqual(prevProps.selectedLoads.grayContainerNo, this.props.selectedLoads.grayContainerNo)) {
      this.setState({ grayContainerNo: this.props.selectedLoads.grayContainerNo });
    }
    if (!_.isEqual(prevProps.selectedLoads.grayChassisNo, this.props.selectedLoads.grayChassisNo)) {
      this.setState({ grayChassisNo: this.props.selectedLoads.grayChassisNo });
    }
    if (!_.isEqual(prevProps.selectedLoads.loadCompletedAt, this.props.selectedLoads.loadCompletedAt)) {
      this.setState({ loadCompletedAt: this.props.selectedLoads.loadCompletedAt });
    }

    if (this.props.selectedLoads?.driverOrder[0]?.driver && this.isChassisV3) {
      this.setState({ canChangeTemplate: true });
    } else {
      this.setState({ canChangeTemplate: false });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !_.isEqual(this.props.selectedLoads, nextProps.selectedLoads) ||
      !_.isEqual(this.state, nextState)
    );
  }

  componentWillUnmount() {
    if (this.socketupdatedSupportMoves) {
      this.socketupdatedSupportMoves.off("value");
    }
    if (this.socketDriverPayUpdateRef) {
      this.socketDriverPayUpdateRef.off("value");
    }
  }

  getLoadDriverOrder = () => {
    if (this.props.selectedLoads) {
      this.setState({
        driverOrder: this.props.selectedLoads.driverOrder,
        isPrePullCharge: this.props.selectedLoads.isPrePullCharge,
        isNextContainerNeedToPickup: this.props.selectedLoads.isNextContainerNeedToPickup,
        allowDriverCompletion: this.props.selectedLoads.allowDriverCompletion,
        changeCompletionDate: null,
        isGrayPool: this.props.selectedLoads.isGrayPool,
        grayChassisNo: this.props.selectedLoads.grayChassisNo,
        grayContainerNo: this.props.selectedLoads.grayContainerNo,
      });
    }
    if (this.props.selectedLoads.preSetOrderStatus) {
      this.setState({ selectedRoute: this.props.selectedLoads.preSetOrderStatus });
    } else {
      this.setState({ selectedRoute: "" });
    }
  };

  handleDragStart = (e) => {
    this.setState({isDragging:true})
    e.dataTransfer.setData("loadStatusType", e.target.id);
    e.stopPropagation();
  };

  handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  handleKeyDown(event) {
    if (event.keyCode === 13) {
      this.handleDragOver(event);
    }
  }
  handleDragEnter = (index) => {
    if (this.state.isDragging && this.state.droppedIndex !== index)
      this.setState({ droppedIndex: index });
  };

  handleOnDrop = async (e) => {
    const loadStatusType = e.dataTransfer.getData("loadStatusType");
    this.setState({currentLoadEventType : loadStatusType});
    let currentIndex = this.state.droppedIndex;

    let driverOrder = _.cloneDeep(this.state.driverOrder);
    if(currentIndex >= 100){
      currentIndex -= 101;
    }

    currentIndex+=1
    
    if ((driverOrder?.[currentIndex]?.combineTripId && loadStatusType !== loadEvents.DELIVERLOAD) || driverOrder?.[currentIndex]?.arrived) return;
    const isDeliveryBefore = this.state.driverOrder.findIndex((x,i)=>x?.type === "DELIVERLOAD" && !x?.isVoidOut && i < currentIndex) !== -1
    if(isDeliveryBefore && loadStatusType === "PULLCONTAINER"){
      toastr.show("Pickup Container cannot be after Load Delivery","error")
      return;
    }
    const beforeLiftOnAndNotChassisEvent = driverOrder?.[currentIndex]?.type === "LIFTON" && !["CHASSISTERMINATION", "CHASSISPICK"].includes(loadStatusType) ;
    if(driverOrder?.[currentIndex]?.type === "HOOKCONTAINER" || beforeLiftOnAndNotChassisEvent){
        if(!driverOrder?.[currentIndex].isVoidOut)
          currentIndex += 1
        else{
          const validNextEvent = this.state.driverOrder.findIndex((x,i)=>(!x?.isVoidOut && i>currentIndex))
          if(validNextEvent >= 0)
            currentIndex += (validNextEvent - currentIndex) + 1
        }
    }
    if (loadStatusType === "COMPLETED") {
      if(currentIndex === this.state.driverOrder.length){

        this.setState({ allowDriverCompletion: true }, () => {
          this.handleOrderStatusChange("update");
        });
        return;
      }else {
        toastr.show("Completed status should be at end Only","error")
        return;
      }
    }
    if(currentIndex === this.state.driverOrder.length){
      const afterLiftOffAndNotChassisEvent = this.state.driverOrder?.[currentIndex - 1]?.type === "LIFTOFF" && !["CHASSISTERMINATION", "CHASSISPICK"].includes(loadStatusType) ;
      if(this.state.driverOrder?.[currentIndex - 1]?.type === "DROPCONTAINER" || afterLiftOffAndNotChassisEvent){
        const HookEvent = createLoadStatus(
          DRIVER_ORDER_VALIDATION[this.state.driverOrder?.[currentIndex - 1]?.type]?.relatedEvent,
          this.state.driverOrder?.[currentIndex - 1]?.customerId,
          null,
          driverOrder?.[currentIndex]?.prevType,
          driverOrder?.[currentIndex]?.loadAssignedDate,
          driverOrder?.[currentIndex]?.isGrayPool,
          null,
          null
          )
          
          driverOrder.splice(currentIndex, 0, HookEvent)
          currentIndex+=1
      }else if(this.state.driverOrder?.[currentIndex - 1]?.combineTripId){
        return;
      }
    }

    const belowEvent = driverOrder?.[currentIndex]

    if((belowEvent && belowEvent?.isVoidOut))
      return;

    let driverId = belowEvent?.driver
    let prevType = belowEvent?.prevType
    let isGrayPool = belowEvent?.isGrayPool
    let moveId = belowEvent?.moveId
    let loadAssignedDate = belowEvent && belowEvent.loadAssignedDate;
    let moveBreak = false

    let prevValidEventIndex = findPrevValidEventIndex(driverOrder, currentIndex);
    const prevValidEvent = driverOrder?.[prevValidEventIndex] || null;
    this.setState({prevValidEventId : prevValidEvent?._id})
    if(currentIndex === driverOrder.length || loadStatusType === "CHASSISTERMINATION"){
      driverId = prevValidEvent?.driver;
      prevType = prevValidEvent?.type;
      isGrayPool = prevValidEvent?.isGrayPool
      moveId = prevValidEvent?.moveId
      loadAssignedDate = prevValidEvent?.loadAssignedDate
    }
    const newEventsToAdd = []
    newEventsToAdd.push(createLoadStatus(
      loadStatusType,
      this.state[`${loadStatusType}CUSTOMER`],
      driverId,
      prevType,
      loadAssignedDate,
      isGrayPool,
      null,
      moveId
    ))
    if(DRIVER_ORDER_VALIDATION[loadStatusType]?.relatedEvent && this.state.driverOrder.length > currentIndex){
      newEventsToAdd.push(createLoadStatus(
        DRIVER_ORDER_VALIDATION[loadStatusType]?.relatedEvent,
        this.state[`${loadStatusType}CUSTOMER`],
        null,
        prevType,
        loadAssignedDate,
        isGrayPool,
        null,
        null
      ))
      moveBreak = true
    }
    driverOrder.splice(
      currentIndex,
      0,
      ...newEventsToAdd
      )
    if(moveBreak){
      driverOrder.forEach((event,index)=>{
        if(index>currentIndex){
          delete event.driver 
          delete event.loadAssignedDate 
          delete event.moveId
        }
      })
    }
    const driverOrderValidation = isValidDriverOrder(driverOrder)
    if(!driverOrderValidation?.isValid){
      toastr.show(driverOrderValidation.message, "error")
      return;
    }
    this.setState({ driverOrder }, () => {
      this.handleOrderStatusChange("update");
    });
  }

  assignDriver = (value, index, removeMove) => {
    if (value?.allInfos?.driver?.accountHold) {
      toastr.show("This driver is not able to be selected because they are marked in an ON HOLD status in Driver Profiles", "error");
      return;
    }
    let driverOrder = _.cloneDeep(this.state.driverOrder);

    let startIndexOfLag = index;
    if (index < driverOrder.length) {

      if (value) {
        for (let i = startIndexOfLag; i < driverOrder.length; i++) {
          if (!driverOrder[i].isVoidOut) {
            driverOrder[i].driver = value && value.allInfos;
            driverOrder[i].loadAssignedDate = moment().toISOString();
            driverOrder[i].dispatchedBy = this.currentUserDetails?._id
            if (
              tmsEndMoveEvents.includes(driverOrder[i].type) &&
              !driverOrder[i].isVoidOut &&
              driverOrder[i+1]?.type !== loadEvents.CHASSISTERMINATION
            )
              break;
          }
        }
      } else {
        for (let i = startIndexOfLag; i < driverOrder.length; i++) {
          if (!driverOrder[i].isVoidOut) {
            driverOrder[i].driver = null;
            delete driverOrder[i].arrived;
            delete driverOrder[i].departed;
            delete driverOrder[i].loadAssignedDate;
            delete driverOrder[i].dispatchedBy;
            removeMove && delete driverOrder[i].moveId;
          }
        }
      }
      let editInputs = _.cloneDeep(this.state.editInputs)
      editInputs.push('driver')
      this.setState({ driverOrder, editInputs}, () =>
        this.handleOrderStatusChange("update", `select-driver-${index}`, index)
      );
    }
  };

  assignDriverOnRemoval = (value, loadAssignedDate, index) => {
    let driverOrder = _.cloneDeep(this.state.driverOrder);
    for (let i = index; i < driverOrder.length; i++) {
      if (!driverOrder[i].isVoidOut) {
        driverOrder[i].driver = value;
        driverOrder[i].loadAssignedDate = loadAssignedDate;
        driverOrder[i].isGrayPool = driverOrder[index-1]?.isGrayPool;
        driverOrder[i].isBobtail = driverOrder[index-1]?.isBobtail;
        driverOrder[i].moveId = driverOrder[index-1]?.moveId
        if (tmsEndMoveEvents.includes(driverOrder[i].type) && !driverOrder[i].isVoidOut && driverOrder[i+1]?.type !== loadEvents.CHASSISTERMINATION) 
          break;
      }
    }
    this.setState({ driverOrder }, () =>
      this.handleOrderStatusChange("update")
    );
  };

  removeStatus = (index) => {
    let driverOrder = _.cloneDeep(this.state.driverOrder);
    let driver, loadAssignedDate;

    const currentEventType = driverOrder[index].type;
    const releatedEventType = DRIVER_ORDER_VALIDATION[currentEventType]?.relatedEvent || "";
    let fromIndex = index;
    let totalEventsToRemove = 1;
    if(releatedEventType){
      if(tmsDrops.includes(currentEventType)){
        const nextRelatedEventIndex = driverOrder.findIndex((x,i)=>i>index && x.type === releatedEventType && !x.isVoidOut);
        if(nextRelatedEventIndex !== -1){
          if(driverOrder?.[nextRelatedEventIndex]?.combineTripId){
            return;
          }
          totalEventsToRemove = nextRelatedEventIndex - index + 1;
          if(driverOrder[index-1].driver)
          driver = driverOrder[index-1].driver
          loadAssignedDate = driverOrder[index-1].loadAssignedDate+""
        }else{
          totalEventsToRemove = driverOrder.length - index;
        }
      }else{
        let prevRelatedEventIndex = -1
        driverOrder.forEach((x,i)=>{
          if(i<index && x.type === releatedEventType){
            prevRelatedEventIndex = i
          }}) 
          if(prevRelatedEventIndex !== -1){
            if(driverOrder?.[prevRelatedEventIndex]?.combineTripId){
              return;
            }
            fromIndex = prevRelatedEventIndex
            totalEventsToRemove = index - prevRelatedEventIndex + 1
            if(driverOrder[fromIndex-1].driver)
            driver = driverOrder[prevRelatedEventIndex-1].driver
            loadAssignedDate = driverOrder[prevRelatedEventIndex-1].loadAssignedDate+""
          }
      }
    }
    driverOrder.splice(fromIndex, totalEventsToRemove)  
    this.setState({ driverOrder }, () => {
      if (driver) {
        this.assignDriverOnRemoval(driver, loadAssignedDate, fromIndex);
      } else {
        this.handleOrderStatusChange("update");
      }
    });
  };

  validateOrderStatus = (driverOrder) => {
    // let isValid = driverOrder.filter((status) => !status.customerId).length > 0? false: true;
    // if(!isValid){
    //     toastr.show("Please assign a driver and/or a customer.", 'error');
    //     return false;
    // }
    let inValidStatus = driverOrder.find(
      (status) =>
        status.driver &&
        !status.customerId &&
        ["CHASSISPICK", "RETURNCONTAINER", "CHASSISTERMINATION"].indexOf(
          status.type
        ) === -1
    );
    if (inValidStatus) {
      toastr.show(
        "A valid location is required here." + inValidStatus.type,
        "error"
      );
      return false;
    }

    inValidStatus = driverOrder.find(
      (status) => status.driver && !status.loadAssignedDate
    );
    if (inValidStatus) {
      toastr.show(
        "Load Assigned Date is required for " + inValidStatus.type,
        "error"
      );
      return false;
    }

    const fDriverIndx = driverOrder.findIndex(
      (status) => !(status?.driver || status?.drayosCarrier) && !status.isVoidOut
    );
    if (fDriverIndx > -1) {
      driverOrder.forEach((status, i) => {
        if (i > fDriverIndx) {
          if (!status.isVoidOut && status.driver) {
            inValidStatus = true;
          }
        }
      });
      if (inValidStatus && !isManageFleetOrOutSource().isMultiTender) {
        toastr.show(`${getDriverAndCarrierName()} is required`, "error");
        return false;
      }
    }

    if (this.props.selectedLoads?.type_of_load != "ROAD") {
      if (
        [
          loadEvents.DROPCONTAINER,
          loadEvents.LIFTOFF,
          loadEvents.RETURNCONTAINER,
          loadEvents.CHASSISTERMINATION,
        ].indexOf(driverOrder[driverOrder.length - 1].type) === -1
      ) {
        toastr.show(
          "Last status must be either dropped, return container or chassis termination",
          "error"
        );
        return false;
      }
    }
    return true;
  };

  updateState = (obj) => {
    this.setState(obj);
  };

  handleInCurrentDriverOrder = (obj) => {
    if (
      obj.action[0]?.actionType === ActionType.ADD &&
      obj.action[0]?.actionEvent === loadEvents.CHASSISPICK
    ) {
      this.updateStatus(false, false, false, false, false, false, false, {
        eventType: loadEvents.CHASSISPICK,
        action: ActionType.ADD,
        id: obj?.id,
        driver: obj?.driver,
        driverName: obj?.driverName,
        moveId: obj?.moveId,
      });
    } else if (
      obj.action[0]?.actionType === ActionType.ADD &&
      obj.action[0]?.actionEvent === loadEvents.CHASSISTERMINATION
    ) {
      this.updateStatus(false, false, false, false, false, false, false, {
        eventType: loadEvents.CHASSISTERMINATION,
        action: ActionType.ADD,
        id: obj?.id,
        driver: obj?.driver,
        driverName: obj?.driverName,
        moveId: obj?.moveId,
      });
    } else if (
      obj.action[0]?.actionType === ActionType.REMOVE &&
      obj.action[0]?.actionEvent === loadEvents.CHASSISPICK
    ) {
      this.updateStatus(false, false, false, false, false, false, false, {
        eventType: loadEvents.CHASSISPICK,
        action: ActionType.REMOVE,
        id: obj?.id,
        driver: obj?.driver,
        driverName: obj?.driverName,
      });
    } else if (
      obj.action[0]?.actionType === ActionType.REMOVE &&
      obj.action[0]?.actionEvent === loadEvents.CHASSISTERMINATION
    ) {
      this.updateStatus(false, false, false, false, false, false, false, {
        eventType: loadEvents.CHASSISTERMINATION,
        action: ActionType.REMOVE,
        id: obj?.id,
        driver: obj?.driver,
        driverName: obj?.driverName,
      });
    }
  };

  checkIsChassisV3 = (chassisV3) => {
    if (chassisV3[0]?.error) {
      this.setState({ isInvalidLeg: chassisV3[0]?.error, isLoading: false });
    } else {
      this.setState({
        showModal: true,
        suggestions: chassisV3,
        isLoading: false,
      });
    }
    return true;
  };

  updateDriverOrderById = (type, driverOrderId, updatedDate, field) => {

    if (this.props?.selectedLoads?.driverOrder) {
      const findLoadEvent = this.props.selectedLoads.driverOrder.find((lEvent) => lEvent._id === driverOrderId._id);
      if(updatedDate && _.isEqual(moment(findLoadEvent?.[type]).format("DD/MM/YYYY HH:mm"),moment(updatedDate).format("DD/MM/YYYY HH:mm")) ) return;
    }

    this.setState({ isLoading: true });
    const formData = new FormData();
    formData.append(
      "reference_number",
      this.props.selectedLoads && this.props.selectedLoads.reference_number
    );
    formData.append("driverOrderId", driverOrderId._id);
    formData.append("arrivedOrDeparted", type);
    if (updatedDate) {
      if(!moment(updatedDate).isValid()) return;
      formData.append(
        "date",
        moment(updatedDate).toISOString()
      );
      formData.append("strictChange", true);
    }
    updateDriverOrderById(formData)
      .then((result) => {
        let eventProperties = {
          source: 'web'
        }
        amplitudeTrack('CHANGE_STATUS',eventProperties)
        toastr.show("You have updated the status!", "success");
        this.transitionEffect(field,true);
        if (
          type === "departed" &&
          [
            loadEvents.PULLCONTAINER,
            loadEvents.HOOKCONTAINER,
            loadEvents.LIFTON,
          ].includes(driverOrderId.type) &&
          driverOrderId.isBobtail
        ) {
          const newFormData = new FormData();
          newFormData.append("description", "Bobtail");
          newFormData.append("amount", 0);
          newFormData.append("approved", false);
          newFormData.append(
            "driverId",
            driverOrderId.driver && driverOrderId.driver._id
          );
          newFormData.append(
            "reference_number",
            this.props.selectedLoads &&
              this.props.selectedLoads.reference_number
          );
          newFormData.append(
            "containerNo",
            this.props.selectedLoads && this.props.selectedLoads.containerNo
          );
          newFormData.append("loadType", driverOrderId.type);
          if (this.props?.selectedLoads?.terminal) {
            newFormData.append(
              "terminal",
              this.props.selectedLoads.terminal._id
            );
          }
          newFormData.append("loadId",this.props?.selectedLoads?._id);
          manualDriverPay(newFormData);
          this.manualCustomerRate();
        }
        this.setState({ changeCompletionDate: null, isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        this.transitionEffect(field,false);
        toastr.show("The update has failed.  Please try again.", "error");
        console.log("Error ------", err);
      });
  };

  manualCustomerRate = () => {
    let pricing =
      this.props.selectedLoads && this.props.selectedLoads.pricing
        ? this.props.selectedLoads.pricing
        : [];
    let existingBobtailCharge = pricing.find(
      (price) => price.name === "bobtail_charge"
    );
    if (!existingBobtailCharge) {
      let bobtailPrice = {
        amount: 0,
        chargePerDay: 0,
        chargePerMinute: 0,
        finalAmount: "0.00",
        unit: "0.00",
        freeDays: 0,
        perType: "fixed",
        name: "bobtail_charge",
        chargeType: "MANUALLY_CHANGED",
        description: "Bobtail",
      };
      pricing.push({ ...bobtailPrice });
      var pricingData = {
        pricing: pricing,
        reference_number: this.props.selectedLoads.reference_number,
        isSaveCustomerRates: false,
      };
      UpdatePricing("tms/updatePricingLoad", pricingData);
    }
  };
  removeStatusDriverOrderById = (type, driverOrderId) => {
    this.setState({ isLoading: true });
    const formData = new FormData();
    formData.append(
      "reference_number",
      this.props.selectedLoads && this.props.selectedLoads.reference_number
    );
    formData.append("driverOrderId", driverOrderId);
    formData.append("arrivedOrDeparted", type);

    removeStatusDriverOrderById(formData)
      .then((result) => {
        toastr.show("You have updated the status!", "success");
        this.setState({ isLoading: false });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
        toastr.show("The update has failed.  Please try again.", "error");
        console.log("Error ------", err);
      });

    // confirm(
    //   "Alert",
    //   `Do you really want to remove this status date?`,
    //   (confirm) => {
    //     if (confirm) {
    //    ß
    //     }
    //   }
    // );
  };

  pullVoid = (status, index) => {
    if (!status.driver) {
      toastr.show("Please assign a driver.", "error");
      return;
    }

    let driverOrder = _.cloneDeep(this.state.driverOrder);
    let isRemoveDriver = false;
    if (
      [
        loadEvents.PULLCONTAINER,
        loadEvents.HOOKCONTAINER,
        loadEvents.LIFTON,
      ].includes(status.type)
    ) {
      isRemoveDriver = true;
    }
    const newStatus = { ...driverOrder[index] };
    delete newStatus.arrived;
    delete newStatus.departed;

    driverOrder.splice(index + 1, 0, newStatus);
    driverOrder[index].isVoidOut = true;
    this.setState({ driverOrder }, () => {
      this.updateStatus(true, isRemoveDriver, true, false, status);
    });
  };

  removePullVoid = (status, index) => {
    let driverOrder = _.cloneDeep(this.state.driverOrder);
    driverOrder[index].isVoidOut = false;
    let driverId = driverOrder[index].driver;
    if (driverOrder[index + 1] && driverOrder[index + 1].type === status.type) {
      driverOrder.splice(index + 1, 1);
    }
    for (let i = index; i < driverOrder.length; i++) {
      if (!driverOrder[i].isVoidOut) {
        driverOrder[i].driver = driverId;
        if (tmsEndMoveEvents.includes(driverOrder[i].type) && !driverOrder[i].isVoidOut && driverOrder[i+1]?.type !== loadEvents.CHASSISTERMINATION)
          break;
      }
    }

    this.setState({ driverOrder }, () => {
      this.updateStatus(true);
    });
  };

  assignedDriver = () => {
    this.setState({ isCall: false, isShowTripModal: false }, () => {
      this.updateStatus(false, false, false, false, false, "select-driver-0");
    });
  };
  updateStatus = async (
    keepModalOpen,
    isRemoveDriver,
    isVoidOut,
    isBobtail,
    currentLeg,
    field,
    index,
    eventType
  ) => {
    let driverOrder = _.cloneDeep(this.state.driverOrder);
    if (!this.validateOrderStatus(driverOrder)) {
      return;
    }
    let customerAddress =
      currentLeg &&
      `${
        currentLeg.address
          ? currentLeg.address.address
            ? currentLeg.address.address
            : ""
          : ""
      }`.trim();
    this.props.changeSavingLoadStatus &&
      this.props.changeSavingLoadStatus(true);
    this.setState({ isLoading: true, showModal: false });
    driverOrder.forEach((status) => {
      if (status.customerId) {
        status.address = status.customerId.address;
        status.city = status.customerId.city;
        status.state = status.customerId.state;
        status.country = status.customerId.country;
        status.zip_code = status.customerId.zip_code;
        status.company_name =
          status.customerId.company_name &&
          status.customerId.company_name.toUpperCase();
        status.customerId = status.customerId._id;
      } else {
        delete status.address;
        delete status.city;
        delete status.state;
        delete status.country;
        delete status.zip_code;
        delete status.company_name;
        delete status.customerId;
      }

      if (status.driver) {
        status.driverName = `${status.driver.name} ${
          status.driver.lastName || ""
        }`;
        status.driver = status.driver._id;
      } else {
        delete status.driver;
        delete status.driverName;
      }
    });

    const formData = new FormData();
    if (eventType) {
      let mappedDriverOrder = mappedPayloadSuggestion(driverOrder, eventType);
      if (mappedDriverOrder?.length > 0)
        driverOrder = _.cloneDeep(mappedDriverOrder);
    }
    const driverOrderValidation = isValidDriverOrder(driverOrder)
    if(!driverOrderValidation.isValid){
      this.setState({ isLoading: false })
      toastr.show(driverOrderValidation.message, "error")
      return
    }
    formData.append(
      "reference_number",
      this.props.selectedLoads && this.props.selectedLoads.reference_number
    );
    formData.append("driverOrder", JSON.stringify(driverOrder));
    formData.append("preSetOrderStatus", this.state.selectedRoute);
    formData.append(
      "allowDriverCompletion",
      this.state.allowDriverCompletion ? true : false
    );

    formData.append(
      "isPrePullCharge",
      this.state.isPrePullCharge ? true : false
    );
    formData.append(
      "isNextContainerNeedToPickup",
      this.state.isNextContainerNeedToPickup ? true : false
    );
    formData.append("isGrayPool", this.state.isGrayPool ? true : false);
    if (this.state.isGrayPool) {
      if (this.state.grayContainerNo)
        formData.append("grayContainerNo", this.state.grayContainerNo);
      if (this.state.grayChassisNo)
        formData.append("grayChassisNo", this.state.grayChassisNo);
      if (this.state.grayChassisType)
        formData.append("grayChassisType", this.state.grayChassisType);
      if (this.state.grayChassisSize)
        formData.append("grayChassisSize", this.state.grayChassisSize);
      if (this.state.grayChassisOwner)
        formData.append("grayChassisOwner", this.state.grayChassisOwner);
      if (this.state.grayChassisId)
        formData.append("grayChassisId", this.state.grayChassisId);
    }
    if (index === 0) {
      let isTrip = await checkTrips(
        this.props.selectedLoads._id,
        this.props.selectedLoads.orderId,
        this.props.selectedLoads.reference_number,
        driverOrder[0]?.driver?.value
      );
      if (isTrip) {
        this.setState({
          isShowTripModal: true,
          tripDetails: isTrip,
          updateField: field,
        });
        return;
      }
    }

    if (this.state.currentLoadEventType && this.state.currentLoadEventType === loadEvents.DELIVERLOAD) {
      const payload = {
        referenceNumber: this.props.selectedLoads && this.props.selectedLoads.reference_number,
        prevEvent: this.state.prevValidEventId
      }
      actionCreators.addDeliveryEvent(payload)
        .then((result) => {
          field && this.transitionEffect(field, true);
          if (result?.isChassisv3) {
            let isChassiV3 = this.checkIsChassisV3(result?.isChassisv3);
            if (isChassiV3) return;
          } else {
            this.setState({
              addLegLoader: false,
              showModal: false,
              indexForSelect: null,
              saveLoader: false,
              isLoading: false,
              currentLoadEventType: null,
              prevValidEventId: null
            });
            !!!field && toastr.show("Updated Successfully", "success");
            this.props.changeSavingLoadStatus &&
              this.props.changeSavingLoadStatus(false);
          }
        })
        .catch((err) => {
          this.setState({  })
          field && this.transitionEffect(field, false);
          this.props.changeSavingLoadStatus &&
            this.props.changeSavingLoadStatus(false);
          this.setState({
            isLoading: false,
            addLegLoader: false,
            indexForSelect: null,
            driverOrder: this.props.selectedLoads.driverOrder,
            currentLoadEventType: null,
            prevValidEventId: null
          });
          !!!field &&
            toastr.show("The update has failed.  Please try again.", "error");
          console.log("Error ------", err);
        });
    } else {
      setLoadStatusOrder(formData)
        .then((result) => {
          field && this.transitionEffect(field, true);
          if (result?.isChassisv3) {
            let isChassiV3 = this.checkIsChassisV3(result?.isChassisv3);
            if (isChassiV3) return;
          }
          else {
            this.setState({
              addLegLoader: false,
              showModal: false,
              indexForSelect: null,
              saveLoader: false,
              isLoading: false
            });
            !!!field && toastr.show("Updated Successfully", "success");
            this.props.changeSavingLoadStatus &&
              this.props.changeSavingLoadStatus(false);
          }
        })
        .catch((err) => {
          field && this.transitionEffect(field, false);
          this.props.changeSavingLoadStatus &&
            this.props.changeSavingLoadStatus(false);
          this.setState({
            isLoading: false,
            addLegLoader: false,
            indexForSelect: null,
            driverOrder: this.props.selectedLoads.driverOrder,
          });
          !!!field &&
            toastr.show("The update has failed.  Please try again.", "error");
          console.log("Error ------", err);
        });
    }
  };

  handleOrderStatusChange = (label, field, index) => {
    const disabled =
      _.isEqual(this.state.driverOrder, this.props.selectedLoads.driverOrder) &&
      this.props.selectedLoads.isPrePullCharge === this.state.isPrePullCharge &&
      this.props.selectedLoads.isNextContainerNeedToPickup ===
        this.state.isNextContainerNeedToPickup &&
      this.props.selectedLoads.allowDriverCompletion ===
        this.state.allowDriverCompletion &&
      this.props.selectedLoads.grayContainerNo === this.state.grayContainerNo &&
      this.props.selectedLoads.grayChassisNo === this.state.grayChassisNo;
    const isCompleted =
      [
        "COMPLETED",
        "BILLING",
        "APPROVED",
        "UNAPPROVED",
        "REBILLING",
        "PARTIALLY_PAID",
        "FULL_PAID",
      ].indexOf(this.props.selectedLoads.status) > -1;

    if (
      this.state.grayContainerNo &&
      !/^[A-Za-z]{4}\d{6,7}$/.test(this.state.grayContainerNo)
    ) {
      toastr.show("Please Enter Correct Gray Container No", "warning");
      this.setState({ grayContainerNo: "" });
      this.props.changeSavingLoadStatus &&
        this.props.changeSavingLoadStatus(false);
      return;
    }

    if (!disabled && !isCompleted) {
      if (label === "update") {
        this.updateStatus(false, false, false, false, false, field, index);
      } else if (label === "discard") {
        this.getLoadDriverOrder();
      }
    } else if (isCompleted) {
      toastr.show("The load is now completed!", "error");
    }
  };

  setCustomerToStatus = (index, value, status) => {
    let driverOrder = _.cloneDeep(this.state.driverOrder);
    driverOrder[index].customerId = value && value.allInfos;

    if (
      status.type === loadEvents.DROPCONTAINER &&
      driverOrder[index + 1]?.type === loadEvents.HOOKCONTAINER
    ) {
      driverOrder[index + 1].customerId = value && value.allInfos;
    } else if (
      status.type === loadEvents.HOOKCONTAINER &&
      driverOrder[index - 1].type === loadEvents.DROPCONTAINER
    ) {
      driverOrder[index - 1].customerId = value && value.allInfos;
    }

    
    if (
      status.type === loadEvents.LIFTOFF
      ) {
      const nextLiftOnIndex =driverOrder.findIndex((x,i)=>(x.type === loadEvents.LIFTON && !x.isVoidOut && i>index))
      if(nextLiftOnIndex !== -1)
      driverOrder[nextLiftOnIndex].customerId = value && value.allInfos;
    }  else if (
      status.type === loadEvents.LIFTON 
    ) {
      const _revDriverOrder = _.cloneDeep(driverOrder).reverse()
      const prevLiftOffIndex = _revDriverOrder.findIndex((x,i)=>(x.type === loadEvents.LIFTOFF && !x.isVoidOut && i>(driverOrder.length - index - 1)))
      console.log(driverOrder.length - prevLiftOffIndex)
      if(prevLiftOffIndex !== -1 && driverOrder.length - prevLiftOffIndex - 1 >=0)
      driverOrder[driverOrder.length - prevLiftOffIndex - 1 ].customerId = value && value.allInfos;
    } 

    this.setState({ driverOrder }, () =>
      this.handleOrderStatusChange("update", `leg-${index}`)
    );
  };

  createLegsFromDriverOrder= (driverOrder) => {
    const driverLegs = movesFromDriverOrder(driverOrder);
    const _supportMoves = _.cloneDeep(this.state.supportMoves)

    const allDriverLegs = []

    let i = 0;
    while(i < driverLegs.length){
        const currentMove = driverLegs?.[i];
        const moveId = currentMove?.[0]?.moveId+""
        allDriverLegs.push(driverLegs[i]);
      i++;
    }
   return allDriverLegs;
  }

  getChassis = (searchTerm = "") => {
    return new Promise((resolve, reject) => {
      this.props
        .getTMSChassis({ limit: 10, searchTerm })
        .then((result) => {
          let data =
            result &&
            result.map((d) => ({
              value: d._id,
              label: d.chassisNo,
              allInfo: d,
            }));
          resolve(data);
        })
        .catch((err) => {
          console.log("-*-* GET Chassis error -*-*", err);
          reject(err);
        });
    });
  };

  onRemovePress(e, input, showToast = true) {
    const params = {
      reference_number: this.props.selectedLoads.reference_number,
      key: input.name,
    };
    if (!this.props.disabled) {
      removeValueLoad(params).then((result) => {
        showToast && this.transitionEffect(input.name, true);
      });
    }
  }

  grayChassisUpdate(value) {
    if (value) {
      this.setState(
        {
          grayChassisNo: value.label,
          grayChassisType: value.allInfos
            ? value.allInfos.chassisType
              ? value.allInfos.chassisType._id
              : null
            : null,
          grayChassisSize: value.allInfos
            ? value.allInfos.chassisSize
              ? value.allInfos.chassisSize._id
              : null
            : null,
          grayChassisOwner: value.allInfos
            ? value.allInfos.chassisOwner
              ? value.allInfos.chassisOwner._id
              : null
            : null,
          grayChassisId: value.value ? value.value : null,
        },
        () => {
          this.handleOrderStatusChange("update", "grayChassisNo");
        }
      );
    }
  }

  getDrivers(info, dIndex, leg) {
    let drivers = [];
    if (this.props.driverList) {
      let ddrivers = this.props.driverList;
      ddrivers.forEach((d) => (d.disableValue = false));
      const isDeliveryBefore = info.driverOrder.findIndex(
        (d, index) => index<dIndex && d.type === "DELIVERLOAD"
      ) !== -1
      const isDeliveryInThisMove = leg.findIndex(event => event.type === "DELIVERLOAD") !== -1
      const lastDropEvent = info.driverOrder[dIndex - 1]
      let eventBeforeDrop; 
      info.driverOrder.forEach((event,index)=>{
        if(index < dIndex - 1 && !event.isVoidOut)
          eventBeforeDrop = event;
      })
      let isLoaded = this.props.selectedLoads?.type_of_load === "IMPORT" ? !isDeliveryBefore : ( this.props.selectedLoads?.type_of_load === "EXPORT" ? (isDeliveryBefore || isDeliveryInThisMove) : false );
      if(lastDropEvent && eventBeforeDrop && 
        lastDropEvent.prevType === "DELIVERLOAD" &&
        (
          (lastDropEvent.customerId && eventBeforeDrop.customerId &&  _.isEqual(lastDropEvent.customerId, eventBeforeDrop.customerId) ) || 
          (lastDropEvent.customerId?._id && eventBeforeDrop.customerId?._id && (lastDropEvent.customerId._id+"" === eventBeforeDrop.customerId._id+""))
        )){
          if (info.isReadyForPickup) {
            isLoaded = info.type_of_load === "EXPORT";
          }else{
            isLoaded = info.type_of_load === "IMPORT";
          }
      }
      drivers = getValidDrivers(ddrivers, this.state.driverValidation, info, isLoaded);
    } else {
      drivers = [];
    }
    return drivers.map((d) => {
      return {
        value: d._id,
        label: `${d.name} ${d.lastName || ""}`.trim(),
        allInfos: d,
        isDisabled: d.disableValue,
        isDeleted: d.isDeleted,
        isBlocked: d.isBlocked,
      };
    });
  }

  transitionEffect = (field, color) => {
    if(!field) return;
    const element = document.querySelector(`.${field}`);
    let dateOrInpt = ["input-wrapper"];
    const isThere = dateOrInpt.some((e) => element?.className?.includes(e));
    const transitionElement = (isThere
    ? document.querySelector(`.${field} > div > input`)
    : document.querySelector(`.${field} > div`)) || document.querySelector(`.${field} > input`);
    if (color) {
      transitionElement?.classList.remove("background-failure");
      transitionElement?.classList.add("background-success");
      setTimeout(() => {
        transitionElement?.classList.remove(
          "background-success",
          "text-danger"
        );
      }, 400);
    } else {
      transitionElement?.classList.add("background-error", "text-danger");
      setTimeout(() => {
        transitionElement?.classList.add("background-failure", "text-danger");
        transitionElement?.classList.remove("background-error", "text-danger");
      }, 400);
    }
  };

  updateStopOffName = async (value, status, field) => {
      let param ={
        loadId: this.props.selectedLoads._id,
        eventId: status._id,
        stopOffType: value.value,
        stopOffName: value.label
      }

      let driverOrder = _.cloneDeep(this.state.driverOrder)

      driverOrder.forEach((p, index) => {
        if (p._id === status._id) {
          driverOrder[index].stopOffType = value.value
          driverOrder[index].stopOffTypeName = value.label
        }
      })
      this.setState({ driverOrder }, () => {
        updateStopOffType(param)
        .then((res) => {
          toastr.show("Update Successfully!", 'success')
          this.transitionEffect(field, true);
        })
        .catch((err) => {
          toastr.show(err, 'error')
          this.transitionEffect(field, false);
          console.log("error -=-=-= ", err)
        })
      })

  }

  renderReturn() {
    const disabled =
      _.isEqual(this.state.driverOrder, this.props.selectedLoads.driverOrder) &&
      this.props.selectedLoads.isPrePullCharge === this.state.isPrePullCharge &&
      this.props.selectedLoads.isNextContainerNeedToPickup ===
        this.state.isNextContainerNeedToPickup &&
      this.props.selectedLoads.allowDriverCompletion ===
        this.state.allowDriverCompletion &&
      this.props.selectedLoads.grayChassisNo === this.state.grayChassisNo &&
      this.props.selectedLoads.grayContainerNo === this.state.grayContainerNo;

    const isCompleted =
      [
        "COMPLETED",
        "BILLING",
        "APPROVED",
        "UNAPPROVED",
        "REBILLING",
        "PARTIALLY_PAID",
        "FULL_PAID",
      ].indexOf(this.props.selectedLoads.status) > -1;

    const loadCompletedAt = this.props.selectedLoads.loadCompletedAt;

    const selectedLoad = this.props.selectedLoads;
    const allDriverLegs = this.createLegsFromDriverOrder(
      this.state.driverOrder
    );
    const carrierDetails = JSON.parse(getStorage("carrierDetail"));
    const userBasicSettings = JSON.parse(getStorage("userBasicSettings")) 
    const timeZone = getStorage("timeZone");
    const selectedMoveFilter = this.state.selectedMoveFilter;

    const driverLegs = allDriverLegs.filter((dl) => {
      if (!selectedMoveFilter) return true;
      else if (selectedMoveFilter === "containerMove" && !dl[0]?.isSupportMove)
        return true;
      else if (selectedMoveFilter === "supportMove" && dl[0]?.isSupportMove)
        return true;
      return false;
    });

    const firstMoveEvent = driverLegs?.[0]?.[0];
    const isLoadInCombineTrip = (i) => {
      let ans = false;
      driverLegs.forEach((leg, index) => {
        if (index >= i) {
          ans = leg.find((d) => d.combineTripId);
          if (ans) return;
        }
      });
      return ans;
    };
    return (
      <React.Fragment>
        <div className="tab position-relative">
          {this.props.dispatcherModal && (
            <div className="d-flex align-items-center m-2 mb-3">
              <h5 className="font-20 mb-0">
                Add {statusType[this.showEvent]} in Routing
              </h5>
              <div className="d-flex ml-auto">
                <button
                  className="btn btn-white shadow-none mr-10"
                  onClick={() => this.props.closeStatusModal()}
                >
                  Close
                </button>
              </div>
            </div>
          )}
          <div className="form-row-md mt-10">
            <div
              className="col fcpopup__sidebar pr-0 fcpopup__sticktop"
              style={{ maxWidth: 210 }}
            >
              <div className="card p-10 mb-10">
                <div className="form-group mb-0">
                  <label className="mb-10">Drag & Drop {statusType[this.showEvent]} to Load</label>

                  {ROUTING_DRAG_LEGS.filter((x)=>x?.leg === this.showEvent).map((dLeg) => {
                    if (
                      this.props.selectedLoads?.type_of_load === "ROAD" &&
                      [
                        "CHASSISPICK",
                        "RETURNCONTAINER",
                        "CHASSISTERMINATION",
                      ].includes(dLeg.leg)
                    ) {
                      return;
                    }
                    if (
                      ["STOPOFF"].includes(dLeg.leg) &&
                      !userBasicSettings?.isStopOff
                    ) {
                      return;
                    }
                    return (
                      <button
                        className="btn btn-outline-light btn-lg mb-1 d-flex align-items-center justify-content-start w-100 px-2 grabbable"
                        id={dLeg.leg}
                        draggable
                        onDragStart={this.handleDragStart}
                        onDragOver={this.handleDragOver}
                        onDragEnd={() => this.setState({ droppedIndex: null, isDragging: false })}
                      >
                        <IconDrag className="mr-10 text-gray-200" />
                        <span>{dLeg.legTitle}</span>
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="col routing_info--card pl-10">
              {this.state.isLoading && <LoaderBar />}

              {driverLegs.length > 0 &&
                driverLegs.map((leg, legIndex) => {
                  let legColor = legIndex % 2 == 0 ? "blue" : "pink";
                  const isCombinedTrip = leg.find((d) => d.combineTripId);

                  let firstStatus = leg.find((status) => !status.isVoidOut);
                  let currentLegIndex = leg.findIndex(
                    (status) => !status.isVoidOut
                  );

                  if (!firstStatus) firstStatus = leg[0];
                  const dIndex = leg[0]?.dIndex;
                  if (
                    this.state.driverOrder[dIndex] &&
                    this.state.driverOrder[dIndex].isGrayPool
                  )
                    legColor = "gray";

                  if (leg.length > 0 && firstStatus?.isSupportMove) {
                    return (
                      <SupportMove
                        move={leg}
                        legIndex={legIndex}
                        legColor={legColor}
                        userTimeFormat={this.userTimeFormat}
                        reference_number={
                          this.props.selectedLoads?.reference_number
                        }
                        supportMoves={this.state.supportMoves}
                        loadDetails={this.props.selectedLoads}
                      />
                    );
                  }
                  if (leg.length > 0 && !firstStatus?.isSupportMove) {
                    return (
                      <div className="card card-blue mb-10">
                        {this.state.driverOrder[dIndex]?.isGrayPool && (
                          <div
                            className={`bg-gray-700 d-flex align-items-center p-10 ${
                              legIndex > 0 &&
                              "rounded-top-left rounded-top-right"
                            }`}
                          >
                            <span className="text-white">
                              <IconPool className="text-white" />
                            </span>
                            <h5 className="font-16 font-weight-500 text-white ml-10 mb-0">
                              Gray Pool
                            </h5>
                          </div>
                        )}
                        <div className="p-15">
                          <div className="row mb-0 align-items-center">
                            <span>
                              <div
                                className={`btn btn-rounded card-${legColor}__avatar text-white mx-10 line-height-18 ml-15`}
                              >
                                <div className="avatar-title">
                                  Container Move {legIndex + 1}
                                </div>
                              </div>
                            </span>
                            <div className="p-0 select-wrapper__input">
                              <Select
                                options={this.getDrivers(selectedLoad, dIndex, leg)
                                  .filter(
                                    (dd) => !dd.isDeleted && !dd.isBlocked
                                  )
                                  .map((d) => d)}
                                value={
                                  firstStatus.driver
                                    ? {
                                        label: `${firstStatus.driver.name} ${
                                          firstStatus.driver.lastName || ""
                                        }`.trim(),
                                        value: firstStatus.driver._id,
                                      }
                                    : null
                                }
                                onChange={(value) => {
                                  this.assignDriver(
                                    value,
                                    dIndex,
                                    null,
                                  );
                                }}
                                placeholder="Select Driver"
                                className={`w-200 select-driver-${dIndex}`}
                                isDisabled={
                                  firstStatus.isVoidOut ||
                                  firstStatus.arrived ||
                                  isCompleted ||
                                  isCombinedTrip
                                }
                                isClearable={!isLoadInCombineTrip(legIndex)}
                                styles={newSmSelectStyle}
                              />
                            </div>

                            <div className="p-0">
                              <div className="p-0 mx-15">
                                <div className="form-group mb-0">
                                  {!firstStatus.isVoidOut &&
                                    !firstStatus.arrived && (
                                      <button
                                        className="btn btn-sm btn-success w-150 text-center"
                                        onClick={() =>
                                          this.updateDriverOrderById(
                                            "arrived",
                                            firstStatus
                                          )
                                        }
                                        disabled={
                                          !disabled ||
                                          !firstStatus.driver || isCombinedTrip
                                        }
                                      >
                                        <span className="w-100">
                                          Start Move
                                        </span>
                                      </button>
                                    )}
                                  {firstStatus &&
                                    !firstStatus.isVoidOut &&
                                    firstStatus.driver &&
                                    firstStatus.arrived && (
                                      <div className="form-group w-200 mb-0">
                                        <div
                                          className={`input-wrapper loadAssignDate-${dIndex}`}
                                        >
                                          <ExactDateWrapper
                                            className={`loadAssignDate-${dIndex} laDate`}
                                            displayTimeZone={getStorage(
                                              "timeZone"
                                            )}
                                            componentClassName={`date-arrived-${firstStatus._id}`}
                                            disabled={isCompleted || !disabled || isCombinedTrip}
                                            defaultDate={
                                              firstStatus.arrived
                                                ? moment(
                                                    firstStatus.arrived
                                                  )
                                                : ""
                                            }
                                            dateFormat="MM-DD-yyyy hh:mm A"
                                            timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                                            OnBlurUpdate={(_date) =>
                                              this.updateDriverOrderById(
                                                "arrived",
                                                firstStatus,
                                                _date,
                                                `date-arrived-${firstStatus._id}`
                                              )
                                            }
                                            hideShowSelectDate={true}
                                            isShowTime={true}
                                            name="arrivedDate"
                                          />
                                          {!isCompleted &&
                                            (
                                              <div
                                                className="input-icon p-0 pr-10 text-muted"
                                                onClick={() =>
                                                  {!isCombinedTrip && this.removeStatusDriverOrderById(
                                                    "arrived",
                                                    firstStatus._id
                                                  )}
                                                }
                                              >
                                                <IconTimes />
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="hr-light m-0"></div>
                        <div className="p-10 pt-0">
                          <div className="form-row">
                            <div className="col">
                              {this.state.driverOrder[dIndex].isGrayPool && (
                                <div className="mb-0 mt-3 mr-10 ml-10 graypool-card">
                                  <div className="form-row bg-warning-50 p-10">
                                    <div className="d-flex col-md-6 px-0 align-items-center graypool-form">
                                      <h6 className="m-0 font-12 font-medium graypool-title">
                                        Gray Container #
                                      </h6>
                                      <form className="ml-10 w-200 graypool-input">
                                        <div className="form-group m-0">
                                          <div className="input-wrapper grayContainerNo">
                                            <input
                                              type="text"
                                              className="form-control transition-white-field"
                                              placeholder="Enter Container"
                                              name="grayContainerNo"
                                              autoComplete="off"
                                              value={
                                                this.state.grayContainerNo
                                                  ? this.state.grayContainerNo
                                                  : ""
                                              }
                                              onChange={(e) => {
                                                this.setState({
                                                  grayContainerNo:
                                                    e.target.value,
                                                });
                                              }}
                                              onKeyDown={(e) => {
                                                this.handleKeyDown(e);
                                              }}
                                              onBlur={() => {
                                                this.handleOrderStatusChange(
                                                  "update",
                                                  "grayContainerNo"
                                                );
                                              }}
                                            />
                                            {(this.props.selectedLoads
                                              .grayContainerNo ||
                                              this.state.grayContainerNo) && (
                                              <div className="input-icon">
                                                <span
                                                  onClick={(e) => {
                                                    this.onRemovePress(null, {
                                                      name: "grayContainerNo",
                                                    });
                                                  }}
                                                >
                                                  <IconTimes />
                                                </span>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </form>
                                    </div>
                                    <div className="d-flex col-md-6 px-0 align-items-center mt-xs-1 graypool-form">
                                      <h6 className="m-0 font-12 font-medium graypool-title">
                                        Gray Chassis #
                                      </h6>
                                      <form className="ml-10 graypool-input">
                                        <div className="form-group m-0">
                                          <AsyncCreatableSelect
                                            menuPlacement="auto"
                                            placeholder="Select.."
                                            className="w-200 grayChassisNo"
                                            defaultOptions={this.props.chassis.map(
                                              (chassis) => ({
                                                label: chassis.chassisNo,
                                                value: chassis._id,
                                                allInfos: chassis,
                                              })
                                            )}
                                            cacheOptions
                                            loadOptions={this.getChassis}
                                            isClearable
                                            formatCreateLabel={(inputValue) =>
                                              `Add "${inputValue}"`
                                            }
                                            value={
                                              this.state.grayChassisNo
                                                ? {
                                                    label:
                                                      this.state.grayChassisNo,
                                                    value:
                                                      this.state.grayChassisNo,
                                                  }
                                                : null
                                            }
                                            onChange={(value) => {
                                              if (value) {
                                                this.grayChassisUpdate(value);
                                              } else {
                                                this.onRemovePress(null, {
                                                  name: "grayChassisNo",
                                                });
                                              }
                                            }}
                                            onCreateOption={(value) => {
                                              this.setState({
                                                grayChassisNo: value,
                                              });
                                            }}
                                            onKeyDown={(e) =>
                                              this.handleKeyDown(e)
                                            }
                                            // onBlur={()=> this.handleOrderStatusChange("update") }
                                            styles={newSmSelectStyle}
                                          />
                                        </div>
                                      </form>
                                    </div>
                                  </div>
                                </div>
                              )}
                              <div
                                className={`form-row align-items-end mr_1 ml_1 pt-10`}
                                key={100+dIndex}
                                onDrop={(e) => {
                                  if (
                                    this.state.isDragging &&
                                    !leg[0]?.combineTripId
                                  ) {
                                    this.handleOnDrop(e);
                                  }
                                }}
                                onDragOver={(e) => {
                                    this.handleDragOver(e);
                                }}
                                onDragEnter={() => {
                                    this.handleDragEnter(100+dIndex);
                                }}
                              >
                                {this.state.droppedIndex >= 100 &&
                                (this.state.droppedIndex - 100) === dIndex &&
                                  !leg[0]?.combineTripId && (
                                    <div className="col-12 kanban-card-view mb-2">
                                      <p className="text-center my-4">
                                        Drop Your Status Here
                                      </p>
                                    </div>
                                  )}
                              </div>
                                  
                              {leg.map((status, statusIndex) => {
                                const formattedAddress = addressParser(status);
                                const index = dIndex + statusIndex;
                                
                                status = updateStatusTypeForLoad(this.props.selectedLoads, status);
                                const addressComp = ROUTING_ADDRESS_FIELDS[status.type];

                                const nextValidLegIndex = leg.findIndex((d, i) => i > statusIndex && !d.isVoidOut);
                                let nextMoveLeg = null;
                                if(driverLegs[legIndex + 1]){
                                  const nextMove = driverLegs[legIndex + 1];
                                  nextMoveLeg =  nextMove[0];
                                }

                                const nextValidLeg = leg[nextValidLegIndex];

                                let timeDurationOfEvent = getDurationInHHMMFormat(
                                   nextValidLeg?.arrived, status?.departed,
                                  (userBasicSettings?.isUserSpecificTimeZoneEnabled && {fromTimeZone: status?.timeZone || timeZone, toTimeZone: status?.timeZone || timeZone})
                                );
                                let nextEventDuration = getDurationInHHMMFormat(
                                  nextValidLeg?.departed, 
                                  nextValidLeg?.arrived,
                                  (userBasicSettings?.isUserSpecificTimeZoneEnabled && {fromTimeZone: status?.timeZone || timeZone, toTimeZone: nextValidLeg?.timeZone || timeZone})
                                );
                                let distanceBwEvents = getDistanceBetweenEvents(leg, statusIndex, nextValidLegIndex);
                                const distanceMeasure = carrierDetails?.carrier?.distanceMeasure || "mi";

                                const editDriverPayData = this.state.editDriverPayData;
                                const isEditingPay = editDriverPayData?.fromEventId === status._id && editDriverPayData?.toEventId === nextValidLeg?._id;
                                const currentDriverId = nextValidLeg?.driver?._id || nextValidLeg?.driver
                                let driverPay = null;
                                if (isEditingPay) driverPay = editDriverPayData;
                                if (!driverPay) {
                                  driverPay = this.state.driverPays.find((pay) => pay.fromEventId === status._id && pay.toEventId === nextValidLeg?._id && pay?.createdBy?._id === currentDriverId );
                                }
                                const dynamicHeight = `${111 * (nextValidLegIndex - statusIndex - 1) + 93 + "px"}`;

                                return (
                                  <React.Fragment>
                                    <div className="position-relative">
                                      <div
                                        onDrop={this.state.isDragging && this.handleOnDrop}
                                        onDragOver={(e) =>
                                          this.handleDragOver(e)
                                        }
                                        onDragEnter={() => {
                                          this.handleDragEnter(index);
                                        }}
                                      >
                                        <div
                                          className={`outline-card p-10 mb-1`}
                                        >
                                          <div className="d-flex align-items-center mb-10">
                                            {status.driver &&
                                              !status.isVoidOut &&
                                              status.arrived &&
                                              status.departed && (
                                                <IconCompleted className="mr-2" />
                                              )}
                                            <span className="font-14 text-dark font-wight-500 mr-10">
                                              {statusType[status.type]}
                                            </span>
                                              {status.type === "STOPOFF" &&
                                                <div className="p-0 select-wrapper__input">
                                                  <Select
                                                    isDisabled={isCombinedTrip || !status._id || status?.arrived}
                                                    placeholder="Select Option"
                                                    className={`w-200 stopoff-${index}`}
                                                    styles={newSmSelectStyle}
                                                    options={STOPOFF_TYPES()?.filter((d) => !d.isDeleted)?.map((d) => { return { label:d.name, value: d._id }} )}
                                                    value={status?.stopOffType ? {value: status?.stopOffType, label: status?.stopOffTypeName} : ""}
                                                    onChange={(e) => {
                                                        this.updateStopOffName(e,status, `stopoff-${index}`)
                                                    }}
                                                  />
                                                </div>
                                              }
                                            <div className="d-flex ml-auto">
                                            {!status?.isVoidOut && status?.arrived && 
                                              !nextValidLeg?.arrived && !nextMoveLeg?.arrived &&
                                              !loadCompletedAt &&
                                                (<button
                                                    className="btn btn-xss btn-secondary text-center mr-10"
                                                    onClick={() =>
                                                      this.pullVoid(
                                                        status,
                                                        index
                                                      )
                                                    }
                                                    disabled={
                                                      !disabled ||
                                                      !status.driver
                                                    }
                                                  >
                                                    <span className="w-100">
                                                      Void Out
                                                    </span>
                                                  </button>
                                                )}
                                              {!status.isVoidOut &&
                                                !status.departed && 
                                                !status.combineTripId &&(
                                                  <button
                                                    className="btn btn-link text-danger p-0"
                                                    disabled={isCompleted}
                                                    onClick={() => {
                                                      if (leg.length > 1) {
                                                        this.removeStatus(
                                                          index
                                                        );
                                                      }
                                                    }}
                                                  >
                                                    <IconTrash />
                                                  </button>
                                                )}
                                            </div>
                                          </div>
                                          <div className="form-group">
                                            {addressComp && (
                                              <AsyncSelect
                                                className={`w-100 leg-${index}`}
                                                isClearable
                                                defaultOptions={makeOptionsForSelectWithStatus(
                                                  this.props?.[
                                                    addressComp.optionsField
                                                  ],
                                                  "company_name",
                                                  "address.address",
                                                  "_id",
                                                  "isConnected"
                                                )}
                                                cacheOptions
                                                value={
                                                  defaultAddressFormat() ===
                                                    addressFormatEnum.EU
                                                    ? status.customerId ? {
                                                      label: <StatusDropdownWrapper label={`${status?.customerId?.company_name} ${formattedAddress}`?.trim()} terminal={status?.customerId} />,
                                                      value:
                                                        status.customerId
                                                          ?._id,
                                                    } : null
                                                    : status.customerId
                                                      ? {
                                                        label: <StatusDropdownWrapper label={`${status.customerId.company_name} ${status.customerId.address ? status.customerId.address.address : ""}`?.trim()} terminal={status?.customerId} />,
                                                        value:
                                                          status.customerId._id,
                                                      }
                                                      : null
                                                }
                                                onChange={(value) =>
                                                  this.setCustomerToStatus(
                                                    index,
                                                    value,
                                                    status
                                                  )
                                                }
                                                loadOptions={
                                                  addressComp.loadOptions
                                                }
                                                isDisabled={
                                                  status.isVoidOut ||
                                                  isCompleted
                                                }
                                                styles={newSmSelectStyle}
                                                isLoading={
                                                  status.type ===
                                                    "RETURNCONTAINER" &&
                                                  this.state.isLoader
                                                }
                                              />
                                            )}
                                          </div>
                                          <div className="d-flex mb-0 align-items-center">
                                            {status.isVoidOut && (
                                              <span class="btn btn-xss bg-danger text-light mr-2">
                                                Driver:{" "}
                                                {`${status.driver.name} ${
                                                  status.driver.lastName || ""
                                                }`.trim()}
                                              </span>
                                            )}
                                            {status.isVoidOut && (
                                              <span class="btn bg-danger text-light btn-xss mr-2">
                                                Voided Out{" "}
                                                { !nextValidLeg?.arrived && !isCompleted && !isCombinedTrip && (
                                                  <span
                                                    className="ml-2"
                                                    style={{
                                                      cursor: "pointer",
                                                    }}
                                                    onClick={() =>
                                                      !nextValidLeg?.arrived && !isCompleted && !isCombinedTrip &&
                                                      this.removePullVoid(
                                                        status,
                                                        index
                                                      )
                                                    }
                                                  >
                                                    <IconTimes />
                                                  </span>
                                                )}
                                              </span>
                                            )}
                                            {!status.isVoidOut &&
                                              !status.departed && (
                                                <button
                                                  className="btn btn-xss btn-success w-150 text-center"
                                                  onClick={() =>
                                                    this.updateDriverOrderById(
                                                      "departed",
                                                      status
                                                    )
                                                  }
                                                  disabled={
                                                    !disabled || !status.driver || isCombinedTrip
                                                  }
                                                >
                                                  <span className="w-100">
                                                    Arrived
                                                  </span>
                                                </button>
                                              )}
                                            {status.driver &&
                                              !status.isVoidOut &&
                                              status.departed && (
                                                <div className="form-group mb-0">
                                                  <div
                                                    className={`input-wrapper loadAssignDate-${dIndex}`}
                                                  >
                                                    <ExactDateWrapper
                                                      className={`loadAssignDate-${dIndex} laDate`}
                                                      isShowTime={true}
                                                      dateFormat="MM-DD-yyyy hh:mm A"
                                                      componentClassName={`date-departed-${status._id}`}
                                                      timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                                                      disabled={
                                                        isCompleted || !disabled || isCombinedTrip
                                                      }
                                                      displayTimeZone={getStorage(
                                                        "timeZone"
                                                      )}
                                                      defaultDate={
                                                        status.departed
                                                          ? moment(
                                                              status.departed
                                                            )
                                                          : ""
                                                      }
                                                      OnBlurUpdate={(_date) =>
                                                        this.updateDriverOrderById(
                                                          "departed",
                                                          status,
                                                          _date,
                                                          `date-departed-${status._id}`
                                                        )
                                                      }
                                                      hideShowSelectDate={true}
                                                      name="arrivedDate"
                                                    />
                                                    {!isCompleted &&
                                                      (<div
                                                          className="input-icon p-0 pr-10 text-muted"
                                                          onClick={() =>
                                                            {!isCombinedTrip && this.removeStatusDriverOrderById(
                                                              "departed",
                                                              status._id
                                                            )}
                                                          }
                                                        >
                                                          <IconTimes />
                                                        </div>
                                                      )}
                                                  </div>
                                                </div>
                                              )}
                                            {!status.isVoidOut &&
                                              nextValidLeg &&
                                              (nextValidLeg.driver ||
                                                !nextValidLeg.arrived) && (
                                                <div className="mx-10 bg-gray-50 p-1 minwidth-60px px-1 h-24px text-center rounded-lg">
                                                  {status.driver && nextValidLeg.arrived
                                                  ? (
                                                    <span className="font-10 font-weight-bold">
                                                      {timeDurationOfEvent}
                                                    </span>
                                                  ) : (
                                                    <span
                                                      className="font-12 font-weight-bold"
                                                      style={{
                                                        color:
                                                          "rgba(23, 42, 65, 0.5)",
                                                      }}
                                                    >
                                                      -
                                                    </span>
                                                  )}
                                                </div>
                                              )}
                                            {!status.isVoidOut &&
                                              nextValidLeg &&
                                              !nextValidLeg.arrived && (
                                                <button
                                                  className="btn btn-xss btn-success w-150 text-center"
                                                  onClick={() =>
                                                    this.updateDriverOrderById(
                                                      "arrived",
                                                      nextValidLeg
                                                    )
                                                  }
                                                  disabled={
                                                    !disabled || !status.driver || isCombinedTrip
                                                  }
                                                >
                                                  <span className="w-100">
                                                    Departed
                                                  </span>
                                                </button>
                                              )}
                                            {!status.isVoidOut &&
                                              nextValidLeg &&
                                              nextValidLeg.driver &&
                                              nextValidLeg.arrived && (
                                                <div className="form-group mb-0">
                                                  <div
                                                    className={`input-wrapper loadAssignDate-${dIndex}`}
                                                  >
                                                    <ExactDateWrapper
                                                      className={`loadAssignDate-${dIndex} laDate`}
                                                      displayTimeZone={getStorage(
                                                        "timeZone"
                                                      )}
                                                      componentClassName={`date-arrived-${nextValidLeg?._id}`}
                                                      disabled={
                                                        isCompleted || !disabled || isCombinedTrip
                                                      }
                                                      defaultDate={
                                                        nextValidLeg.arrived
                                                          ? moment(
                                                              nextValidLeg.arrived
                                                            )
                                                          : ""
                                                      }
                                                      dateFormat="MM-DD-yyyy hh:mm A"
                                                      timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                                                      OnBlurUpdate={(_date) =>
                                                        this.updateDriverOrderById(
                                                          "arrived",
                                                          nextValidLeg,
                                                          _date,
                                                          `date-arrived-${nextValidLeg?._id}`
                                                        )
                                                      }
                                                      hideShowSelectDate={true}
                                                      isShowTime={true}
                                                      name="arrivedDate"
                                                    />
                                                    {!isCompleted &&
                                                      (
                                                        <div
                                                          className="input-icon p-0 pr-10 text-muted"
                                                          onClick={() =>
                                                            {!isCombinedTrip && this.removeStatusDriverOrderById(
                                                              "arrived",
                                                              nextValidLeg._id
                                                            )}
                                                          }
                                                        >
                                                          <IconTimes />
                                                        </div>
                                                      )}
                                                  </div>
                                                </div>
                                              )}
                                          </div>
                                          {addressComp &&
                                            status.type === "RETURNCONTAINER" &&
                                            this.props.isEmptyEnabled && (
                                              <div className="d-flex form-row-md">
                                                <div className="form-group mb-0 mt-10 col">
                                                  <div className="w-100">
                                                    <RecommendedReturnTerminals
                                                      row={
                                                        this.props.selectedLoads
                                                      }
                                                      isLoadInfo={true}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                        </div>
                                        {this.state.droppedIndex === index && (
                                          <div className="col-12 kanban-card-view my-2">
                                            <p className="text-center my-4">
                                              Drop Your Status Here
                                            </p>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </React.Fragment>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                })}

              {this.state.allowDriverCompletion && (
                <div className="card p-15 card-blue mb-10">
                  <div
                    className={`outline-card outline-card--blue p-10 mb-1`}
                    key={"driverCompletion"}
                  >
                    <div className="d-flex align-items-center mb-10">
                      {ICONS.COMPLETED}
                      <span className="font-14">Completed</span>
                      <div className="d-flex ml-auto">
                        {loadCompletedAt && (
                          <Datetime
                            className="mr-2 input-primary"
                            timeFormat={false}
                            dateFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                            inputProps={{ disabled: isCompleted }}
                            displayTimeZone={getStorage("timeZone")}
                            value={moment(loadCompletedAt)}
                          />
                        )}
                        {!isCompleted && (
                          <button
                            className="btn btn-link text-danger"
                            onClick={() => {
                              this.setState(
                                { allowDriverCompletion: false },
                                () => this.handleOrderStatusChange("update")
                              );
                            }}
                          >
                            <IconTrash />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
  render() {
    const loggedInUser = JSON.parse(localStorage.getItem("loggedInUser"));
    if (loggedInUser) {
      if (
        loggedInUser.role === "carrier" ||
        loggedInUser.role === "customer" ||
        loggedInUser.role === "fleetcustomer"
      ) {
        return this.renderReturn();
      } else if (loggedInUser.permissions.length !== 0) {
        if (
          loggedInUser.permissions.includes("customer_service_order_status")
        ) {
          return this.renderReturn();
        } else {
          return (
            <div className="nopermission">
              <h1>You do not have permission to view this page</h1>
            </div>
          );
        }
      } else {
        return (
          <div className="nopermission">
            <h1>You do not have permission to view this page</h1>
          </div>
        );
      }
    }
  }
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { ...actionCreators, hardRemoveDriver },
      dispatch
    ),
  };
}

function mapStateToProps(state) {
  return {
    driverList: state.TmsReducer.drivers,
    customers: state.TmsReducer.customers,
    shippersOptions: state.TmsReducer.shippers,
    consigneesOptions: state.TmsReducer.consignees,
    callers: state.TmsReducer.callers,
    customers: state.TmsReducer.customers,
    allCustomers: state.TmsReducer.allCustomers,
    chassisTerms: state.TmsReducer.chassisTerms,
    chassis: state.chassisReducer.chassis,
    isEmptyEnabled: state.TmsReducer.isEmptyEnabled,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddRoutingEvent);
