import axios from 'axios';
import { getStorage, setStorage, toastr } from '../../services/Common.services';
import { browserHistory } from 'react-router';
import store from './../../redux/store';
import { updateUserType } from '../Login/actionCreators';

axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  const carrierDetail = JSON.parse(getStorage("carrierDetail"));
  if (error.status === 401 || error?.response?.status === 401) {
    localStorage.clear();
    sessionStorage.clear();
    toastr.show('Something went wrong.  Please try to login again!', 'error');
    if(carrierDetail?.strictSessionPolicy) {
      setStorage("sessionEnd", true);
    }
    window.location.reload();
    // store.dispatch(updateUserType(null))
  }
  else if (error.status >= 500) {
  } else {
    const currentPath = window.location.pathname
    if(currentPath === '/verify') return Promise.reject(error);
    if (error.data && error.data.message && !error.data?.shouldHideToastr) { toastr.show(error.response ? error.response.data.message : error.data.message, 'error'); };
    return Promise.reject(error);
  }
});
