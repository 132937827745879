import Avatar from "Components/Common/Avatar";
import { IconAI, IconAngleArrowDown, IconAngleArrowTop, IconCalendar, IconDotSeperator, IconLongArrowBottom, IconLongArrowRight, IconPlus } from "Components/Common/Icons";
import { smallSelectStyle } from "assets/js/select-style";
import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import _ from "lodash";
import { connectToLoads, draftLoadEmailInfo, getEmailTemplateValue, getReplyEmailTemplate, replyEmail, reviewTenderLoad, updateDraftLoadInfo } from "../../../actionCreator";
import ComapareDataBox from "./components/ComapareDataBox";
import CompareTextBox from "./components/CompareTextBox";
import CompareSelectBox from "./components/CompareSelectBox";
import CompareAsyncSelectBox from "./components/CompareAsyncDataBox";
import { uploadDocumentForLoad } from "../../../../Load/actionCreator";
import { shallowEqual, useSelector } from "react-redux";
import { amplitudeTrack, checkCustomerProfilePermission, displayName, getStorage, isTerminalTrackingEnabled, makeOptionsForSelect, showForCarrier, showForTerminal, toastr } from "../../../../../../services";
import { getTMSconsigneeAsyncSearch, getTMSshipperAsyncSearch, getPreSetOrderStatus, getTMSshipperAsyncSearchForSelectWithStatus, getTMSconsigneeAsyncSearchForSelectWithStatus } from "../../../../Load/DriverOrderUtility";
import { LoaderBar } from "Components/Common/LoaderBar";
import CompareEquipmentAsyncSelectBox from "./components/CompareEquipmentAsyncSelectBox";
import { getCallers, getContainerOwner, getContainerSize, getContainerType, newDocUpload } from "../../../../Dispatcher/actionCreators";
import { getChassis } from "../../../../LoadRoutingV2/actionCreator";
import { getChassisSize, getChassisType } from "../../../../AddChassis/actionCreators";
import confirm from "Components/Common/ConfirmAert";
import { NO_DELIVERY_TEMPLATES } from "pages/tms/carrier/BulkUploadNew/constants";
import moment from "moment";
import { createLoad, editLoad, handleTrackUpdate } from "pages/tms/services";
import configuration from "../../../../../../config";
import { mappingFields, AllFieldState, profileFields, ocrMappingFields, exportFields, importFields, loadRefNumbersRegex } from "./constants"
import { transitionEffectForElement, transitionEffectForInput } from "../../../../Assigned/PortPal/TransitionEffects";
import EmailComponent from "./components/EmailComponent.js"
import { convertEmailTos, encryptEmbeddedEmailData, getSubjectForEmail } from "pages/tms/Email/helper";
import { AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE, EMAIL_PROVIDER, SEND_EMAIL_TYPE } from "pages/tms/Email/constant";
import ItemModel from './components/ItemModel'
import { getLiveShareURL } from "../../../../../trucker/NewTrack1/actionCreators.js";
import { isEmbeddedEmailConnected } from "../../../helper.js";
import DataGrid from "react-data-grid"
import InputCell from "./components/Grid/InputCell"
import DateCell from "./components/Grid/DateCell"
import CheckBoxCell from "./components/Grid/CheckBoxCell"
import SelectCell from "./components/Grid/SelectCell"
import { getTimeZone } from "../../../../NewDispatcher/constants.js";
import ReviewTenderInfo from './components/ReviewTenderInfo'
import { isTareWeightEnabled } from "Components/Common/functions";
import {notification_email_options} from '../../../../Settings/Notifications/constant'
import ExcelViewer from "./components/ExcelViewer.jsx"
import { size, trailerTypes } from "pages/tms/constant";

const NewOrderReviewModal = ({ show, hide, draftLoadEmails, rowData, updateDraftloadEmails, getRefNumberByThread,setGetLoadsByThreadVals, emailPeople, isEmailMode,updateTagInOneMail, getReply }) => {

  const [selectedDraftLoadEmail, setSelectedDraftLoadEmail] = useState(draftLoadEmails[0]);
  const [isLoading, setIsLoading] = useState(true);
  const [isButtonLoader,setButtonLoader] = useState(false)

  const [emailTo, setEmailTo] = useState([]);
  const [emailCC,setEmailCC] = useState([])
  const [emailSubject, setEmailSubject] = useState("");
  const [emailBody, setEmailBody] = useState(`<p>We have received and accepted your order.</p>`);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [showEmailReply, setShowEmailReply] = useState(false);
  const [selectedDraftLoadInfo, setSelectedDraftLoadInfo] = useState(draftLoadEmails[0]?.draftLoad)

  const { shippers, consignees, callers } = useSelector((state) => state.TmsReducer, shallowEqual);
  const { terminals } = useSelector((state) => state.HomeReducer, shallowEqual);

  const callersOptions = makeOptionsForSelect(callers, "company_name", "address.address", "_id");
  const shipperOptions = makeOptionsForSelect(shippers, "company_name", "address.address", "_id");
  const consigneesOptions = makeOptionsForSelect(consignees, "company_name", "address.address", "_id");
  const terminalsOptions = makeOptionsForSelect(terminals, "name", null, "_id");

  const returnLocationOptions = makeOptionsForSelect(shippers, "company_name", "address.address", "_id");
  const { containerSize, containerType, containerOwner } = useSelector((state) => state.containerReducer, shallowEqual);
  const { chassis, chassisOwner, chassisSize, chassisType } = useSelector(
    (state) => state.chassisReducer,
    shallowEqual
  );

  const trailerTypeOptions = trailerTypes.map((tt) => ({
    label: tt,
    value: tt,
  }))

  const trailerSizeOptions = size.map((ts) => ({
    label: ts,
    value: ts,
  }))
  const containerSizeList = makeOptionsForSelect(containerSize, "name", "", "_id");
  const containerTypeList = makeOptionsForSelect(containerType, "name", "", "_id");
  const containerSizeOptions = selectedDraftLoadInfo?.type_of_load === "ROAD" ? trailerSizeOptions : containerSizeList;
  const containerTypeOptions = selectedDraftLoadInfo?.type_of_load === "ROAD" ? trailerTypeOptions : containerTypeList;
  const containerOwnerOptions = makeOptionsForSelect(containerOwner, "company_name", "address.address", "_id");

  const chassisOptions = makeOptionsForSelect(chassis, "chassisNo", "", "_id");
  const chassisSizeOptions = makeOptionsForSelect(chassisSize, "name", "", "_id");
  const chassisTypeOptions = makeOptionsForSelect(chassisType, "name", "", "_id");
  const chassisOwnerOptions = makeOptionsForSelect(chassisOwner, "company_name", "address.address", "_id");
  const loadTypes = [
    { value: "IMPORT", label: "IMPORT" },
    { value: "EXPORT", label: "EXPORT" },
    { value: "ROAD", label: "ROAD" },
  ];
  const routeTypes = [
    { label: "Local", value: "Local" },
    { label: "Highway", value: "Highway" },
  ];

  const containerNoOptions = draftLoadEmails?.map(x => ({
    label: x?.draftLoad?.containerNo?.value || x?.fileName,
    value: x?.draftLoad?._id,
    data: x
  }))

  const [notes,setNotes] = useState(selectedDraftLoadInfo?.officeNote)
  const [activeTab, setActiveTab] = useState("CONTAINER")
  const selectedDraftLoadInfoNumber = containerNoOptions.findIndex( x => x.value === selectedDraftLoadInfo._id )
  const isDisableRef = useRef(false)
  const isEmailConnected = isEmbeddedEmailConnected()
  const embeddedEmailAccount = JSON.parse(getStorage('embeddedEmailAccount'))
  const { provider, email: userEmail } = embeddedEmailAccount ?? {};

  const replyEmailTemplate = async () => {
    try {
      const data = await getReplyEmailTemplate()
      if (data) {
        setEmailSubject(data.emailSubject)
        setEmailBody(data.emailBody)     
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(()=>{
     replyEmailTemplate()
  },[])

  const _updateDraftInfoFn = async (className, key, value, loadID, isFromDatagrid) => {
    try {
      if(key === "bookingNo" && !loadRefNumbersRegex.test(value)) {
        toastr.show(`Invalid Booking No`, "error");
        return;
      }
      isDisableRef.current = true
      let payload = { [key]: value }
      if(key === "type_of_load") {
        //update value of containerSize and containerType from payload when type of load change
        const sizeOptions = value === "ROAD" ? trailerSizeOptions : containerSizeList;
        const typeOptions = value === "ROAD" ? trailerTypeOptions : containerTypeList;

        const containerSize = sizeOptions.find(x => x.label === selectedDraftLoadInfo?.containerSize?.value?.name);
        const containerType = typeOptions.find(x => x.label === selectedDraftLoadInfo?.containerType?.value?.name);

        const size = {
          id: containerSize?.value,
          name: containerSize?.label
        }
        const type = {
          id: containerType?.value,
          name: containerType?.label
        }
        payload = {
          ...payload,
          ...({ containerSize: containerSize ? size : '' }),
          ...({ containerType: containerType ? type: '' })
        };
      }
      const data = await updateDraftLoadInfo(loadID || selectedDraftLoadInfo._id, payload)
      if(!loadID || loadID === selectedDraftLoadInfo._id)
        setSelectedDraftLoadInfo(data.data);

      const _draftLoadEmails = draftLoadEmails?.map(x => {
        if(x.draftLoadId === (loadID || selectedDraftLoadEmail.draftLoadId)){
          x.draftLoad = data.data
        }
        return x;
      })
      updateDraftloadEmails(_draftLoadEmails)
      setNotes(data?.data?.officeNote??{})
      await new Promise((resolve)=>setTimeout(()=>{resolve()},300))
      if(loadID){
        className && transitionEffectForElement(`${className}`, true);
      }else{
        className && transitionEffectForInput(`${className}`, true);
      }
      if(ocrMappingFields.includes(key)){
        reviewALLTender(true)
      }
      isDisableRef.current = false
    } catch (error) {
      if(loadID){
        className && transitionEffectForElement(`${className}`, false);
      }else{
        className && transitionEffectForInput(`${className}`, false);
      }
      toastr.show("something went wrong.", "error")
      isDisableRef.current = false
    }
  }


  const _getDraftLoadEmailInfo = async () => {
    try {
      let res = await draftLoadEmailInfo(rowData.message.threadId)
      updateDraftloadEmails(res)
    } catch (error) {
      console.log(error)
    }
  }

  const createdLoad = () => {
    const tenderList = []
    for(let item of draftLoadEmails){
      const { draftLoad: data } = item
      data.attachmentUrl = item.attachmentUrl
      const newLoadData = {
        type_of_load: data.type_of_load,
        preSetOrderStatus: data?.routeType?.value ?? ''
      };
      for(const key in data) {
        if(profileFields.includes(key)) {
          if(key === "chassisNo"){
            newLoadData[key] = data[key]?.value?.name ?? undefined;
            newLoadData['chassisId'] = data[key]?.value?.id ?? undefined;
          } else if(key === "containerSize" || key === "containerType"){
            // replace keys according load type
            const trailerName = key === "containerSize"  ? "trailerSize" : "trailerType"
            const result = isAvailableValue(data[key]?.value?.name, key)            
            if(data.type_of_load === "ROAD") {
              newLoadData[trailerName] = result ? data[key]?.value?.name ?? undefined : undefined;
            } else {
              newLoadData[key] = result ? data[key]?.value?.id ?? undefined: undefined;
            }
          } else {
            newLoadData[key] = data[key]?.value?.id ?? undefined;
          }
        }
        if(['consignee', 'shipper'].includes(key)) {
          newLoadData[key] = data[key].map((c) => c?.value?.id).filter(Boolean)
        }
        if(mappingFields.includes(key)) {
          newLoadData[key] = data[key]?.value ?? undefined
        }
        if(key === "vesselETADate") {
          newLoadData['vessel'] = data[key].value
        }
        if(key === "loadtime"){
          newLoadData['loadTime'] = data[key].value
        }
        if(key === "returnDate"){
          newLoadData['return'] = data[key].value
        }
      }
  
      if (!newLoadData?.type_of_load && getStorage("currentUserRole") != "customer") {
        toastr.show(`Please select a load type! For :${newLoadData.containerNo}`, "error");
        return;
      }
      if (!newLoadData?.caller && getStorage("currentUserRole") != "customer") {
        toastr.show(`Please select a customer ${newLoadData.containerNo ? `for :${newLoadData.containerNo}` : "!"}`, "error");
        return;
      }
      if (
        newLoadData?.type_of_load !== "BILL_ONLY" &&
        (!newLoadData?.shipper || newLoadData?.shipper.length === 0) &&
        getStorage("currentUserRole") != "customer"
      ) {
        toastr.show(`Please select a shipper ${newLoadData.containerNo ? `for :${newLoadData.containerNo}` : "!"}`, "error");
        return;
      } else if (
        newLoadData?.type_of_load !== "BILL_ONLY" &&
        (!newLoadData?.consignee || newLoadData?.consignee.length === 0) &&
        (!NO_DELIVERY_TEMPLATES.includes(data?.preSetOrderStatus || newLoadData?.preSetOrderStatus) || newLoadData?.type_of_load === "ROAD") &&
        getStorage("currentUserRole") != "customer"
      ) {
        toastr.show(`Please select a consignee ${newLoadData.containerNo ? `for :${newLoadData.containerNo}` : "!"}`, "error");
        return;
      } else if (
        newLoadData?.type_of_load !== "BILL_ONLY" &&
        newLoadData?.type_of_load !== "ROAD" &&
        !newLoadData?.emptyOrigin &&
        NO_DELIVERY_TEMPLATES.includes(data?.preSetOrderStatus) &&
        getStorage("currentUserRole") != "customer"
      ) {
        toastr.show(`Please select a return location ${newLoadData.containerNo ? `for :${newLoadData.containerNo}` : "!"}`, "error");
        return;
      }
      if (
        !data?.terminal &&
        ((getStorage("currentUserRole") == "fleetmanager" &&
          configuration.idForTerminal.indexOf(JSON.parse(getStorage("loggedInUser")).fleetManager.carrier) != -1) ||
          (getStorage("currentUserRole") == "carrier" &&
            configuration.idForTerminal.indexOf(getStorage("currentUserID")) != -1))
      ) {
        toastr.show(`Please select the terminal. For :${newLoadData.containerNo}`, "error");
        return;
      }
      const params = {
        type_of_load: newLoadData?.type_of_load,
        status: "PENDING",
        preSetOrderStatus: newLoadData?.preSetOrderStatus,
      };

      if (newLoadData?.type_of_load !== "EXPORT") {
        delete newLoadData.bookingNo
      }
      if(newLoadData.bookingNo && !loadRefNumbersRegex.test(newLoadData.bookingNo)) {
        delete newLoadData.bookingNo
      }
      if (newLoadData?.type_of_load === "EXPORT") {
        importFields.forEach(field => delete newLoadData[field]);
      }
      if (newLoadData?.type_of_load === "IMPORT") {
        exportFields.forEach(field => delete newLoadData[field]);
      }
      if (getStorage("currentUserRole") != "customer") {
        params.caller = newLoadData?.caller;
        params.shipper = newLoadData?.shipper;
        if (!NO_DELIVERY_TEMPLATES.includes(data?.preSetOrderStatus || newLoadData?.preSetOrderStatus) || newLoadData?.type_of_load === "ROAD") {
          params.consignee = newLoadData?.consignee;
        }
        if (NO_DELIVERY_TEMPLATES.includes(data?.preSetOrderStatus || newLoadData?.preSetOrderStatus) && newLoadData?.type_of_load !== "ROAD") {
          params.consignee = [];
        }
        if (newLoadData?.emptyOrigin && data?.type_of_load !== "ROAD") {
          params.emptyOrigin = newLoadData.emptyOrigin;
        }
      }
      if (
        data?.terminal &&
        ((getStorage("currentUserRole") == "fleetmanager" &&
          configuration.idForTerminal.indexOf(JSON.parse(getStorage("loggedInUser")).fleetManager.carrier) != -1) ||
          (getStorage("currentUserRole") == "carrier" &&
            configuration.idForTerminal.indexOf(getStorage("currentUserID")) != -1))
      ) {
        params.terminal = newLoadData?.terminal;
      }
      if (newLoadData.containerNo && !/^[A-Za-z]{4}\d{6,7}$/.test(newLoadData.containerNo)) {
        toastr.show("Please Enter Correct Container Number", "warning");
        return;
      } else if (newLoadData.containerNo) {
        params.containerNo = newLoadData.containerNo;
      }
      let obj = new Array();
      delete newLoadData.routeType;
      obj = Object.keys(AllFieldState).reduce((result, key) => {
        result[key] = newLoadData[key];
        return result;
      }, {});
      const allFieldParams = Object.fromEntries(
        Object.entries(obj).filter(([key, value]) => value !== "" && value !== null && value !== undefined)
      );
      if(data?.hasOwnProperty('hazmat')){
        params.hazmat = data.hazmat
      }
      if(data?.hasOwnProperty('overWeight')){
        params.overWeight = data.overWeight
      }
      if(data?.hasOwnProperty('overHeight')){
        params.overHeight = data.overHeight
      }
      if(data?.hasOwnProperty('liquor')){
        params.liquor = data.liquor
      }
      if(data?.hasOwnProperty('hot')){
        params.hot = data.hot
      }
      if(data?.hasOwnProperty('isGenset')){
        params.isGenset = data.isGenset
      }
      if(data?.route?.value){
        params.routeType = data?.route?.value
      }
      if(data?.tareWeight?.value){
        params.tareWeight = data?.tareWeight?.value
      }
      if(data?.reservationNo?.value){
        params.reservationNo = data?.reservationNo?.value
      }
      if(data?.pickupTimes?.value){
        params.pickupTimes = JSON.stringify(newLoadData?.shipper?.map((x, i) => {
          const apt = { customerId: x }
          if (i === 0) {
            apt.pickupFromTime = data?.pickupTimes?.value
            apt.pickupToTime = data?.pickupTimes?.value
          }
          return apt
        }) || [])
      }
      if (data?.deliveryTimes?.value) {
        params.deliveryTimes = JSON.stringify(newLoadData?.consignee?.map((x, i) => {
          const apt = { customerId: x }
          if (i === 0) {
            apt.deliveryFromTime = data?.deliveryTimes?.value
            apt.deliveryToTime = data?.deliveryTimes?.value
          }
          return apt
        }) || [])
      }
      const mergedParams = { ...params, ...allFieldParams, isCreatedByOCR: true };
      tenderList.push({ params: mergedParams, data })
    }
    _createLoad(tenderList);
  };

  function replacePlaceholders(html, values) {
    return html.replace(/@([^@]+)@/g, (match, p1) => {
        let key = p1.trim();
        return values.hasOwnProperty(key) ? values[key] : match;
    });
}

  const modifyReplyEmailBody = async (reference_numbers) => {
    const emailBodyMatchesRegExp = new RegExp(
      `@(${notification_email_options.join('|')})@`, 'g'
    );
    const emailSubjectMatchesRegExp = new RegExp(
      `@(${notification_email_options.join('|')})@`, 'g'
    );
    const emailBodyMatches = emailBody?.match(emailBodyMatchesRegExp) ?? [];
    const emailSubjectMatches = emailSubject?.match(emailSubjectMatchesRegExp) ?? [];

    let replacedKeys = [...emailBodyMatches.map(match => match.slice(1, -1)), ...emailSubjectMatches.map(match => match.slice(1, -1))];
    replacedKeys = [...new Set(replacedKeys)]
    const re = await getEmailTemplateValue({ referenceNumbers: reference_numbers, replacedKeys })
    let nEmailBody = replacePlaceholders(emailBody, re.replacedValue);
    let nEmailSubject = replacePlaceholders(emailSubject, re.replacedValue);
    return { nEmailBody, nEmailSubject }
  }
  const _editLoads = async (referenceNumbers, params) => {
    const editedLoads = params.map((p) => {
      return new Promise((resolve, reject) => {
        editLoad(p).then(() => resolve()).catch((err) => {
          console.log(err);
          resolve()
        })
      })
    })
    await Promise.all(editedLoads)
    _replyEmail(referenceNumbers);
  }
  const _createLoad = async (tenderList) => {
    if (!emailTo?.length) {
      toastr.show("Pls Add email To ", "error");
      return;
    }
    if (!emailSubject?.length) {
      toastr.show("Pls Add email Subject ", "error");
      return;
    }

    const loadPromises = tenderList.map(async ({ params, data }) => {
      return new Promise(async(resolve,reject)=>{
        params.createSource = "EEI";

        setButtonLoader(true);
        const currentUser = JSON.parse(getStorage("loggedInUser"));
        let carrierId = currentUser._id;
  
        try {
          const res = await createLoad({
            type_of_load: params.type_of_load,
            status: params.status,
            preSetOrderStatus: params.preSetOrderStatus,
            caller: params.caller,
            shipper: params.shipper,
            consignee: params.consignee,
            ...(params?.terminal && { terminal: params.terminal }),
            isFromEEI:true
          })
          const reference_number = res.data.data.reference_number
          toastr.show(`Your ${reference_number} load is created!`, "success");
          await updateDraftLoadInfo(data._id, { isLoadCreated: true, loadReferenceNumber: reference_number })   

          // Find keys with systemm generated 
          let keysWithIsSystemGenerated = [];
          _.forOwn(data, (value, key) => {
            if (_.isObject(value) && value.isSystemGenerated === true) {
              keysWithIsSystemGenerated.push(key);
            }
          });
          let editParams={...params};
          // remove system generated fields
          let removedParams=['type_of_load','status','preSetOrderStatus', 'caller','shipper','consignee', 'terminal', ...keysWithIsSystemGenerated];
          removedParams.forEach(key => {
            delete editParams[key];
            if(key=="vesselETADate") delete editParams['vessel'];
          });
          resolve({reference_number, editParams: { reference_number: reference_number, ...editParams, ...(params?.containerNo && { isContainerConfirm: true }) }})
          // system generated track fields
          let trackPrams={}
          keysWithIsSystemGenerated.forEach(key=>{
            trackPrams[key]=params[key]
            if(key=="vesselETADate" && params["vessel"]){
              trackPrams['eta'] = params["vessel"]
              delete trackPrams['vesselETADate'];
            }
            if(key=="callerbillLandingNo" && params["callerbillLandingNo"]){
              trackPrams['billOfLading'] = params["callerbillLandingNo"]
              delete trackPrams['callerbillLandingNo'];
            }
            if(key=="deliveryOrderNo" && params["deliveryOrderNo"]){
              trackPrams['vesselName'] = params["deliveryOrderNo"]
              delete trackPrams['deliveryOrderNo'];
            }
            if(key=="releaseNo" && params["releaseNo"]){
              trackPrams['voyageName'] = params["releaseNo"]
              delete trackPrams['releaseNo'];
            }
            if(key=="sealNo" && params["sealNo"]){
              trackPrams['seal'] = params["sealNo"]
              delete trackPrams['sealNo'];
            } 
              if(key=="dischargedDate" && params["dischargedDate"]){
              trackPrams['dischargeDate'] = params["dischargedDate"]
              delete trackPrams['dischargedDate'];
            }
            if(key=="cutOffDate" && params["cutOffDate"]){
              trackPrams['cutOff'] = params["cutOffDate"]
              delete trackPrams['cutOffDate'];
            }
            if(key=="containerSize" && data["containerSize"]?.isSystemGenerated && data["containerSize"]?.value?.name){
              trackPrams['containerSize'] = data["containerSize"]?.value?.name;
            }
            if(key=="containerType" && data["containerType"]?.isSystemGenerated &&  data["containerType"]?.value?.name){
              trackPrams['containerType'] = data["containerType"]?.value?.name;
            }
            if(key=="containerOwner" && data["containerOwner"]?.isSystemGenerated && data["containerOwner"]?.value?.name){
              trackPrams['containerOwner'] = data["containerOwner"]?.value?.name;
            }
            if(key=="outgateDate" && params["outgateDate"]){
              trackPrams['outGate']={
                date: params["outgateDate"]
              }
              delete trackPrams['outgateDate'];
            }
            if(key=="ingateDate" && params["ingateDate"]){
              trackPrams['inGate']={
                date: params["ingateDate"]
              }
              delete trackPrams['ingateDate'];
            }
            
          })
          if(!_.isEmpty(trackPrams)) {
            if(trackPrams['inGate'] || trackPrams['outGate']){
              let gateParams={};
              if(trackPrams['outGate'])gateParams['outGate']= trackPrams['outGate'];
              if(trackPrams['inGate'])gateParams['inGate']= trackPrams['inGate'];
              handleTrackUpdate({reference_number: reference_number,...gateParams}).then().catch((err) => console.log(err))
              delete trackPrams['outGate'];
              delete trackPrams['inGate'];
            }
            handleTrackUpdate({reference_number: reference_number, ...trackPrams}).then().catch((err) => console.log(err))
          }
          downloadAndUploadPDF(data.attachmentUrl, reference_number)
        } catch (error) {
          reject('Something went wrong')
          console.log("🚀 ~ file: NewOrderReviewModal.jsx:173 ~ error:", error);
        }
      })
    })
    try {
      const data = await Promise.all(loadPromises);
      toastr.show("Loads connect successfully", "success");
      if (isEmailConnected) _getDraftLoadEmailInfo()
      updateTagInOneMail(rowData?.message?.threadId)
      const referenceNumbers = data.map(d => d.reference_number)
      const editParams = data.map(d => d.editParams)
      _editLoads(referenceNumbers, editParams)
      let payload = {
        reference_number: referenceNumbers,
        thread_id: rowData?.message?.threadId,
        accountId: rowData?.message?.grantId
      }
      connectToLoads(payload).then(async (res) => {
        getRefNumberByThread()
      }).catch((err) => { })
      setButtonLoader(false);
      hide();
    } catch (error) {
      setButtonLoader(false);
      console.error("Error processing loads in parallel:", error);
    }
  };


  const downloadAndUploadPDF = async (attachmentUrl, reference_number) => {
    try {
      const response = await fetch(attachmentUrl);
      if (!response.ok) {
        console.error('Error:', response);
      }
      const pdfBlob = await response.blob();
  
      const filename = `${new Date().getTime()}.pdf`;
  
      const formData = new FormData();
      formData.append('document', pdfBlob, filename);
      formData.append('filename', filename);
  
      const result = await newDocUpload(formData);
  
      await uploadDocumentForLoad({
        reference_number: reference_number,
        type: "Delivery Order",
        url: result?.data?.url,
        fileSize: pdfBlob.size, 
      });
  
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const reviewALLTender = async (noLoading = false) => {
    try {
      const data = draftLoadEmails.map((i, index) => {
        return new Promise(async (resolve, reject) => {
          if (index === 0 && !noLoading) setIsLoading(true);
          const reviewTender = await reviewTenderLoad(i.draftLoadId);
          if (reviewTender) {
            const _draftLoadEmails = draftLoadEmails.map(e => {
              if (e?.draftLoadId === reviewTender?._id) e.draftLoad = reviewTender
              return e
            })
            updateDraftloadEmails(_draftLoadEmails)
            setSelectedDraftLoadInfo((prev) => {
              if (prev._id === reviewTender._id) return reviewTender
              return prev
            });
          }
          if (index === 0 && !noLoading) setIsLoading(false);
          resolve(true)
        })
      })
      await Promise.all(data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    reviewALLTender()
  }, [])

  useEffect(() => {
    if (selectedDraftLoadEmail) {
      if (!selectedDraftLoadEmail?.draftLoad?.isProcessed) {
        const customers = emailPeople.map((p) => ({ id: p.organizationId, name: p.organizationName })).filter((p) => p.id !== '' && p.name !== '')
        if (customers?.length) updateDraftLoadInfo(selectedDraftLoadEmail.draftLoad._id, { caller: customers[0] }).then((data) => setSelectedDraftLoadInfo(data.data))
      }
      setSelectedDraftLoadInfo(selectedDraftLoadEmail?.draftLoad)
      setNotes(selectedDraftLoadEmail?.draftLoad?.officeNote)
    }
  }, [selectedDraftLoadEmail])


  useEffect(() => {
    const { from, subject, cc, to } = rowData.message;
    setEmailSubject(getSubjectForEmail(subject, SEND_EMAIL_TYPE.REPLY));
    setEmailTo(convertEmailTos(from || []));
    const _emailCc = [...(cc ?? []),...(to ?? [])]?.filter(ele => ele?.email?.toLowerCase() !== userEmail?.toLowerCase())
    setEmailCC(convertEmailTos(_emailCc || []));
    setUploadedDocuments([])
  }, [rowData]);

  const _replyEmail = async(LoadreferenceNumbers) => {

    const { nEmailBody, nEmailSubject } = await modifyReplyEmailBody(LoadreferenceNumbers)
    let newEmailBody = nEmailBody;
    
    const documentIds = uploadedDocuments?.map((val) => val?.id);
    const encryptedBody = newEmailBody ? encryptEmbeddedEmailData(newEmailBody) : "";
    const cc = JSON.stringify(convertEmailTos(emailCC))
    const mailData = {
      messageId: rowData.message.id,
      body: {
        to: JSON.stringify(convertEmailTos(emailTo)),
        ...(cc.length > 0 && { cc }),
        subject: provider === EMAIL_PROVIDER.GMAIL ? rowData?.message?.subject : nEmailSubject,
        ...(encryptedBody && { body: encryptedBody }),
      },
    }
    const emailData = new FormData();
    emailData.append('messageId', rowData.message.id);
    for (const [key, value] of Object.entries(mailData.body)) {
      emailData.append(key, value);
    }
    emailData.append("all", "true")
      replyEmail(emailData).then((data) => {
        getReply(true,data)
        toastr.show("Email Replyed.", "success")
      }).catch((err) => {
        toastr.show("Something went wrong email not sent.", "error")
        console.log("err", err)
      })
  }

 const OpenModal = () => {
    setIsOpenModal(true)
  }

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const containerColumns = [
    {
      key: "cont",
      name: "Cont. #",
      cellClass: "cell containerNo",
      resizable: true,
      headerCellClass: "justify-content-start",
      minWidth: 124,
    
      formatter: ({ row }) => { 
        return <InputCell 
          value={row?.draftLoad?.containerNo?.value} 
          className={`containerNo-${row?.draftLoadId}`}
          onBlur={(value) => {
            if (value && !/^[A-Za-z]{4}\d{6,7}$/.test(value)) {
              toastr.show("Please Enter Correct Container Number", "warning");
              return;
            } 
            _updateDraftInfoFn(`containerNo-${row?.draftLoadId}`, "containerNo", value, row.draftLoadId, true)
          }} 
        /> 
      },
    },
    {
      key: "seal",
      name: "Seal #",
      cellClass: "cell",
      resizable: true,
      headerCellClass: "justify-content-start",
      minWidth: 124,
     
      formatter: ({ row }) => {
        return <InputCell 
        value={row?.draftLoad?.sealNo?.value} 
        className={`seal-${row?.draftLoadId}`}
        onBlur={(value) => {
          _updateDraftInfoFn(`seal-${row?.draftLoadId}`, "sealNo", value, row.draftLoadId, true)
        }} 
      /> 
      },
    },
    {
      key: "pickUp",
      name: "Pick Up #",
      cellClass: "cell",
      resizable: true,
      headerCellClass: "justify-content-start",
      minWidth: 124,
    
      formatter: ({ row }) => {
        return <InputCell 
          value={row?.draftLoad?.callerPONo?.value} 
          className={`callerPONo-${row?.draftLoadId}`}
          onBlur={(value) => {
            _updateDraftInfoFn(`callerPONo-${row?.draftLoadId}`, "callerPONo", value, row.draftLoadId, true)
          }} 
        /> 
      },
    },
    {
      key: "appointment",
      name: "Appointment #",
      cellClass: "cell",
      resizable: true,
      headerCellClass: "justify-content-start",
      minWidth: 124,
     
      formatter: ({ row }) => {
        return <InputCell 
          value={row?.draftLoad?.appointmentNo?.value} 
          className={`appointmentNo-${row?.draftLoadId}`}
          onBlur={(value) => {
            _updateDraftInfoFn(`appointmentNo-${row?.draftLoadId}`, "appointmentNo", value, row.draftLoadId, true)
          }} 
        /> 
      },
    },
    {
      key: "return",
      name: "Return #",
      cellClass: "cell",
      headerCellClass: "justify-content-start",
      minWidth: 124,
      resizable: true,
  
      formatter: ({ row }) => {
        return <InputCell 
          value={row?.draftLoad?.returnNo?.value} 
          className={`returnNo-${row?.draftLoadId}`}
          onBlur={(value) => {
            _updateDraftInfoFn(`returnNo-${row?.draftLoadId}`, "returnNo", value, row.draftLoadId, true)
          }} 
        /> 
      },
    },
    {
      key: "reservation",
      name: "Reservation #",
      cellClass: "cell",
      headerCellClass: "justify-content-start",
      minWidth: 124,
      resizable: true,
      formatter: ({ row }) => {
        return <InputCell 
          value={row?.draftLoad?.reservationNo?.value} 
          className={`reservationNo-${row?.draftLoadId}`}
          onBlur={(value) => {
            _updateDraftInfoFn(`reservationNo-${row?.draftLoadId}`, "reservationNo", value, row.draftLoadId, true)
          }} 
        /> 
      },
    },
    {
      key: "reference",
      name: "Reference #",
      cellClass: "cell",
      headerCellClass: "justify-content-start",
      minWidth: 124,
      resizable: true,
      formatter: ({ row }) => {
        return <InputCell 
          value={row?.draftLoad?.secondaryReferenceNo?.value} 
          className={`secondaryReferenceNo-${row?.draftLoadId}`}
          onBlur={(value) => {
            _updateDraftInfoFn(`secondaryReferenceNo-${row?.draftLoadId}`, "secondaryReferenceNo", value, row.draftLoadId, true)
          }} 
        /> 
      },
    },
    {
      key: "size",
      name: "Size",
      cellClass: "cell",
      headerCellClass: "justify-content-start",
      minWidth: 56,
      resizable: true,
      formatter: ({ row }) => {
        const containerSize = containerSizeOptions.find(x => x.label === row?.draftLoad?.containerSize?.value?.name)        
        return <SelectCell 
          value={containerSize ? row?.draftLoad?.containerSize?.value?.name : ""} 
          className={`containerSize-${row?.draftLoadId}`}
          onSelect={(e) => {
            _updateDraftInfoFn(`containerSize-${row?.draftLoadId}`, "containerSize", { id: e?.value, name: e?.label }, row.draftLoadId, true)
          }} 
          options={containerSizeOptions}
        />
      },
    },
    {
      key: "type",
      name: "Type",
      cellClass: "cell",
      headerCellClass: "justify-content-start",
      minWidth: 56,
      resizable: true,
      formatter: ({ row }) => {
        const containerType = containerTypeOptions.find(x => x.label === row?.draftLoad?.containerType?.value?.name)
        return <SelectCell 
          value={containerType ? row?.draftLoad?.containerType?.value?.name : ""} 
          className={`containerType-${row?.draftLoadId}`}
          onSelect={(e) => {
            _updateDraftInfoFn(`containerType-${row?.draftLoadId}`, "containerType", { id: e?.value, name: e?.label }, row.draftLoadId, true)
          }} 
          options={containerTypeOptions}
        />
      },
    },
    {
      key: "ssl",
      name: "SSL",
      cellClass: "cell",
      headerCellClass: "justify-content-start",
      minWidth: 110,
      resizable: true,
      formatter: ({ row }) => {
        return <SelectCell 
          value={row?.draftLoad?.containerOwner?.value?.name} 
          className={`containerOwner-${row?.draftLoadId}`}
          onSelect={(e) => {
            _updateDraftInfoFn(`containerOwner-${row?.draftLoadId}`, "containerOwner", { id: e?.value, name: e?.label }, row.draftLoadId, true)
          }} 
          options={containerOwnerOptions}
        />
      },
    },
    {
      key: "pickUPApt",
      name: "Pick Up APT",
      cellClass: "cell",
      headerCellClass: "justify-content-start",
      minWidth: 110,
     
      formatter: ({ row }) => {
        return  <DateCell
          value={row?.draftLoad?.pickupTimes?.value}
          className={`pickupTimes-${row?.draftLoadId}`}
          onBlur={(date) => {
            _updateDraftInfoFn(`pickupTimes-${row?.draftLoadId}`, "pickupTimes", date, row?.draftLoadId, true)
          }}
        />
      },
    },
    {
      key: "delApt",
      name: "Del. APT",
      cellClass: "cell",
      headerCellClass: "justify-content-start",
      minWidth: 110,
      
      formatter: ({ row }) => {
        return  <DateCell
          value={row?.draftLoad?.deliveryTimes?.value}
          className={`deliveryTimes-${row?.draftLoadId}`}
          onBlur={(date) => {
            _updateDraftInfoFn(`deliveryTimes-${row?.draftLoadId}`, "deliveryTimes", date, row?.draftLoadId, true)
          }}
        />
      },
    },
    {
      key: "eta",
      name: "ETA",
      cellClass: "cell",
      headerCellClass: "justify-content-start",
      minWidth: 120,
      resizable: true,
      formatter: ({ row }) => {
        return <DateCell 
          value={row?.draftLoad?.vesselETADate?.value}
          className={`vesselETADate-${row?.draftLoadId}`}
          onBlur={(date) => {
            _updateDraftInfoFn(`vesselETADate-${row?.draftLoadId}`, "vesselETADate", date, row?.draftLoadId, true)
          }}
        />
      },
    },
    {
      key: "lfd",
      name: "LFD",
      cellClass: "cell",
      headerCellClass: "justify-content-start",
      minWidth: 120,
      resizable: true,
      formatter: ({ row }) => {
        return <DateCell
          value={row?.draftLoad?.lastFreeDay?.value}
          className={`lastFreeDay-${row?.draftLoadId}`}
          onBlur={(date) => {
            _updateDraftInfoFn(`lastFreeDay-${row?.draftLoadId}`, "lastFreeDay", date, row?.draftLoadId, true)
          }}
        />
      },
    },

  ]

  const equipmentColumns = [
    {
      key: "cont",
      name: "Cont. #",
      cellClass: "cell containerNo",
      resizable: true,
      headerCellClass: "justify-content-start",
      minWidth: 124,
    
      formatter: ({ row }) => { 
        return <InputCell 
          value={row?.draftLoad?.containerNo?.value} 
          className={`containerNo-${row?.draftLoadId}`}
          onBlur={(value) => {
            if (value && !/^[A-Za-z]{4}\d{6,7}$/.test(value)) {
              toastr.show("Please Enter Correct Container Number", "warning");
              return;
            } 
            _updateDraftInfoFn(`containerNo-${row?.draftLoadId}`, "containerNo", value, row.draftLoadId, true)
          }} 
        /> 
      },
    },
    {
      key: "chassisNo",
      name: "Chassis #",
      cellClass: "cell",
      headerCellClass: "justify-content-start",
      minWidth: 124,
      resizable: true,
      formatter: ({row}) => { 
        return <SelectCell 
          value={row?.draftLoad?.chassisNo?.value?.name} 
          className={`chassisNo-${row?.draftLoadId}`}
          onSelect={(e) => {
            _updateDraftInfoFn(`chassisNo-${row?.draftLoadId}`, "chassisNo", { id: e?.value, name: e?.label }, row.draftLoadId, true)
          }} 
          options={chassisOptions}
        /> 
      },
    },
    {
      key: "size",
      name: "Size",
      cellClass: "cell",
      headerCellClass: "justify-content-start",
      minWidth: 56,
      resizable: true,
      formatter: ({ row }) => {
        return <SelectCell 
          value={row?.draftLoad?.chassisSize?.value?.name} 
          className={`chassisSize-${row?.draftLoadId}`}
          onSelect={(e) => {
            _updateDraftInfoFn(`chassisSize-${row?.draftLoadId}`, "chassisSize", { id: e?.value, name: e?.label }, row.draftLoadId, true)
          }} 
          options={chassisSizeOptions}
        />
      },
    },
    {
      key: "type",
      name: "Type",
      cellClass: "cell",
      headerCellClass: "justify-content-start",
      minWidth: 110,
      resizable: true,
      formatter: ({ row }) => {
        return <SelectCell 
          value={row?.draftLoad?.chassisType?.value?.name} 
          className={`chassisType-${row?.draftLoadId}`}
          onSelect={(e) => {
            _updateDraftInfoFn(`chassisType-${row?.draftLoadId}`, "chassisType", { id: e?.value, name: e?.label }, row.draftLoadId, true)
          }} 
          options={chassisTypeOptions}
        />
      },
    },
    {
      key: "chassisOwner",
      name: "Owner",
      cellClass: "cell",
      headerCellClass: "justify-content-start",
      minWidth: 110,
      resizable: true,
      formatter: ({ row }) => {
        return <SelectCell 
          value={row?.draftLoad?.chassisOwner?.value?.name} 
          className={`chassisOwner-${row?.draftLoadId}`}
          onSelect={(e) => {
            _updateDraftInfoFn(`chassisOwner-${row?.draftLoadId}`, "chassisOwner", { id: e?.value, name: e?.label }, row.draftLoadId, true)
          }} 
          options={chassisOwnerOptions}
        />
      },
    },
    {
      key: "genset",
      name: "Genset #",
      cellClass: "cell",
      headerCellClass: "justify-content-start",
      minWidth: 110,
      resizable: true,
      formatter: ({ row }) => {
        return <InputCell 
        value={row?.draftLoad?.genset?.value} 
        className={`genset-${row.draftLoadId}`}
        onBlur={(value) => {
          _updateDraftInfoFn(`genset-${row?.draftLoadId}`, "genset", value, row.draftLoadId, true)
        }} 
      /> 
      },
    },
    {
      key: "commodity",
      name: "Commodity",
      cellClass: "cell",
      headerCellClass: "justify-content-start",
      minWidth: 110,
      resizable: true,
      formatter: ({ row }) => {
        return <InputCell 
          value={row?.draftLoad?.items?.value?.[0]?.commodity}
          className={`commodity-${row?.draftLoadId}`}
          onBlur={(value) => {
            const data = _.cloneDeep(row?.draftLoad?.items?.value || [])
            if(data?.[0])
              data[0].commodity = value
            else 
              data[0] = [{
                commodity: value,
                weight: "",
                weightKgs: "",
                description: "",
                pieces: "",
                units: "",
                weightUnitType: "",
                pallets: "",
                palletsUnits: ""
              }]
            _updateDraftInfoFn(`commodity-${row?.draftLoadId}`, "items", data, row.draftLoadId, true)
          }} 
        />
      },
    },
    {
      key: "pieces",
      name: "Pieces",
      cellClass: "cell",
      headerCellClass: "justify-content-start",
      minWidth: 110,
      resizable: true,
      formatter: ({ row }) => {
        return <InputCell 
          value={row?.draftLoad?.items?.value?.[0]?.pieces}
          className={`pieces-${row?.draftLoadId}`}
          onBlur={(value) => {
            const data = _.cloneDeep(row?.draftLoad?.items?.value || [])
            if(data?.[0])
              data[0].pieces = value
            else 
              data[0] = [{
                pieces: value,
                weight: "",
                weightKgs: "",
                commodity: "",
                description: "",
                units: "",
                weightUnitType: "",
                pallets: "",
                palletsUnits: ""
              }]
            _updateDraftInfoFn(`pieces-${row?.draftLoadId}`, "items", data, row.draftLoadId, true)
          }} 
        />
      },
    },
    {
      key: "weight",
      name: "Weight Lbs",
      cellClass: "cell",
      headerCellClass: "justify-content-start",
      minWidth: 110,
      resizable: true,
      formatter: ({ row }) => {
        return <InputCell 
          value={row?.draftLoad?.items?.value?.[0]?.weight}
          className={`weightLbs-${row?.draftLoadId}`}
          isNumber={true}
          onBlur={(value) => {
            const data = _.cloneDeep(row?.draftLoad?.items?.value || [])
            const weightInLBs = Number(value)
            const weightKGs = (parseFloat(weightInLBs) * 0.453592).toFixed(2);
            if(data?.[0]){
              data[0].weight = weightInLBs?.toFixed(2)
              data[0].weightKgs = weightKGs
            }
            else 
              data[0] = [{
                weight: weightInLBs?.toFixed(2),
                weightKgs: weightKGs
              }]
            _updateDraftInfoFn(`weightLbs-${row?.draftLoadId}`, "items", data, row.draftLoadId, true)
          }} 
        />
      },
    },
    {
      key: "weightKGs",
      name: "Weight Kgs",
      cellClass: "cell",
      headerCellClass: "justify-content-start",
      minWidth: 110,
      resizable: true,
      formatter: ({ row }) => {
        return <InputCell 
          value={row?.draftLoad?.items?.value?.[0]?.weightKgs}
          className={`weightKgs-${row?.draftLoadId}`}
          isNumber={true}
          onBlur={(value) => {
            const data = _.cloneDeep(row?.draftLoad?.items?.value || [])
            const weightKGs = Number(value)
            const weightInLBs = (parseFloat(weightKGs) * 2.20462).toFixed(2);
            if(data?.[0]){
              data[0].weightKgs = weightKGs?.toFixed(2)
              data[0].weight = weightInLBs
            }
            else {
              data[0] = [{ 
                weightKgs: weightKGs?.toFixed(2),
                weight: weightInLBs
              }]
            }
            _updateDraftInfoFn(`weightKgs-${row?.draftLoadId}`, "items", data, row.draftLoadId, true)
          }} 
        />
      },
    },
    {
      key: "temperature",
      name: "Temperature",
      cellClass: "cell",
      headerCellClass: "justify-content-start",
      minWidth: 110,
      resizable: true,
      formatter: ({ row }) => {
        return <InputCell 
        value={row?.draftLoad?.temperature?.value} 
        className={`temperature-${row.draftLoadId}`}
        onBlur={(value) => {
          _updateDraftInfoFn(`temperature-${row.draftLoadId}`, "temperature", value, row.draftLoadId, true)
        }} 
      /> 
      },
    },
    {
      key: "route",
      name: "Route",
      cellClass: "cell",
      headerCellClass: "justify-content-start",
      minWidth: 110,
      resizable: true,
      formatter: ({ row }) => {
        return <SelectCell 
          value={row?.draftLoad?.route?.value} 
          className={`route-${row?.draftLoadId}`}
          onSelect={(e) => {
            _updateDraftInfoFn(`route-${row?.draftLoadId}`, "route", e?.value, row.draftLoadId, true)
          }} 
          options={routeTypes}
        />
      },
    },
    {
      key: "scac",
      name: "SCAC",
      cellClass: "cell",
      headerCellClass: "justify-content-start",
      minWidth: 110,
      resizable: true,
      formatter: ({ row }) => {
        return <InputCell 
        value={row?.draftLoad?.scac?.value} 
        className={`scac-${row.draftLoadId}`}
        onBlur={(value) => {
          _updateDraftInfoFn(`scac-${row.draftLoadId}`, "scac", value, row.draftLoadId, true)
        }} 
      /> 
      },
    },
    ...(isTareWeightEnabled() ? [{
      key: "tareWeight",
      name: "Tare Weight",
      cellClass: "cell",
      headerCellClass: "justify-content-start",
      minWidth: 110,
     
      formatter: ({ row }) => {
        return <InputCell 
          value={row?.draftLoad?.tareWeight?.value} 
          className={`tareWeight-${row.draftLoadId}`}
          onBlur={(value) => {
            _updateDraftInfoFn(`tareWeight-${row.draftLoadId}`, "tareWeight", value, row.draftLoadId, true)
          }} 
        /> 
      },
    }] : []),
    {
      key: "hazmat",
      name: "Hazmat",
      cellClass: "cell",
      headerCellClass: "justify-content-start",
      minWidth: 65,
     
      formatter: ({ row }) => {
        return <CheckBoxCell 
          value={row?.draftLoad?.hazmat} 
          className={`hazmat-${row.draftLoadId}`}
          onSelect={(value) => {
            _updateDraftInfoFn(`hazmat-${row.draftLoadId}`, "hazmat", value, row.draftLoadId, true)
          }} 
        />
      },
    },
    {
      key: "overweight",
      name: "Overweight",
      cellClass: "cell",
      headerCellClass: "justify-content-start",
      minWidth: 75,
     
      formatter: ({ row }) => {
        return <CheckBoxCell
          value={row?.draftLoad?.overWeight} 
          className={`overWeight-${row.draftLoadId}`}
          onSelect={(value) => {
            _updateDraftInfoFn(`overWeight-${row.draftLoadId}`, "overWeight", value, row.draftLoadId, true)
          }} 
        />
      },
    },
    {
      key: "overHeight",
      name: "Overheight",
      cellClass: "cell",
      headerCellClass: "justify-content-start",
      minWidth: 75,
     
      formatter: ({ row }) => {
        return <CheckBoxCell
          value={row?.draftLoad?.overHeight} 
          className={`overHeight-${row.draftLoadId}`}
          onSelect={(value) => {
            _updateDraftInfoFn(`overHeight-${row.draftLoadId}`, "overHeight", value, row.draftLoadId, true)
          }} 
        />
      },
    },
    {
      key: "liquor",
      name: "Liquor",
      cellClass: "cell",
      headerCellClass: "justify-content-start",
      minWidth: 65,
     
      formatter: ({ row }) => {
        return <CheckBoxCell
          value={row?.draftLoad?.liquor} 
          className={`liquor-${row.draftLoadId}`}
          onSelect={(value) => {
            _updateDraftInfoFn(`liquor-${row.draftLoadId}`, "liquor", value, row.draftLoadId, true)
          }} 
        />
      },
    },
    {
      key: "hot",
      name: "Hot",
      cellClass: "cell",
      headerCellClass: "justify-content-start",
      minWidth: 65,
     
      formatter: ({ row }) => {
        return <CheckBoxCell
          value={row?.draftLoad?.hot} 
          className={`hot-${row.draftLoadId}`}
          onSelect={(value) => {
            _updateDraftInfoFn(`hot-${row.draftLoadId}`, "hot", value, row.draftLoadId, true)
          }} 
        />
      },
    },

  ]

  useEffect(() => {
    const keyUp = (e) => {
      const _currentIndex = (draftLoadEmails ?? [])?.findIndex( x => x?.draftLoadId === selectedDraftLoadInfo._id )
      const modal = document.querySelector(".review-tender-modal")
      const isModalInFocus = document.activeElement === modal
      if (isModalInFocus && e?.code === "ArrowDown" && (_currentIndex + 1) < draftLoadEmails?.length) {
        const _data = draftLoadEmails[_currentIndex + 1]
        setSelectedDraftLoadEmail(_data)
      }
      if (isModalInFocus && e?.code === "ArrowUp" && (_currentIndex - 1) >= 0) {
        const _data = draftLoadEmails[_currentIndex - 1]
        setSelectedDraftLoadEmail(_data)
      }
    }

    const debounceKeyUp = _.debounce(keyUp, 300)
    document.addEventListener("keyup", debounceKeyUp)
    return () => {
      document.removeEventListener("keyup", debounceKeyUp)
    }
  },[selectedDraftLoadInfo])

  const isSystemGenerated = (field)=>{
    return selectedDraftLoadInfo && selectedDraftLoadInfo[field] && selectedDraftLoadInfo[field]?.isSystemGenerated && !!selectedDraftLoadInfo[field]?.value;
  }

  const currentUser = JSON.parse(getStorage("loggedInUser"));
  const {customerProfileCustomersPermission, customerProfileShipperPermission, customerProfileConsigneePermission} = checkCustomerProfilePermission(currentUser)
  
  const getItemOnSearch = async (fnName, value) => {
    try {
      const params = {searchTerm: value, limit: 10}
      const res = await fnName(params)
      return res.data.data ?? [];
    } catch (error) {
      console.log("error", error)
    }
  }

  const getContainerTypeAndSizeValue = (listName)=>{
    const list = listName === "containerSize" ? containerSizeOptions : containerTypeOptions
    const data = list.find(x => x.label === selectedDraftLoadInfo?.[listName]?.value?.name);
    if(data){ 
      return { 
        label:selectedDraftLoadInfo?.[listName]?.value?.name,
        value:selectedDraftLoadInfo?.[listName]?.value?.name
      }
    }
    return ""
  }

  const isAvailableValue = (value, key) => {
    const containerType = containerTypeOptions.find(x => x.label === value)
    const containerSize = containerSizeOptions.find(x => x.label === value)
    return key === "containerSize" ? containerSize : containerType;
  }
  return (
    <Modal show={show} className="review-tender-modal" dialogClassName="modal-fullpage review-tender-modal" backdropClassName="z-1050">
     <Modal.Header className="shadow-2 px-15 py-10 z-2">
     <ReviewTenderInfo totalLoads={draftLoadEmails?.length}/>
        <div className="d-flex align-items-center">
          <div className="font-15 line-height-20 mr-40">  
            {selectedDraftLoadEmail?.fileName}
          </div>
          <div className="d-flex align-items-center gap-15">
            {showForTerminal() && <div>
              <div className="text-muted">Terminal</div>
              <div>{selectedDraftLoadInfo?.terminal?.value?.name ?? "-"}</div>
            </div>}
            <div>
              <div className="text-muted">Route Type</div>
              <span className="badge badge-sm badge-light">{getPreSetOrderStatus().find(e=>e?.value===selectedDraftLoadInfo?.routeType?.value)?.label??"-"}</span>
            </div>
            <div>
              <div className="text-muted">Upload Date</div>
              <div>
                {moment(selectedDraftLoadEmail?.createdAt)?.tz(getTimeZone({preferred: true}))?.format('MM/DD')} <IconDotSeperator className="mx-1" /> {moment(selectedDraftLoadEmail?.createdAt)?.tz(getTimeZone({preferred: true}))?.format('HH:mm A')}
              </div>
            </div>
            <div>
              <div className="text-muted">By User</div>
              <div className="d-flex align-items-center">
                <Avatar className="avatar-xs">{ displayName(selectedDraftLoadEmail?.uploadedBy?.name||"System")}</Avatar>
                <span className="ml-2">{selectedDraftLoadEmail?.uploadedBy?.name||"System"}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center gap-10">
          <button className="btn btn-outline-light" onClick={hide}>
            Close
          </button>
          <button className="btn btn-primary"
            disabled={isLoading || isButtonLoader}
            onClick={() => {
              confirm(
                "",
                "Are you done reviewing? Your load(s) will be created.",
                (value) => {
                  if (value) {
                    if (!selectedDraftLoadInfo?.isLoadCreated) {
                      createdLoad();
                    }
                    let eventProperties = {
                      source : isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_RECIPIENTS_MODAL : AMPLITUDE_EVENTS_SOURCE.GRID_RECIPIENTS_MODAL
                    }
                    amplitudeTrack(AMPLITUDE_EVENTS.REVIEW_TENDER_ACCEPT, eventProperties);
                  }
                },
                { isCreateLoad: true }
              );
            }}>
              {isButtonLoader && <span
                           className="spinner-border spinner-border-sm mr-2"
                           role="status"
                           aria-hidden="true"
                         ></span>}
            Create Load & Reply to Email
          </button>
        </div>
      </Modal.Header>
      <Modal.Body className="bg-gray-50 p-0 overflow-hidden">
      {isLoading ? <LoaderBar/>:
        <div className="d-flex gap-15 pb-100">
          <div style={{ width: 400 }}>
           <div className="p-10 shadow-2 bg-white position-sticky top-0 pl-15 z-1">
              <div className="font-14 font-weight-500 text-dark mb-1">{selectedDraftLoadInfoNumber + 1} of {draftLoadEmails?.length || 0} Loads</div>
              <div className="d-flex align-items-center">
              <div className="mr-10 input-wrapper" style={{width: '290px'}}>
                <Select
                  className="containerNo-dropdown"
                  placeholder="Container No"
                  options={containerNoOptions}
                  onChange={(val) => setSelectedDraftLoadEmail(val.data)}
                  value={{ 
                    label: selectedDraftLoadEmail?.draftLoad?.containerNo?.value || selectedDraftLoadEmail?.fileName,
                    value: selectedDraftLoadEmail?.draftLoad?._id,
                    data: selectedDraftLoadEmail
                  }}
                  styles={{
                    ...smallSelectStyle,
                    control: (base, state) => ({
                      ...smallSelectStyle.control(base, state),
                      paddingLeft: "20px",
                    }),
                  }}
                />
                <div className="input-icon left-0 right-inherit pt_6 tooltip-label">
                      <div className="avatar-circle avatar-xs bg-white text-dark font-10 font-weight-normal border-1 border-gray-100 line-height-22">
                        {selectedDraftLoadInfoNumber + 1}
                      </div>
                    </div>
              </div>
                  <div className="d-flex gap-5">
                    <div>
                      <button 
                        className="btn btn-outline-light wh-30px p-0 d-flex align-items-center justify-content-center"
                        onClick={()=>{
                          if(isDisableRef.current) return
                          if((selectedDraftLoadInfoNumber - 1) >= 0){
                            const _data = draftLoadEmails[selectedDraftLoadInfoNumber - 1]
                            setSelectedDraftLoadEmail(_data)
                          }
                        }}
                      >
                        <IconAngleArrowTop className="text-muted" />
                      </button>
                    </div>
                    <div>
                      <button 
                        className="btn btn-outline-light wh-30px p-0 d-flex align-items-center justify-content-center"
                        onClick={()=>{
                          if(isDisableRef.current) return
                          if((selectedDraftLoadInfoNumber + 1) < draftLoadEmails?.length){
                            const _data = draftLoadEmails[selectedDraftLoadInfoNumber + 1]
                            setSelectedDraftLoadEmail(_data)
                          }
                        }}
                      >
                        <IconAngleArrowDown />
                      </button>
                    </div>
                  </div>
              </div>
           </div>
          <div className="px-15 pt-10 bg-white w-100 overflow-y-auto" style={{height: "calc(100vh - 300px)", paddingBottom: "200px"}}>
            {/* {selectedDraftLoadInfo?.containerNo?.value && <div className="font-16 line-height-20 mb-10">{selectedDraftLoadInfo?.containerNo?.value}</div>} */}
            <div className="d-flex flex-column gap-2 mb-1">
              <div>
                <label><span className="text-danger">* </span>Load Type</label>
                <Select
                  className="type-of-load"
                  styles={smallSelectStyle}
                  placeholder="load type"
                  options={loadTypes}
                  value={selectedDraftLoadInfo?.type_of_load ? {
                    "value": selectedDraftLoadInfo?.type_of_load,
                    "label": selectedDraftLoadInfo?.type_of_load
                  } : {}}
                  onChange={(val) => _updateDraftInfoFn('type-of-load', "type_of_load", val.value)}
                />
              </div>
            </div>
            <div className="d-flex flex-column gap-2 mb-1">
              <div>
                <label><span className="text-danger">* </span>Route Type</label>
                <Select
                  className="routeType"
                  styles={smallSelectStyle}
                  placeholder="route type"
                  options={getPreSetOrderStatus()}
                  value={selectedDraftLoadInfo?.routeType?.value===""?getPreSetOrderStatus().find(e=>e?.value===""):getPreSetOrderStatus().find(e=>e?.value===selectedDraftLoadInfo?.routeType?.value)??{}}
                  onChange={(val) => _updateDraftInfoFn('routeType', "routeType", val.value)}
                />
              </div>
            </div>

            <div className="d-flex flex-column gap-8">
              <CompareAsyncSelectBox
                className="customer"
                isRequired={true}
                label="Customer"
                textValue={selectedDraftLoadInfo?.caller?.text ?? ""}
                placeholder="Select Customer"
                defaultOptions={callersOptions}
                loadOptions={getCallers}
                value={selectedDraftLoadInfo?.caller?.value?.name ?{label:selectedDraftLoadInfo?.caller?.value?.name,value:selectedDraftLoadInfo?.caller?.value?.name}:{}}
                onChange={(value) => _updateDraftInfoFn("customer", "caller", { id: value.value, name: value.label })}
                isAddCreateLabel={customerProfileCustomersPermission}
                addType="caller"
                createOptionPosition="last"
                formatCreateLabel="Add New Customer"
              />
              {selectedDraftLoadInfo?.shipper?.length > 0 ? selectedDraftLoadInfo?.shipper.map((e, index) => (
                <CompareAsyncSelectBox
                  className={`shipper-${index}`}
                  key={index}
                  isRequired={true}
                  label="Port"
                  placeholder={`Select ${selectedDraftLoadInfo.type_of_load === "ROAD" ? "Shipper" : "Port"}`}
                  defaultOptions={shipperOptions}
                  loadOptions={isTerminalTrackingEnabled() ? getTMSshipperAsyncSearchForSelectWithStatus : getTMSshipperAsyncSearch}
                  textValue={e?.name ?? ""}
                  value={e?.value?.name ? { label: e?.value?.name + (e?.value?.address ? ' | ' + e?.value?.address : ""), value: e?.value?.name, allInfos: e?.value } : null}
                  onChange={(i) => _updateDraftInfoFn(`shipper-${index}`, `shipper`, [{ _id: e._id, value: { id: i.value, name: i.label } }])}
                  isAddCreateLabel={customerProfileShipperPermission}
                  addType="shipper"
                  createOptionPosition="first"
                  formatCreateLabel={
                    `Add New ${
                      selectedDraftLoadInfo.type_of_load === "ROAD"
                        ? "Shipper"
                        : "Port"
                    }`
                  }
                />
              )) :
                <CompareAsyncSelectBox
                  className="shipper"
                  isRequired={true}
                  label="Port"
                  placeholder={`Select ${selectedDraftLoadInfo.type_of_load === "ROAD" ? "Shipper" : "Port"}`}
                  defaultOptions={shipperOptions}
                  loadOptions={isTerminalTrackingEnabled() ? getTMSshipperAsyncSearchForSelectWithStatus : getTMSshipperAsyncSearch}
                  textValue={""}
                  onChange={(i) => _updateDraftInfoFn("shipper", `shipper`, [{ value: { id: i.value, name: i.label } }])}
                  isAddCreateLabel={customerProfileShipperPermission}
                  addType="shipper"
                  createOptionPosition="first"
                  formatCreateLabel={
                    `Add New ${
                      selectedDraftLoadInfo.type_of_load === "ROAD"
                        ? "Shipper"
                        : "Port"
                    }`
                  }
                />
              }
              { (!NO_DELIVERY_TEMPLATES.includes(selectedDraftLoadInfo?.routeType?.value) ||  selectedDraftLoadInfo?.type_of_load=== "ROAD") && (selectedDraftLoadInfo?.consignee?.length > 0 ? selectedDraftLoadInfo?.consignee.map((e, index) => (
                <CompareAsyncSelectBox
                  className={`consignee-${index}`}
                  isRequired={true}
                  label="Consignee"
                  placeholder={`Select Consignee`}
                  defaultOptions={consigneesOptions}
                  loadOptions={isTerminalTrackingEnabled() ?  getTMSconsigneeAsyncSearchForSelectWithStatus : getTMSconsigneeAsyncSearch}
                  textValue={e?.name ?? ""}
                  value={e?.value?.name ?{label: e?.value?.name + (e?.value?.address ? ' | ' + e?.value?.address : ""),value:e?.value?.name, allInfos: e?.value}: null}
                  onChange={(i) => _updateDraftInfoFn(`consignee-${index}`, `consignee`, [{ _id: e._id, value: { id: i.value, name: i.label } }])}
                  isAddCreateLabel={customerProfileConsigneePermission}
                  addType="consignee"
                  createOptionPosition="first"
                  formatCreateLabel="Add New Consignee"
                />
              )) :
                <CompareAsyncSelectBox
                  className="consignee"
                  isRequired={true}
                  label="Consignee"
                  placeholder={`Select Consignee`}
                  defaultOptions={consigneesOptions}
                  loadOptions={isTerminalTrackingEnabled() ? getTMSconsigneeAsyncSearchForSelectWithStatus : getTMSconsigneeAsyncSearch}
                  textValue={""}
                  onChange={(i) => _updateDraftInfoFn("consignee", `consignee`, [{ value: { id: i.value, name: i.label } }])}
                  isAddCreateLabel={customerProfileConsigneePermission}
                  addType="consignee"
                  createOptionPosition="first"
                  formatCreateLabel="Add New Consignee"
                />
              )}

                {selectedDraftLoadInfo?.type_of_load !== "ROAD" && <CompareAsyncSelectBox
                  className="emptyOrigin"
                  label="Return location"
                  placeholder={`Select Return Location`}
                  defaultOptions={returnLocationOptions}
                  loadOptions={isTerminalTrackingEnabled() ? getTMSshipperAsyncSearchForSelectWithStatus : getTMSshipperAsyncSearch}
                  textValue={selectedDraftLoadInfo?.emptyOrigin?.text?.name ?? ""}
                  value={selectedDraftLoadInfo?.emptyOrigin?.value?.name ?{label:selectedDraftLoadInfo?.emptyOrigin?.value?.name,value:selectedDraftLoadInfo?.emptyOrigin?.value?.name, allInfos: selectedDraftLoadInfo?.emptyOrigin?.value} : null}
                  onChange={(i) => _updateDraftInfoFn("emptyOrigin", `emptyOrigin`, { id: i.value, name: i.label })}
                  isAddCreateLabel={customerProfileConsigneePermission || customerProfileCustomersPermission || customerProfileShipperPermission}
                  addType="caller"
                  createOptionPosition="last"
                  formatCreateLabel="Add New Profile"
                />}

              {showForTerminal() && (
                <CompareSelectBox
                  className="terminal"
                  isRequired={true}
                  label="Terminal"
                  options={terminalsOptions}
                  textValue={selectedDraftLoadInfo?.terminal?.text ?? ""}
                  value={selectedDraftLoadInfo?.terminal?.value?.name ?{label:selectedDraftLoadInfo?.terminal?.value?.name,value:selectedDraftLoadInfo?.terminal?.value?.name}:{}}
                  onChange={(value) => _updateDraftInfoFn("terminal", `terminal`, { id: value.value, name: value.label })}
                />
              )}
               <hr className="mx-15" />
                <div className="d-flex justify-content-between align-items-center">
                  <div className="text-muted font-14 font-medium">Freight Description</div>
                  <button className="btn btn-primary" onClick={OpenModal}>Review Freight Description</button>
                </div>

                {isOpenModal && <ItemModel 
                  data={_.cloneDeep(selectedDraftLoadInfo?.items)}
                  isOpenModal={isOpenModal} 
                  closeModal={closeModal} 
                  onSave={async(data) => { 
                    const res = await _updateDraftInfoFn(null, `items`, data) 
                    return res;
                  }} 
                />}
            
              <hr className="mx-15" />
              
              <div className="text-muted font-14 font-medium line-height-20">Dates</div>
              {selectedDraftLoadInfo.type_of_load === "EXPORT" &&
                <ComapareDataBox
                  className="loadtime"
                  label="Load"
                  index={1}
                  textValue={selectedDraftLoadInfo?.loadtime?.text ?? ""}
                  value={selectedDraftLoadInfo?.loadtime?.value ?? ""}
                  saveDate={(val) => _updateDraftInfoFn("loadtime", "loadtime", val)}
                />}
              {selectedDraftLoadInfo.type_of_load === "EXPORT" &&
                <ComapareDataBox
                  className="cutOff"
                  label="Cut Off"
                  index={2}
                  textValue={selectedDraftLoadInfo?.cutOff?.text ?? ""}
                  value={selectedDraftLoadInfo?.cutOff?.value ?? ""}
                  saveDate={(val) => _updateDraftInfoFn("cutOff", "cutOff", val)}
                />}
              {selectedDraftLoadInfo?.type_of_load === "EXPORT" &&
                <ComapareDataBox
                  className="containerAvailableDay"
                  label="ERD"
                  index={3}
                  textValue={selectedDraftLoadInfo?.containerAvailableDay?.text ?? ""}
                  value={selectedDraftLoadInfo?.containerAvailableDay?.value ?? ""}
                  saveDate={(val) => _updateDraftInfoFn("containerAvailableDay", "containerAvailableDay", val)}
                />}
              {selectedDraftLoadInfo?.type_of_load === "EXPORT" &&
                <ComapareDataBox
                  className="returnDate"
                  label="Date Returned"
                  index={4}
                  textValue={selectedDraftLoadInfo?.returnDate?.text ?? ""}
                  value={selectedDraftLoadInfo?.returnDate?.value ?? ""}
                  saveDate={(val) => _updateDraftInfoFn("returnDate", "returnDate", val)}
                />
              }

              {selectedDraftLoadInfo?.type_of_load === "IMPORT" &&
                <ComapareDataBox
                  className={`vesselETADate ${isSystemGenerated('vesselETADate') ? "bg-brand-100" :""}`}
                  label="Vessel ETA"
                  index={5}
                  textValue={selectedDraftLoadInfo?.vesselETADate?.text ?? ""}
                  value={selectedDraftLoadInfo?.vesselETADate?.value ?? ""}
                  saveDate={(val) => _updateDraftInfoFn("vesselETADate", "vesselETADate", val)}
                />
              }

              {selectedDraftLoadInfo.type_of_load === "IMPORT" &&
                <ComapareDataBox
                  className={`lastFreeDay ${isSystemGenerated('lastFreeDay') ? "bg-brand-100" :""}`}
                  index={6}
                  label="Last Free Day"
                  textValue={selectedDraftLoadInfo?.lastFreeDay?.text ?? ""}
                  value={selectedDraftLoadInfo?.lastFreeDay?.value ?? ""}
                  saveDate={(val) => _updateDraftInfoFn("lastFreeDay", "lastFreeDay", val)}
                />
              }

              {selectedDraftLoadInfo.type_of_load === "IMPORT" &&
                <ComapareDataBox
                  className={`dischargedDate ${isSystemGenerated('dischargedDate') ? "bg-brand-100" :""}`}
                  label="Discharged Date"
                  index={7}
                  textValue={selectedDraftLoadInfo?.dischargedDate?.text ?? ""}
                  value={selectedDraftLoadInfo?.dischargedDate?.value ?? ""}
                  saveDate={(val) => _updateDraftInfoFn("dischargedDate", "dischargedDate", val)}
                />
              }

              {selectedDraftLoadInfo.type_of_load === "IMPORT" &&
                <ComapareDataBox
                  className="emptyDay"
                  label="Empty Date"
                  index={8}
                  textValue={selectedDraftLoadInfo?.emptyDay?.text ?? ""}
                  value={selectedDraftLoadInfo?.emptyDay?.value ?? ""}
                  saveDate={(val) => _updateDraftInfoFn("emptyDay", "emptyDay", val)}
                />
              }

              {["IMPORT", "EXPORT"].includes(selectedDraftLoadInfo.type_of_load) &&
                <ComapareDataBox
                  className="freeReturnDate"
                  label={selectedDraftLoadInfo.type_of_load === "IMPORT" ? "Return Day" : "Per Diem LFD"}
                  index={9}
                  textValue={selectedDraftLoadInfo?.freeReturnDate?.text ?? ""}
                  value={selectedDraftLoadInfo?.freeReturnDate?.value ?? ""}
                  isShowTime={false}
                  saveDate={(val) => _updateDraftInfoFn("freeReturnDate", "freeReturnDate", val)}
                />
              }

              <ComapareDataBox
                className={`outgateDate ${isSystemGenerated('outgateDate') ? "bg-brand-100" :""}`}
                label="Outgate Date"
                index={10}
                textValue={selectedDraftLoadInfo?.outgateDate?.text ?? ""}
                value={selectedDraftLoadInfo?.outgateDate?.value ?? ""}
                saveDate={(val) => _updateDraftInfoFn("outgateDate", "outgateDate", val)}
              />

              <ComapareDataBox
                className={`ingateDate ${isSystemGenerated('ingateDate') ? "bg-brand-100" :""}`}
                label="Ingate Date"
                index={11}
                textValue={selectedDraftLoadInfo?.ingateDate?.text ?? ""}
                value={selectedDraftLoadInfo?.ingateDate?.value ?? ""}
                saveDate={(val) => _updateDraftInfoFn("ingateDate", "ingateDate", val)}
              />

              <ComapareDataBox
              className="readyToReturnDate"
                label="Ready To Return Date"
                index={12}
                textValue={selectedDraftLoadInfo?.readyToReturnDate?.text ?? ""}
                value={selectedDraftLoadInfo?.readyToReturnDate?.value ?? ""}
                saveDate={(val) => _updateDraftInfoFn("readyToReturnDate", "readyToReturnDate", val)}
              />

            <hr className="mx-15" />
            <div className="text-muted font-14 font-medium line-height-20 my-10">Reference Numbers</div>
                <div className="d-flex flex-column gap-8">
                <CompareTextBox
                  className={`callerbillLandingNo ${isSystemGenerated('callerbillLandingNo') ? "bg-brand-100" :""}`}
                  label="Master Bill of Landing"
                  placeholder="e.g. 0000000"
                  textValue={selectedDraftLoadInfo?.callerbillLandingNo?.text ?? ""}
                  value={selectedDraftLoadInfo?.callerbillLandingNo?.value ?? ""}
                  SaveVal={(val => _updateDraftInfoFn("callerbillLandingNo", "callerbillLandingNo", val))}
                />

                <CompareTextBox
                  className="doNo"
                  label="House Bill of Landing"
                  placeholder="e.g. 0000000"
                  textValue={selectedDraftLoadInfo?.doNo?.text ?? ""}
                  value={selectedDraftLoadInfo?.doNo?.value ?? ""}
                  SaveVal={(val => _updateDraftInfoFn("doNo", "doNo", val))}
                />

                <CompareTextBox
                  className={`sealNo ${isSystemGenerated('sealNo') ? "bg-brand-100" :""}`}
                  label="Seal #"
                  placeholder="e.g. 0000000"
                  textValue={selectedDraftLoadInfo?.sealNo?.text ?? ""}
                  value={selectedDraftLoadInfo?.sealNo?.value ?? ""}
                  SaveVal={(val => _updateDraftInfoFn("sealNo", "sealNo", val))}
                />

                <CompareTextBox
                  className="secondaryReferenceNo"
                  label="Reference #"
                  placeholder="e.g. 0000000"
                  textValue={selectedDraftLoadInfo?.secondaryReferenceNo?.text ?? ""}
                  value={selectedDraftLoadInfo?.secondaryReferenceNo?.value ?? ""}
                  SaveVal={(val => _updateDraftInfoFn("secondaryReferenceNo", "secondaryReferenceNo", val))}
                />
                <CompareTextBox
                  className={`deliveryOrderNo ${isSystemGenerated('deliveryOrderNo') ? "bg-brand-100" :""}`}
                  label="Vessel Name"
                  placeholder="e.g. 0000000"
                  textValue={selectedDraftLoadInfo?.deliveryOrderNo?.text ?? ""}
                  value={selectedDraftLoadInfo?.deliveryOrderNo?.value ?? ""}
                  SaveVal={(val => _updateDraftInfoFn("deliveryOrderNo", "deliveryOrderNo", val))}
                />

                <CompareTextBox
                  className={`releaseNo ${isSystemGenerated('releaseNo') ? "bg-brand-100" :""}`}
                  label="Voyage #"
                  placeholder="e.g. 0000000"
                  textValue={selectedDraftLoadInfo?.releaseNo?.text ?? ""}
                  value={selectedDraftLoadInfo?.releaseNo?.value ?? ""}
                  SaveVal={(val => _updateDraftInfoFn("releaseNo", "releaseNo", val))}
                />

                <CompareTextBox
                  className="purchaseOrderNo"
                  label="Purchase Order #"
                  placeholder="e.g. 0000000"
                  textValue={selectedDraftLoadInfo?.purchaseOrderNo?.text ?? ""}
                  value={selectedDraftLoadInfo?.purchaseOrderNo?.value ?? ""}
                  SaveVal={(val => _updateDraftInfoFn("purchaseOrderNo", "purchaseOrderNo", val))}
                />

                <CompareTextBox
                  className="shipmentNo"
                  label="Shipment #"
                  placeholder="e.g. 0000000"
                  textValue={selectedDraftLoadInfo?.shipmentNo?.text ?? ""}
                  value={selectedDraftLoadInfo?.shipmentNo?.value ?? ""}
                  SaveVal={(val => _updateDraftInfoFn("shipmentNo", "shipmentNo", val))}
                />

                <CompareTextBox
                  className="callerPONo"
                  label="Pick Up #"
                  placeholder="e.g. 0000000"
                  textValue={selectedDraftLoadInfo?.callerPONo?.text ?? ""}
                  value={selectedDraftLoadInfo?.callerPONo?.value ?? ""}
                  SaveVal={(val => _updateDraftInfoFn("callerPONo", "callerPONo", val))}
                />

                <CompareTextBox
                  className="appointmentNo"
                  label="Appointment #"
                  placeholder="e.g. 0000000"
                  textValue={selectedDraftLoadInfo?.appointmentNo?.text ?? ""}
                  value={selectedDraftLoadInfo?.appointmentNo?.value ?? ""}
                  SaveVal={(val => _updateDraftInfoFn("appointmentNo", "appointmentNo", val))}
                />

                <CompareTextBox
                  className="returnNo"
                  label="Return #"
                  placeholder="e.g. 0000000"
                  textValue={selectedDraftLoadInfo?.returnNo?.text ?? ""}
                  value={selectedDraftLoadInfo?.returnNo?.value ?? ""}
                  SaveVal={(val => _updateDraftInfoFn("returnNo", "returnNo", val))}
                />
                <CompareTextBox
                  className="reservationNo"
                  label="Reservation #"
                  placeholder="e.g. 0000000"
                  textValue={selectedDraftLoadInfo?.reservationNo?.text ?? ""}
                  value={selectedDraftLoadInfo?.reservationNo?.value ?? ""}
                  SaveVal={(val => _updateDraftInfoFn("reservationNo", "reservationNo", val))}
                />
                {selectedDraftLoadInfo.type_of_load === "EXPORT" && 
                  <CompareTextBox
                    className="bookingNo"
                    label="Booking #"
                    placeholder="e.g. 0000000"
                    textValue={selectedDraftLoadInfo?.bookingNo?.text ?? ""}
                    value={selectedDraftLoadInfo?.bookingNo?.value ?? ""}
                    SaveVal={(val => _updateDraftInfoFn("bookingNo", "bookingNo", val))}
                  />
                }
                </div>

              <hr className="mx-15" />

              <div className="text-muted font-14 font-medium line-height-20">Equipment</div>

              <CompareTextBox
                className="containerNo"
                label="Container #"
                placeholder="e.g. 0000000"
                textValue={selectedDraftLoadInfo?.containerNo?.text ?? ""}
                value={selectedDraftLoadInfo?.containerNo?.value ?? ""}
                SaveVal={(val=>_updateDraftInfoFn("containerNo", "containerNo", val))}

              />
              <CompareEquipmentAsyncSelectBox
               className={`containerSize ${isSystemGenerated('containerSize') ? "system-generated" :""}`}
               isRequired={true}
               isClearable={true}
               label="Container Size"
               placeholder="Size"
               defaultOptions={containerSizeOptions}
               loadOptions={getContainerSize}
               textValue={selectedDraftLoadInfo?.containerSize?.text ?? ""}
               value={getContainerTypeAndSizeValue("containerSize")}
               onChange={(value) => _updateDraftInfoFn("containerSize", `containerSize`,  value?.label? { id: value.value, name: value.label }:"")}
             
              />
              <CompareEquipmentAsyncSelectBox
               className={`containerType ${isSystemGenerated('containerType') ? "system-generated" :""}`}
               isRequired={true}
               isClearable={true}
               label="Container Type"
               placeholder="Type"
               defaultOptions={containerTypeOptions}
               loadOptions={getContainerType}
               textValue={selectedDraftLoadInfo?.containerType?.text ?? ""}
               value={getContainerTypeAndSizeValue("containerType")}
               onChange={(value) => _updateDraftInfoFn("containerType", `containerType`, value?.label? { id:  value.value, name: value.label }:"")}
              />
              <CompareEquipmentAsyncSelectBox
               className={`containerOwner ${isSystemGenerated('containerOwner') ? "system-generated" :""}`}
               isRequired={true}
               isClearable={true}
               label="Container Owner"
               placeholder="Owner"
               defaultOptions={containerOwnerOptions}
               loadOptions={getContainerOwner}
               textValue={selectedDraftLoadInfo?.containerOwner?.text ?? ""}
               value={selectedDraftLoadInfo?.containerOwner?.value?.name ?{label:selectedDraftLoadInfo?.containerOwner?.value?.name,value:selectedDraftLoadInfo?.containerOwner?.value?.name}:""}
               onChange={(value) => _updateDraftInfoFn("containerOwner", `containerOwner`, value?.label? { id: value.value, name: value.label }:"")}
             
              />
              <CompareEquipmentAsyncSelectBox
               className="chassisNo"
               isRequired={true}
               isClearable={true}
               label="Chassis #"
               defaultOptions={chassisOptions}
               loadOptions={getChassis}
               textValue={selectedDraftLoadInfo?.chassisNo?.text ?? ""}
               value={selectedDraftLoadInfo?.chassisNo?.value?.name ?{label:selectedDraftLoadInfo?.chassisNo?.value?.name,value:selectedDraftLoadInfo?.chassisNo?.value?.name}:""}
               onChange={(value) => _updateDraftInfoFn("chassisNo", `chassisNo`, value?.label? { id: value.value, name: value.label }:"")}
             
              />
              <CompareEquipmentAsyncSelectBox
               className="chassisSize"
               isRequired={true}
               isClearable={true}
               label="Chassis Size"
               placeholder="Size"
               defaultOptions={chassisSizeOptions}
               loadOptions={(value)=> getItemOnSearch(getChassisSize, value)}
               textValue={selectedDraftLoadInfo?.chassisSize?.text ?? ""}
               value={selectedDraftLoadInfo?.chassisSize?.value?.name ?{label:selectedDraftLoadInfo?.chassisSize?.value?.name,value:selectedDraftLoadInfo?.chassisSize?.value?.name}:""}
               onChange={(value) => _updateDraftInfoFn("chassisSize", `chassisSize`, value?.label? { id: value.value, name: value.label }:"")}
             
              />
              <CompareEquipmentAsyncSelectBox
               className="chassisType"
               isRequired={true}
               isClearable={true}
               label="Chassis Type"
               placeholder="Type"
               defaultOptions={chassisTypeOptions}
               loadOptions={(value)=> getItemOnSearch(getChassisType, value)}
               textValue={selectedDraftLoadInfo?.chassisType?.text ?? ""}
               value={selectedDraftLoadInfo?.chassisType?.value?.name ?{label:selectedDraftLoadInfo?.chassisType?.value?.name,value:selectedDraftLoadInfo?.chassisType?.value?.name}:""}
               onChange={(value) => _updateDraftInfoFn("chassisType", `chassisType`, value?.label?{ id: value.value, name: value.label }:"")}
             
              />
              <CompareEquipmentAsyncSelectBox
              className="chassisOwner"
               isRequired={true}
               isClearable={true}
               label="Chassis Owner"
               placeholder="Owner"
               defaultOptions={chassisOwnerOptions}
               textValue={selectedDraftLoadInfo?.chassisOwner?.text ?? ""}
               value={selectedDraftLoadInfo?.chassisOwner?.value?.name ?{label:selectedDraftLoadInfo?.chassisOwner?.value?.name,value:selectedDraftLoadInfo?.chassisOwner?.value?.name}:""}
               onChange={(value) => _updateDraftInfoFn("chassisOwner", `chassisOwner`, value?.label?{ id: value.value, name: value.label }:"")}
             
              />
              <CompareTextBox
                className="genset"
                label="Genset #"
                placeholder="e.g. 0000000"
                textValue={selectedDraftLoadInfo?.genset?.text ?? ""}
                value={selectedDraftLoadInfo?.genset?.value ?? ""}
                SaveVal={(val=>_updateDraftInfoFn("genset", "genset", val))}
              />

              <CompareTextBox
                className="temperature"
                label="Temperature"
                placeholder="e.g. 0000000"
                textValue={selectedDraftLoadInfo?.temperature?.text ?? ""}
                value={selectedDraftLoadInfo?.temperature?.value ?? ""}
                SaveVal={(val=>_updateDraftInfoFn("temperature", "temperature", val))}
              />
              <CompareEquipmentAsyncSelectBox
                  className="route"
                  isRequired={true}
                  isClearable={true}
                  label="Route"
                  defaultOptions={routeTypes}
                  textValue={selectedDraftLoadInfo?.route?.text ?? ""}
                  value={selectedDraftLoadInfo?.route?.value ?{label:selectedDraftLoadInfo?.route?.value,value:selectedDraftLoadInfo?.route?.value}:""}
                  onChange={(value) => _updateDraftInfoFn("route", `route`, value?.label??"")}
              />

              <CompareTextBox
               className="scac"
                label="SCAC"
                placeholder="e.g. 0000000"
                textValue={selectedDraftLoadInfo?.scac?.text ?? ""}
                value={selectedDraftLoadInfo?.scac?.value ?? ""}
                SaveVal={(val=>_updateDraftInfoFn("scac", "scac", val))}
              />
             <div class="form-group">
              <ul>
                <li className="d-flex ml-1">
                  <input
                    type="checkbox"
                    class={`form-check-input rounded-3 border-secondary hazmat`}
                    id="hazmat"
                    value="1"
                    checked={selectedDraftLoadInfo?.hazmat}
                    onChange={(e) => {
                      _updateDraftInfoFn("hazmat", "hazmat", e.target.checked)
                    }}
                  />
                  <label class="m-0 pl-10" for="hazmat" id="hazmat-label">
                    Hazmat
                  </label>
                </li>
                <li className="d-flex mt-10 ml-1">
                  <input
                    type="checkbox"
                    class={`form-check-input rounded-3 border-secondary overWeight`}
                    id="overWeight"
                    value="2"
                    checked={selectedDraftLoadInfo?.overWeight}
                    onChange={(e) => {
                      _updateDraftInfoFn("overWeight", "overWeight", e.target.checked)
                    }}
                  />
                  <label class="m-0 pl-10" for="hazmat" id="hazmat-label">
                    Overweight
                  </label>
                </li>
                <li className="d-flex mt-10 ml-1">
                  <input
                    type="checkbox"
                    class={`form-check-input rounded-3 border-secondary overHeight`}
                    id="overHeight"
                    value="2"
                    checked={selectedDraftLoadInfo?.overHeight}
                    onChange={(e) => {
                      _updateDraftInfoFn("overHeight", "overHeight", e.target.checked)
                    }}
                  />
                  <label class="m-0 pl-10" for="hazmat" id="hazmat-label">
                    Overheight
                  </label>
                </li>
                <li className="d-flex mt-10 ml-1">
                  <input
                    type="checkbox"
                    class={`form-check-input rounded-3 border-secondary liquor`}
                    id="liquor"
                    value="2"
                    checked={selectedDraftLoadInfo?.liquor}
                    onChange={(e) => {
                      _updateDraftInfoFn("liquor", "liquor", e.target.checked)
                    }}
                  />
                  <label className="m-0 pl-10" for="liquor" id="liquor-label">
                    {showForCarrier() ? "Liquid" : "Liquor"}
                  </label>
                </li>
                <li className="d-flex mt-10 ml-1">
                  <input
                    type="checkbox"
                    class={`form-check-input rounded-3 border-secondary hot`}
                    id="hot"
                    value="2"
                    checked={selectedDraftLoadInfo?.hot}
                    onChange={(e) => {
                      _updateDraftInfoFn("hot", "hot", e.target.checked)
                    }}
                  />
                  <label className="m-0 pl-10" for="hot" id="hot-label">
                    Hot
                  </label>
                </li>
                <li className="d-flex mt-10 ml-1">
                  <input
                    type="checkbox"
                    class={`form-check-input rounded-3 border-secondary isGenset`}
                    id="isGenset"
                    value="1"
                    checked={selectedDraftLoadInfo?.isGenset}
                    onChange={(e) => {
                      _updateDraftInfoFn("isGenset", "isGenset", e.target.checked)
                    }}
                  />
                  <label class="m-0 pl-10" for="isGenset" id="isGenset-label">
                    Genset
                  </label>
                </li>
              </ul>
            </div>
              <hr className="mx-15" />
              <div className="text-muted font-14 font-medium line-height-20">Notes</div>
              <div class="form-group">
                <label>Load</label>
                <textarea
                  className={`form-control officeNote`}
                  value={notes?.value ?? ""}
                  placeholder="Notes for Office"
                  name="officeNote"
                  onChange={(e)=>setNotes((prev=>({...prev,value:e.target.value})))}
                  rows={4}
                  onBlur={(e) => {
                    _updateDraftInfoFn("officeNote", "officeNote", notes?.value)
                  }}
                ></textarea>
              </div>
             
            </div>
            
          </div>
          </div>
          {(selectedDraftLoadEmail.attachmentUrl.includes('.xlsx') || selectedDraftLoadEmail.attachmentUrl.includes('.xls')) ? 
            <ExcelViewer attachmentUrl={selectedDraftLoadEmail.attachmentUrl} />
            :<div className="p-10 ocr-doc w-100 overflow-y-auto" style={{height: "calc(100vh - 396px)" }}>
              <embed
                id="pdf-embed"
                width="100%"
                type="application/pdf"
                style={{ height: "calc(100vh - 70px)" }}
                src={`${selectedDraftLoadEmail.attachmentUrl}#toolbar=1`}
              />
            </div>
          }
        </div>
      }
      </Modal.Body>
      <Modal.Footer className="w-100 justify-content-start p-10">
        <div className="w-100">
          <div className="d-flex align-items-center justify-content-between">          
            <div className="d-flex align-items-center gap-10 mb-10">
            <ul className="nav nav-compact border-0" role="tablist">
              <li className="nav-item" onClick={()=>setActiveTab("CONTAINER")}>
                <a className={`nav-link ${activeTab === "CONTAINER" ? "active" : ""}`}>
                  Container
                </a>
              </li>
              <li className="nav-item" onClick={()=>setActiveTab("EQUIPMENT")}>
                <a className={`nav-link ${activeTab === "EQUIPMENT" ? "active" : ""}`}>
                  Equipment
                </a>
              </li>
            </ul>

          </div>
            <div className="ml-auto d-flex align-items-center">
              <div className="font-10 font-weight-normal text-muted line-height-14">Recognized by PORTPRO AI</div>
              <IconAI className="text-defaultbrand-500 ml-10" />
            </div>
          </div>
          {<DataGrid
            columns={activeTab === "CONTAINER" ? containerColumns : equipmentColumns}
            rows={draftLoadEmails}
            rowHeight={30}
            headerRowHeight={26}
            className={"fill-grid border-gray-100 border-1 rounded-5 rdg-light mb-40"}
            style={{ height: `calc(20vh)` }}
          />}
        </div>
        <div className="p-10 position-absolute left-0 right-0 bottom-0 m-0 bg-white shadow-6">
          {!showEmailReply && <div className='border-gray-200 border-1 rounded-4 w-100'>
            <div className="app-search">
              <input
                type="text"
                className="input-search-left form-control pl-10"
                placeholder="Click Here To View Email Reply"
                onFocus={() => {
                  setShowEmailReply(true)
                }}
              />
            </div>
          </div>}
          {showEmailReply && 
            <button 
              className="btn btn-outline-light wh-30px p-0 d-flex align-items-center justify-content-center"
              onClick={()=> setShowEmailReply(false)}
            >
              <IconAngleArrowDown />
            </button>
          }
          { showEmailReply && 
            <>
              <EmailComponent
                showEmailTO={true}
                showEmailCC={true}
                showEmailBCC={false} 
                showEmailSubject={true}
                emailTo={emailTo}
                setEmailTo={setEmailTo}
                emailCc={emailCC}
                setEmailCc={setEmailCC}
                emailSubject={emailSubject}
                setEmailSubject={setEmailSubject}
                emailBody={emailBody}
                setEmailBody={setEmailBody}
                uploadedDocuments={uploadedDocuments}
                setUploadedDocuments={setUploadedDocuments}
              />
            </>
          }
        </div>
       </Modal.Footer>
    </Modal>
  );
};

export default NewOrderReviewModal;
