import DOMPurify from "dompurify";

const decodeText = (text) => {
  const parser = new DOMParser();
  return parser.parseFromString(text, "text/html")?.body?.textContent || text;
};

const SafeTextDecode = ({ text }) => {
  if (!text) {
    return <span>(No Content)</span>; // Provide a fallback UI
  }

  // Decode the HTML entities
  const decodedText = decodeText(text);

  // Sanitize the decoded text to remove any harmful content
  const sanitizedText = DOMPurify.sanitize(decodedText);

  return <span>{sanitizedText}</span>;
};

export default SafeTextDecode;
