import _ from "lodash"
import { useContext, useEffect, useMemo, useState, useCallback } from "react"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import { IconAttachment, IconTimes } from "Components/Common/Icons"
import EmailEditor from "Components/Common/NewEmailEditor/EmailEditor"
import { Modal } from "react-bootstrap"
import { getStorage, toastr } from "services"
import { createDraftEmail, sendMail, updateDraftEmail } from "../actionCreator"
import { EMAIL_CONTEXT, EMAIL_MSG, EMAIL_PROVIDER, EMAIL_TABS } from "../constant"
import { bytesToKB, convertEmailTos, encryptEmbeddedEmailData, findClass, getSignatureBody } from "../helper"
import { useDeleteDraftEmail } from "../hooks"
import DocumentUpload from "./EmailDetails/DocumentUpload"
import ReciptionsInput from "./EmailDetails/ReciptionsInput"

import ReactTooltip from "react-tooltip"
import { amplitudeTrack } from "../../../../services"
import { getAttachmentsByThread } from "../actionCreator"
import { AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE } from "../constant"
import { v4 as uuidv4 } from 'uuid';
const Buffer = require('buffer/').Buffer

function CreateEmailModal(props) {
  const {
    show,
    hide,
    draftEmail: draft = {},
    allEmailSignature= [],
    getAllEmailSignatures
  } = props
  
  const [emailTo, setEmailTo] = useState([])
  const [emailCc, setEmailCc] = useState([])
  const [emailBcc, setEmailBcc] = useState([])
  const [emailSubject, setEmailSubject] = useState("")
  const [editorState, setEditorState] = useState("")
  const [inpBody, setInpBody] = useState("")
  const [isApiCall, setIsApiCall] = useState(false)
  const [isDraftApiCall, setIsDraftApiCall] = useState(false);
  const [draftId, setDraftId] = useState("");
  const [isDraftLoaded, setIsDraftLoaded] = useState(false);
  const [mailSubject, setMailSubject] = useState("");
  const [hasUserEdited, setHasUserEdited] = useState(false);
  const [draftEmail, setDraftEmail] = useState({});
  const context = useContext(EMAIL_CONTEXT)
  const { allEmails = [], setAllEmails, activeNav, resetState, allLabels } = context
  const embeddedEmailAccount = JSON.parse(getStorage("embeddedEmailAccount"));
  const { provider } = embeddedEmailAccount ?? {};

  useEffect(() => {
    if (Object?.keys(draft)?.length > 0) setDraftEmail(draft);
  }, [draft]);
  
  const onEditorStateChange = (data, isUseEffectCall = false) => {
    setEditorState(data)
    setInpBody(data)
    
    if (!isUseEffectCall && data?.replaceAll('"', "") !== lastSavedDraft?.body?.replaceAll('"', "")) {
      setHasUserEdited(true);
    }
  }

  const [openDocumentUploadModal, setOpenDocumentUploadModal] = useState(false)

  const [uploadedDocuments, setUploadedDocuments] = useState([])
  const [showInfoCc, setShowInfoCc] = useState(false);
  const [showInfoBcc, setShowInfoBcc] = useState(false);
  const { _deleteDraftEmail } = useDeleteDraftEmail(allEmails, setAllEmails);

  const [lastSavedDraft, setLastSavedDraft] = useState({
    to: [],
    cc: [],
    bcc: [], 
    subject: "",
    body: "",
    attachments: []
  });

  const checkDraftDataDifference = useCallback(() => {
    const cleanBody = (body) => (body ?? "")
    .replaceAll("<html>", "")
    .replaceAll("</html>", "")
    .replaceAll("<head>", "")
    .replaceAll("</head>", "")
    .replaceAll("<body>", "")
    .replaceAll("</body>", "")
    .replaceAll(`<meta http-equiv="Content-Type" content="text/html; charset=utf-8">`, "")
    .replaceAll(`<div>\n<br></div>`, "")
    .replaceAll(`<div><br></div>`, "")
    .replaceAll(`<div></div>`, "")
    .trim();

    const lastSavedBody = cleanBody(lastSavedDraft?.body);
    const currentBody = cleanBody(inpBody);

    const hasChanged = [
      !_.isEqual(lastSavedDraft.subject, emailSubject),
      !_.isEqual(lastSavedDraft.to?.map((e) => ({ email: e.email, name: e.name })), emailTo?.map((e) => ({ email: e.email, name: e.name }))),
      !_.isEqual(lastSavedDraft.cc?.map((e) => ({ email: e.email, name: e.name })), emailCc?.map((e) => ({ email: e.email, name: e.name }))),
      !_.isEqual(lastSavedDraft.bcc?.map((e) => ({ email: e.email, name: e.name })), emailBcc?.map((e) => ({ email: e.email, name: e.name }))),
      !_.isEqual(lastSavedDraft.attachments?.map((val) => val?.id), uploadedDocuments?.map((val) => val?.id)),
      !_.isEqual(lastSavedBody, currentBody)
    ].some(Boolean);

    return hasChanged;
  }, [lastSavedDraft, emailTo, emailCc, emailBcc, emailSubject, uploadedDocuments, inpBody]);

  const removeDocument = (fileId, index) => {
    const _documentFile = _.cloneDeep(uploadedDocuments)
    const newDocList = _documentFile?.filter((document, ii) => ii !== index)
    setUploadedDocuments(newDocList)
  }

  const processBase64Images = async (htmlContent) => {
    // Function to extract image src attributes using regex
    const extractImageSrcs = (html) => {
      const regex = /data:image\/[bmp,gif,ico,jpg,png,svg,webp,x\-icon,svg+xml]+;base64,[a-zA-Z0-9,+,/]+={0,2}/gm;
      const matches = html.match(regex) || [];
      return matches;
    };

    try {
      const imgSrcs = extractImageSrcs(htmlContent);
      let cidHtmlContent = htmlContent;
      const attachments = [];
      imgSrcs.forEach((src, index) => {
        const cid = `${uuidv4()}`;
        const base64Data = src.match(/data:image\/[^;]+;base64,([^'"]+)/)[1];
        const contentType = src.match(/data:image\/([^;]+);base64/)[1];
        const filename = `image${index}.${contentType}`;

        const buffer = Buffer.from(base64Data, 'base64');
        const size = buffer.length;

        cidHtmlContent = cidHtmlContent.replace(src, `cid:${cid}`);

        attachments.push({
          filename: filename,
          content: base64Data,
          content_type: `image/${contentType}`,
          is_inline: true,
          content_id: cid,
          content_disposition: 'inline',
          size
        });
      });
      return { htmlContent: cidHtmlContent, attachments }
    } catch (error) {
      console.error('Error downloading images:', error);
      toastr.show(`${error}`, 'error');
      setIsApiCall(false)
    }
  };

  const sendMailPayload = async () => {
    if(!emailTo?.length){
      toastr.show("Add Email To.", "error")
      return
    }
    // const documentIds = uploadedDocuments?.map(val => val?.id)
    const { htmlContent: cidHtmlContent, attachments } = await processBase64Images(inpBody);
    const encryptedBody = cidHtmlContent ? encryptEmbeddedEmailData(cidHtmlContent) : ""

    let payload = new FormData();
    payload.append("to", JSON.stringify(convertEmailTos(emailTo)))
    if(emailSubject) payload.append("subject", emailSubject)
    if(emailCc?.length) payload.append("cc", JSON.stringify(convertEmailTos(emailCc)))
    if(emailBcc?.length) payload.append("bcc", JSON.stringify(convertEmailTos(emailBcc)))
    if(uploadedDocuments?.length) {
      uploadedDocuments.forEach((file) => {
        payload.append("attachments", file)
      })
    }
    if (attachments?.length) {
      attachments.forEach((doc) => {
        payload.append("inlineAttachments", JSON.stringify(doc));
      });
    }

    if(encryptedBody) payload.append("body", encryptedBody)
    setIsApiCall(true)
    debouncedHandleCreateUpdateDraft.cancel();

    sendMail(payload).then(async (data)=>{
      if (Object.keys(draftEmail)?.length) {
        try {
          const param = { ids: [draftEmail.id] };
          _deleteDraftEmail(param, true);
        } catch (error) {
          console.log("error", error)
        }
      }
      if(activeNav === EMAIL_TABS.SENT){
        resetState()
      }
      toastr.show(EMAIL_MSG.EMAIL_SENT, "success")
      let eventProperties = {
        source: AMPLITUDE_EVENTS_SOURCE.CREATE_EMAIL_MODAL
      }
      amplitudeTrack(AMPLITUDE_EVENTS.SEND_EMAIL, eventProperties)
      setIsApiCall(false)
      hide()
    }).catch((err)=>{
      setIsApiCall(false)
      console.log("err",err)
      toastr.show(EMAIL_MSG.SOMETHING_WENT_WRONG, "error")
    })  
  }

  const createDraft = () => {
    const hasChanged = checkDraftDataDifference()
    if(!hasChanged) {
      return
    }
    // Check if only default empty body with no other changes
    if (!emailTo?.length && !emailCc?.length && !emailBcc?.length && 
        !emailSubject && !uploadedDocuments?.length && 
        (inpBody === "" || inpBody === "<div><br></div>")) {
      return;
    }

    const encryptedBody = inpBody ? encryptEmbeddedEmailData(inpBody) : ""
    const payload = new FormData();
    if(emailSubject) payload.append("subject", emailSubject)
    if(emailTo?.length) payload.append("to", JSON.stringify(emailTo.map(e => ({ email: e.email, name: e.name }))))
    if(emailCc?.length) payload.append("cc", JSON.stringify(emailCc.map(e => ({ email: e.email, name: e.name }))))
    if(emailBcc?.length) payload.append("bcc", JSON.stringify(emailBcc.map(e => ({ email: e.email, name: e.name }))))
    if(uploadedDocuments?.length) {
      uploadedDocuments.forEach((file) => {
        payload.append("attachments", file)
      })
    }
    if(encryptedBody) payload.append("body", encryptedBody)

    if (!payload || payload.entries().next().value === undefined) {
      return;
    }
    
    setIsDraftApiCall(true)
    createDraftEmail(payload).then((data) => {
      const id = provider === EMAIL_PROVIDER.GMAIL ? data?.rawData?.threadId : data?.rawData?.id;
      setDraftId(id);
      const { rawData = {} } = data || {};
      const { subject, ...draft } = rawData;
      setDraftEmail({ ...draft });
      // Update lastSavedDraft after successful creation
      setLastSavedDraft({
        to: [...emailTo],
        cc: [...emailCc], 
        bcc: [...emailBcc],
        subject: emailSubject,
        body: inpBody,
        attachments: [...uploadedDocuments]
      });
      
      // Get Draft Folder Id For Outlook Mails
      const draftFolderId = allLabels?.find((x)=> x?.name === "Drafts")?.id;
      if(activeNav === EMAIL_TABS.DRAFT || activeNav === draftFolderId){
        // resetState()

        // const data = allEmails.map((e) => {
        //   console.log(e)
        //   if (e.id === rawData.threadId) {
        //     return { ...e, draftIds: [...e.draftIds, rawData?.id] };
        //   }
        //   return e;
        // });
        // console.log("datadata",data)
        const updatedRawData = provider === EMAIL_PROVIDER.GMAIL ? {
          ...rawData,
          id: id,
          draftIds: [rawData?.id]
        } : rawData
        setAllEmails([updatedRawData, ...allEmails]);
      }
      setIsDraftApiCall(false)
    }).catch((err) => {
      setIsDraftApiCall(false)
      console.log("err", err)
      toastr.show(EMAIL_MSG.SOMETHING_WENT_WRONG, "error")
    })
  }
  const updateDraft = () => {
    const hasChanged = checkDraftDataDifference()
    if(!hasChanged) {
      return
    }
    const encryptedBody = inpBody ? encryptEmbeddedEmailData(inpBody) : ""

    const payload = new FormData();
    payload.append("id", draftEmail?.id || draftId)
    if(emailSubject) payload.append("subject", emailSubject)
    if(emailTo?.length) payload.append("to", JSON.stringify(emailTo.map(e => ({ email: e.email, name: e.name }))))
    if(emailCc?.length) payload.append("cc", JSON.stringify(emailCc.map(e => ({ email: e.email, name: e.name }))))
    if(emailBcc?.length) payload.append("bcc", JSON.stringify(emailBcc.map(e => ({ email: e.email, name: e.name }))))

    if(uploadedDocuments?.length) {
      uploadedDocuments.forEach((file) => {
        payload.append("attachments", file)
      })
    }
    if(encryptedBody) payload.append("body", encryptedBody)
    
    setIsDraftApiCall(true)
    updateDraftEmail(payload).then((data) => {
      const { rawData = {} } = data || {};
      const { subject, ...draft } = rawData;
      setDraftEmail({ ...draft });
      // Update lastSavedDraft after successful update
      setLastSavedDraft({
        to: [...emailTo],
        cc: [...emailCc],
        bcc: [...emailBcc],
        subject: emailSubject, 
        body: inpBody,
        attachments: [...uploadedDocuments]
      });
      
      // Get Draft Folder Id For Outlook Mails
      const draftFolderId = allLabels?.find((x)=> x?.name === "Drafts")?.id;
      if(activeNav === EMAIL_TABS.DRAFT || activeNav === draftFolderId){
        // resetState()
        const data = allEmails.map((e) => {
          if(provider === EMAIL_PROVIDER.GMAIL){
            if(e.draftIds?.includes(rawData.id)){
              let dataToUpdate = {
                subject: rawData?.subject,
                snippet: rawData?.snippet,
                from: rawData?.from,
                id: rawData?.threadId
              }
              return { ...e, ...dataToUpdate }
            }
          }
          if (e.id === rawData.id) {
            return { ...e, ...rawData };
          }
          return e;
        });
        setAllEmails(data);
      }
      setIsDraftApiCall(false)
    }).catch((err) => {
      setIsDraftApiCall(false)
      console.log("err", err)
      toastr.show(EMAIL_MSG.SOMETHING_WENT_WRONG, "error")
    })
  }

  const setDraftDataToModal = async (isNew = false, defaultSignature) => {
    try {
      let { id, to = [], cc = [], bcc = [], attachments, subject, body } = draftEmail ?? {}

      if (id) setDraftId(id);
      if (subject) {
        setEmailSubject(subject)
      }
      if (to?.length) {
        setEmailTo(to)
      }
      if (cc?.length) {
        setEmailCc(cc)
      }
      if (bcc?.length) {
        setEmailBcc(bcc)
      }
      if (body) {
        if (isNew) {
          const _class = findClass(editorState);
          if (_class?.length > 0) {
            const _signature = allEmailSignature.filter(value => _class.includes(value?._id));
            if (_signature?.length) {
              const tempElement = document.createElement('div');
              tempElement.innerHTML = editorState;
              const elementsToRemove = tempElement.getElementsByClassName(_signature?.[0]?._id);
              if (elementsToRemove.length > 0) {
                elementsToRemove[0].parentNode.removeChild(elementsToRemove[0]);
              }
              body = tempElement.innerHTML + `${getSignatureBody(defaultSignature, true)}`;
            } else {
              body = editorState + `${getSignatureBody(defaultSignature, true)}`;
            }
          }
        }
        onEditorStateChange(body || "", true)
      }

      if (attachments?.length && !isNew) {
        const param = {
          accountId: attachments?.[0]?.grantId ?? embeddedEmailAccount?.accountId,
        }
        let messageId = draftEmail?.id;
        if(provider === EMAIL_PROVIDER.GMAIL){
          messageId = draftEmail?.threadId
        }
        const { attachments: allFiles, isSuccess, message: errorMessage } = await getAttachmentsByThread(param, messageId);
        if (!isSuccess) toastr.show(errorMessage, 'error')
        if (!allFiles?.length) return;
        const files = await Promise.all(allFiles?.map(async (file) => {
          const arrayBuffer = new Uint8Array(file.buffer.data).buffer;
          const blob = new Blob([arrayBuffer], { type: file.content_type });
          const fileData = new File([blob], file.filename, {
            type: blob.type,
            size: file.size
          });
          fileData.id = file.id;
          return fileData
        }))
        setUploadedDocuments(files)
      }
      
      // Set initial state for lastSavedDraft after loading draft data
      setLastSavedDraft({
        to: [...to] || [],
        cc: [...cc] || [],
        bcc: [...bcc] || [],
        subject: subject || "",
        body: body || "",
        attachments: attachments || []
      });
      
    } catch (error) {
      console.error(error)
    } finally {
      setIsDraftLoaded(true)
    }
  }

  const getInitialState = (defaultValue) => {
    if (defaultValue?.body) {
      let bodyFornewSignatureEmail = getSignatureBody(defaultValue, true)
      setEditorState(bodyFornewSignatureEmail || "")
      setInpBody(bodyFornewSignatureEmail || "")
      
      // Set initial state for lastSavedDraft for new email
      setLastSavedDraft({
        to: [],
        cc: [],
        bcc: [],
        subject: "",
        body: bodyFornewSignatureEmail || "",
        attachments: []
      });
    } else {
      setEditorState("")
      setInpBody("")
      
      // Set empty initial state for lastSavedDraft
      setLastSavedDraft({
        to: [],
        cc: [],
        bcc: [],
        subject: "",
        body: "",
        attachments: []
      });
    }
  }

  useEffect(() => {
    const defaultSignature = allEmailSignature.find((p) => p?.useDefaultForNewEmail)
    if (defaultSignature) {
      if (Object.keys(draftEmail)?.length) {
        setDraftDataToModal(false, defaultSignature)
      } else {
        getInitialState(defaultSignature)
        setIsDraftLoaded(true)
      }
    } else if(Object.keys(draftEmail)?.length) {
      setDraftDataToModal(false, defaultSignature)
    } else {
      onEditorStateChange('', true);
      setIsDraftLoaded(true)
    }
  }, [allEmailSignature, draftEmail?.id])

  const handleCreateUpdateDraft = useCallback(() => {
    if (!draftId && !isDraftApiCall) createDraft();
    else updateDraft();

    let eventProperties = {
      source : AMPLITUDE_EVENTS_SOURCE.CREATE_EMAIL_MODAL
    }
    amplitudeTrack(AMPLITUDE_EVENTS.SAVE_TEMPLATE,eventProperties)
  }, [draftId, emailTo, emailCc, emailBcc, emailSubject, inpBody, uploadedDocuments, isDraftApiCall]);

  const debouncedHandleCreateUpdateDraft = useMemo(() => {
    const debounced = _.debounce(() => {
      if (isDraftLoaded && hasUserEdited) {
        handleCreateUpdateDraft();
      }
    }, 500);
  
    return debounced;
  }, [isDraftLoaded, hasUserEdited, handleCreateUpdateDraft, uploadedDocuments]);
  useEffect(() => {
      debouncedHandleCreateUpdateDraft.call();
    return () => {
      debouncedHandleCreateUpdateDraft.cancel();
    };
  }, [emailTo, emailCc, emailBcc, mailSubject, inpBody, uploadedDocuments]);

  const handleInfoCc = () => {
    setShowInfoCc(true);
  };
  
  const handleInfoBcc = () => {
    setShowInfoBcc(true);
  };

  return (
    <>
      <Modal show={show} dialogClassName="modal-fullpage modal-dialog-scrollable">
        <Modal.Header>
          <Modal.Title>New Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group form-row align-items-start">
            <label className="col-md-1 my-1">To</label>
            <div className="col-md-11 d-flex align-items-center">
              <ReciptionsInput placeholder={"Enter To"} email={emailTo} setEmail={(emails) => {
                setEmailTo(emails)
                setHasUserEdited(true)
              }} autoFocus={true}/>
              {!showInfoCc && <button className="btn btn-outline-light text-primary ml-2" onClick={handleInfoCc}>CC</button>}
              {!showInfoBcc && <><button data-tip data-for="BccToolTip"className="btn btn-outline-light text-primary ml-2" onClick={handleInfoBcc}>BCC</button> <ReactTooltip id="BccToolTip" place="top">Add BCC recipients</ReactTooltip></>}
            </div>
          </div>
          {showInfoCc &&
          <div className="form-group form-row align-items-start">
            <label className="col-md-1 my-1">CC</label>
            <div className="col-md-11">
              <ReciptionsInput placeholder={"Enter CC"} email={emailCc} setEmail={(emails) => {
                setEmailCc(emails)
                setHasUserEdited(true)
              }} autoFocus={showInfoCc} />
            </div>
          </div>}
          {showInfoBcc &&
          <div className="form-group form-row align-items-start">
            <label className="col-md-1 my-1">BCC</label>
            <div className="col-md-11">
              <ReciptionsInput placeholder={"Enter BCC"} email={emailBcc} setEmail={(emails) => {
                setEmailBcc(emails)
                setHasUserEdited(true)
              }} autoFocus={showInfoBcc} />
            </div>
          </div>}
          <div className="form-group form-row align-items-center">
            <label className="col-md-1 col-form-label">Email Subject</label>
            <div className="col-md-11 d-flex">
              <input
                type="text"
                className="form-control"
                placeholder="Enter Subject"
                value={emailSubject}
                onChange={(e) => {
                  setEmailSubject(e.target.value)
                  setHasUserEdited(true)
                }}
                onBlur={(e) => setMailSubject(e.target.value)}
              />
            </div>
          </div>
          <div className="form-group mb-0">
            <EmailEditor onEditorStateChange={onEditorStateChange} editorState={editorState} allEmailSignature={allEmailSignature} getAllEmailSignatures={getAllEmailSignatures} isEmailSignature={true} isCreateSignature={true} />
          </div>
          <div className="d-flex align-items-center mt-10">
            <div className="mr-2">
              <button className="btn btn-outline-light" 
              onClick={() => {
                setOpenDocumentUploadModal(true)
                let eventProperties = { source: AMPLITUDE_EVENTS_SOURCE.CREATE_EMAIL_MODAL }
                amplitudeTrack(AMPLITUDE_EVENTS.UPLOAD_ATTACHMENTS,eventProperties);
              }} 
              disabled={isApiCall || isDraftApiCall}>
                <IconAttachment />
              </button>
            </div>
            <div className="d-flex flex-wrap align-items-center gap-5 overflow-y-auto custom-scrollbar-sm" style={{maxHeight: "80px"}}>
              {(uploadedDocuments?.length > 0) &&
                uploadedDocuments.map((document, index) => (
                  
                  <>
                    <span className="badge border-1 border-gray-100 d-flex align-items-center">
                      <span className="text-primary font-12">{document?.name}</span>
                      <span className="text-muted ml-10 font-12">{`${bytesToKB(document?.size)} KB`}</span>
                      <span className="pointer" 
                      onClick={(e) => removeDocument(document, index)}
                      >
                        <IconTimes className="font-12" />
                      </span>
                    </span>
                  </>
                ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
        <div className="d-flex align-items-center gap-10 ml-auto">
              <button 
              className="btn btn-close"
              onClick={() => {
                if (!isDraftApiCall) {
                  handleCreateUpdateDraft();
                }
                setDraftEmail({})
                hide()
              }}
              >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={() => { sendMailPayload() }}
              disabled={isApiCall || isDraftApiCall}
            >
              Send
              {isApiCall && <span class="spinner-border spinner-border-sm ml-1"></span>}
            </button>
            </div>
        </Modal.Footer>
      </Modal>

      {openDocumentUploadModal && (
        <DocumentUpload
          setOpenDocumentUploadModal={setOpenDocumentUploadModal}
          removeDocument={removeDocument}
          uploadedDocuments={uploadedDocuments}
          setUploadedDocuments={setUploadedDocuments}
          setHasUserEdited={setHasUserEdited}
        />
      )}
    </>
  )
}

export default CreateEmailModal
