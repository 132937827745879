import React, { useEffect, useRef, useState } from "react";
import { IconAngleArrowDown, IconConnect, IconPlus } from "Components/Common/Icons";
import { IconDisconnect, IconThreeDots, Icondots, IconInfoCircleFilledWhite } from "Components/Common/Icons";
import ReactTooltip from "react-tooltip";
import { useOnClickOutside } from "hooks";
import { convertTMSStatusName, isEmptyOrLoaded } from "../../../../../../Components/Common/functions";
import moment from "moment";
import {
  DateTimeFormatUtils,
  auditKey,
  checkUserPermisison,
  getStorage,
  userTimeZone,
  amplitudeTrack,
} from "../../../../../../services";
import AssignedCSRs from "./assignedCSR";
import AddConnectToLoad from "../AddConnectToLoad";
import { IconAngleArrowTop, IconReset } from "../../../../../../Components/Common/Icons";
import EmailToolTip from "../EmailToolTip";
import { AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE, PERMISSION_ERROR } from "../../../constant";
import Port from "../../../../../../Components/CustomerServiceSideBar/Tabs/Components/Port";
import { useHover } from "../../../../../../hooks";
import SideBarContactInfo from "../../../../NewDispatcher/DispatcherTable/Components/portals/SideBarContactInfo";
import { calculateLoadDistance, getLoadRoute } from "../../../../../../Components/CustomerServiceSideBar/actions";
import { streetTile } from "../../../../../trucker/NewTrack1/utility";

import configuration from "../../../../../../config";
import mapboxgl from "mapbox-gl";

import "@mapbox/mapbox-gl-draw/dist//mapbox-gl-draw.css";
import { getTimeZone } from "../../../../NewDispatcher/constants";

const polyline = require("@mapbox/polyline");
mapboxgl.accessToken = configuration.map_box_api_key;
const ConnectedLoad = (props) => {
  const {
    connectedLoads,
    connectedLoadDetails,
    listAllCSR,
    AllCSRList,
    assignCSRtoLoad,
    loadingAssignCSR,
    handleDisconnect,
    setIsConnectToLoad,
    handleOpenNewLoad,
    setShowLoadModel,
    isEmailMode
  } = props;
  const [showAddLoadConnect, setShowAddLoadConnect] = useState(false);

  const addLoadDropdownRef = useRef(null);

  const canSeeLoad = checkUserPermisison(["customer_service_load_info"]);

  const handleToggleLoadConnect = () => {
    setShowAddLoadConnect((prevState) => !prevState);
  };

  return (
    <div className="d-flex flex-column gap-5 mx-10 m-2">
      <div className="d-flex align-items-center pb_5">
        <div className="font-14 font-medium line-height-20">{connectedLoads?.length ?? "No"} Connected Loads</div>
        <button
          className="btn btn-sm btn-primary ml-auto"
          onClick={() => {
            if (canSeeLoad) {
              handleToggleLoadConnect();
            }
          }}
          ref={addLoadDropdownRef}
        >
          <IconPlus className="mr-1" />
          Add
        </button>
        {showAddLoadConnect && (
          <AddConnectToLoad
            cellRef={addLoadDropdownRef}
            handleOpenNewLoad={handleOpenNewLoad}
            handleConnectLoad={() => {
              setIsConnectToLoad(true);
              setShowAddLoadConnect(false);
            }}
            setShowAddLoadConnect={setShowAddLoadConnect}
          />
        )}
      </div>
      <div>
      {connectedLoadDetails?.map((loadDetails, index) => {
        const load = connectedLoads?.find((ld) => ld?.referenceNumber === loadDetails?.reference_number);

        return (
          loadDetails && (
            <LoadCard
              key={index}
              loadIndex={index}
              load={load}
              loadDetails={loadDetails}
              listAllCSR={listAllCSR}
              AllCSRList={AllCSRList}
              assignCSRtoLoad={assignCSRtoLoad}
              loadingAssignCSR={loadingAssignCSR}
              handleDisconnect={handleDisconnect}
              canSeeLoad={canSeeLoad}
              setShowLoadModel={setShowLoadModel}
              setShowAddLoadConnect={setShowAddLoadConnect}
              isEmailMode={isEmailMode}
            />
          )
        );
      })}
      </div>
    </div>
  );
};

const LoadCard = (props) => {
  const {
    load,
    loadIndex,
    loadDetails,
    listAllCSR,
    AllCSRList,
    assignCSRtoLoad,
    loadingAssignCSR,
    handleDisconnect,
    canSeeLoad,
    setShowLoadModel,
    setShowAddLoadConnect,
    isEmailMode
  } = props;
  const [isActive, setActive] = useState(false);
  const [showDotsDropdown, setShowDotsDropdown] = useState(false);
  const dropCellRef = useRef();
  const dropBoxRef = useRef();
  const handleToggle = () => {
    setActive((prevState) => !prevState);
  };

  const handleToggleDotsDropdown = () => {
    setShowDotsDropdown((prevState) => !prevState);
  };

  useOnClickOutside([dropCellRef, dropBoxRef], () => setShowDotsDropdown(false));

  const emptyOrLoadedInfo = loadDetails && loadDetails.driverOrder ? isEmptyOrLoaded(loadDetails) : null;
  let statusObj = loadDetails
    ? convertTMSStatusName(
        loadDetails.type_of_load,
        ["HOOKCONTAINER_ARRIVED", "HOOKCONTAINER_DEPARTED"].indexOf(loadDetails?.status) > -1 && !loadDetails.isLive
          ? "DROPCONTAINER_DEPARTED"
          : loadDetails?.status,
        loadDetails,
        false,
        true
      )
    : null;
  const addCSRtoLoad = (loadId, csr) => {
    const loadCSRs = loadDetails.assignedCSR;
    const csrName = csr.label ?? csr.name;
    const loadCSRIDs = loadCSRs?.map((c) => c._id);
    loadCSRIDs.push(csr.value ?? csr._id);
    assignCSRtoLoad(loadId, loadCSRIDs, false, csrName);
  };
  const removeCSRfromLoad = (loadId, csr) => {
    const loadCSRs = loadDetails.assignedCSR;
    const csrName = csr.label ?? csr.name;
    const loadCSRIDs = loadCSRs?.map((c) => c._id);
    const index = loadCSRIDs.indexOf(csr.value ?? csr._id);
    loadCSRIDs.splice(index, 1);
    assignCSRtoLoad(loadId, loadCSRIDs, true, csrName);
  };
  const getLinkToolTip = () => {
    let str = "Connected to email";
    if (load?.linkedAt) {
      str += ` on ${moment(load?.linkedAt).tz(getTimeZone({preferred: true})).format("MM/DD/YY")}`;
    }
    if (load?.linkedBy?.name) {
      str += ` by ${load?.linkedBy?.name}`;
    }
    if (load?.isAutomaticallyLinked && load?.matchedCriteria && Object.keys(load?.matchedCriteria)?.length) {
      str += " Load matches";
      Object.keys(load?.matchedCriteria).forEach((key, index) => {
        str += ` ${auditKey[key] ?? key} ${load?.matchedCriteria[key]}`;
        if (index < Object.keys(load?.matchedCriteria)?.length - 1) {
          str += ",";
        }
      });
    }
    return str;
  };

  const openLoadModel = () => {
    setShowLoadModel(loadDetails?.reference_number);
    setShowAddLoadConnect(false);
  };
  return (
    <div className="bg-white rounded-5 mb-1">
      <div className="p-2" 
        onClick={()=>{ 
          handleToggle() 
          let eventProperties = {
            source: isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_OPEN_LOAD_DROPDOWN : AMPLITUDE_EVENTS_SOURCE.GRID_OPEN_LOAD_DROPDOWN
          }
          amplitudeTrack(AMPLITUDE_EVENTS.OPEN_LOAD_MODAL,eventProperties)
        }}>
      <div className="d-flex align-items-center pr-1 pointer gap-5">
        <div className="font-bold">{loadDetails?.reference_number}</div>
        <span
          className={`text-capitalize text-white text-truncate badge badge-sm ${props?.deleted ? "" : "badge-sm"} badge-status${
            emptyOrLoadedInfo && emptyOrLoadedInfo.status === "empty" ? "-light" : ""
          }-${statusObj && statusObj.colorStatus ? statusObj.colorStatus : "purple"} ml-auto`}
        >
          {statusObj && statusObj.status && statusObj.status.toLowerCase()}
          {emptyOrLoadedInfo && emptyOrLoadedInfo.status ? (
            <span className="ml-1 ">- {emptyOrLoadedInfo.status}</span>
          ) : (
            ""
          )}
        </span>
        {isActive ? <IconAngleArrowTop className="flex-shrink-0" /> : <IconAngleArrowDown className="flex-shrink-0" />}
      </div>
      <div className="d-flex flex-column pr-1 pointer" >
        <div className="font-12 font-weight-normal">{loadDetails?.containerNo || ""}</div>
        <div className="text-muted font-12 font-weight-normal">{loadDetails?.consignee?.[0]?.company_name || ""}</div>
      </div>
      </div>
      {isActive && (
        <>
          <div className="hr-light my-0" />
          <div className="p-10 d-flex flex-column gap-10">
            <div className="d-flex align-items-center gap-2">
              <div className="font-16 line-height-20 font-medium">{loadDetails?.callerInfo?.company_name}</div>
              <span data-tip={getLinkToolTip()} data-for="load-connect-email-tooltip">
                <IconConnect />
              </span>
              <ReactTooltip id="load-connect-email-tooltip" className="mw-140 py-1 px-2" effect="solid" />

              <div className="ml-auto position-relative">
                <span
                  className="ml-auto pointer position-relative"
                  ref={dropCellRef}
                  onClick={handleToggleDotsDropdown}
                >
                  <Icondots />
                </span>
                {showDotsDropdown && (
                  <div
                    className="card card--shadow-5 popdrop popdrop--bottom popdrop--right popdrop--sm mb-0 p-1"
                    ref={dropBoxRef}
                  >
                    <div className="popdrop__dropdown-item pointer" onClick={() => handleDisconnect(loadDetails, AMPLITUDE_EVENTS.DISCONNECT_LOAD, { source: isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_EMAIL_MODEL : AMPLITUDE_EVENTS_SOURCE.GRID_EMAIL_MODEL })}>
                      <IconDisconnect className="mr-1 text-muted" />
                      Disconnect Load
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex">
              <div className="text-muted">Assigned to Load</div>
              <AssignedCSRs
                assignedCSR={loadDetails?.assignedCSR}
                addCSR={(csr) => addCSRtoLoad(loadDetails?._id, csr)}
                removeCSR={(csr) => removeCSRfromLoad(loadDetails?._id, csr)}
                listAllCSR={listAllCSR}
                isLoading={loadingAssignCSR?.includes(loadDetails?._id)}
                AllCSRList={AllCSRList}
                isConnectedLoads={true}
                isEmailMode={isEmailMode}
              />
            </div>
            <div data-tip={PERMISSION_ERROR.LOAD_PERMISSION} data-for={`load-disconnect`}>
              <button
                className="btn btn-outline-light btn-block"
                onClick={() => {
                  if (canSeeLoad) {
                    openLoadModel();
                    let eventProperties = {
                      source: isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_OPEN_LOAD_BTN : AMPLITUDE_EVENTS_SOURCE.GRID_OPEN_LOAD_BTN
                    }
                    amplitudeTrack(AMPLITUDE_EVENTS.OPEN_LOAD,eventProperties)
                  }
                }}
              >
                Open Load
              </button>
              {!canSeeLoad && <EmailToolTip toolTipId={"load-disconnect"} />}
            </div>
          </div>

          <div className="hr-light my-0" />
          {loadDetails?.type_of_load !== "BILL_ONLY" && (
            <>
              <div className="d-flex flex-column gap-10 p-10">
                <div className="font-14 font-medium mb_5">Pickup Terminal</div>
                {loadDetails?.shipper.map((shipper, sI) => {
                  return (
                    <React.Fragment key={sI}>
                      <Location location={shipper} showContactInfo={true} />
                    </React.Fragment>
                  );
                })}
              </div>

              <div className="hr-light my-0" />
              <div className="d-flex flex-column gap-10 p-10">
                <div className="font-14 font-medium mb_5">Delivery Warehouse</div>
                {loadDetails?.consignee.map((consignee, cI) => {
                  return (
                    <React.Fragment key={cI}>
                      <Location location={consignee} showContactInfo={true} />
                    </React.Fragment>
                  );
                })}
              </div>
              <div className="hr-light my-0" />
              {loadDetails?.emptyOrigin && (
                <div className="d-flex flex-column gap-10 p-10">
                  <div className="font-14 font-medium mb_5">Return Terminal</div>
                  <Location location={loadDetails?.emptyOrigin} />
                </div>
              )}
            </>
          )}

          <div className="hr-light my-0" />
          <LoadSummary load={loadDetails} loadIndex ={loadIndex} isEmailMode={isEmailMode} />
        </>
      )}
    </div>
  );
};

const Location = ({ location, showContactInfo,isEmailMode }) => {
  const [referenceElementRef, referenceElement] = useHover();
  const [showToolTip, setShowToolTip] = useState(false);
  const mainDivRef = useRef(null);
  const handleIconHover = () => {
    setShowToolTip(true);
  };
  const handleMainDivMouseLeave = (e) => {
    const isMouseOutside = e.relatedTarget && !mainDivRef.current.contains(e.relatedTarget);
    if (isMouseOutside) {
      setShowToolTip(false);
    }
  };
  return (
    <div>
      <div className="d-flex align-items-center" ref={mainDivRef} onMouseLeave={handleMainDivMouseLeave}>
        <div className="font-medium mr-2">{location?.company_name ?? location?.name}</div>
        {showContactInfo && (
          <div
            className={`bg-light wh-16px rounded-circle d-flex justify-content-center align-items-center ${
              referenceElement && "bg-gray-200 pointer"
            }`}
            ref={referenceElementRef}
            onMouseEnter={handleIconHover}
          >
            <IconInfoCircleFilledWhite className="text-muted" />
          </div>
        )}
      </div>
      <div>{location?.address?.address}</div>
      {showToolTip && (
        <SideBarContactInfo
          opne={showToolTip}
          refNo={referenceElementRef?.current}
          email={location?.email}
          mobile={location?.mobile}
          main_contact_name={location?.main_contact_name}
          color={"gray-700"}
          cssClass={"load_emailinfo"}
          setShowToolTip={setShowToolTip}
          isEmail={true}
        />
      )}
    </div>
  );
};

const LoadSummary = ({ load, loadIndex, isEmailMode }) => {
  const [totalDistance, setTotalDistance] = useState("");
  const mapContainerId = `map-container-${loadIndex}`;
  let mapContainer1 = useRef(null);
  const map1 = useRef(null);
  let routeLayerGroup = null;
  let routeLayers = [];
  let routePoints = [];
  const timezone = getTimeZone({preferred: true});
  const convertToTimezone = (date, format) => {
    return date
      ? moment(date)
          .tz(timezone)
          .format(format ?? "MM/DD/YY")
      : "";
  };

  const calculateDistance = () => {
    if (load?.totalMiles) {
      if (load.totalMiles > 6000) setTotalDistance("6000+");
      else setTotalDistance(load.totalMiles.toFixed(2));
    }
  };

  const recalculateDistance = () => {
    calculateLoadDistance(load?.reference_number).then((res) => {
      if (res?.data?.totalMiles) {
        if (res.data.totalMiles > 6000) setTotalDistance("6000+");
        else setTotalDistance(res.data.totalMiles.toFixed(2));
      }
    });
  };
  useEffect(() => {
    calculateDistance();
  }, [load]);
  const pickupTime = convertToTimezone(load?.pickupTimes?.[0]?.pickupFromTime);
  const deliveryTime = convertToTimezone(load?.deliveryTimes?.[0]?.deliveryToTime);
  const deliveredTime = load?.driverOrder?.find((leg) => leg.type === "DELIVERLOAD")?.arrived;
  const deliveredDate = convertToTimezone(deliveredTime);

  const measureUnits = load?.carrier?.carrier?.distanceMeasure;
  let measureUnit = measureUnits && measureUnits == "km" ? "Kilometers" : "Miles";

  const loadMap = () => {
    if (mapContainer1.current) {
      if (!map1?.current) {
        map1.current = new window.L.Map(mapContainerId, {
          zoom: 1,
          minZoom: 1,
          center: [35.71, -75.25],
          layers: [streetTile()],
        });
      }
    }
  };

  const _getLoadRoute = () => {
    if (map1?.current) {
      map1 && map1?.current?.stop();
      if (load?.reference_number) {
        getLoadRoute(load?.reference_number)
          .then((routePath) => {
            let pointsArr = routePath?.map((d) => polyline.decode(d));
            routePoints = pointsArr?.slice().map((pArr) => pArr.map((p) => p.slice().reverse()));
            updateRouteLine();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };
  const updateRouteLine = () => {
    if (map1?.current) {
      addRouteToMap();
    }
  };

  const addRouteToMap = () => {
    if (!routeLayerGroup) {
      const layerGroup = new window.L.LayerGroup(null, {
        pane: "overlayPane",
      });
      layerGroup.addTo(map1?.current);
      routeLayerGroup = layerGroup;
    }
    if (routeLayers.length > 0 && routeLayerGroup) {
      // delete existing routes
      routeLayerGroup.clearLayers();
      routeLayers = [];
    }
    const routeLayer = window.L.polyline(
      routePoints[0].map((e) => e.reverse()),
      {
        color: "var(--color-primary-500)",
        weight: 7,
        opacity: 1.0,
      }
    );
    routeLayers?.push(routeLayer);
    routeLayer?.addTo(routeLayerGroup);
    boundToRouteLine();
  };

  const boundToRouteLine = () => {
    let coordinates = routePoints[0];
    if (coordinates.length > 0) {
      map1?.current?.fitBounds(new window.L.LatLngBounds(coordinates[0], coordinates[coordinates.length - 1]));
    }
  };
  useEffect(() => {
    // if (!selectRefNo || selectRefNo?.value === "") return
    // _getLoadDetails(selectRefNo?.value)
    setTimeout(() => {
      loadMap();
    }, 1000);
    setTimeout(() => {
      _getLoadRoute();
    }, 2000);
  }, []);
  return (
    <div className="d-flex flex-column gap-10 p-10">
      <div className="font-14 font-medium mb_5">Load Info Summary</div>
      <div className="d-flex align-items-center justify-content-between">
        <span className="text-muted">Pick Up Appt</span>
        <span>{pickupTime}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <span className="text-muted">Delivery Appt</span>
        <span>{deliveryTime}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <span className="text-muted">Delivered</span>
        <span>{deliveredDate}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <span className="text-muted">Return Appt</span>
        <span>{convertToTimezone(load?.returnFromTime)}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <span className="text-muted">Last Free Day</span>
        <span>{convertToTimezone(load?.lastFreeDay)}</span>
      </div>

      <div className="hr-light my-0" />
      <div className="d-flex align-items-center justify-content-between">
        <span className="text-muted">Created by</span>
        <span>{`${load?.addedBy?.name}`?.trim()}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <span className="text-muted">Created at</span>
        <span>{convertToTimezone(load?.createdAt, DateTimeFormatUtils.fullDateTimeFormat())}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <span className="text-muted">Container #</span>
        <span>{load?.containerNo ?? ""}</span>
      </div>
      {load?.type_of_load === "IMPORT" && (
        <div className="d-flex align-items-center justify-content-between">
          <span className="text-muted">BOL #</span>
          <span>{load?.callerbillLandingNo ?? ""}</span>
        </div>
      )}
      {load?.type_of_load === "EXPORT" && (
        <div className="d-flex align-items-center justify-content-between">
          <span className="text-muted">BKG #</span>
          <span>{load?.bookingNo ?? ""}</span>
        </div>
      )}
      <div className="d-flex align-items-center justify-content-between">
        <span className="text-muted">Chassis #</span>
        <span>{load?.chassisNo ?? ""}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <span className="text-muted">SSL</span>
        <span>{load?.containerOwner?.company_name ?? ""}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <span className="text-muted">Size</span>
        <span>{load?.type_of_load === "ROAD" ? load?.trailerSize : load?.containerSize?.name ?? ""}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <span className="text-muted">Type</span>
        <span>{load?.type_of_load === "ROAD" ? load?.trailerType : load?.containerType?.name ?? ""}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <span className="text-muted">Reference</span>
        <span>{load?.secondaryReferenceNo ?? ""}</span>
      </div>
      <div className="d-flex align-items-center justify-content-between">
        <span className="text-muted">Total {measureUnit}</span>
        <div>
          <span>{totalDistance}</span>
          <button
            className="btn btn-link pr-0"
            onClick={() => {
              recalculateDistance();
              let eventProperties = {
                source: isEmailMode === 'card' ? AMPLITUDE_EVENTS_SOURCE.CARD_REFRESH_DISTANCE_BTN : AMPLITUDE_EVENTS_SOURCE.GRID_REFRESH_DISTANCE_BTN
              }
              amplitudeTrack(AMPLITUDE_EVENTS.EMAIL_RESET_DISTANCE ,eventProperties);
            }}
          >
            <span>
              <IconReset className="text-primary" />
            </span>
          </button>
        </div>
      </div>
      <div className="align-items-center">
        <span className="text-muted">Preview </span>
        <div className="ml-auto">
          <div
            id={mapContainerId}
            className="mapboxgl-map-300 mt-10"
            style={{ height: 300 }}
            ref={mapContainer1}
          ></div>
          <div className="overlay mt-10"></div>
        </div>
      </div>
    </div>
  );
};
export default ConnectedLoad;
