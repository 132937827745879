export function HosAvailability({ hosAvailability, twoColumns = false, className = "" }) {
  return (
    <div className={`${twoColumns? "grid grid-cols-2" : "d-flex flex-wrap" } row-gap-2 column-gap-3 ${className}`}>
      <div className={`badge badge-sm badge-brand-50 text-dark text-left`}>
        <span className="font-normal">Cycle:</span> {hosAvailability?.cycleRemaining ?? "-"}
      </div>
      <div className={`badge badge-sm badge-brand-50 text-dark text-left`}>
        <span className="font-normal">Shift:</span> {hosAvailability?.shiftRemaining ?? "-"}
      </div>
      <div className={`badge badge-sm badge-brand-50 text-dark text-left`}>
        <span className="font-normal">Drive:</span> {hosAvailability?.driveRemaining ?? "-"}
      </div>
      <div className={`badge badge-sm badge-brand-50 text-dark text-left`}>
        <span className="font-normal">Break:</span> {hosAvailability?.breakRemaining ?? "-"}
      </div>
    </div>
  );
}
