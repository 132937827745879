import { Circle, Polygon, useMap } from "react-leaflet";
import { useTrackingDriver, useTrackingHistoryDispatch, useTrackingLoad, useTrackingShared, useTrackingTruck } from "../../../context/TrackingContext";
import { useEffect, useMemo } from "react";
import _ from "lodash";
import LegPopup from "./Components/LegPopup";
import { TRACKING_HISTORY_ACTIONS } from "../../../store/actions";
import { isMoveEndingEvent } from "../../../utility";
import moment from "moment";
import { trackingSharedSelectors } from "pages/trucker/Tracking/store/selectors";
import { getBoundsIncludingRadius } from "pages/trucker/Tracking/Components/Shared/Utils/map.utils";
import { ALL_LOAD_TYPES, LOAD_STATUSES } from "constants";

const CIRCLE_GEOFENCE_RADIUS = 10; // some profiles don't have well defined geofence, they have location coordinates (lat, lng), we draw default circlular geofence for those

export default function LegMarkers() {
  const { selectedDriverOrder, selectedEvent, selectedMove, selectedLoadHistory, selectedLoad } = useTrackingLoad();
  const dispatchTrackingHistory = useTrackingHistoryDispatch();
  const { selectedTruck } = useTrackingTruck();
  const { selectedDriver } = useTrackingDriver();
  const stateShared = useTrackingShared();
  const contextType = trackingSharedSelectors.currentTrackingContextType(stateShared);

  const selectedFilterMoveEvents = selectedDriverOrder?.driverOrders ?? [];
  const selectedMoveEvents = useMemo(() => {
    let _driverOrder = selectedLoad?.driverOrder ?? [];
    if (selectedEvent || selectedMove) _driverOrder = _driverOrder?.filter(d => d?.moveId === (selectedEvent?.moveId ?? selectedMove));
    if (contextType !== "loadInfo" && (selectedTruck?.driver || selectedDriver?._id)) _driverOrder = _driverOrder?.filter(d => (d?.driver?._id ?? d?.driver) == (selectedTruck?.driver ?? selectedDriver?._id));

    return _driverOrder ?? [];
  }, [selectedLoad, selectedDriver, selectedEvent, selectedMove]);

  const correctedDriverOrders = useMemo(() => {
    return selectedMoveEvents?.map((d, index, driverOrderArr) => {
      const validNextLegIndex = driverOrderArr.findIndex((order, i) => i > index && !order.isVoidOut);

      const departedDate = index < driverOrderArr.length - 1 ? driverOrderArr?.[validNextLegIndex]?.arrived : null;
      const arrived = d.departed;
      let departed = departedDate;
      const isEndingMove = isMoveEndingEvent(driverOrderArr[index], driverOrderArr[validNextLegIndex]);
      const lastEvent = selectedLoad?.driverOrder?.findLast(o => !o.isVoidOut);
      const isRoadAndCompleted = selectedLoad?.type_of_load === ALL_LOAD_TYPES.ROAD && selectedLoad?.status === LOAD_STATUSES.COMPLETED;
      const isDateException = (lastEvent?._id === d?._id && isRoadAndCompleted) 
        || (isEndingMove && d?.type === "RETURNCONTAINER");
      if (isDateException) departed = selectedLoad?.loadCompletedAt;
  
      
      return { ...d, departed, arrived, isEndingMove, isDateException, validNextLegIndex };
    });
  }, [selectedMoveEvents]);

  const driverLocationHistory = useMemo(() => {
    const selectedEventIndex = selectedMoveEvents?.findIndex(d => d?._id === selectedEvent?._id);

    const prevEvents = selectedFilterMoveEvents?.[selectedEventIndex - 1];
    const arrived = selectedEventIndex === 0 ? selectedMoveEvents?.[0]?.arrived : prevEvents?.departed;

    if (!(selectedLoadHistory && Array.isArray(selectedLoadHistory) && selectedLoadHistory.length > 0)) return [];
    if ((selectedEvent && !arrived && !selectedEvent?.departed) || selectedEvent?.isVoidOut) return [];
    if (arrived || selectedEvent?.departed) {
      // const selectedEventIndex = correctedDriverOrders?.findIndex(d => d?._id == selectedEvent?._id);
      // const isFirstIndex = selectedEventIndex == 0;
      // const selectedNextValidIndex = correctedDriverOrders?.findIndex((d, i) => i > selectedEventIndex && !d.isVoidOut);
      // const prevValidEvents = correctedDriverOrders?.filter((d, i) => i < selectedEventIndex && !d.isVoidOut);
      // const prevValidEventsIndices = correctedDriverOrders
      //   .map((d, i) => ({ event: d, index: i }))
      //   .filter(({event, index}) => index < selectedEventIndex && !event.isVoidOut)
      //   .map(({index}) => index); 
      // const selectedPrevValidIndex = prevValidEventsIndices?.[prevValidEvents?.length - 1];
      // const isLastIndex = selectedEventIndex == (correctedDriverOrders?.length - 1);
      // const nextValidEvent = correctedDriverOrders?.[selectedNextValidIndex];
      // if(!correctedDriverOrders?.[selectedEventIndex] || (!isLastIndex ? !nextValidEvent : false)) return [];
      // const isLastMoveEvent = isMoveEndingEvent(correctedDriverOrders?.[selectedEventIndex], nextValidEvent);
      // const arriveDeparted = isLastMoveEvent ? selectedEvent?.arrived : (nextValidEvent?.departed ?? moment().toISOString());

      // if (!arriveDeparted) return [];

      // let startDate =  isFirstIndex ? selectedLoad?.driverOrder?.[0]?.arrived : correctedDriverOrders?.[selectedPrevValidIndex]?.departed;
      // let endDate =  isLastMoveEvent ? selectedEvent?.arrived 
      //   : (nextValidEvent?.arrived ?? selectedEvent?.departed ?? selectedEvent?.arrived);

      return selectedLoadHistory?.filter(d => moment(d?.time ?? d?.date_time).isBetween(arrived, selectedEvent?.departed ?? selectedEvent?.arrived ?? moment().toISOString())).map(d => ({
        lat: d?.l?.[0],
        lng: d?.l?.[1],
        devicetime: d?.time ?? d?.date_time,
        tsp_source: d?.tsp_source
      }))?.filter(d => d?.lat && d?.lng);
    } else {
      return selectedLoadHistory?.map(d => ({
        lat: d?.l?.[0],
        lng: d?.l?.[1],
        devicetime: d?.time ?? d?.date_time,
        tsp_source: d?.tsp_source
      }))?.filter(d => d?.lat && d?.lng);
    }
  }, [correctedDriverOrders, selectedEvent, selectedLoadHistory]);


  useEffect(() => {
    dispatchTrackingHistory({
      type: TRACKING_HISTORY_ACTIONS.SET_GEOPOINTS,
      payload: { geoPoints: [driverLocationHistory ?? []], geoPointsOriginal: [driverLocationHistory] },
    });
  }, [selectedLoadHistory, selectedEvent, driverLocationHistory]);


  const map = useMap();

  const { selectedDriverOrderIndex } = selectedDriverOrder || {};

  const { geofenceCords, addressCords } = useMemo(() => {
    const { address, customerId } = selectedDriverOrder || {};
    let regularCords = [];

    if (customerId?.address?.lat && customerId?.address?.lng) {
      regularCords = [customerId?.address?.lat, customerId?.address?.lng];
    }
    else if (address?.lat && address?.lng) {
      regularCords = [address?.lat, address?.lng];
    }

    let coordsObj = { addressCords: regularCords };
    if (customerId?.geofence?.polygon?.length > 0) {
      let latlngs = customerId?.geofence?.polygon[0] || [];
      let actualLatlng = latlngs.map((arr) => { return arr[0] < 0 ? _.reverse(arr) : arr })
      if (Array.isArray(actualLatlng) && actualLatlng.every(element => Array.isArray(element))) {
        coordsObj = { ...coordsObj, geofenceCords: actualLatlng }
      }
    }
    return coordsObj;
  }, [selectedDriverOrder]);

  useEffect(() => {
    if ((!driverLocationHistory?.length > 0) && addressCords && addressCords?.length > 0) {
      map.setView(addressCords, 10);
    }
  }, [addressCords, driverLocationHistory]);

  useEffect(() => {
    const eventsCords = correctedDriverOrders?.map(d => [d?.address?.lat, d?.address?.lng])?.filter(d => d?.[0] && d?.[1]);
    if (selectedEvent) {
      if (selectedEvent?.address?.lat && selectedEvent?.address?.lng) {
        const selectedEventCords = [selectedEvent?.address?.lat, selectedEvent?.address?.lng];
        const center = [addressCords[1], addressCords[0]];

        const boundsIncludingRadius = getBoundsIncludingRadius(center, CIRCLE_GEOFENCE_RADIUS);

        const allBounds = [
          selectedEventCords,
          geofenceCords ?? [],
        ].filter(item => item.length > 0);

        if (!geofenceCords) {
          allBounds.push(boundsIncludingRadius);
        }

        map.fitBounds(allBounds, { padding: [200, 150] });
      }
    } else if (eventsCords?.length > 0) {
      if (eventsCords?.length > 0) map.fitBounds([eventsCords], { padding: [500, 150] });
    }
  }, [correctedDriverOrders, driverLocationHistory, selectedEvent, geofenceCords]);

  const twoConsecutiveEvents = useMemo(() => {
    if (!selectedEvent || !correctedDriverOrders?.length) return [];

    let firstEventIndex = correctedDriverOrders?.findIndex(d => d?._id === selectedEvent?._id);
    const firstEvent = correctedDriverOrders?.[firstEventIndex];
    // let nextEvent = {};

    // if (firstEventIndex === (correctedDriverOrders?.length - 1)) nextEvent = correctedDriverOrders?.[--firstEventIndex];
    // else nextEvent = correctedDriverOrders?.[++firstEventIndex ?? 0] ?? {};

    return [firstEvent];

  }, [selectedEvent, correctedDriverOrders]);

  return (
    <>
      {(selectedLoad && selectedEvent && (addressCords?.length > 0 || geofenceCords?.length > 0)) && (
        <>
          {
            geofenceCords
              ? (
                <Polygon positions={geofenceCords} color="#367bf6" />
              )
              :
              (
                addressCords && Array.isArray(addressCords) && addressCords.length > 0 && (
                  <Circle center={addressCords} radius={CIRCLE_GEOFENCE_RADIUS} color="#367bf6" />
                )
              )
          }
        </>
      )}

      {!selectedEvent && correctedDriverOrders?.map((d, index) => {
        const { address, customerId } = d || {};
        if (!address && !customerId) return;
        let regularCords = [];

        if (customerId?.address?.lat && customerId?.address?.lng) {
          regularCords = [customerId?.address?.lat, customerId?.address?.lng];
        } else if (address?.lat && address?.lng) {
          regularCords = [address?.lat, address?.lng];
        }

        return (
          <LegPopup
            key={d?._id}
            index={index}
            driverOrder={d}
            driverOrders={correctedDriverOrders ?? []}
            regularCords={regularCords}
            selectedDriverOrderIndex={selectedDriverOrderIndex}
          />
        );
      })}

      {
        twoConsecutiveEvents?.map((d, index) => {
          const { address, customerId } = d || {};
          if (!address && !customerId) return;
          let regularCords = [];

          if (customerId?.address?.lat && customerId?.address?.lng) {
            regularCords = [customerId?.address?.lat, customerId?.address?.lng];
          } else if (address?.lat && address?.lng) {
            regularCords = [address?.lat, address?.lng];
          }

          return (
            <LegPopup
              key={d?._id}
              index={index}
              driverOrder={d}
              driverOrders={correctedDriverOrders ?? []}
              regularCords={regularCords}
              selectedDriverOrderIndex={selectedDriverOrderIndex}
            />
          );
        })
      }
    </>
  );
}
