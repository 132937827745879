import { smallSelectStyle } from "assets/js/select-style";
import React, { useState } from "react";
import Select, { components } from "react-select";
import AsyncSelect from "react-select/async"; // Ensure AsyncSelect is imported

const RenderBadge = ({ label, index, isDisabled }) => {
  return (
    <span key={index ? index : ""} className={`badge badge-sm badge-info ${isDisabled ? "opacity-05 pointer-none" : ""}`}>
      {label}
    </span>
  );
};

const ValueContainer = ({ children, ...props }) => {
  let [values, input] = children;

  if (Array.isArray(values)) {
    const val = (i) => values[i].props.children;
    const { length } = values;

    if (length > 0) {
      const selectedOptions = values.map((element, index) => ({
        value: val(index),
        isDisabled: element.props.data.isDisabled,
      }));
      const displayText =
        length <= 4 ? (
          <div className="d-flex align-items-center text-truncate gap-5">
            {selectedOptions.map((option, index) => (
              <RenderBadge label={option.value} index={index} key={index} isDisabled={option.isDisabled} />
            ))}
          </div>
        ) : (
          <div className="d-flex align-items-center text-truncate gap-5">
            {selectedOptions.slice(0, 4).map((option, index) => (
              <RenderBadge label={option.value} index={index} key={index} isDisabled={option.isDisabled} />
            ))}

            <span data-tip data-for="remaining-selected-options" className="badge badge-sm badge-secondary">
              +{length - 4}
            </span>
          </div>
        );

      values = <>{displayText}</>;
    }
  } else if (values && values.props.data) {
    const isDisabled = values.props.data ? values.props.data.isDisabled : false;
    const displayValue = (
      <div
        style={{
          gridArea: "1 / 1 / 2 / 3",
          maxWidth: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          color: "hsl(0, 0%, 20%)",
          marginLeft: "2px",
          marginRight: "2px",
          boxSizing: "border-box",
        }}
      >
        <RenderBadge label={values.props.children} isDisabled={isDisabled} />
      </div>
    );
    values = <>{displayValue}</>;
  }

  return (
    <components.ValueContainer {...props} className="flex-nowrap">
      {values}
      {input}
    </components.ValueContainer>
  );
};

const CustomOption = ({ innerProps, label, value, isSelected, data, selectProps }) => {
  const { selectedOptions, setSelectedOptions } = selectProps;
  const isCheckboxOptions = selectProps.isCheckboxOptions; // Access isCheckboxOptions from selectProps

  // Handle checkbox selection change
  const handleChange = () => {
    if (isSelected) {
      setSelectedOptions((prev) => prev.filter((option) => option.value !== value));
    } else {
      setSelectedOptions((prev) => [...prev, data]);
    }
  };

  return (
    <div className={`rsc-option ${isSelected ? "rsc-option--selected" : ""}`} {...innerProps}>
      {isCheckboxOptions && (
        <input type="checkbox" checked={isSelected} onChange={handleChange} className="mr-10" id={label} />
      )}
      <RenderBadge label={label} isDisabled={data.isDisabled} />
    </div>
  );
};

const CustomBadgeSelectV2 = ({
  value,
  isCheckboxOptions = false,
  isAsync = false,
  isMulti = false,
  cacheOptions = false,
  placeholder,
  className,
  defaultOptions,
  loadOptions,
  options,
  menuPosition = "",
  menuIsOpen = undefined,
  onChange: handleSelectChange,
  isDisabled,
  isClearable,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(value);

  const SelectComponent = isAsync ? AsyncSelect : Select;

  return (
    <SelectComponent
      loadOptions={isAsync ? loadOptions : undefined}
      cacheOptions={cacheOptions}
      defaultOptions={defaultOptions ? defaultOptions : ""}
      options={!isAsync ? options : undefined}
      value={selectedOptions}
      onChange={(selected) => {
        handleSelectChange(selected || [])
        setSelectedOptions(selected || []);
      }}
      components={{
        Option: CustomOption,
        ValueContainer,
      }}
      isMulti={isMulti}
      closeMenuOnSelect={isMulti ? false : true}
      hideSelectedOptions={false}
      selectedOptions={selectedOptions}
      setSelectedOptions={setSelectedOptions}
      isCheckboxOptions={isCheckboxOptions} // Pass isCheckboxOptions to selectProps
      styles={smallSelectStyle}
      placeholder={placeholder}
      className={className}
      menuPosition={menuPosition}
      menuIsOpen={menuIsOpen}
      isDisabled={isDisabled}
      isClearable={isClearable}
    />
  );
};

export default CustomBadgeSelectV2;