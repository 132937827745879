import axiosClient from "./axiosClient";

export const getAppointmentScreenshots = async ({ startDate, endDate, page, limit, referenceNumber, moveType, isAutoAppointment}) => {
  if (!startDate) throw new Error("Missing required field: startDate.");
  const query = new URLSearchParams();
  query.append("startDate", startDate);
  endDate && query.append("endDate", endDate);
  limit && query.append("limit", limit ?? 10);
  page && query.append("page", parseInt(page) ?? 1);
  referenceNumber && query.append("referenceNumber", referenceNumber);
  moveType && query.append("moveType", moveType);
  isAutoAppointment && query.append("isAutoAppointment", isAutoAppointment)
  return axiosClient().get(`myapp/appointments-screenshot?${query}`);
};

export const updateAppointmentScreenshots = async ({ screenshotId, appointmentId, screenshotCategory }) => {
  const updateApptPayload = {
    appointmentId,
    screenshotCategory,
  };

  return axiosClient().put(`myapp/appointments-screenshot/${screenshotId}`, updateApptPayload);
};
