import React, { useEffect, useRef, useState } from "react";

import _ from "lodash";

import DriversList from "./index";
import { dynamicDriverAndCarrierPlaceHolder } from "services";
import DropDownInputCustom from "pages/tms/NewDispatcher/DispatcherTable/Components/portals/DropDownInputCustom";
import { VENDOR_TYPES } from "pages/tms/DrayosCarrier/constants";

const DriversSelect = (props) => {
  const { options, onChange, value, isClearable, isDisabled, className, dropDownClass,component, loadDetails, selectedDriver, showOptionTypes = ["DRIVER", VENDOR_TYPES.CARRIER, VENDOR_TYPES.BARGE], bulkHosAvailability } = props;
  const [isShow, setIsShow] = useState(false);
  const cellRef = useRef();

  return (
    <div className="text-center w-100" onClick={() => !isDisabled && setIsShow(prevState => !prevState)}>
      <div className="d-inline-block position-relative w-100">
        <DropDownInputCustom 
          Ref={cellRef}
          inputProps={
            { 
              readOnly: true,
              placeholder: `Select ${dynamicDriverAndCarrierPlaceHolder(showOptionTypes)}`,
              type: "text",
              disabled: isDisabled,
              value: value?.label || '',
              className:className,
              selectedDriver: value
            }
          }
          className={dropDownClass}
          isClearable={isClearable}
          onClearable={()=>{setIsShow(false);onChange(null)}}
          component={component} 
          isShow={isShow}
        />
        
        {isShow && (
          <DriversList
            Row={loadDetails}
            CellRef={cellRef}
            drivers={options}
            onChange={onChange}
            onClose={setIsShow}
            value={value}
            bulkHosAvailability={bulkHosAvailability}
            id="pp-overlay-container1"
            showOptionTypes={showOptionTypes}
          />
        )}
      </div>
    </div>
  );
};

export default DriversSelect;
