import React from "react";

const CellSpinner = ({ wrapperClass, className }) => {
  return (
    <div
      data-testid="cell-spinner"
      className={`text-center position-relative ${wrapperClass ? wrapperClass : ""} ${className ? className : ""}`}
    >
      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </div>
  );
};

export default CellSpinner;
