import { uploadLogoFile } from "pages/tms/Profile/actionCreators";
import { convertUrlToBuffer } from "../../actionCreator";
import { isPortproAssetsUrl } from "../../helper";

// Main function to process all images
export const downloadSignImages = async (htmlContent) => {
  // Function to extract image src attributes using regex
  const extractImageSrcs = (html) => {
    try {
      const regex = /<img [^>]*src="([^"]+)"|data:image\/[^;]+;base64,([^'"]+)/g;
      let matches;
      const srcs = [];
      while ((matches = regex.exec(html)) !== null) {
        if (matches[1]) {
          srcs.push(matches[1]);
        }
      }
      return srcs;
    } catch (error) {
      console.error("Error extracting image sources:", error);
      return [];
    }
  };

  // Download and convert single image to file
  const downloadSignatureImageFromUrl = async (src) => {
    try {
      if (!src) {
        throw new Error("Invalid image source");
      }

      if (src.startsWith("data:image/") || isPortproAssetsUrl(src)) {
        return { url: src };
      }

      const { data: bufferData } = await convertUrlToBuffer({ url: src });
      if (!bufferData) {
        throw new Error("Failed to convert URL to buffer");
      }

      const base64String = bufferData.split(",")[1];
      if (!base64String) {
        throw new Error("Invalid buffer data format");
      }

      const binaryString = atob(base64String);
      const bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      const mimeType = bufferData.split(":")[1]?.split(";")[0] || "image/jpeg";
      const filename = src.split("/").pop() || "image.jpg";

      const file = new File([bytes], filename, { type: mimeType });

      return { file, filename };
    } catch (error) {
      console.error("Error processing image:", error);
      return { url: src }; // Return original URL on error
    }
  };

  // Upload image and get URL
  const uploadImageAndGetUrl = async (src) => {
    try {
      const result = await downloadSignatureImageFromUrl(src);

      // If result contains url, return it directly (for data URLs or errors)
      if (result.url) {
        return result;
      }

      const { file, filename } = result;
      const formData = new FormData();
      formData.append("document", file);
      formData.append("filename", filename);

      const { data: uploadedImage } = await uploadLogoFile(formData);
      if (!uploadedImage) {
        throw new Error("Failed to upload image");
      }

      return uploadedImage;
    } catch (error) {
      console.error("Error uploading image:", error);
      return { url: src }; // Return original URL on error
    }
  };

  try {
    if (!htmlContent) {
      return { imgSrcs: [], uploadedImages: [] };
    }

    const imgSrcs = extractImageSrcs(htmlContent);
    if (!imgSrcs.length) {
      return { imgSrcs: [], uploadedImages: [] };
    }

    const uploadPromises = imgSrcs.map((src) => uploadImageAndGetUrl(src));
    const uploadedImages = await Promise.all(uploadPromises);

    return { imgSrcs, uploadedImages };
  } catch (error) {
    console.error("Error processing images:", error);
    return { imgSrcs: [], uploadedImages: [] };
  }
};
  