import { default as React, useState } from 'react';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { IconAngleArrowDown, IconAngleArrowTop, IconDelete } from 'Components/Common/Icons';
import { encryptEmbeddedEmailData } from '../../helper';
import { createSignature } from '../../actionCreator';
import _ from 'lodash';
import { LoaderBar } from '../../../../../Components/Common/LoaderBar';
import { amplitudeTrack, toastr } from '../../../../../services';
import EmailEditor from "../../../../../Components/Common/NewEmailEditor/EmailEditor"
import { AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE } from '../../constant';
import { downloadSignImages } from './helper';

const AddSignature = ({expandedSignature,expandSignature, data, deleteSignature, getAllEmailSignatures}) => {
    const [editorState, setEditorState] = useState('')
    const [inpBody, setInpBody] = useState();
    const [name, setName] = useState();
    const [isLoading, setIsLoading] = useState(false)
    const [isOnChange, setIsOnChange] = useState(false)

    const onEditorStateChange = (_data) => {
        if (_data === '<p><br></p>') {
            setInpBody('')
            setEditorState('')
            return
        }
        setEditorState(_data)
        setInpBody(_data)
    }

    const createNewSignature = async (id) => {
      let payload = _.cloneDeep(data);
        payload = {
            body: inpBody || payload.body,
            name: name,
            removeDashInReply: payload.removeDashInReply,
            useDefaultForNewEmail: payload.useDefaultForNewEmail,
            useDefaultForReply: payload.useDefaultForReply,
        }
        setIsLoading(true)
        if(payload?.body){
            const { imgSrcs, uploadedImages } = await downloadSignImages(payload?.body)
            imgSrcs.forEach((src, index) => {
                payload.body = payload.body.replace(src, uploadedImages[index].url);
            })
        }

        if(payload.body) payload.body = encryptEmbeddedEmailData(payload.body)
      createSignature(payload)
        .then((data) => {
          getAllEmailSignatures();
          setIsLoading(false)
          toastr.show(`Create successfully!`, 'success');
            let eventProperties = {
                source: AMPLITUDE_EVENTS_SOURCE.SIGNATURE_MODAL
            }
            amplitudeTrack(AMPLITUDE_EVENTS.SIGNATURE_CREATE, eventProperties);
        })
        .catch((err) => {
            setIsLoading(false)
          console.log("🚀 ~ createSignature ~ err:", err);
          toastr.show(`${err}`, 'error');
        });
    };
    return (
        <>
        {isLoading && <LoaderBar></LoaderBar>}
             <div className={`table-row-shadow`}>
            <div className='mb_2 rounded-5 overflow-hidden'>
                <div className='d-flex align-items-center justify-content-between bg-white p-2 border-bottom-1 border-gray-100' >
                    <div className='d-flex align-items-center' onClick={() => expandSignature(data?._id)} >
                        <h6 className={`m-0 font-12 ${name ? 'text-dark' : "text-muted"} font-weight-500`}>{name ? name : "- NO NAME"}</h6>
                       {expandedSignature?.[data?._id] ?  <IconAngleArrowTop /> : <IconAngleArrowDown />}
                    </div>
                    <div onClick={() => deleteSignature(data?._id, true)}>
                        <IconDelete className="text-muted" />
                    </div>
                </div>

                {expandedSignature?.[data?._id] && <div className='form-row'>
                    <div className='col-md-12'>
                        <div className='bg-white p-10'>
                            <div className="form-group">
                                <label><span className="text-danger">*</span>Signature name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    placeholder="No signature"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div className="form-group mb-0">
                                <EmailEditor onEditorStateChange={onEditorStateChange} editorState={editorState} setIsOnChange={setIsOnChange}/>
                            </div>
                            <div className="form-group mb-0 mt-1">
                                <div class="custom-control custom-checkbox d-flex align-items-center pl-0">
                                    <input
                                        type="checkbox"
                                        class="form-check-input rounded-3 border-secondary"
                                        id="hazmat"
                                    />
                                    <label class="m-0 pl-10" for="hazmat" id="hazmat-label">Insert signature in replies and remove the "--" line that precedes it</label>
                                </div>
                            </div>
                            <div className='d-flex justify-content-end'>
                            <button className='btn btn-link' onClick={() =>  deleteSignature(data?._id, true)}>Cancel</button>
                            <button className='btn btn-sm btn-primary' disabled={!name || !isOnChange} onClick={() => createNewSignature()}>Save Changes</button>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>   
            </div>       
        </>
    )
}

export default AddSignature
