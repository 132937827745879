import moment from "moment";
import { getStorage } from "../../../services/Common.services";
export const getDateFilter = ({ isDualTransaction = false }) => {
    const baseDate = new Date();
    const dayOptions = [
        {
            label: "All Days",
            value: "all",
            listLabel: "All Days"
        },
        {
            label: "Past Due",
            value: "past due",
            listLabel: "Past Due",
        },
        {
          label: "Today + Past Due",
          value: "today + past due",
          listLabel: "Today + Past Due",
        },
        {
            label: "Today",
            value: "0",
            listLabel: "Today",
        },
    ];

    isDualTransaction && dayOptions.unshift({
        label: "All Moves",
        value: "all moves",
        listLabel: "All Moves"
    });

    for (let i = 1; i <= 7; i++) {
        baseDate.setDate(baseDate.getDate() + 1);
        let label = baseDate.toLocaleDateString("en-US", { weekday: "long" })
        let value = label + ` (+${i})`
        if (i == 1) value = "Tomorrow (+1)"
        dayOptions.push({
            label: label,
            value: `${i}`,
            listLabel: value,
        });
    }
    return dayOptions;
};

export const getSelectedDate = (filterValue) => {
    const timeZone = getStorage("timeZone");
    let isValidDate = moment(filterValue, moment.ISO_8601).isValid();
    let date = moment().tz(timeZone).toISOString();
    if(isValidDate) {
        date = moment(filterValue).tz(timeZone).toISOString();
    } else {
        if(filterValue == "all"){
            date = null;
        } else if(filterValue == "past due") {
            date = null;
        } else if(filterValue == "today + past due") {

        } else {
            date = moment().tz(timeZone).add(filterValue, "days").toISOString();
        }
    }
    return date;
};