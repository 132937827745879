import React, { useEffect, useMemo } from "react";
import moment from "moment";
import _ from "lodash";

import { IconLiquor, IconTruck, IconWarning, IconWeightTool } from "../Icons";
import StatusComponent from "../StatusComponent";
import LiveDriverAvatar from "pages/trucker/Tracking/Components/TrackingDrivers/Drivers/Components/LiveDriverAvatar";
import { getNewAddressFormLatLng } from "pages/trucker/Tracking/utility";

import { convertDriverStatusName } from "../functions";

import LiveAddressAndTime from "pages/trucker/Tracking/Components/TrackingDrivers/Drivers/Components/LiveAddressAndTime";
import { LIVE_ENTITIES_ACTIONS, LOAD_ACTIONS } from "pages/trucker/Tracking/store/actions";
import { useCurrentUser, useFirebaseRef } from "hooks";
import { useLiveEntitiesDispatch, useTrackingLoadDispatch } from "pages/trucker/Tracking/context/TrackingContext";
import { DateTimeFormatUtils, getStorage, isCabotageEnabled, IsELDEnabled } from "../../../services";
import { cabotagePointCalculate } from "pages/tms/LoadRoutingV2/actionCreator";
import { CABOTAGE_CODE_STATUS } from "pages/tms/LoadRoutingV2/constant.js";
import { HosAvailability } from "./HosAvailability";



const DriverCard = (props) => {
    const { driver, value, cardView, onClose, onChange, index, cursor, setCursor, Row, setShowViolatedModal, hosAvailability } = props;
    const { currentCarrierId } = useCurrentUser();
    const { getFirebaseRefByNameSpace } = useFirebaseRef();
    const dispatchLoad = useTrackingLoadDispatch();
    const dispatchLiveEntities = useLiveEntitiesDispatch();
    
    const cabotageConfig = useMemo(() => {
        const timeZone = getStorage("timeZone");
        const cabotage = driver?.allInfos?.driver?.truck?.cabotage;
        return {
            workHour: cabotage ? `${cabotage?.cabotage}/${cabotage?.maxCabotage}` : '',
            cabotageDate: cabotage?.shouldExitBy ? moment(cabotage?.shouldExitBy).tz(timeZone).format(DateTimeFormatUtils.fullDateFormat()) : ''
        };
    }, [driver?.allInfos?.driver?.truck?.cabotage]);

    const selectDriver = (event, el) => {
        event.stopPropagation();
        if (!el?.isDisabled) {
            onClose();
            onChange(el);
        }
    };
    useEffect(() => {
        const driverLocationNamespace = `${currentCarrierId}/currentLocation/${driver?.value}`;
        const driverLocationRef = getFirebaseRefByNameSpace({
            overrideNamespace: driverLocationNamespace,
            enableFleetmanager: true,
        });
        driverLocationRef.once("value", handleDriverLocationUpdate);
    }, []);
    const handleDriverLocationUpdate = (snapshot) => {
        const driverLocation = snapshot?.val();


        if (driverLocation?.location?.length > 0) {
            driverLocation.location = driverLocation?.location?.reverse();
            dispatchLiveEntities({
                type: LIVE_ENTITIES_ACTIONS.ADD_DRIVER_CURRENT_COORDINATES,
                payload: {
                    entity: driver?.allInfos,
                    liveTrackingMetadata: { ...driverLocation, source: "mobile", state: "online", entityType: "driver" },
                },
            });
        }
    };

    const handleSelectDriver = async (el, driver) => {
		try {
            el.stopPropagation();

            if(!setShowViolatedModal) return selectDriver(el, driver);
			if (setShowViolatedModal && isCabotageEnabled()) {
                onClose();
				let nextValidEvent = Row?.driverOrder?.find(event => !event?.driver && !event?.drayosCarrier && !event?.isVoidOut);
				const payload = {
					newDriverId: driver?.value,
					moveId: nextValidEvent?.moveId,
					reference_number: Row?.reference_number,
				};
				cabotagePointCalculate(payload).then((res) => {
                    if (res?.code === CABOTAGE_CODE_STATUS.VIOLATED) {
                        setShowViolatedModal({ message: res?.message ?? '',  driver, res});
                    } else {
                        selectDriver(el, driver)
                    }
                })
			} else if (!el.isDisabled) {
				selectDriver(el, driver)
			}
		} catch (error) {
			console.error(error);
		}
	}


    return (
        <div
            id={`driver-${index}`}
            tabIndex="0"
            className={`d-flex flex-column gap-6 border-gray-50 rounded-5 px-2 py-1 mb-1 outline-none Ref${driver?.value}  ${(driver?.value === value?.value || cursor === index) ? "bg-gray-50" : ""} ${driver?.isDisabled ? "pointer-not-allowed opacity-05 " : "pointer"} ${cardView ? "border-1" : ""}`}
            key={driver?.value}
            onClick={(e) => handleSelectDriver(e, driver)}
            onKeyDown={(e) => e.keyCode === 13 && selectDriver(e, driver)}
            onMouseEnter={()=>setCursor(index)}
        >
            <div className="d-flex align-items-start gap-5">
                <div className="d-flex align-items-center">
                    {cardView && (
                        <div className="flex-shrink-0 align-self-center position-relative mr-10">
                            <LiveDriverAvatar driver={driver?.allInfos} />
                        </div>
                    )}

                    <div className={`text-truncate mr-15 gap-5`}>
                        <div className="d-flex align-items-center gap-5">
                        <span className="text-dark font-14 font-medium line-height-20 text-truncate text-wrap mw-100">
                            {driver.label}
                        </span>

                        <span className="badge badge-sm badge-light">
                            {`${(driver?.allInfos?.role || driver?.allInfos?.vendorType || '')?.charAt(0).toUpperCase()}${(driver?.allInfos?.role || driver?.allInfos?.vendorType || '')?.slice(1)?.toLowerCase()}`}
                        </span>
                        </div>
                        {cardView && driver?.allInfos?.role === "driver" &&<LiveAddressAndTime driver={driver?.allInfos} />}
                    </div>
                </div>
                <div className="ml-auto text-right mw-50">
                    {driver?.allInfos?.driver?.status && (
                        <StatusComponent statusObj={convertDriverStatusName(driver?.allInfos?.driver?.status, false)} />
                    )}
                    {cardView && (
                        <div className="d-flex align-items-center justify-content-end mt-1">
                            {driver?.allInfos?.driver?.hazmat && <IconWarning className="dispatch-icon text-warning-500 " />}
                            {driver?.allInfos?.driver?.overweight && (
                                <IconWeightTool className="dispatch-icon text-gray-700" />
                            )}
                            {driver?.allInfos?.driver?.liquor === "true" && (
                                <IconLiquor className="text-dark dispatch-icon" />
                            )}
                        </div>
                    )}
                </div>
            </div>
            {cardView && (
                <div>
                    <div className="d-flex align-items-center">
                        {driver?.allInfos?.driver?.truck?.equipmentID && (
                            <div className="mr-15 d-flex align-items-center">
                                <IconTruck className="text-muted" />{" "}
                                <span className="font-12 font-weight-500 text-dark ml-1">
                                    {driver?.allInfos?.driver?.truck?.equipmentID}
                                </span>
                            </div>
                        )}
                        {/* <div className="d-flex align-items-center">
                      <IconChassis className="text-muted" /> <span className="font-12 font-weight-500 text-dark ml-1">XXYZ41, 40’HC</span>
                    </div> */}
                    </div>
                </div>
            )}
            {cardView && (
                <div className="d-flex align-items-center gap-10 flex-wrap">
                   {IsELDEnabled() && driver?.allInfos?.driver?.isEldAssigned && <HosAvailability hosAvailability={hosAvailability} />}

                    {isCabotageEnabled() &&
                        <div className='d-flex align-items-center gap-3 flex-wrap'>
                            <>
                                <div className='badge badge-sm badge-brand-50 text-dark text-left'>
                                    <span className='font-normal'>Cabotage Points:</span> {cabotageConfig?.workHour ? cabotageConfig.workHour : ' -'}
                                </div>
                                <div className='badge badge-sm badge-brand-50 text-dark text-left'>
                                    <span className='font-normal'>Cabotage:</span> {cabotageConfig?.cabotageDate ? cabotageConfig.cabotageDate : ' -'}
                                </div>
                            </>
                        </div>
                    }
                </div>
            )}
        </div>

    );
};

export default DriverCard;
