import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import useWindowSize from '../../../../Components/CustomHooks/useWindowSize';
import { AMPLITUDE_EVENTS, AMPLITUDE_EVENTS_SOURCE } from '../constant';
import { StatusSelect } from './EmailStatus';
const StatusSelectOptions = ({ idName, threadId, accountId, setShowStatus, isEmailMode, isFromBottomBar, handleHideBatchMenu ,setupdateStatusData,
    setupdateStatusDataFlag}) => {
    const boxRef = useRef(null)
    const style = useBoxPosition(document.getElementById(idName), boxRef)
    return createPortal(
        <div
            ref={boxRef}
            style={style}
            className='bg-white rounded-5 p-10 d-flex gap-2 shadow-5 z-1051'
        >
            <StatusSelect
                threadId={threadId}
                accountId={accountId}
                setShowStatus={setShowStatus}
                handleHideBatchMenu={handleHideBatchMenu}
                event={AMPLITUDE_EVENTS.EMAIL_STATUS}
                isFromBottomBar={isFromBottomBar}
                eventProperties= {{ source: isEmailMode==='card' ? AMPLITUDE_EVENTS_SOURCE.CARD_BOTTOM_BAR : AMPLITUDE_EVENTS_SOURCE.GRID_BOTTOM_BAR}}
                setupdateStatusData={setupdateStatusData}
                setupdateStatusDataFlag={setupdateStatusDataFlag}
            />
        </div>,
        document.getElementById("pp-overlay-container")
    );
}

export default StatusSelectOptions;


const useBoxPosition = (cellRef, boxRef, refHeight) => {
    const [style, setStyle] = useState({
        position: "absolute",
        top: 0,
        transition: "none",
        opacity: 0,
    });

    const { height: screenH, width: screenW } = useWindowSize();

    const calculateBoxPosition = useCallback(() => {
        const {
            x: cellX,
            y: cellY,
            height: cellH,
        } = cellRef?.getBoundingClientRect();
        const { width: boxW, height: boxH } = boxRef?.current?.getBoundingClientRect();
        const clickX = cellX;
        const clickY = cellY + cellH;

        const right = screenW - clickX > boxW;
        const left = !right;
        const top = screenH - clickY > (refHeight || boxH) + 5;
        const bottom = !top;
        const style = {
            position: "absolute",
            opacity: 1,
            transition: "none",
            zIndex: 10000
        };
        if (right) {
            style.left = `${clickX + 20}px`;
        }

        if (left) {
            style.left = `${screenW - boxW}px`;
        }

        if (top) {
            style.top = `${clickY - 80}px`;
        }
        if (bottom) {
            style.bottom = `${screenH - cellY - 45}px`;
        }

        if (cellRef?.offsetWidth) {
            style.width = `${boxRef?.current?.offsetWidth}px`
        }
        setStyle(style);
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            calculateBoxPosition();
        }, 500)
        calculateBoxPosition();
        return () => clearInterval(interval);
    }, [screenH, screenW]);

    return style;
};
