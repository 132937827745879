import React, { Component, createRef } from "react";
import { Field } from "redux-form";
import { connect } from "react-redux";
import { InputField } from "../formComponent";
import AsyncSelect from "react-select/async";
import Select, {components} from "react-select";
import AsyncCreatableSelect from "react-select/async-creatable";
import * as tmsAction from "./actionCreator";
import { ReferenceInfo } from "../../../Components/Common/FeatureOf";
import { ContainerInfo } from "../../../Components/Common/FeatureOf";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { HTTP } from "../../../services/Http.service";
import { getStorage, isGlobalizationEnabled, isMilitaryTime,DateTimeFormatUtils, isStandardPort, LoadInfoLabel, amplitudeTrack, isTerminalContainerStatusEnabled, isManageFleetOrOutSource, isMileStoneEnabled, isCustomerLogin, getSyncConfig, isConnectedWithOutSource, isCustomerAccount, isSyncRequest, makeOptionsForSelectWithStatus, getConnectionStatusForTerminal, TerminalConnectionStatus, StatusDropdownWrapper, isNewEndorsement, isISOTypeEnabled, isCustomsTypeEnabled, isCabotageEnabled, isTerminalTrackingEnabled } from "../../../services/Common.services";
import { withCurrentUserSettings, withAwaitablePrompt } from "../../../hocs";
import { APPOINTMENT_MESSAGES } from "../../../constants";
import RequestedAppointmentTimeInfo from "./Components/RequestedAppointmentTimeInfo";
import ReactTooltip from "react-tooltip";
import UpdateBillCharge from "../../tms/Load/UpdateBillCharge";
import { checkAccountReceivablePermission } from "../../../services";
import { CABOTAGE_CODE_STATUS } from "../LoadRoutingV2/constant";
import { RemoveDriverModal } from "../LoadRoutingV2/RemoveDriverModal";
import { cabotagePointCalculate } from "../LoadRoutingV2/actionCreator";

import {
  editLoad,
  removeValueLoad,
  getTerminal,
  getChassisSize,
  getChassisType,
  getTMSChassisOwner,
  getTMSContainerOwner,
  UpdatePricing,
} from "../services";
import {
  toastr,
  makeOptionsForSelect,
  showForCarrier,showForTerminal
} from "./../../../services/Common.services";
import "react-datetime/css/react-datetime.css";
import configuration from "../../../config";
import {
  getChassisPickCustomers,
  getEmptyOriginCustomers,
  getChassisTerminationCustomers,
  getTMScaller,
  getTMSshipper,
  getTMSconsignee,
  getContainerType,
  getCommodityProfile,
  getContainerSize,
  getTMSChassis,
  getAllCustomers,
  getTMScallerFormatted,
  getISOEquipmentType,
} from "../Dispatcher/actionCreators";
import { bindActionCreators } from "redux";

import ExtraLabel from "../../../Components/Common/ExtraLabel";
import {
  IconCalendar,
  IconMinus,
  IconPlus,
  IconTimes,
  IconPool,
  IconInfoFilled,
  IconMilestone,
  IconCancel,
  IconQuickBook,
  IconDelete,
  IconWarning,
} from "../../../Components/Common/Icons";
import _ from "lodash";
import { newSmallSelectStyle, smallSelectStyle } from "../../../assets/js/select-style";
import confirm from "../../../Components/Common/ConfirmAert";
import DateWrapper from "../NewDispatcher/DispatcherTable/Components/CustomDatePicker/DateWrapper";
import RecommendedReturnTerminals from "./RecommendedReturnTerminals";
import CommodityComponent from "./CommodityComponent";
import { NumberFormatterV2 } from "../../../Components/CustomerServiceSideBar/Tabs/Components/NumberFormater";
import PreventChassisOptions from "./PreventChassisOptions";
import { RoutingOnly } from "../../../Components/RoutingOnly/RoutingOnly";
import { APPOINTMENT_STATUSES, MOVE_TYPES } from "../../../constants";
import { isTareWeightEnabled } from "../../../Components/Common/functions";
import PopperTooltip from "../NewDispatcher/DispatcherTable/Components/portals/PopperTooltip";
import { NO_DELIVERY_TEMPLATES } from "../carrier/BulkUploadNew/constants";
import { containerStatusOption, customsTypeOption, DUAL_TRANSACTION_MESSAGES, loadEvents } from "../constant";
import { getTMSChassis3 } from "../AddChassis/actionCreators";
import RoutingEvent from "../LoadRouting/RoutingEvent";
import SyncError from "./SyncError";
import { ALL_COMPLETED_LOADS_STATUS, SYNC_EVENT_TYPE, SYNC_REQUEST_SENT, autoAcceptDotStyle, differenceInItems, getAlertSyncMessage, getClassNameFromAlert, getMappedObjForSync, getSyncingKeys, loadInfoDates, updateClassInElements } from "./utility";
import SingleLoadSelection from "../ConnectNotices/LoadInfoChanges/SingleLoadSelection";
import MyDates from "./MyDates";
import { REQUEST_TYPES, STATUS, alertMessageType } from "../ConnectNotices/constant";
import config from "../../../config";
import ReviewButton from "./ReviewButton";
import { getEmptyOriginCustomersAsyncSearchForSelectWithStatus, getTMSShipperConsigneeChassisAsyncSearchForSelectWithStatus, getTMSconsigneeAsyncSearchForSelectWithStatus, getTMSshipperAsyncSearchForSelectWithStatus,} from "./DriverOrderUtility";
import { LoadDataContext } from "../NewDispatcher/DispatcherTable/NewDispatcherContext";
import { checkIfAllowedForDirectBooking } from "../NewDispatcher/utility";
import AlertBreakDualTransaction from "../../../Components/Common/AlertBreakDualTransaction";

let freeze = false; //mark delay
let timer; //saved timer
const firebase = config.firebase;

export const getChassisPickCustomersAsyncSearch = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        const r = await getChassisPickCustomers(strSearch); //request
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};
export const getEmptyOriginCustomersAsyncSearch = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        const r = await getEmptyOriginCustomers(strSearch); //request
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};
export const getChassisTerminationCustomersAsyncSearch = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        const r = await getChassisTerminationCustomers(strSearch); //request
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};
export const getTMSshipperAsyncSearch = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        const r = await getTMSshipper(strSearch); //request
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};

export const getTMScallerAsyncSearch = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        const r = await getTMScaller(strSearch); //request
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};

export const getTMScallerAsyncSearchFormatted = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        const r = await getTMScallerFormatted(strSearch); //request
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};


export const getTMSconsigneeAsyncSearch = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        const r = await getTMSconsignee(strSearch); //request
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};

export const getISOCodeAsyncSearch = async (strSearch) => {
  freeze = true; //set mark for stop calls
  return new Promise(async (res, err) => {
    //return promise
    let p = new Promise((res, err) => {
      if (freeze) clearTimeout(timer); //remove  prev timer
      timer = setTimeout(async () => {
        freeze = false;
        const r = await getISOEquipmentType(strSearch); //request
        res(r);
      }, 1000);
    });

    p.then(function (x) {
      res(x);
    });
  });
};

const fetchChassisOptions = async(cb) => {
  const options = await getTMSChassis()()
  cb(options)
}
const CHECKING_DATA = 'Checking Data';

const commodityItems = {
  commodity: "",
  description: "",
  pieces: "",
  units: "",
  weight: "",
  weightKgs: "",
  weightUnitType: "",
  pallets: "",
  palletsUnits: "",
};

class NewCommonLoadComponent extends Component {
  static contextType = LoadDataContext
  constructor(props) {
    super(props);
    this.state = {
      routeTypes: ["Local", "Highway"],
      showCutOffModal: false,
      bookingInfoLoading: false,
      bookingCutOffInfo: undefined,
      terminals: [],
      Terminals: [],
      ContainerDefaultValue: null,
      ContainerOwner: [],
      commodityProfiles: [],
      items: isConnectedWithOutSource() ? [] : [{ ...commodityItems }],
      terminal: "",
      showDateModal: false,
      isLoader: false,
      addCommodity: false,
      chassisOptions:[],
      areOptionsLoading:false,
      showDriverOrderStatus: false,
      hoverOverToolTip: false,
      isPickupApptPrompt: this.props.originalLoad?.pickupAppointmentSystem && this.props.originalLoad?.pickUpApptStatus !== APPOINTMENT_STATUSES.CANCELLED, // Condition for Prompt, appointment exists and status is not CANCELLED
      isEmptyApptPrompt:  this.props.originalLoad?.emptyAppointmentSystem && this.props.originalLoad?.emptyApptStatus !== APPOINTMENT_STATUSES.CANCELLED, // Condition for Prompt, appointment exists and status is not CANCELLED
      resetComponent: 0, // For Appointment confirmation cases only
      referenceNoForDeliveryLocations: [],
      hoveredElementId: null, 
      showLoadPopup: "",
      isShowLoadPopup: false,
      chassisOptionsV3: [],
      isCustomer: isCustomerLogin(),
      isDateSelected: false,
      isReviewModalVisible: false,
      alertMessages: [],
      pendingSyncRequests: [],
      syncLoader: true,
      syncBanner: true,
      selectedTab: isManageFleetOrOutSource()?.isOutSource && isSyncRequest() && isCustomerLogin() ?  "my-dates" : "carrier-dates",
      totalRecievedSyncRequests: [],
      newSyncRequests : [],
      processingRequest:false,
      isShowUpdateModal : false,
      customerInfo : null,
      showPastDateModal: false,
      allowPastDate: false,
      showViolatedModal: {},
      isShowViolatedModal: false
    };
    this.willCall = {};
    this.clearInfosOnAdd = this.clearInfosOnAdd.bind(this);
    this.onRemovePress = this.onRemovePress.bind(this);
    this.selectChassis = this.selectChassis.bind(this);
    this.selectGrayChassis = this.selectGrayChassis.bind(this);
    this.initialValues= {};
    this.userTimeFormat = isMilitaryTime() ? "MM/DD/yyyy HH:mm" : "";
    this.timeZone = getStorage("timeZone");
    this.isChassisV3 = JSON.parse(getStorage("userBasicSettings"))?.isChassisV3
    this.isPreventChassis = JSON.parse(getStorage("userBasicSettings"))?.isPreventChassis
    this.isUpdateConsigneeRouting = JSON.parse(getStorage("userBasicSettings"))?.isUpdateConsigneeRouting;
    this.isEquipmentValidations = JSON.parse(getStorage("userBasicSettings"))?.isEquipmentValidations;
    this.referenceElementRef = createRef()
    this.isManageFleetOrOutSource = isManageFleetOrOutSource()
    this.isShowDeliveryReferenceField = JSON.parse(getStorage("userBasicSettings"))?.isShowDeliveryReferenceField;
    this.isScaleEnabled = JSON.parse(getStorage("userBasicSettings"))?.isScaleEnabled;
    this.isMileStoneEnabled = isMileStoneEnabled();
    this.shiposOrConnectCarrier = isConnectedWithOutSource() && isSyncRequest();
    this.handleProcessingRequest = this.handleProcessingRequest.bind(this)
    this.isShowLiftOffReferenceField = JSON.parse(getStorage("userBasicSettings"))?.isShowLiftOffReferenceField;
    this.handleEditLoad = this.handleEditLoad.bind(this);
    this.handleEditReturnLocationLoad = this.handleEditReturnLocationLoad.bind(this);
  }

  handleApptCancelConfirm = ({promptType = ""}) => {
    if (promptType === 'all' || promptType === MOVE_TYPES.PICKUP)
      this.setState({ isPickupApptPrompt: false });
    if (promptType === 'all' || promptType === MOVE_TYPES.EMPTY)
      this.setState({ isEmptyApptPrompt: false });
  };

  handleProcessingRequest = (processingRequest) => {
    this.setState({processingRequest})
    setTimeout(() => {
      this.setState({ processingRequest: false });
    }, 5000); 
  }

  UNSAFE_componentWillReceiveProps(props) {
    let self = this;
    if (props.customerProfile) {
      if (props.customerProfile.chassisPick) this.setState({ chassisPick: "" });
      if (props.customerProfile.emptyOrigin) this.setState({ emptyOrigin: "" });
      if (props.customerProfile.chassisTermination)
        this.setState({ chassisTermination: "" });
      if (props.customerProfile.terminal && props.customerProfile.terminal._id) {
        this.setState({ terminal:{value: props.customerProfile.terminal._id, label:props.customerProfile.terminal.name} });
      }
      if (
        props.customerProfile.items 
      ) {
        let items = props.customerProfile.items.map((D) => {
          if (D._id) {
            delete D._id;
          }
          return D;
        })
        if(isConnectedWithOutSource()) {
          items = items.filter(obj => Object.values(obj).some(value => value !== "" && value !== null && value !== undefined));
        }
        self.setState({ items });
      }
      if(this.shiposOrConnectCarrier && this.state.syncLoader && !isCustomerAccount()){
        const syncFields = getSyncConfig().syncConnectFields;
        this.loadingSync(syncFields, props.customerProfile)
      }
    }
    if (props.Terminals && props.Terminals.length > 0) {
      self.setState({ Terminals: props.Terminals });
    }

    if (
      (getStorage("currentUserRole") == "fleetmanager" &&
        configuration.idForTerminal.indexOf(
          JSON.parse(getStorage("loggedInUser")).fleetManager.carrier
        ) != -1) ||
      (getStorage("currentUserRole") == "carrier" &&
        configuration.idForTerminal.indexOf(getStorage("currentUserID")) != -1)
    ) {
      if (this.state.Terminals.length === 0) {
        getTerminal().then((response) => {
          const Terminals = response.data.data ? response.data.data : [];
          self.setState({ Terminals });
        });
      }
    }
  
  // check if the updated props is from drayosTrack and set drayostrack alert based on changed props from response
  if( (props.selectedLoads.hasOwnProperty("isDrayosTrackError") || props.selectedLoads.hasOwnProperty("isDrayosTrack")) &&
    this.props.drayosTrackRefLoad==props.selectedLoads.reference_number && this.props.drayosTrackSuccess=='loader'){
      let payload={
        reference_number:this.props.selectedLoads.reference_number,
        status:props.selectedLoads.isDrayosTrackError?'warning':props.selectedLoads.isDrayosTrack?'success':'warning'
      }
      this.props.tmsAction.drayosTrackAlertStatus(payload);
    }
  }
  repetiveAPICall = (obj) => {
    for(const property in obj){
      let finalObj = {
        reference_number: this.props.customerProfile.reference_number,
        [property]: obj[property],
        source: "loadinfo",
      }
      if(property === "containerNo" || property === "grayContainerNo") {
        editLoad(finalObj).then((result)=>{
          if (result.data.data.hasContainer) {
            this.onReConfirmUpdate(
              finalObj,
              this.props.customerProfile.reference_number,
              obj[property]
            )
          }
        }).catch(()=>{})
      }
      else{
        editLoad(finalObj).then(()=>{}).catch(()=>{})
      }
    }
    this.willCall = Object.assign({})
  }
  onPickDeliveryChanges = async (time,update,i) => {
    const { isAppointmentFeatureEnabled } = this.props?.currentUserSettings || {};
    const { openAwaitablePrompt } = this.props;
    const { isDateSelected } = this.state;
    const { isPickupApptPrompt, isEmptyApptPrompt } = this.state;

    let Times = [];
    let field = time.indexOf('pickup') > -1 ? 'shipper' : 'consignee';
    let controlName = time.indexOf('pickup') > -1 ? 'pickupTimes' : 'deliveryTimes';
    if(controlName == "pickupTimes" && isAppointmentFeatureEnabled && (isPickupApptPrompt || isEmptyApptPrompt) && isDateSelected) {
      const port = this.props.selectedLoads?.shipper?.[0]?.company_name ?? this.props.selectedLoads?.shipperName;
      const isPortAllowedForDirectBooking = checkIfAllowedForDirectBooking(port, this.props.standard_ports);
      
      if (!isPortAllowedForDirectBooking) {
        const confirmed = await openAwaitablePrompt('all');
        if (!confirmed) return this.handleAwaitablePromptClose(["pickupFromTime", "pickupToTime"], i);
      }
    }

    this.props.customerProfile[field].forEach((el,i)=>{
      el._id && Times.push({ ...this.props.customerProfile[controlName][i], customerId: el._id })
    })
    let filteredPickup = JSON.stringify(Times)
   
    let obj = {
      reference_number: this.props.customerProfile.reference_number,
      [controlName]: filteredPickup,
      source: "loadinfo"
    }
    if(this.props.isRequireReference){
      toastr.show('You must enter a Reference # to save', 'error');
      this.willCall = {
        ...this.willCall,
        [controlName]: filteredPickup,
      }
      return
    }
    let arr =
      time.indexOf("pickup") > -1
        ? update === "pickupFrom"
          ? [`.pickupTo-${i}`, `.pickupFrom-${i}`]
          : [`.pickupTo-${i}`]
        : update === "deliveryFrom"
        ? [`.deliveryTo-${i}`, `.deliveryFrom-${i}`]
        : [`.deliveryTo-${i}`];

    const elementArr = arr.map((el)=>document.querySelector(el)).map((el)=>el?.querySelector("input"))

    editLoad(obj).then((result)=>{
      if(result?.data?.data === SYNC_REQUEST_SENT){
         toastr.show(SYNC_REQUEST_SENT, "success");
         this.fetchSyncChanges(true);
      }
      Times = [];
      elementArr.forEach((element)=> {element?.classList.remove("background-failure"); element?.classList.add("background-success")})
        setTimeout(()=>{
          elementArr.forEach((element)=> element?.classList.remove("background-success","text-danger"))
        },400)
    }).catch(() => {
      elementArr.forEach((element)=> element?.classList.add("background-error","text-danger"))
      setTimeout(()=>{
        elementArr.forEach((element)=> {element?.classList.add("background-failure","text-danger"); element?.classList.remove("background-error","text-danger")})
      },400)
    })
    this.setState({ isDateSelected: false });
  }

  _saveDeliveryReferenceNumber = (index) => {
    let DeliveryReference = [];
    let noChange = _.isEqual(this.props.originalLoad?.deliveryReference,this.props.customerProfile?.deliveryReference)
    if (noChange) return;

    this.props.customerProfile?.consignee?.forEach((el,i)=>{
      el._id && DeliveryReference.push({ ...this.props.customerProfile?.deliveryReference?.[i], customerId: el._id, customerName: el.company_name })
    })
    let filteredPickup = JSON.stringify(DeliveryReference)
    let obj = {
      reference_number: this.props.customerProfile.reference_number,
      deliveryReference: filteredPickup,
      source: "loadinfo"
    }
    const element =  document.querySelector(`.referenceNoForDeliveryLocations-${index}`)
    editLoad(obj).then(()=>{
      element?.classList.remove("background-failure");
      element?.classList.add("background-success");
      setTimeout(()=>{
        element?.classList.remove("background-success","text-danger");
      },400)
    }).catch(() => {
      element?.classList.add("background-error","text-danger")
      setTimeout(()=>{
        element?.classList.add("background-failure","text-danger");
        element?.classList.remove("background-error","text-danger");
      },400)
    })

  }

  onLiftoffReferenceNumberChange = (index) => {
    let noChange = _.isEqual(this.props.originalLoad?.liftOffReference,this.props.customerProfile?.liftOffReference)
    if (noChange) return;

    let liftOffReference = JSON.stringify(this.props.customerProfile?.liftOffReference)
    let obj = {
      reference_number: this.props.customerProfile.reference_number,
      liftOffReference: liftOffReference,
      source: "loadinfo"
    }
    const element =  document.querySelector(`.liftOffReferenceNo-${index}`)
    editLoad(obj).then(()=>{
      element?.classList.remove("background-failure");
      element?.classList.add("background-success");
      setTimeout(()=>{
        element?.classList.remove("background-success","text-danger");
      },400)
    }).catch(() => {
      element?.classList.add("background-error","text-danger")
      setTimeout(()=>{
        element?.classList.add("background-failure","text-danger");
        element?.classList.remove("background-error","text-danger");
      },400)
    })

  }

  onRemoveAdditionalReferenceNos = async (key, value) => {

  }

  onDateSelect = async (key,value) => {
    const { isAppointmentFeatureEnabled } = this.props?.currentUserSettings || {};
    const { openAwaitablePrompt } = this.props;

    this.setState({ showDateModal: false })
    if (key == 'lastFreeDay') {
      let eventProperties = {
        source: 'dispatcher_table'
      }
      amplitudeTrack("EDIT_LFD",eventProperties)
    }
    if(!value) return
    let finalValue = value;
    let noChange = this.props.originalLoad[key] && this.props.originalLoad[key].eta ? _.isEqual(this.props.originalLoad[key].eta,finalValue) : _.isEqual(this.props.originalLoad[key],finalValue)
    if (noChange) return

    if(key == "returnToTime" && isAppointmentFeatureEnabled && this.state.isEmptyApptPrompt) {
      const port = this.props.selectedLoads?.emptyOrigin?.company_name ?? this.props.selectedLoads?.emptyOriginName;
      const isPortAllowedForDirectBooking = checkIfAllowedForDirectBooking(port, this.props.standard_ports);

      if (!isPortAllowedForDirectBooking) {
        const confirmed = await openAwaitablePrompt(MOVE_TYPES.EMPTY);
        if (!confirmed) this.handleAwaitablePromptClose(["returnToTime"]);
      }
    }
    if(key === "readyToReturnDate" && !this.state.allowPastDate){
      const currentDate = moment().format();
      const returnDate = moment(value);
      if(returnDate.isBefore(currentDate)){
        this.setState({showPastDateModal: true})
        return;
      }
    }
    let obj = {
      reference_number: this.props.customerProfile.reference_number,
      [key]: finalValue,
      source: "loadinfo"
    }
    if(this.props.isRequireReference){
      toastr.show('You must enter a Reference # to save', 'error');
      this.willCall = {
        ...this.willCall,
        [key]: finalValue
      }
      return
    }
    const element = document.querySelector(`.${key}`);
    const inputElement = element.querySelector("input");
    editLoad(obj).then((result)=>{
      if(result?.data?.data === SYNC_REQUEST_SENT) {
        // toastr.show(SYNC_REQUEST_SENT, "success");
        this.fetchSyncChanges(true);
      } else {
        let eventProperties = {
          source: "load_info_modal",
          fieldName: key,
          value: finalValue,
          reference_number: this.props.customerProfile.reference_number
        }
        amplitudeTrack("EDIT_LOAD",eventProperties)
      }
      inputElement?.classList.remove("background-failure");
      inputElement?.classList.add("background-success");
      this.setState({showPastDateModal: false})
      this.setState({allowPastDate: false})
      setTimeout(()=>{
        inputElement?.classList.remove("background-success","text-danger");
      },400)
    }).catch((err) => {
      inputElement?.classList.add("background-error","text-danger")
      setTimeout(()=>{
        inputElement?.classList.add("background-failure","text-danger");
        inputElement?.classList.remove("background-error","text-danger");
      },400)
  })}


  onMyDateSelect = async (key, value) => {
    try {
      const loadTocheck = _.cloneDeep(this.props.originalLoad);
      if (!loadTocheck?.myDates) {
        loadTocheck.myDates = {}
      }
      if (!value) return
      let finalValue = value;
      let noChange = loadTocheck["myDates"][key] && loadTocheck["myDates"][key].eta ? _.isEqual(loadTocheck["myDates"][key].eta, finalValue) : _.isEqual(loadTocheck["myDates"][key], finalValue)
      if (noChange) return

      let obj = {
        reference_number: this.props.customerProfile.reference_number,
        myDates: {
          [key]: finalValue,
        },
        source: "loadinfo"
      }
      if (this.props.isRequireReference) {
        toastr.show('You must enter a Reference # to save', 'error');
        this.willCall = {
          ...this.willCall,
          [key]: finalValue
        }
        return
      }
      const element = document.querySelector(`.${key}`);
      let inputElement;
      if (element) {
        inputElement = element.querySelector("input");
      }
      editLoad(obj).then((result) => {
        if(result?.data?.data === SYNC_REQUEST_SENT){
          //  toastr.show(SYNC_REQUEST_SENT, "success");
           this.fetchSyncChanges(true);
        }
        inputElement?.classList.remove("background-failure");
        inputElement?.classList.add("background-success");
        setTimeout(() => {
          inputElement?.classList.remove("background-success", "text-danger");
        }, 400)
      }).catch((err) => {
        inputElement?.classList.add("background-error", "text-danger")
        setTimeout(() => {
          inputElement?.classList.add("background-failure", "text-danger");
          inputElement?.classList.remove("background-error", "text-danger");
        }, 400)
      })
    } catch (err) {
      console.log(err, "errr")
    }
  }
  onReConfirmUpdate = (data,reference_number,key) => {
    let title = "Container: " + data[key];
    let body = (
      <div>
        <h4>
            This container is already in the system.
        </h4>
        <div>
          <p>
            Do you want to save the{" "}
            {"Container: " + data[key]}{" "}
            to Load {reference_number} anyway?
          </p>
        </div>
      </div>
    );
    confirm(title, body, (confirm) => {
      if (confirm) {
        data.isContainerConfirm = true
        const element = document.querySelector(`.${key}`);
        return editLoad(data).then(()=>{
          element?.classList.remove("background-failure");
          element?.classList.add("background-success");
          setTimeout(()=>{
            element?.classList.remove("background-success","text-danger");
          },400)
        }).catch((err) => {
            element?.classList.add("background-error","text-danger");
        setTimeout(()=>{
            element?.classList.add("background-failure","text-danger");
            element?.classList.remove("background-error","text-danger");
        },400)
      });
      } else {
        this.props.tmsAction.drayosTrackAlertStatus(null);
        return
      }
    });
};
onReConfirmUpdateReferenceNo = (data,reference_number,key) => {
  let title = "Reference #: " + data[key];
  let body = (
    <div>
      <h4>
          This Reference # is already in the system.
      </h4>
      <div>
        <p>
          Do you want to save the{" "}
          {"Reference #: " + data[key]}{" "}
          to Load {reference_number} anyway?
        </p>
      </div>
    </div>
  );
  confirm(title, body, (confirm) => {
    if (confirm) {
      data.isSecondaryReferenceNoConfirm = true
      const element = document.querySelector(`.${key}`);
      return editLoad(data).then(()=>{
        element?.classList.remove("background-failure");
        element?.classList.add("background-success");
        setTimeout(()=>{
          element?.classList.remove("background-success","text-danger");
        },400)
      }).catch((err) => {
          element?.classList.add("background-error","text-danger");
      setTimeout(()=>{
          element?.classList.add("background-failure","text-danger");
          element?.classList.remove("background-error","text-danger");
      },400)
    });
    } else {
      this.props.tmsAction.drayosTrackAlertStatus(null);
      return
    }
  });
};
isLoader = (val) => {}
  onSelectFieldChange = async (key,value,isUpdateBillToCustomer = false) => {
    const { isAppointmentFeatureEnabled } = this.props?.currentUserSettings || {};
    const { openAwaitablePrompt } = this.props;

    if (isAppointmentFeatureEnabled && this.state.isEmptyApptPrompt && key === "emptyOrigin") {
      const confirmed = await openAwaitablePrompt(MOVE_TYPES.EMPTY);
      if (!confirmed) return this.handleAwaitablePromptClose(["emptyOrigin"]);
    }

    let divInElement;
    const element = document?.querySelector(`.${key}`);

    if(["hazmat", "overWeight", "liquor", "hot", "isGenset", "overHeight", "domestic", "ev", "waste", "gdp", "isRail", "scale", "isStreetTurn"].includes(key)) {
      divInElement = document?.querySelector(`#${key}-label`)
    } else {
      divInElement = element?.querySelector("div");
    }
    const elementToChange = divInElement ? divInElement : element;
    
    const valueToSend = typeof value === "string" ? value.trim() : value;

    if(valueToSend === "" && !!this.props.originalLoad[key]){
      const params = {
        reference_number: this.props.customerProfile.reference_number,
        key: key
      };
      if (!this.props.disabled) {
        removeValueLoad(params).then((result) => {
          if(result?.data?.data === SYNC_REQUEST_SENT){
            toastr.show(SYNC_REQUEST_SENT, "success");
            this.fetchSyncChanges(true);
         }
          elementToChange?.classList.remove("background-failure");
          elementToChange?.classList.add("background-success");
          setTimeout(()=>{
            elementToChange?.classList.remove("background-success","text-danger");
          },400)
          this.props.updateState(key, result.data.data[key]);
        });
      }
      return
    }
 


    if(valueToSend === "" && !this.props.originalLoad[key]) return
    let finalValue;
    if (typeof valueToSend === "boolean" || typeof valueToSend === "string") {
      if(key === "freight" || key === "custom"){
      let noChange = _.isEqual(this.props.originalLoad[key],valueToSend)
      if(noChange) return
    }
    if(this.props.originalLoad[key]){
      let noChange = key === "terminal" ? _.isEqual(this.props.originalLoad[key]._id,valueToSend) : _.isEqual(this.props.originalLoad[key],valueToSend)
      if(noChange) return // || valueToSend === "" for not allowed empty elements
    }
      finalValue = valueToSend
    }
    else {
      if(!this.isEquipmentValidations) {
        let noChange = this.props.originalLoad[key] ? _.isEqual(this.props.originalLoad[key]._id,valueToSend._id) : false
        if(noChange) return
      }
      finalValue = valueToSend._id
    }

    let obj = {
      reference_number: this.props.customerProfile.reference_number,
      [key]: finalValue,
      source: "loadinfo",
      isUpdateBillToCustomer
    }
    if(key === "hot") obj.isHot = value;
    let list = ["secondaryReferenceNo","containerNo","grayContainerNo"]
    if(this.props.isRequireReference && !list.includes(key)){
      toastr.show('You must enter a Reference # to save', 'error');
      this.willCall = {
        ...this.willCall,
        [key]: finalValue
      }
      return
    }
    //Export Detail Change drayosTRACK loader
    if(['containerSize','containerType','hazmat', 'bookingNo', 'emptyOrigin'].includes(key) && this.props.selectedLoads.type_of_load==='EXPORT'){
      let noChange = _.isEqual(this.props.originalLoad[key],value);
      if(!noChange){
        this.onExportDetailChange(key=='emptyOrigin'?value:null,key=='bookingNo'?value:null);
      }
    }

    if (["emptyOrigin"].includes(key) && isCabotageEnabled() && this.props?.selectedLoads?.driver) {
      const res = await cabotagePointCalculate({
        reference_number: obj?.reference_number,
        [key]: obj?.[key],
      });

      if (res?.code === CABOTAGE_CODE_STATUS.VIOLATED) {
        this.setState({ 
          showViolatedModal: {
             message: res?.message ?? '', 
             response: res,
             payload: obj, 
             key, 
             elementToChange, 
             finalValue, 
             fromReturnLocation: true,
            }, 
            isShowViolatedModal: true
          });
        return;
      }
    }
  
    this.handleEditReturnLocationLoad({ payload: obj, key, elementToChange, finalValue });
  }

  handleEditReturnLocationLoad = ({ payload: obj, key, elementToChange, finalValue }) => {
    if(key === "containerNo" || key === "grayContainerNo") {
      if(this.props.isRequireReference){
        toastr.show('You must enter a Reference # to save', 'error');
        this.willCall = {
          ...this.willCall,
          [key]: finalValue
        }
        return
      }
      // loaders for drayosTrack Data Fetch
      this.onPortContainerNoChange()
      editLoad(obj).then((result)=>{
        if(result?.data?.data === SYNC_REQUEST_SENT){
           toastr.show(SYNC_REQUEST_SENT, "success");
           this.fetchSyncChanges(true);
        }
        if (result.data.data.hasContainer) {
          this.onReConfirmUpdate(
            obj,
            this.props.customerProfile.reference_number,
            key
          )
        } else{
          let eventProperties = {
            source: "load_info_modal",
            fieldName: key,
            value: finalValue,
            reference_number: this.props.customerProfile.reference_number
          }
          amplitudeTrack("EDIT_LOAD",eventProperties)
          const element = document.querySelector(`.${key}`);
          element?.classList.remove("background-failure");
          element?.classList.add("background-success");
          setTimeout(()=>{
          element?.classList.remove("background-success","background-error","text-danger");
          },400)
        }
        
      }).catch((err) => {
        if(err?.data?.message== "Invalid Booking No")
          this.props.tmsAction.drayosTrackAlertStatus(null);
        const element = document.querySelector(`.${key}`);
        element?.classList.add("background-error","text-danger");
        setTimeout(()=>{
          element?.classList.add("background-failure","text-danger");
          element?.classList.remove("background-error","text-danger");
        },400)
      })
    }
    else{
      if (key === "secondaryReferenceNo") {
        obj.isCheckValidation = true
      }
      editLoad(obj).then((result)=>{
        if(result?.data?.data === SYNC_REQUEST_SENT){
           toastr.show(SYNC_REQUEST_SENT, "success");
           this.fetchSyncChanges(true);
        }
        if (result.data.data.hasSecondaryReferenceNo) {
          this.onReConfirmUpdateReferenceNo(
            obj,
            this.props.customerProfile.reference_number,
            key
          )
        } else {
          elementToChange?.classList.remove("background-failure");
          if(elementToChange.tagName === "LABEL") elementToChange.style.color = "green"
          else elementToChange?.classList.add("background-success");
          if(!_.isEqual(this.willCall, {})){
            this.repetiveAPICall(this.willCall)
          }
          let eventProperties = {
            source: "load_info_modal",
            fieldName: key,
            value: finalValue,
            reference_number: this.props.customerProfile.reference_number
          }
          amplitudeTrack("EDIT_LOAD",eventProperties)
          setTimeout(()=>{
            elementToChange?.classList.remove("background-success","text-danger");
            elementToChange.style.color = null
          },400)
        }
      }).catch((err) => {
        if(err?.data?.message== "Invalid Booking No")
          this.props.tmsAction.drayosTrackAlertStatus(null);
        elementToChange?.classList.add("background-error","text-danger");
        setTimeout(()=>{
          elementToChange?.classList.add("background-failure","text-danger");
          elementToChange?.classList.remove("background-error","text-danger");
        },400)
    })
    }
  }

  renderClassesForShowReviewButton = (field,syncIndex, event, subField) => {
    if(isConnectedWithOutSource()) {
    const alertInfo = this.getAlertInfo(field, syncIndex,event, subField )?.message;
    if (alertInfo) {
      if (alertInfo.hasOwnProperty("PENDING_RECIEVED")) {
        return " request-wrapper ";
      } else if (alertInfo.hasOwnProperty("PENDING_SENT")) {
        return " request-wrapper request-wrapper-cancel ";
      }
    }
  
    return " ";
    }
  };

  renderClassesForShowCellOfItems = (field,syncIndex, event, subField, itemId) => {
    if(isConnectedWithOutSource()) {
    const alertInfo = this.getAlertInfo(field, syncIndex,event, subField, itemId)?.message;
    if (alertInfo) {
      if (alertInfo.hasOwnProperty(alertMessageType.PENDING_RECIEVED) && alertInfo[alertMessageType.PENDING_RECIEVED]) {
        return "outline-2 rounded-5 bg-warning-50 text-warning-300";
      }  
      if (alertInfo.hasOwnProperty(alertMessageType.PENDING_SENT) && alertInfo[alertMessageType.PENDING_SENT]) {
        return "outline-primary outline-2 rounded-5 bg-brand-50";
      }
      if (alertInfo.hasOwnProperty(alertMessageType.REJECTED_SENT) && alertInfo[alertMessageType.REJECTED_SENT]) {
        return "outline-2 rounded-5 outline-danger bg-danger-50";
      }
    }
    return "";
    }
  };
  onMultipleSelectionChange = async (key,value,i) =>{
    const { isAppointmentFeatureEnabled } = this.props?.currentUserSettings || {};
    const { openAwaitablePrompt } = this.props;

    if(!value) return
    let finalValue;
    let controlField = key === "shipper" ? "pickupTimes": "deliveryTimes";
    let prevValue = this.props.originalLoad[key] && this.props.originalLoad[key].map((obj)=> obj._id)
    finalValue = value && value.map((obj)=> obj._id).filter((obj)=> !_.isEmpty(obj))
    let noChange = _.isEqual(prevValue,finalValue)
    if(noChange) return
    const Times = this.props.customerProfile[controlField].filter((obj)=> !_.isEmpty(obj))
    const finalTimes = JSON.stringify(Times)
    let obj = {
      reference_number: this.props.customerProfile.reference_number,
      [key]: finalValue,
      [controlField]: finalTimes,
      source: "loadinfo"
    }
    if(this.props.isRequireReference){
      toastr.show('You must enter a Reference # to save', 'error');
      this.willCall = {
        ...this.willCall,
        [key]: finalValue,
        [controlField]: finalTimes,
      }
      return
    }

    if (isAppointmentFeatureEnabled && this.state.isPickupApptPrompt && key === "shipper") {
      const confirmed = await openAwaitablePrompt(MOVE_TYPES.PICKUP);
      if (!confirmed) return this.handleAwaitablePromptClose(["shipper", "pickupTimes"], i);
    }

    const element = document.querySelector(`.${key}-${i} > div`)
    if(key=="shipper"){
      this.onPortContainerNoChange(value)
    }
    if (this.isShowDeliveryReferenceField && key === "consignee") {
      let _deliveryReference = _.cloneDeep(this.props.customerProfile.deliveryReference)
      finalValue.forEach((ids, i) => {
          if(ids+"" !== _deliveryReference[i].customerId) {
            let customerName = value.find((item) => item._id === ids)
            _deliveryReference[i].customerId = ids
            _deliveryReference[i].customerName = customerName.company_name
          }
      })
      obj.deliveryReference = JSON.stringify(_deliveryReference)
    }

    if (["shipper", "consignee"].includes(key) && isCabotageEnabled() && this.props?.selectedLoads?.driver) {
      const res = await cabotagePointCalculate({
        [key]: obj?.[key],
        reference_number: obj?.reference_number,
      });

      if (res?.code === CABOTAGE_CODE_STATUS.VIOLATED) {
        this.setState({ isShowViolatedModal: true, showViolatedModal: { message: res?.message ?? '', response: res, payload: obj, key, element, fromReturnLocation: false }});
        return;
      }
    }

    this.handleEditLoad({ payload: obj, key, element });
  }

  handleEditLoad = ({ payload, key, element }) => {
    editLoad(payload).then((result)=>{
      if(result?.data?.data === SYNC_REQUEST_SENT){
        toastr.show(SYNC_REQUEST_SENT, "success");
        this.fetchSyncChanges(true);
      } else {
        let eventProperties = {
          source: "load_info_modal",
          fieldName: key,
          reference_number: this.props.customerProfile.reference_number
        }
        amplitudeTrack("EDIT_LOAD",eventProperties)
      }
      element?.classList.remove("background-failure");
      element?.classList.add("background-success");
      setTimeout(()=>{
        element?.classList.remove("background-success","text-danger");
      },400)
    }).catch(() => {
      element?.classList.add("background-error","text-danger");
      setTimeout(()=>{
        element?.classList.add("background-failure","text-danger");
        element?.classList.remove("background-error","text-danger");
      },400)
    })
  }

  onReturnTimes = async (value) => {
    const { isAppointmentFeatureEnabled } = this.props?.currentUserSettings || {};
    const { openAwaitablePrompt } = this.props;
    const { isEmptyApptPrompt } = this.state;

    if(!value) return
    let finalValue = value;
    let noChange = _.isEqual(this.props.originalLoad["returnFromTime"],finalValue) && _.isEqual(this.props.originalLoad["returnToTime"],finalValue)
    if (noChange) return
    let obj = {
      reference_number: this.props.customerProfile.reference_number,
      returnFromTime: finalValue,
      returnToTime: finalValue,
      source: "loadinfo"
    }
    if(this.props.isRequireReference){
      toastr.show('You must enter a Reference # to save', 'error');
      this.willCall = {
        ...this.willCall,
      returnFromTime: finalValue,
      returnToTime: finalValue,
      }
      return
    }

    if(isAppointmentFeatureEnabled && isEmptyApptPrompt) {
      const port = this.props.selectedLoads?.emptyOrigin?.company_name ?? this.props.selectedLoads?.emptyOriginName;
      const isPortAllowedForDirectBooking = checkIfAllowedForDirectBooking(port, this.props.standard_ports);

      if (!isPortAllowedForDirectBooking) {
        const confirmed = await openAwaitablePrompt(MOVE_TYPES.EMPTY);
        if (!confirmed) {
          this.handleAwaitablePromptClose(["returnFromTime", "returnToTime"]);
          return;
        }
      }
    }

    const arr = [".returnFromTime",".returnToTime"]
    const elementArr = arr.map((el)=>document.querySelector(el)).map((el)=>el?.querySelector("input"))
    editLoad(obj).then((result)=>{
      if(result?.data?.data === SYNC_REQUEST_SENT){
         toastr.show(SYNC_REQUEST_SENT, "success");
         this.fetchSyncChanges(true);
      }
      elementArr.forEach((element)=> {element?.classList.remove("background-failure"); element?.classList.add("background-success")})
        setTimeout(()=>{
          elementArr.forEach((element)=> element?.classList.remove("background-success","text-danger"))
        },400)
    }).catch(() => {
      elementArr.forEach((element)=> element?.classList.add("background-error","text-danger"))
      setTimeout(()=>{
        elementArr.forEach((element)=> {element?.classList.add("background-failure","text-danger"); element?.classList.remove("background-error","text-danger")})
      },400)
    })}

  onRemoveField = (time, updateTime, updateList) => {
    let controlName = time.indexOf('pickup') > -1 ? 'pickupTimes' : 'deliveryTimes';
    let field = time.indexOf('pickup') > -1 ? 'shipper' : 'consignee';
    let updatedTime = updateTime.filter((obj)=> !_.isEmpty(obj))
    let filteredPickup = JSON.stringify(updatedTime)
    let list = updateList.map((obj)=> obj._id).filter((obj)=> !_.isEmpty(obj))
    let obj = {
      reference_number: this.props.customerProfile.reference_number,
      [controlName]: filteredPickup,
      [field]: list,
      source: "loadinfo"
    }
    if (this.isShowDeliveryReferenceField && time === "delivery") {
      let _deliveryReference = _.cloneDeep(this.props.customerProfile.deliveryReference)
      let newDeliveryReference = _deliveryReference.filter((item) => list.includes(item.customerId) )
      obj.deliveryReference = JSON.stringify(newDeliveryReference)
    }
    if(this.props.isRequireReference){
      toastr.show('You must enter a Reference # to save', 'error');
      this.willCall = {
      ...this.willCall,
      [controlName]: filteredPickup,
      [field]: list,
      }
      return
    }
    editLoad(obj).then((result)=>{
      if(result?.data?.data === SYNC_REQUEST_SENT){
         toastr.show(SYNC_REQUEST_SENT, "success");
         this.fetchSyncChanges(true);
      }
      toastr.show("Updated!", "success");
    }).catch((err) => {
      toastr.show('Failed to Update !', 'error');
  })
  }

  onCommodityChanges = (comdArr,index,name) => {
    comdArr.map((ele) => delete ele.volumeUnitQualifier)
    if(isConnectedWithOutSource()){
      comdArr = comdArr?.filter(obj => Object.values(obj).some(value => value !== "" && value !== null && value !== undefined))
      const isApiCallNeeded = differenceInItems({items: comdArr, ...(index > -1 && { commodityIndex: index })}, this.props.originalLoad)
      if(!isApiCallNeeded) return
    }
    let obj = {
      reference_number: this.props.customerProfile.reference_number,
      items: comdArr,
      ...(index > -1 && { commodityIndex: index }),
      source: "loadinfo"
    }
    if(this.props.isRequireReference){
      toastr.show('You must enter a Reference # to save', 'error');
      this.willCall = {
        ...this.willCall,
        items: comdArr,
      }
      return
    }
    let elementArr;
    if(name === "weight" || name === "weightKgs"){
      let elements = [`.weight-${index}`,`.weightKgs-${index}`];
      elementArr = elements.map((el)=>document.querySelector(el))
    } else {
      // let isInput = JSON.parse(getStorage("measureUnits")).isCommodityProfileShow == true
      let element = document.querySelector(`.${name}-${index}`)
      elementArr = name === "commodity" && JSON.parse(getStorage("measureUnits"))?.isCommodityProfileShow ? [element] : [element]
    }
    editLoad(obj).then((result)=>{
      if(result?.data?.data === SYNC_REQUEST_SENT){
         toastr.show(SYNC_REQUEST_SENT, "success");
         this.fetchSyncChanges(true);
         this.props.updateState("items", this.props.originalLoad?.items);
      }
      elementArr.forEach((element)=> {element?.classList.remove("background-failure"); element?.classList.add("background-success")})
        setTimeout(()=>{
          elementArr.forEach((element)=> element?.classList.remove("background-success","text-danger"))
        },400)
        !name && toastr.show("Successfully removed", "success");
    }).catch((err) => {
      elementArr.forEach((element)=> element?.classList.add("background-error","text-danger"))
      setTimeout(()=>{
        elementArr.forEach((element)=> {element?.classList.add("background-failure","text-danger"); element?.classList.remove("background-error","text-danger")})
      },400)
  })}

  tblvalchanges = (index, e, blur) => {
    const { name, value } = e.target;
    const old = [...this.state.items];
    if(value === "" &&  !this.props.originalLoad?.items[index] && this.props.originalLoad?.items[index][name]) return
    old[index][name] = (name === "description" || name === "commodity") ? value : value ? +value : "";
    if (name == "weight" && !blur) {
      old[index]["weightKgs"] = parseFloat((parseFloat(value) * 0.453592).toFixed(2));
    }
    if (name == "weightKgs" && !blur) {
      if(!(/^-?\d*\.?\d*$/).test(value)) return
      old[index]["weight"] = parseFloat((parseFloat(value || 0) * 2.20462).toFixed(2));
    }
    this.setState({
      items: [...old],
    });
    let oldItems = [
      ...this.props.originalLoad?.items,
    ]
    oldItems?.forEach((item)=> {
      item && item._id && delete item._id
    })
    
    if(!_.isEqual(old, oldItems) && blur) this.onCommodityChanges(old,index,name)
    this.props.updateState("items", old);
  };

  DeleteItemRow = (index, itemId) => {
    let data = [...this.state.items];
    if(isConnectedWithOutSource()) {
      data = data.filter(ele => ele.itemId !== itemId)
    } else {
      data = data.filter((ele, i) => i !== index)
    }
    if(this.props.isRequireReference){
      toastr.show('You must enter a Reference # to save', 'error');
      this.willCall = {
        ...this.willCall,
        items: data,
      }
      this.setState({
        items: data,
      })
      return
    }
    this.setState({
      items: data,
    },()=>{
      this.onCommodityChanges(data)
    });
    this.props.updateState("items", data);
  };

  AddItemRows = (item) => {
    if(isConnectedWithOutSource()) {
      const newItem = Object.values(item).some(value => value !== "" && value !== null && value !== undefined)
      if(!newItem) return
    }
    let items = JSON.parse(JSON.stringify(this.state.items));
    items.push(item);
    this.setState({
      items,
      addCommodity: false
    });
    this.updateCommodity(items)
  };

  updateCommodity = (items) => {
    items.map((ele) => delete ele.volumeUnitQualifier)
    if(isConnectedWithOutSource()){
      items = items?.filter(obj => Object.values(obj).some(value => value !== "" && value !== null && value !== undefined))
    }
    let obj = {
      reference_number: this.props.customerProfile.reference_number,
      items: items,
      source: "loadinfo",
    }
    editLoad(obj).then((result)=>{
      if(result?.data?.data === SYNC_REQUEST_SENT){
         toastr.show(SYNC_REQUEST_SENT, "success");
         this.setState({items: this.props.selectedLoads?.items})
         this.fetchSyncChanges(true);
      }
      let element = document.querySelector(`.fd-row-${items.length - 1 }`)
      element?.classList.add("background-success")
      setTimeout(()=>{
        element?.classList.remove("background-success")
      },400)
    })
  }

  checkUpdateCommodityIndex = (itemId, subField, isReviewButton) => {
    let updatedRequest = this.state.alertMessages.filter((alert) => alert?.value?.items?.actionType === "UPDATE" && (isReviewButton ? alert.status === STATUS.PENDING : (alert.status === STATUS.PENDING || alert.status === STATUS.REJECTED || alert.status === STATUS.AUTO_REJECTED)) && alert.value?.items?.commodity?.commodity?.itemId === itemId && (subField === "all" ? true : alert.value?.items?.commodity?.changedFields?.[subField]))
    if(updatedRequest?.length > 0) return true;
    else return false
  }

  checkRemovedCommodityIndex = (itemId) => {
    const removedRequest = this.state.alertMessages.filter((alert) => alert?.value?.items?.actionType === "REMOVE" && alert.status === STATUS.PENDING && alert.value?.items?.commodity?.itemId === itemId)
    if(removedRequest?.length > 0) return true;
    else return false
  }

  getRemovedCommodityTypeAtIndex = (itemId) => {
    const removedRequest = this.state.alertMessages?.find((alert) => alert?.value?.items?.actionType === "REMOVE" && alert.status === STATUS.PENDING && alert.value?.items?.commodity?.itemId === itemId)
    if(removedRequest?.requestType === REQUEST_TYPES.SENT) return {alertMessageType: alertMessageType.PENDING_SENT, requestID: removedRequest?.requestID }
    if(removedRequest?.requestType === REQUEST_TYPES.RECIEVED) return {alertMessageType: alertMessageType.PENDING_RECIEVED}
  }

  getUpdatedCommodityTypeAtIndex = (itemId, subField) => {
    const updatedRequest = this.state.alertMessages?.find((alert) => alert?.value?.items?.actionType === "UPDATE" && alert.status === STATUS.PENDING && alert.value?.items?.commodity?.commodity?.itemId === itemId && alert.value?.items?.commodity?.changedFields?.[subField])
    if(updatedRequest?.requestType === REQUEST_TYPES.SENT) return {alertMessageType: alertMessageType.PENDING_SENT, requestID: updatedRequest?.requestID }
    if(updatedRequest?.requestType === REQUEST_TYPES.RECIEVED) return {alertMessageType: alertMessageType.PENDING_RECIEVED}
  }
 
  updatePricing(isSaveCustomerRates) {
    let valid = true;
    var payload = {
      items: this.state.items,
    };
    if (this.props.selectedLoads) {
      payload.reference_number = this.props.selectedLoads.reference_number;
    } else {
      payload.reference_number = this.props.loads.reference_number;
    }
    if (valid) {
      UpdatePricing("tms/updateItemsLoad", payload)
        .then((response) => {
          if (this.props.updateList) {
            this.props.updateList(response.data.data);
          } else {
            this.props.tmsAction.updateLoad(response.data.data);
          }
          toastr.show("Your pricing has been updated!", "success");
        })
        .catch((err) => { });
    } else {
      toastr.show("Please enter all required fields.", "warning");
    }
  }

  getCutOffDetails(e) {
    this.props.change("bookingNo", e.target.value);
    let url = "tms/bookingInfo";
    const bookingNo = e.target.value;
    const port = this.props?.customerProfile?.shipper[0]
      ? this.props?.customerProfile?.shipper[0]?.company_name
      : "";
    if (
      port == "MAHER" ||
      port == "GLO" ||
      port == "NYCT" ||
      port == "APM" ||
      port == "PNCT"
    ) {
      url = url + "?bookingNo=" + bookingNo + "&port=" + port;
      if (
        this.props?.customerProfile?.shipper[0] &&
        this.props?.customerProfile?.shipper[0]?.company_name &&
        this.props?.customerProfile?.shipper[0]?.company_name == "MAHER"
      ) {
        this.props.TMSContainerOwner.forEach((data) => {
          if (data._id == this.props.formValue.axleload.values.containerOwner) {
            url = url + "?ssl=" + data.company_name;
          }
        });
      }
      if (
        port == "MAHER" &&
        !this.props.formValue.axleload.values.containerOwner
      ) {
        toastr.show("Please choose a SSL.");
        return;
      }
      toastr.show("We are getting booking information.");

      HTTP("GET", url, null, {
        authorization: getStorage("token"),
      })
        .then((result) => {
          if (
            result &&
            result.data.data &&
            result.data.data.result &&
            result.data.data.result.cutOff
          ) {
            this.props.datechanges("cutOff", result.data.data.result.cutOff);
          }
        })
        .catch((error) => {
          this.setState({
            bookingInfoLoading: false,
            bookingCutOffInfo: undefined,
          });
        });
    }
  }

  updateCutOff() {
    const params = {
      reference_number: this.props.customerProfile.reference_number,
      cutOff: this.state.bookingCutOffInfo.result.cutOff,
      source: "loadInfo"
    };
    editLoad(params).then((result) => {
      toastr.show("Updated!", "custom", 2000, {
        background: "#A6D92C",
        text: "#FFFFFF",
      });
      this.props.datechanges("cutOff", params.cutOff);
      this.setState({ showCutOffModal: false });
    });
  }

  selectChassis(data) {
    if (!data) return;

    const {
      value,
      label,
      allInfos: { chassisSize, chassisType, chassisOwner },
      chassisNo
    } = data;
    
    if(this.props.originalLoad.chassisNo === (chassisNo ?? label) 
      && this.props.originalLoad.chassisType?._id === chassisType?._id 
      && this.props.originalLoad.chassisOwner?._id === chassisOwner?._id 
      && this.props.originalLoad.chassisSize?._id === chassisSize?._id){
      return
    }

    setTimeout(() => {
      this.props.updateState("chassisSize", {
        _id: chassisSize?._id,
        name: chassisSize?.name,
      });
    }, 100);
    setTimeout(() => {
      this.props.updateState("chassisType", {
        _id: chassisType?._id,
        name: chassisType?.name,
      });
    }, 200);
    setTimeout(() => {
      this.props.updateState("chassisOwner", {
        _id: chassisOwner?._id,
        company_name: chassisOwner?.company_name,
      });
    }, 300);
    setTimeout(() => {
      this.props.updateState("chassisNo", chassisNo ?? label);
    }, 400);
    if (value) {
      setTimeout(() => {
        this.props.updateState("chassisId", value);
        this.props.onDdlChange({
          target: {
            name: "chassisNo",
            value: chassisNo ?? label,
          },
        });
      }, 400);
    }
    
    let chassisObj = {
      chassisId: value,
      chassisNo: chassisNo ?? label,
      chassisSize: chassisSize?._id,
      chassisType: chassisType?._id,
      chassisOwner: chassisOwner?._id,
    }
    Object.keys(chassisObj).forEach(key => chassisObj[key] === undefined && delete chassisObj[key])
    this.setState({
      ...chassisObj,
    });


    let obj = {
      reference_number: this.props.customerProfile.reference_number,
      ...chassisObj,
      source: "loadinfo"
    }
    
    if(this.props.isRequireReference){
      toastr.show('You must enter a Reference # to save', 'error');
      this.willCall = {
        ...this.willCall,
        ...chassisObj,
      }
      return
    }

    const arr = [".chassisNo",".chassisOwner",".chassisType",".chassisSize"]
    const elementArr = arr.map((el)=>document.querySelector(el)).map((el)=>el?.querySelector("div"))
    
    editLoad(obj).then((result)=>{
      if(result?.data?.data === SYNC_REQUEST_SENT){
         toastr.show(SYNC_REQUEST_SENT, "success");
         this.fetchSyncChanges(true);
      }
      elementArr.forEach((element)=> {element?.classList.remove("background-failure"); element?.classList.add("background-success")})
        setTimeout(()=>{
          elementArr.forEach((element)=> element?.classList.remove("background-success","text-danger"))
        },400)
    }).catch(() => {
      elementArr.forEach((element)=> element?.classList.add("background-error","text-danger"))
      setTimeout(()=>{
        elementArr.forEach((element)=> {element?.classList.add("background-failure","text-danger"); element?.classList.remove("background-error","text-danger")})
      },400)
    })}

  selectGrayChassis(data) {
    if (!data) return;

    const {
      value,
      label,
      allInfos: { chassisSize, chassisType, chassisOwner },
      chassisNo
    } = data;

    if(this.props.originalLoad.grayChassisNo === (chassisNo ?? label)
      && this.props.originalLoad.grayChassisType._id === chassisType._id 
      && this.props.originalLoad.grayChassisOwner._id === chassisOwner._id 
      && this.props.originalLoad.grayChassisSize._id === chassisSize._id){
      return
    }

    if(chassisSize){
      setTimeout(() => {
        this.props.updateState("grayChassisSize", {
          _id: chassisSize._id,
          name: chassisSize.name,
        });
      }, 100);
    }
    if(chassisType){
      setTimeout(() => {
        this.props.updateState("grayChassisType", {
          _id: chassisType._id,
          name: chassisType.name,
        });
      }, 200);
    }
    if(chassisOwner){
      setTimeout(() => {
        this.props.updateState("grayChassisOwner", {
          _id: chassisOwner._id,
          name: chassisOwner.company_name,
        });
      }, 300);
    }
    setTimeout(() => {
      this.props.updateState("grayChassisNo", chassisNo ?? label);
    }, 400);
    if (value) {
      setTimeout(() => {
        this.props.updateState("grayChassisId", value);
        this.props.onDdlChange({
          target: {
            name: "chassisNo",
            value: chassisNo ?? label,
          },
        });
      }, 400);
    }

    let chassisObj = {
      grayChassisId: value,
      grayChassisNo: chassisNo ?? label,
      grayChassisSize: chassisSize._id,
      grayChassisType: chassisType._id,
      grayChassisOwner: chassisOwner._id,
    }

    this.setState({
      ...chassisObj,
    });

    let obj = {
      reference_number: this.props.customerProfile.reference_number,
      ...chassisObj,
      source: "loadinfo"
    }

    if(this.props.isRequireReference){
      toastr.show('You must enter a Reference # to save', 'error');
      this.willCall = {
      ...this.willCall,
      ...chassisObj,
      }
      return
    }

    const arr = [".grayChassisNo",".grayChassisOwner",".grayChassisType",".grayChassisSize"]
    const elementArr = arr.map((el)=>document.querySelector(el)).map((el)=>el?.querySelector("div"));

    editLoad(obj).then((result)=>{
      if(result?.data?.data === SYNC_REQUEST_SENT){
         toastr.show(SYNC_REQUEST_SENT, "success");
         this.fetchSyncChanges(true);
      }
      elementArr.forEach((element)=> {element?.classList.remove("background-failure"); element?.classList.add("background-success")})
        setTimeout(()=>{
          elementArr.forEach((element)=> element?.classList.remove("background-success","text-danger"))
        },400)
    }).catch(() => {
      elementArr.forEach((element)=> element?.classList.add("background-error","text-danger"))
      setTimeout(()=>{
        elementArr.forEach((element)=> {element?.classList.add("background-failure","text-danger"); element?.classList.remove("background-error","text-danger")})
      },400)
    })}

  selectISOEquipmentType(data) {
    if (!data) return;

    const {
      value,
      label,
      allInfos,
    } = data;

    // if(this.props.originalLoad.chassisNo === label 
    //   && this.props.originalLoad.chassisType?._id === containerSize
    //   && this.props.originalLoad.chassisOwner?._id === containerType 
    //   && this.props.originalLoad.chassisSize?._id === chassisSize?._id){
    //   return
    // }
    const allContainerTypeInfo = this.props.containerTypes.find(x=>x._id+"" === allInfos.containerType)
    const allContainerSizeInfo = this.props.containerSizes.find(x=>x._id+"" === allInfos.containerSize)
    

    setTimeout(() => {
      this.props.updateState("containerType", allContainerTypeInfo);
    }, 100);
    setTimeout(() => {
      this.props.updateState("containerSize", allContainerSizeInfo);
    }, 200);
    setTimeout(() => {
      this.props.updateState("isoContainerType", label);
      this.props.onDdlChange({
        target: {
          name: "isoContainerType",
          label,
        },
      });
    }, 300);
    
    let containerObj = {
      isoContainerType: label,
      containerType: allInfos.containerType,
      containerSize: allInfos.containerSize,
    }
    this.setState({
      ...containerObj,
    });


    let obj = {
      reference_number: this.props.customerProfile.reference_number,
      ...containerObj,
      source: "loadinfo"
    }

    const arr = [".isoContainerType",".containerSize",".containerType"]
    const elementArr = arr.map((el)=>document.querySelector(el)).map((el)=>el?.querySelector("div"))
    
    editLoad(obj).then(()=>{
      elementArr.forEach((element)=> {element?.classList.remove("background-failure"); element?.classList.add("background-success")})
        setTimeout(()=>{
          elementArr.forEach((element)=> element?.classList.remove("background-success","text-danger"))
        },400)
    }).catch(() => {
      elementArr.forEach((element)=> element?.classList.add("background-error","text-danger"))
      setTimeout(()=>{
        elementArr.forEach((element)=> {element?.classList.add("background-failure","text-danger"); element?.classList.remove("background-error","text-danger")})
      },400)
    })
  }


     // On remove ReturDates (returntoTime and ReturnFromTime)
    async onRemoveReturnDate(e, input, value) {
      const { isAppointmentFeatureEnabled } = this.props?.currentUserSettings || {};
      const { openAwaitablePrompt } = this.props;
      if (isAppointmentFeatureEnabled && input?.name=="returnDate" && this.state.isEmptyApptPrompt) {
        const port = this.props.selectedLoads?.emptyOrigin?.company_name ?? this.props.selectedLoads?.emptyOriginName;
        const isPortAllowedForDirectBooking = checkIfAllowedForDirectBooking(port, this.props.standard_ports);

        if (!isPortAllowedForDirectBooking) {
          const confirmed = await openAwaitablePrompt(MOVE_TYPES.EMPTY);
          if (!confirmed) {
            if(input.name == "returnDate"){
              this.handleAwaitablePromptClose(["returnFromTime", "returnToTime"]);
            }
            return;
          }
        }
      }

      ["returnFromTime"].forEach(returnType => {
        const params = {
          reference_number: this.props.customerProfile.reference_number,
          key: returnType,
        };
        if (!this.props.disabled) {
          removeValueLoad(params).then((result) => {
            if(result?.data?.data === SYNC_REQUEST_SENT){
              toastr.show(SYNC_REQUEST_SENT, "success");
              this.fetchSyncChanges(true);
           }
            this.willCall = Object.assign({})
            const element = document.querySelector(`.${returnType}`);
            let dates = ["Date","Day","vessel","return","cutOff","loadTime"];
            const isDate = dates.some((str)=>returnType.includes(str))
            const divInElement = isDate ? element?.querySelector("input") : element?.querySelector("div");
            const elementToChange = divInElement ? divInElement : element;
            elementToChange?.classList.remove("background-failure");
            elementToChange?.classList.add("background-success");
            setTimeout(()=>{
              elementToChange?.classList.remove("background-success","text-danger");
            },400)
            this.props.updateState(returnType, result.data.data[returnType], true);
          });
        }
      });
    }
  async onRemovePress(e, input, value, isCarrierDate) {
    const { isAppointmentFeatureEnabled } = this.props?.currentUserSettings || {};
    const { openAwaitablePrompt } = this.props;
    const isClearable = this.state.pendingSyncRequests.find((ele) => ele.requestType === REQUEST_TYPES.RECIEVED && ele.status === STATUS.PENDING && ele.fieldName === input.name)
    if( isClearable && Object.keys(isClearable)?.length) return

    if (isAppointmentFeatureEnabled &&  input?.name=="emptyOrigin" && this.state.isEmptyApptPrompt) {
      const confirmed = await openAwaitablePrompt(MOVE_TYPES.EMPTY);
      if (!confirmed) {
        if (input.name == "emptyOrigin") {
          this.handleAwaitablePromptClose(["emptyOrigin"]);
        }
        return;
      }
    }
    const keyToUpdate = isCarrierDate ? `myDates.${input.name}` : input.name 
    const params = {
      reference_number: this.props.customerProfile.reference_number,
      key: keyToUpdate,
    };
    if((input.name && (['containerSize','containerType','hazmat', 'bookingNo', 'emptyOrigin'].includes(input.name) && this.props.selectedLoads.type_of_load==='EXPORT' ))||
    ((['containerNo'].includes(input.name) && this.props.selectedLoads.type_of_load==='IMPORT'))){
      this.props.tmsAction.drayosTrackAlertStatus(null);
    }
    if(input.name === "chassisNo" && value) {
      let chasses = _.cloneDeep(this.state.chassisOptions);
      let chassisIndex = chasses.findIndex((ch) => ch.chassisNo === value)
      chasses[chassisIndex].isAvailable = true
      this.setState({ chassisOptions: chasses })
    }
    if (!this.props.disabled) {
      removeValueLoad(params).then((result) => {
        if(result?.data?.data === SYNC_REQUEST_SENT){
          toastr.show(SYNC_REQUEST_SENT, "success");
          this.fetchSyncChanges(true);
       }
        this.willCall = Object.assign({})
        const cls = isCarrierDate ? `${input.name}` : input.name 
        const element = document.querySelector(`.${cls}`);
        let dates = ["Date","Day","vessel","return","cutOff","loadTime"];
        const isDate = dates.some((str)=>input.name.includes(str))
        const divInElement = isDate ? element?.querySelector("input") : element?.querySelector("div");
        const elementToChange = divInElement ? divInElement : element;
        elementToChange?.classList.remove("background-failure");
        elementToChange?.classList.add("background-success");
        setTimeout(()=>{
          elementToChange?.classList.remove("background-success","text-danger");
        },400)
        this.props.updateState(input.name, result.data.data[input.name], true);
      });
    }
  }

  async onRemovePressOnDate(e, input, index) {
    const { isAppointmentFeatureEnabled } = this.props?.currentUserSettings || {};
    const { openAwaitablePrompt } = this.props;

    if(input == "pickup" && isAppointmentFeatureEnabled && this.state.isPickupApptPrompt) {
      const port = this.props.selectedLoads?.shipper?.[0]?.company_name ?? this.props.selectedLoads?.shipperName;
      const isPortAllowedForDirectBooking = checkIfAllowedForDirectBooking(port, this.props.standard_ports);
      if (!isPortAllowedForDirectBooking) {
        const confirmed = await openAwaitablePrompt(MOVE_TYPES.PICKUP);
        if (!confirmed) return this.handleAwaitablePromptClose(["pickup"]);
      }
    }

    let params = {
      reference_number: this.props.customerProfile.reference_number,
      source: "loadInfo"
    };
    let customerProfile = JSON.parse(JSON.stringify(this.props?.customerProfile ?? {}));
    delete customerProfile[`${input}Times`][index][`${input}FromTime`];
    delete customerProfile[`${input}Times`][index][`${input}ToTime`];
    params[`${input}Times`] = customerProfile[`${input}Times`];
    params[`${input}Times`].map((d) => {
      delete d._id;
      Object.keys(d).map((key) => {
        if (!d[key]) delete d[key];
      });
    }); // delete _id and falsy keys
    const finalParams = params[`${input}Times`].filter((obj) => !_.isEmpty(obj));
    params[`${input}Times`] = JSON.stringify(finalParams);
    let arr =
      input.indexOf("pickup") > -1
        ? `${input}From` === "pickupFrom"
          ? [`.pickupTo-${index}`, `.pickupFrom-${index}`]
          : [`.pickupTo-${index}`]
        : `${input}From` === "deliveryFrom"
        ? [`.deliveryTo-${index}`, `.deliveryFrom-${index}`]
        : [`.deliveryTo-${index}`];

    const elementArr = arr.map((el)=>document.querySelector(el)).map((el)=>el?.querySelector("input"))
    
    if (!this.props.disabled) {
      editLoad(params).then((res) => {
        if(res?.data?.data === SYNC_REQUEST_SENT){
          toastr.show(SYNC_REQUEST_SENT, "success");
          this.fetchSyncChanges(true);
       }
      if (res.status === 200) {
          elementArr.forEach((element)=> {element?.classList.remove("background-failure"); element?.classList.add("background-success")})
        setTimeout(()=>{
          elementArr.forEach((element)=> element?.classList.remove("background-success","text-danger"))
        },400)
          this.props.updateState(
            `${input}Times`,
            customerProfile[`${input}Times`]
          );
        }
      });
    }
  }
  clearInfosOnAdd() {
    this.setState({
      caller: "",
      shipper0: "",
      consignee0: "",
    });
  }

  _findOption(arrayKey, value, label) {
    let data = this.props[arrayKey].find((d) => d._id === value);

    if (data) return { value: data._id, label: data[label] || data.name };

    return;
  }

  getChassisSize(searchTerm = "") {
    return new Promise((resolve, reject) => {
      getChassisSize({ searchTerm, limit: 10 })
        .then((result) => {
          let data = result.data.data;

          data = data.map((d) => ({
            value: d._id,
            label: d.name,
            allInfos: d,
          }));
          resolve(data);
        })
        .catch((error) => {
          reject(error);
          console.log(error);
        });
    });
  }
  getChassisType(searchTerm = "") {
    return new Promise((resolve, reject) => {
      getChassisType({ searchTerm, limit: 10 })
        .then((result) => {
          let data = result.data.data;
          data = data.map((d) => ({
            value: d._id,
            label: d.name,
            allInfos: d,
          }));
          resolve(data);
        })
        .catch((error) => {
          reject(error);
          console.log(error);
        });
    });
  }
  getTMSChassisOwner(searchTerm = "") {
    return new Promise((resolve, reject) => {
      getTMSChassisOwner({ searchTerm, limit: 10 })
        .then((result) => {
          let data = result.data.data;
          data = data.map((d) => ({
            value: d._id,
            label: d.company_name,
            allInfos: d,
          }));
          resolve(data);
        })
        .catch((error) => {
          reject(error);
          console.log(error);
        });
    });
  }
  getTMSContainerOwner(searchTerm = "") {
    return new Promise((resolve, reject) => {
      getTMSContainerOwner({ searchTerm, limit: 10 })
        .then((result) => {
          let data = result.data.data;
          data = data.map((d) => ({
            value: d._id,
            label: d.company_name,
            allInfos: d,
          }));
          resolve(data);
        })
        .catch((error) => {
          reject(error);
          console.log(error);
        });
    });
  }

  getChassis(searchTerm = "") {
    return new Promise((resolve, reject) => {
      this.setState({areOptionsLoading:true})
      if (this.isChassisV3) {
        let data = this.state.chassisOptionsV3.filter((d) => d.chassisNo.toLowerCase().indexOf(searchTerm.toLowerCase().trim()) != -1);
        resolve(data);
      } else {
      this.props
        .getTMSChassis({ limit: 10, searchTerm })
        .then((result) => {
          this.setState({areOptionsLoading:false})
          let data = result.map((d) => {
            const options = {
            value: d._id,
            label: d.chassisNo,
            allInfos: d,
            }
            if(this.isPreventChassis) options["isDisabled"]=!d.isAvailable
            return options
          });
          resolve(data);
        })
        .catch((err) => {
          this.setState({areOptionsLoading:false})
          console.log(err);
          reject(err);
        });
      }
    });
  }

  loadOptionsForChassis() {
    return new Promise((resolve, reject) => {
      this.setState({areOptionsLoading:true})
      this.props
        .getTMSChassis({ limit: 10 })
        .then((result) => {
          this.setState({ chassisOptions: result, areOptionsLoading:false })
          resolve(result);
        })
        .catch((err) => {
          this.setState({areOptionsLoading:false})
          console.log(err);
          reject(err);
        });
    });
  }

  getCommodityAllProfile() {
    getCommodityProfile()
      .then((data) => {
        this.setState({
          commodityProfiles: data.data.data.map((c) => ({
            label: c.isRestricted ? `${c.name} (Restricted)` : c.name,
            value: c.name,
            name: c.name,
            isDisabled: c.isRestricted,
            allInfo: c,
          })),
        });
      })
      .catch((err) => {
        console.log("err----->>", err);
      });
  }

  componentDidMount() {
    this.getCommodityAllProfile();
    if(this.shiposOrConnectCarrier) this.fetchSyncChanges();
    fetchChassisOptions((data)=>{
      this.setState({chassisOptions:data})
    })
    this.initialValues = this.props && _.cloneDeep(this.props.originalLoad);
    if(this.isChassisV3 && !this.state.isCustomer) {
      getTMSChassis3().then((res) => {
        let chassisOptionsV3 = res?.data?.data?.map((chassis) => ({
          chassisNo: chassis.chassisNo,
          value: chassis._id,
          allInfos: chassis,
          drivers: this.props.drivers,
          label: chassis.latestChassisLocation?.address
          ? `${chassis.chassisNo} - ${chassis.latestChassisLocation?.address ? chassis.latestChassisLocation?.address : ""}` 
          : chassis.chassisNo
        }))
        this.setState({chassisOptionsV3})
      }).catch((err) => {
        console.log("err", err);
      })
    }

    
  }
  componentWillUnmount() {

    this.props.tmsAction.drayosTrackAlertStatus(null);
  }

  componentWillReceiveProps(props) {
    let self = this;
    let ContainerOwner = [];
    let ContainerDefaultValue = null;
    if (props.TMSContainerOwner && props.TMSContainerOwner.length > 0) {
      props.TMSContainerOwner.forEach((owner, i) => {
        if (
          props.customerProfile &&
          props.customerProfile.containerOwner &&
          owner._id == props.customerProfile.containerOwner._id
        ) {
          ContainerDefaultValue = {
            value: owner._id,
            label: owner.company_name,
          };
        }
        ContainerOwner.push({
          label: owner.company_name,
          value: owner._id,
        });
      });
    }
    self.setState({ ContainerOwner }, () => {
      if (ContainerDefaultValue) {
        self.setState({ ContainerDefaultValue });
      }
    });

    if (
      props.newSyncRequests?.updatedAt &&
      (
        (this.state.newSyncRequests?.updatedAt &&
          new Date(props.newSyncRequests?.updatedAt)?.toISOString() !== new Date(this.state.newSyncRequests?.updatedAt)?.toISOString()
        ) ||
        !this.state.newSyncRequests?.updatedAt
      )
    ) {
      this.setState({ newSyncRequests: props.newSyncRequests }, () => {
        this.updateInfoOnRequestsChange(props.newSyncRequests, true);
      });
    }
  }

  // On Apply Tracer Data
  onApplyTracerData = (key, value) => {
    let self = this;
    if (!self.props.disabled) {
      let newValue = value;
      let newKey = key;
      if (key === "containerTypeName") {
        newValue = self.props.containerTypes.find((ty) => {
          if (
            ty.name.includes(value) ||
            (ty.pdfMap && ty.pdfMap.includes(value))
          ) {
            return ty;
          }
        });
        newKey = "containerType";
      }
      if (key === "containerSizeName") {
        newValue = self.props.containerSizes.find((ty) => {
          if (
            ty.name.includes(value) ||
            (ty.pdfMap && ty.pdfMap.includes(value))
          ) {
            return ty;
          }
        });
        newKey = "containerSize";
      }
      if (key === "containerOwnerName") {
        newValue = self.props.containerOwners.find((ty) => {
          if (
            ty.name.includes(value) ||
            (ty.pdfMap && ty.pdfMap.includes(value))
          ) {
            return ty;
          }
        });
        newKey = "containerOwner";
      }

      if (key === "chassisTypeName") {
        newValue = self.props.chassisTypes.find((ty) => {
          if (
            ty.name.includes(value) ||
            (ty.pdfMap && ty.pdfMap.includes(value))
          ) {
            return ty;
          }
        });
        newKey = "chassisType";
      }
      if (key === "chassisSizeName") {
        newValue = self.props.chassisSizes.find((ty) => {
          if (
            ty.name.includes(value) ||
            (ty.pdfMap && ty.pdfMap.includes(value))
          ) {
            return ty;
          }
        });
        newKey = "chassisSize";
      }
      if (key === "chassisOwnerName") {
        newValue = self.props.chassisOwners.find((ty) => {
          if (
            ty.name.includes(value) ||
            (ty.pdfMap && ty.pdfMap.includes(value))
          ) {
            return ty;
          }
        });
        newKey = "chassisOwner";
      }
    }
  };

  onBlurChange = async (e)=> {
    const { isAppointmentFeatureEnabled } = this.props?.currentUserSettings || {};
    const { openAwaitablePrompt } = this.props;
    const { isEmptyApptPrompt, isPickupApptPrompt } = this.state;

    if (e?.target?.name == "containerNo" && this.props.originalLoad?.containerNo !== e.target.value && isAppointmentFeatureEnabled && (isEmptyApptPrompt || isPickupApptPrompt) && this.props.originalLoad?.containerNo?.length === 0) {
      const confirmed = await openAwaitablePrompt('all');
      if (!confirmed) return this.handleAwaitablePromptClose("containerNo");
    }
    this.onSelectFieldChange(e.target.name, e.target.value )
  };

  // loaders for drayosTrack Data Fetch for IMPORTS
  onPortContainerNoChange=(shipper=null)=>{
    if((this.props.selectedLoads.type_of_load==="IMPORT" && this.props.appSettings.includes('IMPORT_AVAILABILITY_INFO') && this.props.trackosSynced)===true) { 
      let load=this.props.selectedLoads;     
      let portname=shipper?.length>0?shipper[0]?.company_name:load?.shipperName;
      // check  to see if containerNo is entered and have valid port
      let isContainer=true;
      if(shipper && ((load.containerNo && load.containerNo=="") || !load.containerNo)) isContainer=false;
      if(portname && isStandardPort(portname, this.props.standard_ports) && isContainer){
          let payload={
            reference_number:load.reference_number,
            status:'loader'
          }
          this.props.tmsAction.drayosTrackAlertStatus(payload);
      }
    }
  }

  // loaders for drayosTrack Data Fetch for EXPORT
  onExportDetailChange=(emptyOrigin=null, bookingNo=null)=>{
    if((this.props.selectedLoads.type_of_load==="EXPORT" && this.props.appSettings.includes('EXPORT_INFO') && this.props.trackosSynced)===true
    ) { 
      let load=this.props.selectedLoads;   
      let portname=emptyOrigin?.company_name?emptyOrigin.company_name:load.emptyOriginName;
      let isBooking=false;
      if((load.bookingNo && load.bookingNo!="")  || load.bookingNo || bookingNo )isBooking=true;
      if(portname && isStandardPort(portname, this.props.standard_ports) && isBooking){
          let payload={
            reference_number:load.reference_number,
            status:'loader'
          }
          this.props.tmsAction.drayosTrackAlertStatus(payload);
      }
    }
  }

  // show driver order status
  changeShowDriverOrderStatus = (value)=>{
    this.setState({
      showDriverOrderStatus: value,
    });
  }

  // handle on click add delivery location
  handleOnAddDeliveryLocation = ({deliveryTimes})=>{
    if(this.isUpdateConsigneeRouting){
      this.changeShowDriverOrderStatus(true);
    }else{
      let newConsignee = this.props.customerProfile && this.props.customerProfile.consignee ? this.props.customerProfile.consignee : [];
      newConsignee.push({});
      deliveryTimes.push({});
      this.props.updateState("deliveryTimes", deliveryTimes);
      this.props.updateState("consignee", newConsignee);
      this.setState({
        ["consignee" + newConsignee.length]: "",
      });
    }
  }

  // handle on remove delivery location
  handleOnRemoveDeliveryLocation = ({deliveryTimes, i})=>{
    if(this.isUpdateConsigneeRouting){
      this.changeShowDriverOrderStatus(true);
    }else{
      let newConsignee = this.props.customerProfile.consignee || [];
      if (newConsignee.length > 1) {
        if (this.isShowDeliveryReferenceField) {
          let newDeliveryReference = this.props.customerProfile.deliveryReference || [];
          newDeliveryReference.splice(i, 1);
          this.props.updateState("deliveryReference",newDeliveryReference);
        }
        newConsignee.splice(i, 1);
        this.props.updateState("consignee",newConsignee);
        this.setState({["consignee" + i]: "",});
        deliveryTimes.splice(i, 1);
        this.onRemoveField("delivery",deliveryTimes,newConsignee)
        this.props.updateState("deliveryTimes",deliveryTimes);
      }
    }
  }

  handleOnRemovePickupLocation = ({pickupTimes, i})=> {
    let newShipper = this.props.customerProfile.shipper || [];
    if (newShipper.length > 1) {
      newShipper.splice(i, 1);
      this.props.updateState(
        "shipper",
        newShipper
      );
      this.setState({
        ["shipper" + i]: "",
      });
      pickupTimes.splice(i, 1);
      this.onRemoveField("pickup",pickupTimes,newShipper)
      this.props.updateState(
        "pickupTimes",
        pickupTimes
      );
    }
  }

  // When user cancels the prompt, reset the original values
  handleAwaitablePromptClose = async (resetFields, fieldIndex, secondaryField, fieldWithKeyValuePair) => {
    // Reset pickup times
    if (resetFields.includes('pickupFromTime') || resetFields.includes('pickupToTime')) {
      this.props.originalLoad?.pickupTimes?.forEach((eachPickupTime) => {
        this.props.datechangesPickupDelivery("pickupFromTime", eachPickupTime.pickupFromTime, fieldIndex);
        // this.props.datechangesPickupDelivery("pickupToTime", eachPickupTime.pickupToTime, fieldIndex);
      });
    }
    // Reset return times
    if (resetFields.includes("returnFromTime") || resetFields.includes("returnToTime")) {
      this.props.datechanges("returnFromTime", this.props.originalLoad?.returnFromTime);
      setTimeout(() => {
        this.props.datechanges("returnToTime", this.props.originalLoad?.returnToTime);
      }, 200); 
    }

    // Reset Container
    if (resetFields === "containerNo") {
      this.props.updateState("containerNo", this.props.originalLoad?.containerNo, true);
    }

    // Reset PickUp Terminal
    if (resetFields.includes('shipper')) {
      const currentShipper = this.props.shippersOptions?.filter(shipper => shipper._id === this.props.originalLoad?.shipper?.[fieldIndex]?._id);
      this.props.updateState(
        "shipper",
        currentShipper
      );
      this.setState({ resetComponent: this.state.resetComponent + 1});
    }

     // Reset Return Terminal
    if (resetFields.includes('emptyOrigin')) {
      const currentEmptyOrigin = this.props.shippersOptions?.find(shipper => shipper._id === this.props.originalLoad?.emptyOrigin?._id);
      this.props.updateState(
        "emptyOrigin",
        currentEmptyOrigin
      );
      this.setState({ resetComponent: this.state.resetComponent + 1});
    }
  };

  isLoadInfoDisabled = () => {
    const chargeDisableStatus = ["APPROVED","BILLING","PARTIAL_PAID", "FULL_PAID"];
    const loadDisableStatus = ["COMPLETED", "APPROVED", "BILLING", "REBILLING", "PARTIAL_PAID", "FULL_PAID"]
    return (chargeDisableStatus.includes(this.props.selectedLoads?.chargeStatus) && loadDisableStatus.includes(this.props.selectedLoads?.status));
  }
  // Function to show the Review modal
  showReviewModal = () => {
    this.setState({ isReviewModalVisible: true });
  };

  // Function to hide the Review modal
  hideReviewModal = () => {
    this.setState({ isReviewModalVisible: false });
  };

  loadingSync = (syncFields, customerProfile) => {
    const { reviewNeededKeys, autoAcceptKeys } = getSyncingKeys(syncFields, customerProfile, this.state.items)
    updateClassInElements(reviewNeededKeys, 'loader', null, this.isManageFleetOrOutSource.isOutSource)
    this.setState({ reviewNeededKeys, autoAcceptKeys })
  }

  getAlertInfo = (fieldName, syncIndex, event, subField, itemId) => {
    let syncParam = syncIndex
    if (fieldName === "loadRoutingLocation") {
      const eventType = SYNC_EVENT_TYPE?.[event] ?? ""
      syncParam = this.props?.selectedLoads?.driverOrder?.filter(x => x.type === eventType)?.filter(x => ![loadEvents.PULLCONTAINER, loadEvents.DELIVERLOAD].includes(x?.type) || x?.customerId )?.[syncIndex]?._id ?? ""
    }
    if (fieldName === "loadRoutingApptTimes") {
      const _events = {
        pickupTimes: loadEvents.PULLCONTAINER,
        deliveryTimes: loadEvents.DELIVERLOAD,
        returnTimes: loadEvents.RETURNCONTAINER
      }
      const eventType = _events[subField]
      syncParam = this.props?.selectedLoads?.driverOrder?.filter(x => x.type === eventType)?.filter(x => ![loadEvents.PULLCONTAINER, loadEvents.DELIVERLOAD].includes(x?.type) || x?.customerId )?.[syncIndex]?._id ?? ""
    }
    if (!this.shiposOrConnectCarrier || !this.state.alertMessages || this.state.alertMessages.length === 0) {
        return { message: false };
    }
    const message = getAlertSyncMessage(this.state.alertMessages, fieldName, syncParam, event, subField, itemId)
    return message;
  };

  mapObjForClass = (alerts) => {
    if (!this.shiposOrConnectCarrier || !alerts || alerts.length === 0) return "";
    const mappedObj = getMappedObjForSync(alerts)
    for (const [key, value] of Object.entries(mappedObj)) {
      let keyToStyle = key;
      
      this.state.selectedTab === 'carrier-dates' && updateClassInElements([keyToStyle], value, null, this.isManageFleetOrOutSource.isOutSource)
    }
  };

  updateInfoOnRequestsChange = (data, isSync = false) => {
      const requests = data?.syncRequests ? _.cloneDeep(data?.syncRequests).reverse() : []
      updateClassInElements([...(this.state.reviewNeededKeys?.length ? this.state.reviewNeededKeys : []), ...(this.state.autoAcceptKeys?.length ?this.state.autoAcceptKeys : []) ], 'input-warning', true, this.isManageFleetOrOutSource.isOutSource)
      const pendingSyncRequests = data?.syncRequests?.filter((request) => request?.status === STATUS.PENDING && request?.requestType === REQUEST_TYPES.RECIEVED) ?? []
      const sentItemRequest = data?.syncRequests?.filter((request) => request?.fieldName === "items" && request?.status === STATUS.PENDING && request?.requestType === REQUEST_TYPES.SENT).map((request) => ({...request.value, requestID: request.requestID})) ?? []
      const receivedItemRequest = data?.syncRequests?.filter((request) => request?.fieldName === "items" && request?.status === STATUS.PENDING && request?.requestType === REQUEST_TYPES.RECIEVED).map((request) => request.value) ?? []
      const sentAddItemRequest = sentItemRequest?.filter((req) => req?.items?.actionType === "ADD") ?? []
      const receivedAddItemRequest = receivedItemRequest?.filter((req) => req?.items?.actionType === "ADD") ?? []
      const totalRecievedSyncRequests = data?.syncRequests?.filter((request) => request?.requestType === REQUEST_TYPES.RECIEVED) ?? []
      this.setState({
        alertMessages: requests,
        pendingSyncRequests: pendingSyncRequests,
        loadSyncRequestsData: data,
        sentAddItemRequest,
        receivedAddItemRequest,
        syncLoader: false,
        totalRecievedSyncRequests: totalRecievedSyncRequests
      }, () => {
        this.setAlertsAndClasses(requests)
        const needToDelay = (requests?.[0]?.requestType === REQUEST_TYPES.SENT && requests?.[0]?.status === STATUS.AUTO_ACCEPTED) || false;

        if (needToDelay) {
          setTimeout(() => {
            this.props.updateCustomerProfileOnRequest(isSync);
          }, 400);
        } else this.props.updateCustomerProfileOnRequest(isSync);
      });
  }
  fetchSyncChanges = async (isSync = false) => {
    if (isCustomerAccount()) return
    tmsAction.getLoadSyncRequests({ load_reference_number: this.props.selectedLoads?.reference_number }).then((data) => {
     this.updateInfoOnRequestsChange(data, isSync);
    }).catch((err) => {
      updateClassInElements(this.state.reviewNeededKeys, 'loader', true, this.isManageFleetOrOutSource.isOutSource)
      console.log("errr", err)
    })
  };

  setAlertsAndClasses = (alertMessages) => {
    updateClassInElements(this.state.reviewNeededKeys, 'loader', true, this.isManageFleetOrOutSource.isOutSource)
    this.mapObjForClass(alertMessages)
  }
  
  isPickUpSyncPending = (i) => {
    if (!this.shiposOrConnectCarrier) return false
    return ((this.getAlertInfo("loadRoutingLocation", i, "Pickup")?.message === alertMessageType.PENDING_RECIEVED))
  }

  isDeliverSyncPending = (i) => {
    if (!this.shiposOrConnectCarrier) return false
    return ((this.getAlertInfo("loadRoutingLocation", i, "Deliver")?.message === alertMessageType.PENDING_RECIEVED) )
  }

  isChargeDisable = () => {
    const chargeDisableStatus = ["APPROVED","BILLING","PARTIAL_PAID", "FULL_PAID"];
    return chargeDisableStatus.includes(this.props.selectedLoads?.chargeStatus);
  }

  updateBillToWhenCustomerUpdate = (isUpdateBillToCustomer = false) => {
    this.setState({isShowUpdateModal: false});
    this.onSelectFieldChange("caller", this.state.customerInfo, isUpdateBillToCustomer);
    this.props.updateState("caller", this.state.customerInfo);
  }
  
  renderPerDiemLFD = ({disabled})=>{
    return( <div className={`form-group col-lg-3 ${this.renderClassesForShowReviewButton("freeReturnDate")}`}>
    <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"freeReturnDate"} getAlertInfo={this.getAlertInfo} />
    <label className="label-control">{this.props.loadType === "IMPORT" ? "Return Day" : "Per Diem LFD"}</label>
      <div className="input-wrapper freeReturnDate">
      <div className="position-relative">
        <DateWrapper 
          disabled={disabled}
          onRemoveDate={(e) => {this.onRemovePress(e, { name: "freeReturnDate" });}}
          OnBlurUpdate={(e) => this.onDateSelect("freeReturnDate", this.props.customerProfile.freeReturnDate)}
          onChange={(e) => {this.props.datechanges("freeReturnDate", e) }}
          displayTimeZone={this.timeZone}
          isShowTime={false}
          defaultDate={ this.props.customerProfile && this.props.customerProfile.freeReturnDate} 
          hideShowSelectDate={true}
        />
        {this.props.customerProfile &&
        this.props.customerProfile.freeReturnDate &&
        !disabled ? (
          <div
            className="input-icon"
            onClick={(e) => {
             this.onRemovePress(e, {
                name: "freeReturnDate",
              });
            }}
          >
            <IconTimes />
          </div>
        ) : (
          <div className="input-icon">
            <IconCalendar />
          </div>
        )}
      </div>
       <SyncError alert={this.getAlertInfo("freeReturnDate")} />
      </div>
    </div>)
  }

  render() {
    let self = this;
    let ddlDisabled = false;
    let loadTime = "";
    let containerAvailableDay = "";
    let isDisabled = this.props.disabled;
    let disabled = this.props.disabled || this.isLoadInfoDisabled()
    const isProfileLocationDisabled = ALL_COMPLETED_LOADS_STATUS.includes(this.props.selectedLoads?.status)
    const { billingInvoiceEditPermission } = checkAccountReceivablePermission();
    if (this.props.newRecord) disabled = true;
    if (
      this.props &&
      this.props.customerProfile &&
      Object.keys(this.props.customerProfile).length > 0
    ) {
      if (this.props.customerProfile.loadTime) {
        loadTime = moment(this.props.customerProfile.loadTime).format(
          DateTimeFormatUtils.fullDateTimeFormat()
        );
      }
      if (this.props.customerProfile.containerAvailableDay) {
        containerAvailableDay = moment(
          this.props.customerProfile.containerAvailableDay
        ).format(DateTimeFormatUtils.fullDateFormat());
      }
      if (
        this.props.customerProfile.chassisNo &&
        typeof this.props.customerProfile.chassisNo !== "string"
      )
        ddlDisabled = true;
    }

    const {
      customerProfile,
      selectedLoads,
      containerSizes,
      containerTypes,
      containerOwners,
      chassis,
      chassisOwners,
      chassisSizes,
      chassisTypes,
      customers,
      stepsEnabled,
      initialStep,
      onExit,
      showExtraData,
      drayosTrackRefLoad,
      drayosTrackSuccess,
      isoContainerTypes
    } = this.props;

    const { AwaitablePromptAlert } = this.props;

    const {
      chassisSize,
      chassisType,
      containerSize,
      containerOwner,
      containerType,
      isoContainerType,
      chassisOwner,
      grayChassisSize,
      grayChassisType,
      grayContainerSize,
      grayContainerOwner,
      grayContainerType,
      grayChassisOwner,
      shipper,
      routeType,
      consignee,
    } = customerProfile;
    let { pickupTimes, deliveryTimes } = customerProfile;

    const shippers = shipper ? shipper : [{}];
    const consignees = consignee ? consignee : [{}];

    pickupTimes = pickupTimes ? pickupTimes : [{}];
    deliveryTimes = deliveryTimes ? deliveryTimes : [{}];
    const QueryString = window.location.search;
    let Step = [];
    let localItem = null;
    if (QueryString === "?reference") {
      Step = Step.concat(ReferenceInfo);
      localItem = localStorage.getItem("reference");
    } else if (QueryString === "?container") {
      Step = Step.concat(ContainerInfo);
      localItem = localStorage.getItem("container");
    }

    // Extra Tracers data for the loads
    let extraData = null;
    if (selectedLoads.extraTracerData) {
      extraData = selectedLoads.extraTracerData;
    }
    let isLoading=drayosTrackRefLoad==this.props.selectedLoads.reference_number && drayosTrackSuccess=='loader' && this.props.selectedLoads.type_of_load==="IMPORT";
    let isExportLoading=drayosTrackRefLoad==this.props.selectedLoads.reference_number && drayosTrackSuccess=='loader' && this.props.selectedLoads.type_of_load==="EXPORT";

    const driverOrder = this.props.selectedLoads?.driverOrder ?? []
    const deliveryEventsOfLoad = driverOrder?.filter((event)=>event.type === "DELIVERLOAD" && event?.customerId && !event.isVoidOut)
    const pickupEventsOfLoad = driverOrder?.filter((event)=>event.type === "PULLCONTAINER" && event?.customerId  && !event.isVoidOut)
    const isTenderAccepted = this.props?.selectedLoads?.driverOrder?.find(x => x.type === "RETURNCONTAINER" && x?.tenderId?.drayOSLoadRefNo)
    const totalNumberOfSyncRequests = this.state.totalRecievedSyncRequests?.filter(request=>request?.fieldName!=="isLive") ?? [];
    const liftoffEventsOfLoad = driverOrder?.filter((event)=>event.type === "LIFTOFF" && !event.isVoidOut)
    const getConnectionStatus = (obj) => 
      getConnectionStatusForTerminal({isConnected: obj.isConnected, isTrackedByTrackOS: obj?.isTrackedByTrackOS, isSystemGeneratedTerminal: obj?.isSystemGeneratedTerminal})

    const filterSelectOptionsValue = (key, option, i) => {
      const optionValue = option && option.company_name ? option : (this.state[key + i] ?? []);
      return {
        label: isTerminalTrackingEnabled() ? <StatusDropdownWrapper label={optionValue.company_name} terminal={optionValue}/> : optionValue.company_name,
        value: optionValue._id,
        allInfos: option,
      }
    }
    
    return (
      <div className="tab mt-10" id="tab-load-info">
        {totalNumberOfSyncRequests?.length > 0 && this.shiposOrConnectCarrier && this.state.syncBanner && <div className="toast-container-relative position-relative mb-10">
          <div className="toast toast-warning mw-100 text-left mb-0 py-2 pr-0">
            <div className="toast-message d-flex align-items-center">
              {totalNumberOfSyncRequests?.length} Received Changes
              <button className="btn btn-sm btn-white ml-auto" onClick={this.showReviewModal}>
                Review Changes
              </button>
              <button className="btn btn-sm btn-link" onClick={() => this.setState({ syncBanner: false })}>
                <IconCancel />
              </button>
            </div>
          </div>
        </div>}
        { this.state.processingRequest  && (<div className="custom-main-toast mt-25 d-flex justify-content-center">
                <div className="toast--sm cstm-toast-warning cstm-toast-wrapper d-flex align-items-center">
                    <div>
                        <div className="font-14 font-weight-normal text-dark">Updating data change approval statuses...</div>
                    </div>
                </div>
          </div>)
        }
        {this.state.isReviewModalVisible &&
          <SingleLoadSelection handleProcessingRequest={this.handleProcessingRequest} isShow={this.state.isReviewModalVisible} handleClose={this.hideReviewModal} selectionData={[this.state.loadSyncRequestsData]} fetchSyncLoad={this.fetchSyncChanges} isFromLoadInfo={true} />
        }


        <div className="form-row-md">
          <div className="col-lg-6 col-md-12 col-sm-12">
            {/* Customer Info */}
            <div className="card py-30 px-20 mb-20">
              <div>
                <h4 className="font-16 mb-20">
                  Load Info
                </h4>

                <div className="form-row">
                  <div className="col-lg form-group">
                    <label className="col-form-label">
                      <span className="text-danger">*</span> Customer
                    </label>
                    <AsyncSelect
                      styles={smallSelectStyle}
                      defaultOptions={makeOptionsForSelect(
                        this.props.callers &&
                          this.props.callers.concat(this.props.allCustomers),
                        "company_name",
                        "address.address",
                        "_id"
                      )}
                      cacheOptions
                      // isClearable
                      className="caller"
                      value={
                        (this.props.customerProfile &&
                          this.props.customerProfile.caller &&
                          this.props.customerProfile.caller.company_name) ||
                        this.state.caller
                          ? {
                              value: this.props.customerProfile.caller._id,
                              label:
                              this.props.customerProfile.caller.company_name,
                            }
                          : this.state.caller
                      }
                      onChange={(customer) => {
                        if (!customer) {
                          this.onRemovePress(null, { name: "caller" });
                          return;
                        }
                        if(billingInvoiceEditPermission && !this.isChargeDisable()) {
                          this.setState({
                            isShowUpdateModal : true,
                            customerInfo : customer.allInfos
                          });
                        } else {
                          this.onSelectFieldChange("caller", customer.allInfos);
                          this.props.updateState("caller", customer.allInfos);
                        }
                        // this.setState({ customer }, () => { })
                      }}
                      loadOptions={getTMScallerAsyncSearch}
                      isDisabled={disabled}
                    />
                      <SyncError alert={this.getAlertInfo("caller")} />
                  </div>
                  {this.state.isShowUpdateModal && billingInvoiceEditPermission && !this.isChargeDisable() && (
                    <UpdateBillCharge 
                      show={this.state.isShowUpdateModal} 
                      onselect={(value) => this.updateBillToWhenCustomerUpdate(value)}
                      close={() => this.setState({isShowUpdateModal: false})}
                    />
                  )}
                  { showForTerminal ()  && (
                    <div className="form-group col-lg" style={{maxWidth: 170}}>
                      <label className="label-control">Terminal<span className="text-danger">*</span></label>
                      {
                        this.state.Terminals && 
                        <Select
                          className="terminal"
                          styles={smallSelectStyle}
                          options={this.state.Terminals.map(it=>({value:it._id, label:it.name}))}
                          value={this.state.terminal}
                          onChange={(opt) => {
                            this.setState({
                              terminal: opt,
                            });
                            this.onSelectFieldChange("terminal", opt.value)
                            this.props.updateState("terminal", opt);
                          }}
                          isDisabled={disabled || isGlobalizationEnabled()}
                        />
                      }
                      <SyncError alert={this.getAlertInfo("terminal")}/>
                    </div>
                  )} 
                </div>
              </div>
          {this.props.selectedLoads.type_of_load !== "BILL_ONLY" 
          ? <>  
              <div className="hr-light my-25 mt-10"></div>
                <React.Fragment>
                  <div>
                    <h4 className="font-16 mb-10">{isTerminalTrackingEnabled() && (this.props?.selectedLoads?.type_of_load === "EXPORT") ? "Empty Pickup Location" : "Pick Up Location"}</h4>
                    <div className="form-row">
                    {shippers.map((shipper, i) => {
                      let disablePickupApt = pickupEventsOfLoad[i]?.arrived || pickupEventsOfLoad?.[i]?.tenderId?.drayOSLoadRefNo || this.isPickUpSyncPending(i);
                      const pendingEventUpdate = (this.getAlertInfo("loadRoutingLocation", i, "Pickup")?.message || this.getAlertInfo("loadRoutingApptTimes", i, "From", "pickupTimes")?.message || this.getAlertInfo("loadRoutingApptTimes", i, "To", "pickupTimes")?.message)

                          return (
                            <div key={i} className={`col-md-12 ${shippers?.length - 1 === i && (this.getAlertInfo("loadRoutingLocation", i, "Pickup")?.message) ? 'mb-10' : ''}`}>
                              <div className="form-row align-items-end">
                              <div className={`col-md-6 ${this.renderClassesForShowReviewButton("loadRoutingLocation", i, "Pickup")}`}>
                                <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal}  routingField={ {field : "loadRoutingLocation", index : i, name:'Pickup'} } getAlertInfo={this.getAlertInfo} />
                                <label>&nbsp;</label>
                                  <div className="form-group" key={i}>
                                    <div className="input-wrapper mb-0">
                                      <AsyncSelect
                                        key={this.state.resetComponent}
                                        placeholder="Select..."
                                        styles={smallSelectStyle}
                                        defaultOptions={isTerminalTrackingEnabled() ? 
                                          makeOptionsForSelectWithStatus(this.props.shippersOptions,"company_name","address.address","_id","isConnected") :
                                          makeOptionsForSelect(this.props.shippersOptions,"company_name","address.address","_id","isConnected")
                                        }
                                        className={`shipper-${i} shipper-${pickupEventsOfLoad?.[i]?._id}`}
                                        cacheOptions
                                        value={filterSelectOptionsValue('shipper', shipper, i)}
                                        onChange={async (shipper) => {
                                          const loadDetail = this.props.originalLoad;
                                          let driverOrder = loadDetail.driverOrder;
                                          let filterEvents = driverOrder.filter(dOrder => !dOrder?.isVoidOut && dOrder.type === loadEvents.PULLCONTAINER);
                                          let removeEvent = filterEvents[i];
                                          if (!shipper && i === 0 && removeEvent.type === loadEvents.PULLCONTAINER && removeEvent?.isDualTransaction && removeEvent?.dualTransactionFrom) {
                                            await AlertBreakDualTransaction(loadDetail, DUAL_TRANSACTION_MESSAGES.UPDATE_ADDRESS, () => { });
                                          }
                                          let newShipper = self.props
                                            .customerProfile.shipper || [{}];
                                          newShipper[i] = shipper.allInfos;
                                          let newPickupTimes = self.props
                                          .customerProfile.pickupTimes || [];
                                          newPickupTimes[i].customerId = shipper.value;
                                          this.setState({
                                            ["shipper" + i]: shipper.label,
                                          });
                                          this.props.updateState(
                                            "shipper",
                                            newShipper
                                            );
                                          this.props.updateState(
                                            "pickupTimes",
                                            newPickupTimes
                                            );
                                          this.onMultipleSelectionChange("shipper", newShipper,i)
                                        }}
                                        loadOptions={
                                          isTerminalTrackingEnabled() ?
                                            (["EXPORT", "ROAD"].includes(this?.props?.selectedLoads?.type_of_load) ? getTMSShipperConsigneeChassisAsyncSearchForSelectWithStatus : getTMSshipperAsyncSearchForSelectWithStatus) :
                                            getTMSshipperAsyncSearch
                                        }
                                        isDisabled={disabled || pickupEventsOfLoad?.[i]?.tenderId?.drayOSLoadRefNo}
                                      />
                                    <SyncError alert={this.getAlertInfo("loadRoutingLocation", i, "Pickup")} className={''} />
                                    </div>
                                  </div>
                              
                                </div>
                                <div className="col-md-6">
                                  <React.Fragment>
                                    <div className="form-row align-items-end">
                                    <div className={`form-group col-md  ${this.renderClassesForShowReviewButton("loadRoutingApptTimes", i, "From", "pickupTimes")}`}>
                                      <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal}  routingField={ {field : "loadRoutingApptTimes", index : i, subField: "pickupTimes", name: "From"} } getAlertInfo={this.getAlertInfo} />
                                      {<RequestedAppointmentTimeInfo label="Pick Up" moveType={MOVE_TYPES.PICKUP} load={selectedLoads} isAbsolute={true} isPortal={false}/>}
                                        <label className="label-control">
                                          From
                                        </label>
                                        <div className={`input-wrapper pickupFrom-${i} pickupTimes-From-${pickupEventsOfLoad?.[i]?._id}`}>
                                          <div className="position-relative">
                                          <DateWrapper
                                            OnBlurUpdate={()=> this.onPickDeliveryChanges("pickup","pickupFrom",i)}
                                            onChange={(e) => {
                                              this.props.datechangesPickupDelivery("pickupFromTime",e,i);
                                              // this.props.datechangesPickupDelivery("pickupToTime",e,i); 
                                              this.setState({ isDateSelected : true });
                                            }}
                                            timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                                            displayTimeZone={this.timeZone}
                                            isShowTime={true}
                                            disabled={disabled || isProfileLocationDisabled}
                                            defaultDate={pickupTimes[i] && pickupTimes[i].pickupFromTime} 
                                            hideShowSelectDate={true}
                                          />
                                          {pickupTimes[i] &&
                                          pickupTimes[i].pickupFromTime &&
                                          !(disabled || isProfileLocationDisabled) ? (
                                            <div
                                              className="input-icon"
                                              onClick={(e) => {
                                                this.onRemovePressOnDate(
                                                  e,
                                                  "pickup",
                                                  i
                                                );
                                              }}
                                            >
                                              <IconTimes />
                                            </div>
                                          ) : (
                                            <div className="input-icon">
                                              <IconCalendar />
                                            </div>
                                          )}
                                        </div>
                                        <SyncError alert={this.getAlertInfo("loadRoutingApptTimes", i, "From", "pickupTimes")} className={'position-absolute'} />
                                        </div>
                                      </div>
                                      <div className="col-md">
                                        <div className={`form-group col-md  ${this.renderClassesForShowReviewButton("loadRoutingApptTimes", i, "To", "pickupTimes")}`}>
                                          <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal}  routingField={ {field : "loadRoutingApptTimes", index : i, subField: "pickupTimes", name: "To"} } getAlertInfo={this.getAlertInfo} />
                                          <label className="label-control">
                                            To
                                          </label>
                                          <div className={`input-wrapper pickupTo-${i} pickupTimes-To-${pickupEventsOfLoad?.[i]?._id}`}>
                                          <div className="position-relative">
                                          <DateWrapper
                                            OnBlurUpdate={()=> this.onPickDeliveryChanges("pickup","pickupTo",i)}
                                            onChange={(e) => 
                                            this.props.datechangesPickupDelivery("pickupToTime",e,i)
                                            }
                                            disabled={disabled || isProfileLocationDisabled}
                                            timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                                            displayTimeZone={this.timeZone}
                                            isShowTime={true}
                                            defaultDate={pickupTimes[i] && pickupTimes[i].pickupToTime} 
                                            hideShowSelectDate={true}
                                          />
                                            {pickupTimes[i] &&
                                            pickupTimes[i].pickupToTime &&
                                            !(disabled || isProfileLocationDisabled) ? (
                                              <div
                                                className="input-icon"
                                                onClick={(e) => {
                                                  this.onRemovePressOnDate(
                                                    e,
                                                    "pickup",
                                                    i
                                                  );
                                                }}
                                              >
                                                <IconTimes />
                                              </div>
                                            ) : (
                                              <div className="input-icon">
                                                <IconCalendar />
                                              </div>
                                            )}
                                          </div>
                                        <SyncError alert={this.getAlertInfo("loadRoutingApptTimes", i, "To", "pickupTimes")} className={'position-absolute'} />
                                        </div>
                                        </div>
                                        </div>
                                        {this.props.customerProfile &&
                                          this.props.customerProfile.shipper &&
                                          this.props.customerProfile.shipper.length >
                                          1 && (
                                            <div className="col-md form-group d-flex align-items-end justify-content-end" style={disabled ? {maxWidth: 30, cursor: "not-allowed"} : {maxWidth: 30}}>
                                              {(disablePickupApt || pendingEventUpdate) && <PopperTooltip
                                                        isOpen={this.state.hoveredElementId === `removePickupApt-${i}`} 
                                                        refNo={document.getElementById(`removePickupApt-${i}`)} 
                                                        name={pendingEventUpdate ? 'This event has pending requests' : 'Pickup event is either in trip or progress'}
                                                        color={'gray-700'} 
                                                        cssClass={"load-driver"} 
                                                    />}
                                              <div
                                                className="btn btn-circle btn-xs bg-soft-danger mb-2 pointer"
                                                id={`removePickupApt-${i}`}
                                                onMouseEnter={()=>{
                                                  this.setState({hoveredElementId: `removePickupApt-${i}`})
                                                }}
                                                onMouseLeave={()=>{
                                                  this.setState({hoveredElementId: null})
                                                }}
                                                onClick={async (e) => {
                                                  if (!(disabled || disablePickupApt || pendingEventUpdate || isProfileLocationDisabled)) {
                                                    const loadDetail = this.props.originalLoad;
                                                    let driverOrder = loadDetail.driverOrder;
                                                    let filterEvents = driverOrder.filter(dOrder => !dOrder?.isVoidOut && dOrder.type === loadEvents.PULLCONTAINER);
                                                    let removeEvent = filterEvents[i];
                                                    if (i === 0 && removeEvent.type === loadEvents.PULLCONTAINER && removeEvent?.isDualTransaction && removeEvent?.dualTransactionFrom) {
                                                      await AlertBreakDualTransaction(loadDetail, DUAL_TRANSACTION_MESSAGES.REMOVE_EVENT, () => {});
                                                    }
                                                    this.handleOnRemovePickupLocation({pickupTimes, i})
                                                  }
                                                }}
                                                style={(disabled || disablePickupApt || pendingEventUpdate || isProfileLocationDisabled) ? {opacity: 0.5} : {}}
                                              >
                                                <IconMinus />
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                    </React.Fragment>
                                </div>
                                </div>
                              </div>
                            )
                        })}
                      </div>
                    <span style={disabled ? {cursor: "not-allowed"} : {}}>
                    <div
                      type="button"
                      className="btn btn-outline-primary btn-sm"
                      onClick={(e) => {
                        !disabled &&
                        !isProfileLocationDisabled &&
                        !pickupEventsOfLoad?.find((puEvent) => puEvent.tenderId?.drayOSLoadRefNo) &&
                        this.setState({ showLoadPopup: "PULLCONTAINER", isShowLoadPopup: true })
                      }}
                      style={(disabled || isProfileLocationDisabled) ? {pointerEvents: "none", opacity: 0.4} : {}}
                    >
                      <IconPlus className="mr-2" />
                      {isTerminalTrackingEnabled() && (this.props?.selectedLoads?.type_of_load === "EXPORT") ? "Add Empty Pickup Location" : "Add Pick Up Location"}
                    </div>
                    </span>
                  </div>
                  <div className="hr-light my-25"></div>
                </React.Fragment>

                <React.Fragment>
                  <div>
                    <h4 className="font-16 mb-10">{ isTerminalTrackingEnabled() && (this.props?.selectedLoads?.type_of_load === "EXPORT") ? "Loading Location" : `${LoadInfoLabel(selectedLoads?.type_of_load, "consignee")}`}
                    {NO_DELIVERY_TEMPLATES.includes( selectedLoads.preSetOrderStatus ) && selectedLoads.type_of_load !== "ROAD" && <>
                      <span 
                      onMouseEnter={()=>this.setState({ hoverOverToolTip: true })} 
                      onMouseLeave={()=> this.setState({ hoverOverToolTip: false })}
                      ref={this.referenceElementRef} className="cursor-pointer ml-2"><IconInfoFilled /></span>
                      <PopperTooltip 
                      isOpen={this.state.hoverOverToolTip} 
                      refNo={this.referenceElementRef?.current} 
                      name={"Please change routing template to add delivery location"}  
                      color={'gray-700'} 
                      cssClass={"add_view mb-3"} 
                      />
                    </>}
                    </h4>
                    <div className="form-row">
                    {consignees.map((consignee, i) => {
                      let disableRemoveApt = deliveryEventsOfLoad[i]?.arrived || deliveryEventsOfLoad?.[i]?.tenderId?.drayOSLoadRefNo || this.isDeliverSyncPending(i)
                      const pendingEventUpdate = (this.getAlertInfo("loadRoutingLocation", i, "Deliver")?.message || this.getAlertInfo("loadRoutingApptTimes", i, "From", "deliveryTimes")?.message || this.getAlertInfo("loadRoutingApptTimes", i, "To", "deliveryTimes")?.message)

                        return (
                          <div className={`col-md-12 ${consignees?.length - 1 === i && (this.getAlertInfo("loadRoutingLocation", i, "Deliver")?.message) ? 'mb-10' : ''}`} key={i}>
                            <div className="form-row align-items-end">
                            <div className={`col-md-6 ${this.renderClassesForShowReviewButton("loadRoutingLocation", i, "Deliver")}`}>
                              <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal}  routingField={ {field : "loadRoutingLocation", index : i, name:'Deliver'} } getAlertInfo={this.getAlertInfo} />
                              <label>&nbsp;</label>
                              <div className="" key={i}>
                                <div className="form-group input-wrapper">
                                  <AsyncSelect
                                    styles={{
                                      ...smallSelectStyle,
                                      control: (base, state) => ({
                                        ...smallSelectStyle.control(base, state),
                                        paddingLeft: (this.props?.customerProfile?.consignee?.length > 1 && this.isShowDeliveryReferenceField) ? "20px" : base.paddingLeft,
                                      }),
                                    }}
                                    defaultOptions={isTerminalTrackingEnabled() ? 
                                      makeOptionsForSelectWithStatus(this.props.consigneesOptions,"company_name","address.address","_id","isConnected") :
                                      makeOptionsForSelect(this.props.consigneesOptions,"company_name","address.address","_id","isConnected")
                                    }
                                    className={`consignee-${i} consignee-${deliveryEventsOfLoad?.[i]?._id}`}
                                    cacheOptions
                                    value={filterSelectOptionsValue('consignee', consignee, i)}
                                    onChange={(consignee) => {
                                      let newConsignee = self.props
                                        .customerProfile.consignee || [{}];
                                      newConsignee[i] = consignee.allInfos;
                                      let newDeliveryTimes = self.props
                                      .customerProfile.deliveryTimes || [];
                                      newDeliveryTimes[i].customerId = consignee.value;
                                      this.setState({
                                        ["consignee" + i]: consignee.label,
                                      });
                                      this.props.updateState(
                                        "consignee",
                                        newConsignee
                                      );
                                      this.props.updateState(
                                        "deliveryTimes",
                                        newDeliveryTimes
                                        );
                                      this.onMultipleSelectionChange("consignee", newConsignee,i)
                                    }}
                                    loadOptions={isTerminalTrackingEnabled() ? getTMSconsigneeAsyncSearchForSelectWithStatus : getTMSconsigneeAsyncSearch}
                                    isDisabled={disabled || deliveryEventsOfLoad?.[i]?.tenderId?.drayOSLoadRefNo || isProfileLocationDisabled}
                                  />
                                  <SyncError alert={this.getAlertInfo("loadRoutingLocation", i, "Deliver")} className={''} />
                                  {this.props.customerProfile &&
                                    this.props.customerProfile.consignee &&
                                    this.props.customerProfile.consignee.length > 1 && this.isShowDeliveryReferenceField &&
                                      <div className="input-icon left-0 right-inherit pt_6">
                                        <div className="avatar-circle avatar-xs bg-white text-dark font-10 font-weight-normal border-1 border-gray-100">
                                          {i+1}
                                        </div>
                                      </div>
                                  }      
                                </div>                       
                              </div>
                            </div>
                            <div className="col-md-6">
                                  <React.Fragment>
                                    <div className="form-row ">
                                      <div className={` col-md ${this.renderClassesForShowReviewButton("loadRoutingApptTimes", i, "From", "deliveryTimes")}`}>
                                        <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal}  routingField={ {field : "loadRoutingApptTimes", index : i, subField: "deliveryTimes", name: "From"} } getAlertInfo={this.getAlertInfo} />
                                        <label className="label-control">
                                          From
                                        </label>
                                        <div className={`input-wrapper deliveryFrom-${i} deliveryTimes-From-${deliveryEventsOfLoad?.[i]?._id}`}>
                                          <div className="position-relatice"> 
                                          <DateWrapper 
                                            disabled={disabled || isProfileLocationDisabled}
                                            OnBlurUpdate={()=> this.onPickDeliveryChanges("delivery","deliveryFrom",i)}
                                            onChange={(e) => {
                                              this.props.datechangesPickupDelivery("deliveryFromTime",e,i);
                                              // this.props.datechangesPickupDelivery("deliveryToTime",e,i); 
                                            }}
                                            timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                                            displayTimeZone={this.timeZone}
                                            isShowTime={true}
                                            defaultDate={deliveryTimes[i] && deliveryTimes[i].deliveryFromTime} 
                                            hideShowSelectDate={true}
                                          />
                                          {deliveryTimes[i] &&
                                          deliveryTimes[i].deliveryFromTime &&
                                          !(disabled || isProfileLocationDisabled) ? (
                                            <div
                                              className="input-icon"
                                              onClick={(e) => {
                                                this.onRemovePressOnDate(
                                                  e,
                                                  "delivery",
                                                  i
                                                );
                                              }}
                                            >
                                              <IconTimes />
                                            </div>
                                          ) : (
                                            <div className="input-icon">
                                              <IconCalendar />
                                            </div>
                                          )}
                                          </div>
                                        <SyncError alert={this.getAlertInfo("loadRoutingApptTimes", i, "From", "deliveryTimes")} className={'position-absolute'} />
                                        </div>
                                      </div>
                                      <div className="col-md">
                                      <div className={`form-group mb-10 ${this.renderClassesForShowReviewButton("loadRoutingApptTimes", i, "To", "deliveryTimes")}`}>
                                        <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal}  routingField={ {field : "loadRoutingApptTimes", index : i, subField: "deliveryTimes", name: "To"} } getAlertInfo={this.getAlertInfo} />
                                          <label className="label-control">
                                            To
                                          </label>
                                          <div className={`input-wrapper deliveryTo-${i} deliveryTimes-To-${deliveryEventsOfLoad?.[i]?._id}`}>
                                          <div className="position-relatice">
                                          <DateWrapper 
                                            disabled={disabled || isProfileLocationDisabled}
                                            OnBlurUpdate={()=> this.onPickDeliveryChanges("delivery","deliveryTo",i)}
                                            onChange={(e) => {
                                              this.props.datechangesPickupDelivery("deliveryToTime",e,i);
                                            }}
                                            displayTimeZone={this.timeZone}
                                            isShowTime={true}
                                            timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                                            defaultDate={deliveryTimes[i] && deliveryTimes[i].deliveryToTime} 
                                            hideShowSelectDate={true}
                                          />
                                            {deliveryTimes[i] &&
                                            deliveryTimes[i].deliveryToTime &&
                                            !(disabled || isProfileLocationDisabled) ? (
                                              <div
                                                className="input-icon"
                                                onClick={(e) => {
                                                  this.onRemovePressOnDate(
                                                    e,
                                                    "delivery",
                                                    i
                                                  );
                                                }}
                                              >
                                                <IconTimes />
                                              </div>
                                            ) : (
                                              <div className="input-icon">
                                                <IconCalendar />
                                              </div>
                                            )}
                                            </div>
                                          </div>
                                          <SyncError alert={this.getAlertInfo("loadRoutingApptTimes", i, "To", "deliveryTimes")} className={'position-absolute'} />
                                        </div>
                                      </div>
                                      {this.props.customerProfile &&
                                    this.props.customerProfile.consignee &&
                                    this.props.customerProfile.consignee
                                      .length > 1 && (
                                        <div className="form-group col-md d-flex align-items-end justify-content-end" style={disabled ? {maxWidth: 30, cursor: "not-allowed"} : {maxWidth: 30}}>
                                          {(disableRemoveApt || pendingEventUpdate) && <PopperTooltip
                                                  isOpen={this.state.hoveredElementId === `removeDeliveryApt-${i}`} 
                                                  refNo={document.getElementById(`removeDeliveryApt-${i}`)} 
                                                  name={pendingEventUpdate ? 'This event has pending requests' : 'Delivery is either in trip or progress'}
                                                  color={'gray-700'} 
                                                  cssClass={"load-driver"} 
                                              />}
                                        <div
                                          className="btn btn-circle btn-xs bg-soft-danger mb-2 pointer"
                                          id={`removeDeliveryApt-${i}`}
                                          onClick={(e) => {
                                            !(disabled || disableRemoveApt || pendingEventUpdate || isProfileLocationDisabled) && this.handleOnRemoveDeliveryLocation({deliveryTimes, i})
                                          }}
                                          onMouseEnter={()=>{
                                            this.setState({hoveredElementId: `removeDeliveryApt-${i}`})
                                          }}
                                          onMouseLeave={()=>{
                                            this.setState({hoveredElementId: null})
                                          }}
                                          style={(disabled || disableRemoveApt || pendingEventUpdate || isProfileLocationDisabled) ? {opacity: 0.5} : {}}
                                        >
                                          <IconMinus />
                                        </div>
                                      </div>
                                    )}
                                    </div>
                                  </React.Fragment>
                            </div>
                            </div>
                          </div>
                      );
                        })}
                    </div>
                    <span style={disabled ? {cursor: "not-allowed"} : {}}>
                    <div
                      type="button"
                      className="btn btn-outline-primary btn-sm"
                      id="addDeliveryApt"
                      onClick={(e) => {
                        !disabled &&
                        !isProfileLocationDisabled &&
                        !deliveryEventsOfLoad?.find((delEvent) => delEvent.tenderId?.drayOSLoadRefNo) &&
                        !NO_DELIVERY_TEMPLATES.includes(selectedLoads.preSetOrderStatus) &&
                        this.setState({ showLoadPopup: "DELIVERLOAD", isShowLoadPopup: true});
                      }}
                      onMouseEnter={()=>{
                        this.setState({hoveredElementId: "addDeliveryApt"})
                      }}
                      onMouseLeave={()=>{
                        this.setState({hoveredElementId: null})
                      }}
                      style={(disabled || isProfileLocationDisabled || NO_DELIVERY_TEMPLATES.includes( selectedLoads.preSetOrderStatus )) ? {opacity: 0.4} : {}}
                    >
                      <IconPlus className="mr-2" />
                      {isTerminalTrackingEnabled() && (this.props?.selectedLoads?.type_of_load === "EXPORT") ? "Add Loading Location" : `Add ${LoadInfoLabel(selectedLoads?.type_of_load, "consignee")}`}
                    </div>
                    </span>
                  </div>
                  {this.state.isShowLoadPopup && selectedLoads && (
                        <Modal
                          show={this.state.isShowLoadPopup}
                          centered="true"
                          bsSize="lg"
                          backdropClassName="z-1050"
                          className="p-4"
                          scrollable="true"
                        >
                          <Modal.Body className="bg-gray-50 p-3 h-100">
                            <RoutingEvent
                              selectedLoads={selectedLoads}
                              reference_number={selectedLoads?.reference_number}
                              closeStatusModal={() => {
                                this.setState({ isShowLoadPopup: false });
                              }}
                              dispatcherModal={true}
                              showEvent={this.state.showLoadPopup}
                              newSyncRequests={this.state.newSyncRequests}
                            />
                          </Modal.Body>
                        </Modal>
                      )}
                  <div className="hr-light my-25"></div>
                </React.Fragment>
              {this.state.showDriverOrderStatus && 
                <RoutingOnly
                  showDriverOrderModal={this.state.showDriverOrderStatus}
                  load = {selectedLoads}
                  setShowDriverOrderModal={this.changeShowDriverOrderStatus}
                  changeTopTab = {this.props.changeTopTab}
                ></RoutingOnly>
              }
              {/* Container Return */}
              <div>
                <h4 className="font-16 mb-20">Return Location</h4>
                <div className="form-row d-flex align-items-end">
                  <div className={`form-group col-md-6 ${this.renderClassesForShowReviewButton("loadRoutingLocation", 0, "Return")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal}  routingField={ {field : "loadRoutingLocation", index : 0, name:'Return'} } getAlertInfo={this.getAlertInfo} />
                  <label>&nbsp;</label>
                    <div className="form-group mb-0">
                    <div className="input-wrapper mb-0">

                    <AsyncSelect
                      key={this.state.resetComponent}
                      placeholder="Select..."
                      styles={smallSelectStyle}
                      className="emptyOrigin"
                      defaultOptions={
                        isTerminalTrackingEnabled() ? 
                          makeOptionsForSelectWithStatus(this.props.shippersOptions,"company_name","address.address","_id","isConnected") : 
                          makeOptionsForSelect(this.props.shippersOptions,"company_name","address.address","_id","isConnected")
                      }
                      cacheOptions
                      isClearable
                      value={
                        this.props.customerProfile &&
                        this.props.customerProfile.emptyOrigin ? (
                            isTerminalTrackingEnabled() ? 
                            {
                              value: this.props.customerProfile.emptyOrigin._id,
                              label: <StatusDropdownWrapper label={this.props.customerProfile.emptyOrigin.company_name} terminal={this.props.customerProfile.emptyOrigin}/>,
                            } :
                            {
                              value: this.props.customerProfile.emptyOrigin._id,
                              label:  this.props.customerProfile.emptyOrigin.company_name,
                            }
                        ) : this.state.emptyOrigin
                      }
                      onChange={async (emptyOrigin) => {
                        let returnContainers = [];
                        this.props.originalLoad?.driverOrder.forEach((d) => {
                          if (d.type === loadEvents.RETURNCONTAINER) {
                            returnContainers.push(d);
                          }
                        }) 
                        let isDualTransactionMove = returnContainers.find(p => (p.customerId?._id + "" !== emptyOrigin?.value?.toString() && p?.isDualTransaction && p?.dualTransactionTo));
                        isDualTransactionMove && !emptyOrigin && await AlertBreakDualTransaction(this.props.originalLoad, DUAL_TRANSACTION_MESSAGES.UPDATE_ADDRESS, () => {});
                        if (!emptyOrigin) {
                          this.onRemovePress(null, { name: "emptyOrigin" });
                          return;
                        }
                        this.props.updateState(
                          "emptyOrigin",
                          emptyOrigin.allInfos
                        );
                        this.onSelectFieldChange("emptyOrigin", emptyOrigin.allInfos)
                        // this.setState({ emptyOrigin }, () => { })
                      }}
                      loadOptions={isTerminalTrackingEnabled() ? getEmptyOriginCustomersAsyncSearchForSelectWithStatus : getEmptyOriginCustomersAsyncSearch}
                      isDisabled={disabled || isTenderAccepted || isProfileLocationDisabled}
                      isLoading={this.state.isLoader}
                    />
                    <SyncError alert={this.getAlertInfo("loadRoutingLocation", 0, "Return")} className={"position-absolute"}/>
                    </div>
                  </div>
                  </div>

                  <div className={`form-group col-lg-3  ${this.renderClassesForShowReviewButton("loadRoutingApptTimes", 0, "From", "returnTimes")}`}>
                    <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"returnFromTime"} getAlertInfo={this.getAlertInfo} routingField={ {field : "loadRoutingApptTimes", index : 0, subField: "returnTimes", name: "From"} }/>
                    {<RequestedAppointmentTimeInfo label="Return" moveType={MOVE_TYPES.EMPTY} load={selectedLoads} isAbsolute={true} isPortal={false} />}
                    <label className="label-control">From </label>
                    <div className="input-wrapper returnFromTime returnTimes-From">
                    <div className="position-relative">
                      <DateWrapper 
                        disabled={disabled || isProfileLocationDisabled} 
                        OnBlurUpdate={() => this.onReturnTimes(this.props.customerProfile?.returnFromTime)}
                        onChange={(e) => {
                          this.props.datechanges("returnFromTime", e);
                          setTimeout(() => {
                            this.props.datechanges("returnToTime", e);
                          }, 200); // Keep this number low, this.props.datechanges() causes rerender and flickers the confirmation prompt
                        }}
                        displayTimeZone={this.timeZone}
                        isShowTime={true}
                        timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                        defaultDate={this.props.customerProfile && this.props.customerProfile?.returnFromTime} 
                        hideShowSelectDate={true}
                      />
                      {this.props.customerProfile &&
                      this.props.customerProfile.returnFromTime &&
                      !(disabled || isProfileLocationDisabled) ? (
                        <div
                          className="input-icon"
                          onClick={(e) => {
                            this.onRemoveReturnDate(e, {
                              name: "returnDate",
                            }); 
                          }}
                        >
                          <IconTimes />
                        </div>
                      ) : (
                        <div className="input-icon">
                          <IconCalendar />
                        </div>
                      )}
                      </div>
                      <SyncError alert={this.getAlertInfo("loadRoutingApptTimes", 0, "From", "returnTimes")} className={"position-absolute"}/>
                    </div>
                  </div>
                  
                  <div className={`form-group col-lg-3  ${this.renderClassesForShowReviewButton("loadRoutingApptTimes", 0, "To", "returnTimes")}`}>
                    <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"returnToTime"} getAlertInfo={this.getAlertInfo} routingField={ {field : "loadRoutingApptTimes", index : 0, subField: "returnTimes", name: "To"} }/>
                    <label className="label-control">To</label>
                    <div className="input-wrapper returnToTime returnTimes-To">
                    <div className="position-relative">
                      <DateWrapper 
                        disabled={disabled || isProfileLocationDisabled} 
                        onRemoveDate={(e) => {this.onRemoveReturnDate(e, { name: "returnDate" });}}
                        OnBlurUpdate={() => this.onDateSelect("returnToTime",this.props.customerProfile?.returnToTime)}
                        onChange={(e) => {this.props.datechanges("returnToTime", e)}}
                        displayTimeZone={this.timeZone}
                        isShowTime={true}
                        timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                        defaultDate={this.props.customerProfile && this.props.customerProfile?.returnToTime} 
                        hideShowSelectDate={true}
                      />
                      {this.props.customerProfile &&
                      this.props.customerProfile.returnToTime &&
                      !(disabled || isProfileLocationDisabled) ? (
                        <div
                          className="input-icon"
                          onClick={(e) => {
                            this.onRemoveReturnDate(e,{ 
                              name: "returnDate"
                            });
                          }}
                        >
                          <IconTimes />
                        </div>
                      ) : (
                        <div className="input-icon">
                          <IconCalendar />
                        </div>
                      )}
                    </div>
                    <SyncError alert={this.getAlertInfo("loadRoutingApptTimes", 0, "To", "returnTimes")} className={"position-absolute"}/>
                    </div>
                  </div>
                </div>
                {/* Recommended Return Terminals */}
                {this.props.isEmptyEnabled && <RecommendedReturnTerminals  row={this.props.customerProfile} isLoadInfo={true} setWrongReturnMessage={(val)=>this.setState({wrongReturnMessage:val})} />}
                
              </div>
              <div className="hr-light my-25"></div>

              <div>
                <h4 className="font-16 mb-20">Chassis Locations</h4>
                <div className="form-row">
                  <div className={`form-group col-lg-6 mb-0 ${this.renderClassesForShowReviewButton("loadRoutingLocation", 0, "Hook Chassis")}`}>
                    <ReviewButton 
                      fetchSyncChanges={this.fetchSyncChanges} 
                      load_reference_number={this.props.selectedLoads.reference_number} 
                      showReviewModal={this.showReviewModal} 
                      getAlertInfo={this.getAlertInfo} 
                      routingField={ {field : "loadRoutingLocation", index : 0, name:'Hook Chassis'} }
                    />
                    <label className={`col-form-label`}>Hook Chassis</label>
                    <AsyncSelect
                      className="chassisPick"
                      styles={smallSelectStyle}
                      defaultOptions={makeOptionsForSelect(
                        this.props.chassisTerms,
                        "company_name",
                        "address.address",
                        "_id",
                        "isConnected"
                      )}
                      cacheOptions
                      isClearable
                      value={
                        this.props.customerProfile &&
                        this.props.customerProfile.chassisPick
                          ? {
                              value: this.props.customerProfile.chassisPick._id,
                              label:
                                this.props.customerProfile.chassisPick
                                  .company_name,
                            }
                          : null
                      }
                      onChange={async (chassisPick) => {
                        const lastEvent = this.props.originalLoad?.driverOrder[0];
                        if (lastEvent.type === loadEvents.PULLCONTAINER && lastEvent.isDualTransaction && lastEvent.dualTransactionFrom) {
                          await AlertBreakDualTransaction(this.props.originalLoad, DUAL_TRANSACTION_MESSAGES.UPDATE_CHASSIS_PICK, () => {});
                        }
                        if (!chassisPick) {
                          this.onRemovePress(null, { name: "chassisPick" });
                          return;
                        }
                        this.props.updateState(
                          "chassisPick",
                          chassisPick.allInfos
                        );
                        this.onSelectFieldChange("chassisPick",chassisPick.allInfos)
                        // this.setState({ chassisPick }, () => { })
                      }}
                      loadOptions={getChassisPickCustomersAsyncSearch}
                      isDisabled={disabled || isTenderAccepted || isProfileLocationDisabled}
                    />
                    <SyncError alert={this.getAlertInfo("loadRoutingLocation", 0, "Hook Chassis")} className={"position-absolute"}/>
                  </div>  
                  <div className={`form-group col-lg-6 mb-0 ${this.renderClassesForShowReviewButton("loadRoutingLocation", 0, "Terminate Chassis")}`}>
                    <ReviewButton 
                      fetchSyncChanges={this.fetchSyncChanges} 
                      load_reference_number={this.props.selectedLoads.reference_number} 
                      showReviewModal={this.showReviewModal} 
                      getAlertInfo={this.getAlertInfo} 
                      routingField={ {field : "loadRoutingLocation", index : 0, name:'Terminate Chassis'} }
                    />
                    <label>Terminate Chassis</label>
                    <AsyncSelect
                      styles={smallSelectStyle}
                      className="chassisTermination"
                      defaultOptions={makeOptionsForSelect(
                        this.props.chassisTerms,
                        "company_name",
                        "address.address",
                        "_id"
                      )}
                      cacheOptions
                      value={
                        this.props.customerProfile &&
                        this.props.customerProfile.chassisTermination
                          ? {
                              value:
                                this.props.customerProfile.chassisTermination
                                  ._id,
                              label:
                                this.props.customerProfile.chassisTermination
                                  .company_name,
                            }
                          : null
                      }
                      onChange={async (chassisTermination) => {
                        const lastEvent = this.props.originalLoad?.driverOrder[this.props.originalLoad?.driverOrder.length - 1];
                        if (lastEvent.type === loadEvents.RETURNCONTAINER && lastEvent.isDualTransaction && lastEvent.dualTransactionTo) {
                          await AlertBreakDualTransaction(this.props.originalLoad, DUAL_TRANSACTION_MESSAGES.UPDATE_CHASSIS_TERMINATION, () => {});
                        }
                        if (!chassisTermination) {
                          this.onRemovePress(null, {
                            name: "chassisTermination",
                          });
                          return;
                        }
                        this.props.updateState(
                          "chassisTermination",
                          chassisTermination.allInfos
                        );
                        this.onSelectFieldChange("chassisTermination",chassisTermination.allInfos)
                        // this.setState({ chassisTermination }, () => {});
                      }}
                      isClearable
                      loadOptions={getChassisTerminationCustomersAsyncSearch}
                      isDisabled={disabled || isTenderAccepted || isProfileLocationDisabled}
                    />
                    <SyncError alert={this.getAlertInfo("loadRoutingLocation", 0, "Terminate Chassis")} className={"position-absolute"}/>
                  </div>
                </div>
              </div>
            </> 
          : <></>
          }
            </div>

            {/* New Section For Holds */}
            <div className="card py-30 px-20 mb-20">
              <h4 className="font-16 mb-20">
                Container Visibility
              </h4>
                <div className="form-row">
                <div className={`form-group col-lg-3 ${this.renderClassesForShowReviewButton("freight")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"freight"} getAlertInfo={this.getAlertInfo} />
                    <label>
                      <ExtraLabel
                        showExtraData={showExtraData}
                        keyName="freight"
                        onApply={this.onApplyTracerData}
                        extraData={extraData}
                        label="Freight Hold"
                      />
                    </label>
                    <Select
                      key={isLoading+'freight'}
                      className={`freight ${!isLoading && !this.props.selectedLoads?.isUserEnteredfreight ? 'system-generated' : '' } ${isLoading?'loader':''}`}
                      styles={smallSelectStyle}
                      options={HoldOrReleased}
                      value={customerProfile?.freight
                        ? HoldOrReleased.find((pt) => pt.value == customerProfile.freight)
                        : null}
                      onChange={(value) => {
                        this.props.updateState("freight", value.value);
                        this.onSelectFieldChange("freight", value.value);
                      }}
                      isDisabled={disabled}
                    />
                  <SyncError alert={this.getAlertInfo("freight")} />
                  </div>
                  <div className={`form-group col-lg-3 ${this.renderClassesForShowReviewButton("custom")}`}>
                    <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"custom"} getAlertInfo={this.getAlertInfo} />
                    <label className="label-control">Custom Hold</label>
                    <Select
                      styles={smallSelectStyle}
                      key={isLoading+'custom'}
                      className={`custom ${!isLoading && !this.props.selectedLoads?.isUserEnteredcustom ? 'system-generated' : ''} ${isLoading?'loader':''}`}
                      options={HoldOrReleased}
                      value={customerProfile?.custom
                        ? HoldOrReleased.find((pt) => pt.value == customerProfile.custom)
                        : null}
                      onChange={(value) => {
                        this.props.updateState("custom", value.value);
                        this.onSelectFieldChange("custom", value.value);
                      }}
                      isDisabled={disabled}
                    />
                    <SyncError alert={this.getAlertInfo("custom")} />
                  </div>
                  <div className={`form-group col-lg-3 ${this.renderClassesForShowReviewButton("carrierHold")}`}>
                    <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"carrierHold"} getAlertInfo={this.getAlertInfo} />
                    <label className="label-control">Carrier Hold</label>
                    <Select
                      styles={smallSelectStyle}
                      options={YesOrNo}
                      className={`carrierHold`}
                      value={YesOrNo.find(
                        (pt) =>
                          this.props.customerProfile &&
                          pt.value === this.props.customerProfile.carrierHold
                      )}
                      onChange={(value) => {
                        this.props.updateState("carrierHold", value.value);
                        this.onSelectFieldChange("carrierHold", value.value);
                      }}
                      isDisabled={disabled}
                    />
                    <SyncError alert={this.getAlertInfo("carrierHold")} />
                  </div>
                  <div className={`form-group col-lg-3 ${this.renderClassesForShowReviewButton("brokerHold")}`}>
                    <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"brokerHold"} getAlertInfo={this.getAlertInfo} />
                    <label>
                      <ExtraLabel
                        showExtraData={showExtraData}
                        keyName="brokerHold"
                        onApply={this.onApplyTracerData}
                        extraData={extraData}
                        label="Broker Hold"
                      />
                    </label>
                    <Select
                      styles={smallSelectStyle}
                      options={YesOrNo}
                      key={isLoading+'brokerHold'}
                      className={`brokerHold ${!isLoading && !this.props.selectedLoads?.isUserEnteredbrokerHold ? 'system-generated' : ''} ${isLoading?'loader':''}`}
                      value={YesOrNo.find(
                        (pt) =>
                          this.props.customerProfile &&
                          pt.value === this.props.customerProfile.brokerHold
                      )}
                      onChange={(value) => {
                        this.props.updateState("brokerHold", value.value);
                        this.onSelectFieldChange("brokerHold", value.value);
                      }}
                      isDisabled={disabled}
                    />
                    <SyncError alert={this.getAlertInfo("brokerHold")} />

                </div>
                {isTerminalContainerStatusEnabled() ? (
                  <div className="form-group col-lg-3">
                    <label className="label-control">Container Status</label>
                    <Select
                      styles={smallSelectStyle}
                      options={containerStatusOption}
                      className={`myApp_tracking_status`}
                      value={
                        this.props.customerProfile &&
                          this.props.customerProfile.myApp_tracking_status
                          ? {
                            value: this.props.customerProfile.myApp_tracking_status,
                            label:
                              this.props.customerProfile.myApp_tracking_status
                          }
                          : null
                      }
                      onChange={(value) => {
                        if (!value) {
                          this.onRemovePress(null, {
                            name: "myApp_tracking_status",
                          });
                          return;
                        }
                        this.props.updateState("myApp_tracking_status", value.value);
                        this.onSelectFieldChange("myApp_tracking_status", value.value);
                      }}
                      isClearable
                      isDisabled={disabled}
                    />
                  </div>
                ) : null}

               {isCustomsTypeEnabled() && <div className={`form-group col-lg-3`}>
                 <label className="label-control">Customs Type</label>
                 <Select
                   styles={smallSelectStyle}
                   options={customsTypeOption}
                   className={`customsType`}
                   value={
                     this.props.customerProfile &&
                       this.props.customerProfile.customsType
                       ? customsTypeOption?.find(val => val?.value === this.props.customerProfile.customsType)
                       : null
                   }
                   onChange={(value) => {
                    if (!value) {
                      this.onRemovePress(null, {
                        name: "customsType",
                      });
                      return;
                    }
                     this.props.updateState("customsType", value.value);
                     this.onSelectFieldChange("customsType", value.value);
                   }}
                   isClearable
                   isDisabled={disabled}
                 />
               </div>}
             </div>
            </div>

          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            {this.props.loadType !== "BILL_ONLY" && (
            <div className="card py-30 px-20 mb-20">
                <h4 className="font-16 mb-20">
                  {(this.shiposOrConnectCarrier && this.isManageFleetOrOutSource.isOutSource && !this.state.isCustomer) ?
                    <div className="d-flex align-items-center gap-10">
                      <ul className="nav nav-compact" role="tablist">
                        <li className="nav-item">
                          <a className={`nav-link ${this.state.selectedTab === "carrier-dates" ? "active" : ""}`} onClick={() => this.setState({ selectedTab: "carrier-dates" }, () => {
                               this.setAlertsAndClasses(this.state.alertMessages)
                          })}>
                            Carrier Dates
                          </a>
                        </li>
                        <li className="nav-item">
                          <a className={`nav-link ${this.state.selectedTab === "my-dates" ? "active" : ""}`} onClick={() => this.setState({ selectedTab: "my-dates" })}>
                            My Dates
                          </a>
                        </li>
                      </ul>
                    </div> : 'Dates'}
                </h4>
                {this.state.selectedTab === "my-dates" && (
                  <>
                    <MyDates
                      props={this.props}
                      isMileStoneEnabled={this.isMileStoneEnabled}
                      onRemovePress={this.onRemovePress}
                      timeZone={this.timeZone}
                      onDateSelect={this.onDateSelect}
                      disabled={disabled}
                      getAlertInfo={this.getAlertInfo}
                      isLoading={isLoading}
                      isExportLoading={isExportLoading}
                      onMyDateSelect={this.onMyDateSelect}
                      userTimeFormat={this.userTimeFormat}
                    />
                  </>
                )}  
              {this.state.selectedTab === "carrier-dates" && <div className="form-row">
                {this.props.loadType === "EXPORT" && (
                  <div className={`form-group col-lg-3 ${this.renderClassesForShowReviewButton("loadTime")}`}>
                    <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal}  field={"loadTime"} getAlertInfo={this.getAlertInfo} />
                    <label className="label-control">Load</label>
                      {this.isMileStoneEnabled && (<><span data-tip data-for={`milestone_export_load`}>
                        <IconMilestone className="ml-1 text-primary" />
                      </span>
                      <ReactTooltip id={`milestone_export_load`}>
                        <span>
                          Milestone Loaded Ready to Return
                        </span>
                      </ReactTooltip></>)}
                    <div className="input-wrapper loadTime">
                      <div className="position-relative">
                        <DateWrapper 
                          disabled={disabled}
                          onRemoveDate={(e) => {this.onRemovePress(e, { name: "loadTime" });}}
                          OnBlurUpdate={(e) => this.onDateSelect("loadTime", this.props.customerProfile?.loadTime)}
                          onChange={(e) => {this.props.datechanges("loadTime", e) }}
                          displayTimeZone={this.timeZone}
                          isShowTime={true}
                          timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                          defaultDate={this.props.customerProfile && this.props.customerProfile?.loadTime} 
                          hideShowSelectDate={true}
                        />
                      {this.props.customerProfile &&
                      this.props.customerProfile.loadTime &&
                      !disabled ? (
                        <div
                          className="input-icon"
                          onClick={(e) => {
                            this.onRemovePress(e, { name: "loadTime" });
                          }}
                        >
                          <IconTimes />{" "}
                        </div>
                      ) : (
                        <div className="input-icon">
                          <IconCalendar />{" "}
                        </div>
                      )}
                      </div>
                       <SyncError alert={this.getAlertInfo("loadTime")} />
                    </div>
                  </div>
                )}
                {this.props.loadType === "EXPORT" && (
                   <div className={`form-group col-lg-3 ${this.renderClassesForShowReviewButton("containerAvailableDay")}`}>
                   <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal}  field={"containerAvailableDay"} getAlertInfo={this.getAlertInfo} />
                    <label className="label-control">ERD</label>
                    <div className="input-wrapper containerAvailableDay">
                    <div className="position-relative">
                        <DateWrapper 
                          disabled={disabled}
                          onRemoveDate={(e) => {this.onRemovePress(e, { name: "containerAvailableDay" });}}
                          OnBlurUpdate={(e) => this.onDateSelect("containerAvailableDay", this.props.customerProfile?.containerAvailableDay)}
                          onChange={(e) => {this.props.datechanges("containerAvailableDay", e) }}
                          displayTimeZone={this.timeZone}
                          isShowTime={false}
                          defaultDate={this.props.customerProfile && this.props.customerProfile?.containerAvailableDay} 
                          hideShowSelectDate={true}
                          isLoader={isExportLoading}
                          key={isExportLoading+'containerAvailableDay'}
                          systemGenerated={!isExportLoading && this.props.selectedLoads?.systemGeneratedcontainerAvailableDay && !this.props.selectedLoads?.isUserEnteredcontainerAvailableDay}
                        />

                      {this.props.customerProfile &&
                      this.props.customerProfile.containerAvailableDay &&
                      !disabled ? (
                        <div
                          className="input-icon"
                          onClick={(e) => {
                            this.onRemovePress(e, {
                              name: "containerAvailableDay",
                            });
                          }}
                        >
                          <IconTimes />{" "}
                        </div>
                      ) : (
                        <div className="input-icon">
                          <IconCalendar />
                        </div>
                      )}
                    </div>
                   <SyncError alert={this.getAlertInfo("containerAvailableDay")} />
                    </div>
                  </div>
                )}
                {this.props.loadType === "EXPORT" && (this.renderPerDiemLFD({disabled}))}
                {this.props.loadType === "EXPORT" && (
                   <div className={`form-group col-lg-3 ${this.renderClassesForShowReviewButton("cutOff")}`}>
                    <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal}  field={"cutOff"} getAlertInfo={this.getAlertInfo} />
                    <label className="label-control">Cutoff</label>
                    <div className="input-wrapper cutOff">
                    <div className="position-relative">
                        <DateWrapper 
                          disabled={disabled}
                          onRemoveDate={(e) => {this.onRemovePress(e, { name: "cutOff" });}}
                          OnBlurUpdate={(e) => this.onDateSelect("cutOff", this.props.customerProfile?.cutOff)}
                          onChange={(e) => {this.props.datechanges("cutOff", e) }}
                          displayTimeZone={this.timeZone}
                          isShowTime={true}
                          timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                          defaultDate={this.props.customerProfile && this.props.customerProfile?.cutOff} 
                          hideShowSelectDate={true}
                          className="right-0"
                          isLoader={isExportLoading}
                          key={isExportLoading+'cutOff'}
                          systemGenerated={!isExportLoading && this.props.selectedLoads?.type_of_load &&  this.props.selectedLoads?.systemGeneratedcutOff && !this.props.selectedLoads?.isUserEnteredcutOff}
                        />

                      {this.props.customerProfile &&
                      this.props.customerProfile.cutOff &&
                      !disabled ? (
                        <div
                          className="input-icon"
                          onClick={(e) => {
                            this.onRemovePress(e, { name: "cutOff" });
                          }}
                        >
                          <IconTimes />{" "}
                        </div>
                      ) : (
                        <div className="input-icon">
                          <IconCalendar />
                        </div>
                      )}
                    </div>
                     <SyncError alert={this.getAlertInfo("cutOff")} />
                    </div>
                  </div>
                )}
                {this.props.loadType === "EXPORT" && (
                 <div className={`form-group col-lg-3 ${this.renderClassesForShowReviewButton("return")}`}>
                 <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal}  field={"return"} getAlertInfo={this.getAlertInfo} />
                    <label className="label-control">Date Returned</label>
                    <div className="input-wrapper return">
                    <div className="position-relative">
                        <DateWrapper 
                          disabled={disabled}
                          onRemoveDate={(e) => {this.onRemovePress(e, { name: "return" });}}
                          OnBlurUpdate={(e) => this.onDateSelect("return", this.props.customerProfile.return)}
                          onChange={(e) => {this.props.datechanges("return", e) }}
                          displayTimeZone={this.timeZone}
                          isShowTime={false}
                          defaultDate={this.props.customerProfile && this.props.customerProfile.return} 
                          hideShowSelectDate={true}
                          className="right-0"
                        />
                      {this.props.customerProfile &&
                      this.props.customerProfile.return &&
                      !disabled ? (
                        <div
                          className="input-icon"
                          onClick={(e) => {
                            this.onRemovePress(e, { name: "return" });
                          }}
                        >
                          <IconTimes />
                        </div>
                      ) : (
                        <div className="input-icon">
                          <IconCalendar />
                        </div>
                      )}
                    </div>
                     <SyncError alert={this.getAlertInfo("return")} />

                  </div>
                  </div>
                )}
                {this.props.loadType === "IMPORT" && (
                  <>
                   {/* ${this.getAlertInfo("vesselETA")?.message?.PENDING_RECIEVED ? "request-wrapper" : ""} */}
                  <div className={`form-group col-lg-3 ${this.renderClassesForShowReviewButton("vesselETA")}`}>
                    <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal}  field={"vesselETA"} getAlertInfo={this.getAlertInfo} />
                    <label className="label-control">Vessel ETA</label>
                    <div className={`input-wrapper vessel vesselETA`}>
                      <div className="position-relative">
                      <DateWrapper 
                        disabled={disabled}
                        onRemoveDate={(e) => {this.onRemovePress(e, { name: "vessel" });}}
                        OnBlurUpdate={(e) => this.onDateSelect("vessel", this.props.customerProfile?.vessel?.eta)}
                        onChange={(e) => {this.props.vesselChange("vessel", e) }}
                        displayTimeZone={this.timeZone}
                        isShowTime={true}
                        timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                        defaultDate={ this.props.customerProfile && this.props.customerProfile.vessel && this.props.customerProfile.vessel?.eta} 
                        hideShowSelectDate={true}
                        isLoader={isLoading}
                        key={isLoading+'vessel'}
                        systemGenerated={!isLoading && this.props.selectedLoads?.systemGeneratedEstimatedToArrive && !this.props.selectedLoads?.isUserEnteredvessel}
                      />
                      {this.props.customerProfile &&
                      this.props.customerProfile.vessel &&
                      !disabled ? (
                        <div
                          className="input-icon"
                          onClick={(e) => {
                            this.onRemovePress(e, { name: "vessel" });
                          }}
                        >
                          <IconTimes />
                        </div>
                      ) : isLoading ? <span></span> : (
                        <div className="input-icon">
                          <IconCalendar />
                        </div>
                      )}
                      </div>
                      <SyncError alert={this.getAlertInfo("vesselETA")} />
                    </div>
                  </div>
                  </>
                )}
                {this.props.loadType === "IMPORT" && (
                  <div className={`form-group col-lg-3 ${this.renderClassesForShowReviewButton("lastFreeDay")}`}>
                    <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"lastFreeDay"} getAlertInfo={this.getAlertInfo} />
                    <label className="label-control">Last Free Day</label>
                      <div className="input-wrapper lastFreeDay">
                      <div className="position-relative">
                      <DateWrapper 
                        disabled={disabled}
                        onRemoveDate={(e) => {this.onRemovePress(e, { name: "lastFreeDay" });}}
                        OnBlurUpdate={(e) => this.onDateSelect("lastFreeDay", this.props.customerProfile.lastFreeDay)}
                        onChange={(e) => {this.props.datechanges("lastFreeDay", e) }}
                        displayTimeZone={this.timeZone}
                        isShowTime={false}
                        defaultDate={ this.props.customerProfile && this.props.customerProfile.lastFreeDay } 
                        hideShowSelectDate={true}
                        isLoader={isLoading}
                        key={isLoading+'lastFreeDay'}
                        systemGenerated={!isLoading && this.props.selectedLoads?.systemGeneratedLastFreeDay && !this.props.selectedLoads?.isUserEnteredlastFreeDay}
                      />
                      {this.props.customerProfile &&
                      this.props.customerProfile.lastFreeDay &&
                      !disabled ? (
                        <div
                          className="input-icon"
                          onClick={(e) => {
                            this.onRemovePress(e, { name: "lastFreeDay" });
                          }}
                        >
                          <IconTimes />
                        </div>
                      ) : isLoading ? <span></span> : (
                        <div className="input-icon">
                          <IconCalendar />
                        </div>
                      )}
                      </div>
                      <SyncError alert={this.getAlertInfo("lastFreeDay")} />
                    </div>
                  </div>
                )}

                {this.props.loadType === "IMPORT" && (
                  <div className={`form-group col-lg-3 ${this.renderClassesForShowReviewButton("dischargedDate")}`}>
                    <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"dischargedDate"} getAlertInfo={this.getAlertInfo} />
                    <label className="label-control">Discharged Date</label>
                    <div className="input-wrapper dischargedDate">
                    <div className="position-relative">
                      <DateWrapper 
                        disabled={disabled}
                        onRemoveDate={(e) => {this.onRemovePress(e, { name: "dischargedDate" });}}
                        OnBlurUpdate={(e) => this.onDateSelect("dischargedDate", this.props.customerProfile.dischargedDate)}
                        onChange={(e) => {this.props.datechanges("dischargedDate", e) }}
                        displayTimeZone={this.timeZone}
                        isShowTime={true}
                        timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                        defaultDate={ this.props.customerProfile && this.props.customerProfile.dischargedDate} 
                        hideShowSelectDate={true}
                        className="right-0"
                        isLoader={isLoading}
                        key={isLoading+'dischargeDate'}
                        systemGenerated={!isLoading && this.props.selectedLoads?.systemGeneratedDischarge && !this.props.selectedLoads?.isUserEnterdischargedDate}
                      />
                      {this.props.customerProfile &&
                      this.props.customerProfile.dischargedDate &&
                      !disabled ? (
                        <div
                          className="input-icon"
                          onClick={(e) => {
                            this.onRemovePress(e, { name: "dischargedDate" });
                          }}
                        >
                          <IconTimes />
                        </div>
                      ) : isLoading ? <span></span> : (
                        <div className="input-icon">
                          <IconCalendar />
                        </div>
                      )}
                      </div>
                       <SyncError alert={this.getAlertInfo("dischargedDate")} />
                    </div>
                  </div>
                )}

              <div className={`form-group col-lg-3 ${this.renderClassesForShowReviewButton("outgateDate")}`}>
                <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"outgateDate"} getAlertInfo={this.getAlertInfo} />
                  <label className="label-control">Outgate Date</label>
                  {this.isMileStoneEnabled && (
                  <>
                    <span data-tip data-for={`milestone_outgate_date`}>
                      <IconMilestone className="ml-1 text-primary" />
                    </span>
                    <ReactTooltip id={`milestone_outgate_date`}>
                      <span>
                        Milestone Outgated
                      </span>
                    </ReactTooltip>
                  </>)}
                  <div className="input-wrapper outgateDate">
                  <div className="position-relative">
                    <DateWrapper 
                      disabled={disabled}
                        onRemoveDate={(e) => {this.onRemovePress(e, { name: "outgateDate" });}}
                        OnBlurUpdate={(e) => this.onDateSelect("outgateDate", this.props.customerProfile.outgateDate)}
                        onChange={(e) => {this.props.datechanges("outgateDate", e) }}
                        displayTimeZone={this.timeZone}
                        isShowTime={true}
                        timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                        defaultDate={ this.props.customerProfile && this.props.customerProfile.outgateDate} 
                        hideShowSelectDate={true}
                        className="right-0"
                        isLoader={isLoading}
                        key={isLoading+'outgateDate'}
                        systemGenerated={!isLoading && this.props.selectedLoads?.systemGeneratedOutGateDate && !this.props.selectedLoads?.isUserEnteredoutgateDate}
                    />
                    {this.props.customerProfile &&
                    this.props.customerProfile.outgateDate &&
                    !disabled ? (
                      <div
                        className="input-icon"
                        onClick={(e) => {
                          this.onRemovePress(e, { name: "outgateDate" });
                        }}
                      >
                        <IconTimes />
                      </div>
                    ) : isLoading ? <span></span> : (
                      <div className="input-icon">
                        <IconCalendar />
                      </div>
                    )}
                  </div>
                  <SyncError alert={this.getAlertInfo("outgateDate")} />
                  </div>
                </div>

                {this.isMileStoneEnabled && this.props.loadType === "IMPORT" && (
                  <div className="form-group col-lg-3 ">                       
                  <label className="label-control">Delivered To Unload Date</label>
                  
                  <span data-tip data-for={`milestone_delivered_to_unload`}>
                    <IconMilestone className="ml-1 text-primary"/>
                  </span>
                  <ReactTooltip id={`milestone_delivered_to_unload`}>
                    <span>
                      Milestone Delivered To Unload
                    </span>
                  </ReactTooltip>

                  <div className="input-wrapper deliveredToUnloadDate">
                  <div className="position-relative">
                    <DateWrapper 
                      disabled={disabled}
                        onRemoveDate={(e) => {this.onRemovePress(e, { name: "deliveredToUnloadDate" });}}
                        OnBlurUpdate={(e) => this.onDateSelect("deliveredToUnloadDate", this.props.customerProfile?.deliveredToUnloadDate)}
                        onChange={(e) => {this.props.datechanges("deliveredToUnloadDate", e) }}
                        displayTimeZone={this.timeZone}
                        timeFormat={this.userTimeFormat}
                        isShowTime={true}
                        defaultDate={ this.props.customerProfile && this.props.customerProfile?.deliveredToUnloadDate} 
                        hideShowSelectDate={true}
                        className="right-0"
                    />
                    {this.props.customerProfile &&
                    this.props.customerProfile?.deliveredToUnloadDate &&
                    !disabled ? (
                      <div
                        className="input-icon"
                        onClick={(e) => {
                          this.onRemovePress(e, { name: "deliveredToUnloadDate" });
                        }}
                      >
                        <IconTimes />
                        </div>
                        ) : (
                          <div className="input-icon">
                            <IconCalendar />
                          </div>
                        )}
                      </div>
                      </div>
                    </div>
                  )}

                {this.isMileStoneEnabled && this.props.loadType === "EXPORT" && (
                  <div className="form-group col-lg-3 ">
                  <label className="label-control">Delivered To Load Date</label>
                      <span data-tip data-for={`milestone_delivered_to_load_date`}>
                        <IconMilestone className="ml-1 text-primary" />
                      </span>
                      <ReactTooltip id={`milestone_delivered_to_load_date`}>
                        <span>
                          Milestone Delivered To Load
                        </span>
                      </ReactTooltip>
                  <div className="input-wrapper deliveredToLoadDate">
                  <div className="position-relative">
                    <DateWrapper 
                      disabled={disabled}
                        onRemoveDate={(e) => {this.onRemovePress(e, { name: "deliveredToLoadDate" });}}
                        OnBlurUpdate={(e) => this.onDateSelect("deliveredToLoadDate", this.props.customerProfile?.deliveredToLoadDate)}
                        onChange={(e) => {this.props.datechanges("deliveredToLoadDate", e) }}
                        displayTimeZone={this.timeZone}
                        isShowTime={true}
                        timeFormat={this.userTimeFormat}
                        defaultDate={ this.props.customerProfile && this.props.customerProfile?.deliveredToLoadDate} 
                        hideShowSelectDate={true}
                        className="right-0"
                    />
                    {this.props.customerProfile &&
                    this.props.customerProfile?.deliveredToLoadDate &&
                    !disabled ? (
                      <div
                        className="input-icon"
                        onClick={(e) => {
                          this.onRemovePress(e, { name: "deliveredToLoadDate" });
                        }}
                      >
                        <IconTimes />
                      </div>
                    ) : (
                      <div className="input-icon">
                        <IconCalendar />
                      </div>
                    )}
                  </div>
                  </div>
                </div>
                )}

                {this.isMileStoneEnabled && this.props.loadType === "EXPORT" && (
                  <div className="form-group col-lg-3 ">
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <label className="label-control" style={{ marginRight: '5px' }}>Picked Up Loaded Container Date</label>
                        <span data-tip data-for={`export_milestone_picked_up`}>
                          <IconMilestone className="ml-1 text-primary"/>
                        </span>
                        <ReactTooltip id={`export_milestone_picked_up`}>
                          <span>
                            Milestone Picked Up Loaded Container
                          </span>
                        </ReactTooltip>
                      </div>
                  <div className="input-wrapper pickedUpLoadedContainerDate">
                  <div className="position-relative">
                    <DateWrapper 
                      disabled={disabled}
                        onRemoveDate={(e) => {this.onRemovePress(e, { name: "pickedUpLoadedContainerDate" });}}
                        OnBlurUpdate={(e) => this.onDateSelect("pickedUpLoadedContainerDate", this.props.customerProfile?.pickedUpLoadedContainerDate)}
                        onChange={(e) => {this.props.datechanges("pickedUpLoadedContainerDate", e) }}
                        displayTimeZone={this.timeZone}
                        timeFormat={this.userTimeFormat}
                        isShowTime={true}
                        defaultDate={ this.props.customerProfile && this.props.customerProfile?.pickedUpLoadedContainerDate} 
                        hideShowSelectDate={true}
                        className="right-0"
                    />
                    {this.props.customerProfile &&
                    this.props.customerProfile?.pickedUpLoadedContainerDate &&
                    !disabled ? (
                      <div
                        className="input-icon"
                        onClick={(e) => {
                          this.onRemovePress(e, { name: "pickedUpLoadedContainerDate" });
                        }}
                      >
                        <IconTimes />
                      </div>
                    ) : (
                      <div className="input-icon">
                        <IconCalendar />
                      </div>
                    )}
                  </div>
                  </div>
                </div>
                )}

                {this.props.loadType === "IMPORT" && (
                  <div className={`form-group col-lg-3 ${this.renderClassesForShowReviewButton("emptyDay")}`}>
                    <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"emptyDay"} getAlertInfo={this.getAlertInfo} />
                    <label className="label-control">Empty Date</label>
                    {this.isMileStoneEnabled && (
                    <>
                      <span data-tip data-for={`milestone_empty_date`}>
                        <IconMilestone className="ml-1 text-primary" />
                      </span>
                      <ReactTooltip id={`milestone_empty_date`}>
                        <span>
                          Milestone Empty Ready to Return
                        </span>
                      </ReactTooltip>
                    </>)}
                    <div className="input-wrapper emptyDay">
                    <div className="position-relative">
                      <DateWrapper 
                        disabled={disabled}
                        onRemoveDate={(e) => {this.onRemovePress(e, { name: "emptyDay" });}}
                        OnBlurUpdate={(e) => this.onDateSelect("emptyDay", this.props.customerProfile.emptyDay)}
                        onChange={(e) => {this.props.datechanges("emptyDay", e) }}
                        displayTimeZone={this.timeZone}
                        isShowTime={false}
                        defaultDate={ this.props.customerProfile && this.props.customerProfile.emptyDay} 
                        hideShowSelectDate={true}
                      />
                      {this.props.customerProfile &&
                      this.props.customerProfile.emptyDay &&
                      !disabled ? (
                        <div
                          className="input-icon"
                          onClick={(e) => {
                            this.onRemovePress(e, { name: "emptyDay" });
                          }}
                        >
                          <IconTimes />
                        </div>
                      ) : isLoading ? <span></span> : (
                        <div className="input-icon">
                          <IconCalendar />
                        </div>
                      )}
                    </div>
                     <SyncError alert={this.getAlertInfo("emptyDay")} />
                    </div>
                  </div>
                )}
                {this.props.loadType === "IMPORT" && (this.renderPerDiemLFD({disabled}))}

              <div className={`form-group col-lg-3 ${this.renderClassesForShowReviewButton("ingateDate")}`}>
                <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"ingateDate"} getAlertInfo={this.getAlertInfo} />
                  <label className="label-control">Ingate Date</label>
                  {this.isMileStoneEnabled && (
                  <>
                    <span data-tip data-for={`milestone_ingate_date`}>
                      <IconMilestone className="ml-1 text-primary" />
                    </span>
                    <ReactTooltip id={`milestone_ingate_date`}>
                      <span>
                        Milestone Ingated
                      </span>
                    </ReactTooltip>
                  </>)}
                  <div className="input-wrapper ingateDate">
                  <div className="position-relative">
                    <DateWrapper 
                      disabled={disabled}
                        onRemoveDate={(e) => {this.onRemovePress(e, { name: "ingateDate" });}}
                        OnBlurUpdate={(e) => this.onDateSelect("ingateDate", this.props.customerProfile.ingateDate)}
                        onChange={(e) => {this.props.datechanges("ingateDate", e) }}
                        displayTimeZone={this.timeZone}
                        isShowTime={true}
                        timeFormat={DateTimeFormatUtils.fullDateTimeFormat()}
                        defaultDate={ this.props.customerProfile && this.props.customerProfile.ingateDate} 
                        hideShowSelectDate={true}
                        className="right-0"
                        isLoader={isLoading}
                        key={isLoading+'outgateDate'}
                        systemGenerated={!isLoading && this.props.selectedLoads?.systemGeneratedInGateDate && !this.props.selectedLoads?.isUserEnteredingateDate}
                    />
                    {this.props.customerProfile &&
                    this.props.customerProfile.ingateDate &&
                    !disabled ? (
                      <div
                        className="input-icon"
                        onClick={(e) => {
                          this.onRemovePress(e, { name: "ingateDate" });
                        }}
                      >
                        <IconTimes />
                      </div>
                    ) : isLoading ? <span></span> : (
                      <div className="input-icon">
                        <IconCalendar />
                      </div>
                    )}
                  </div>
                   <SyncError alert={this.getAlertInfo("ingateDate")} />
                  </div>
                </div>
                
                {this.props.loadType !== "BILL_ONLY" && (
                  <div className={`form-group col-lg-3 ${this.renderClassesForShowReviewButton("readyToReturnDate")}`}>
                    <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"readyToReturnDate"} getAlertInfo={this.getAlertInfo} />
                    <label className="label-control">Ready To Return Date</label>
                    <div className="input-wrapper readyToReturnDate">
                    <div className="position-relative">
                      <DateWrapper 
                        disabled={disabled}
                        onRemoveDate={(e) => {this.onRemovePress(e, { name: "readyToReturnDate" });}}
                        OnBlurUpdate={(e) => this.onDateSelect("readyToReturnDate", this.props.customerProfile.readyToReturnDate)}
                        onChange={(e) => {this.props.datechanges("readyToReturnDate", e) }}
                        displayTimeZone={this.timeZone}
                        isShowTime={true}
                        className="right-0"
                        defaultDate={ this.props.customerProfile && this.props.customerProfile.readyToReturnDate } 
                        hideShowSelectDate={true}
                      />
                      {this.props.customerProfile &&
                      this.props.customerProfile.readyToReturnDate &&
                      !disabled ? (
                        <div
                          className="input-icon"
                          onClick={(e) => {
                           this.onRemovePress(e, {
                              name: "readyToReturnDate",
                            });
                          }}
                        >
                          <IconTimes />
                        </div>
                      ) : (
                        <div className="input-icon">
                          <IconCalendar />
                        </div>
                      )}
                    </div>
                     <SyncError alert={this.getAlertInfo("readyToReturnDate")} />
                    </div>
                  </div>
                )}

                {this.isMileStoneEnabled && (this.props.loadType === "IMPORT" || this.props.loadType === "EXPORT") && (
                 <div className={`form-group col-lg-3 `}>
                  <label className="label-control">Completed Date</label>
                      <span data-tip data-for={`milestone_completed_date`}>
                        <IconMilestone className="ml-1 text-primary" />
                      </span>
                      <ReactTooltip id={`milestone_completed_date`}>
                        <span>
                          Milestone Completed
                        </span>
                      </ReactTooltip>
                  <div className="input-wrapper loadCompletedDate">
                  <div className="position-relative">
                    <DateWrapper 
                      disabled={disabled}
                        onRemoveDate={(e) => {this.onRemovePress(e, { name: "loadCompletedDate" });}}
                        OnBlurUpdate={(e) => this.onDateSelect("loadCompletedDate", this.props.customerProfile?.loadCompletedDate)}
                        onChange={(e) => {this.props.datechanges("loadCompletedDate", e) }}
                        displayTimeZone={this.timeZone}
                        isShowTime={true}
                        timeFormat={this.userTimeFormat}
                        defaultDate={ this.props.customerProfile && this.props.customerProfile?.loadCompletedDate} 
                        hideShowSelectDate={true}
                        className="right-0"
                    />
                    {this.props.customerProfile &&
                    this.props.customerProfile?.loadCompletedDate &&
                    !disabled ? (
                      <div
                        className="input-icon"
                        onClick={(e) => {
                          this.onRemovePress(e, { name: "loadCompletedDate" });
                        }}
                      >
                        <IconTimes />
                      </div>
                    ) : (
                      <div className="input-icon">
                        <IconCalendar />
                      </div>
                    )}
                  </div>
                  </div>
                </div>
                )}
              </div>}
            </div>)}
            <div className={`card py-30 pl-0 pr-0 pb-0 px-20 mb-20`}>
             <div className="pl-20 pb-20 pr-20">
              <h4 className="font-16 mb-20">
                Equipment
              </h4>

              <div className="form-row">
                <div className={`form-group col-lg-3 ${this.renderClassesForShowReviewButton("containerNo")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"containerNo"} getAlertInfo={this.getAlertInfo} />
                  <Field
                    type="text"
                    className={`form-control containerNo transition-white-field ${this.props.selectedLoads.isContainerInvalid ? "border-danger" : "" }`}
                    component={InputField}
                    name="containerNo"
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    label="Container #" 
                    onBlur={this.onBlurChange}
                    alert={this.getAlertInfo("containerNo")}
                  />
                {this.props.selectedLoads.isContainerInvalid && <span className="text-danger">
                            Incorrect check digit
                </span> }
                </div>
                <div className={`form-group col-lg-3 ${this.renderClassesForShowReviewButton("containerSize")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"containerSize"} getAlertInfo={this.getAlertInfo} />
                  <label>
                    <ExtraLabel
                      showExtraData={showExtraData}
                      keyName="containerSizeName"
                      onApply={this.onApplyTracerData}
                      extraData={extraData}
                      label="Size"
                    />
                  </label>
                  <div className="input-wrapper">
                    <AsyncSelect
                      styles={smallSelectStyle}
                      disabled={disabled}
                      defaultOptions={containerSizes.map((cs) => ({
                        label: cs.name,
                        value: cs._id,
                        allInfos: cs,
                      }))}
                      key={isLoading+'containerSize'}
                      className={`containerSize ${!isLoading && !this.props.selectedLoads?.isUserEnteredcontainerSize && this.props.selectedLoads?.systemGeneratedContainerSizeName ? 'system-generated' : ''} ${isLoading?'loader':''}`}
                      isClearable
                      loadOptions={getContainerSize}
                      value={
                        containerSize ? {
                          label: containerSize.name,
                          value: containerSize._id,
                        } : ''
                      }
                      onChange={(data, { action }) => {
                        data && 
                          this.props.onDdlChange({
                            target: {
                              name: "containerSize",
                              value: data.allInfos,
                            },
                          });
                        if (action === "clear") {
                          this.onRemovePress(null, {
                            name: "containerSize",
                          });
                        }
                        data && this.onSelectFieldChange("containerSize",data.allInfos)
                      }}
                      isDisabled={disabled}
                    />
                    <SyncError alert={this.getAlertInfo("containerSize")} />
                  </div>
                </div>
                <div className={`form-group col-lg-3 ${this.renderClassesForShowReviewButton("containerType")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"containerType"} getAlertInfo={this.getAlertInfo} />
                  <label>
                    <ExtraLabel
                      showExtraData={showExtraData}
                      keyName="containerTypeName"
                      onApply={this.onApplyTracerData}
                      extraData={extraData}
                      label="Type"
                    />
                  </label>
                  <div className="input-wrapper">
                    <AsyncSelect
                      styles={smallSelectStyle}
                      disabled={disabled}
                      defaultOptions={containerTypes.map((ct) => ({
                        label: ct.name,
                        value: ct._id,
                        allInfos: ct,
                      }))}
                      loadOptions={getContainerType}
                      value={
                        containerType ? {
                          label: containerType.name,
                          value: containerType._id,
                        } : ''
                      }
                      key={isLoading+'containerType'}
                      className={`containerType ${!isLoading && !this.props.selectedLoads?.isUserEnteredcontainerType && this.props.selectedLoads?.systemGeneratedTypeName ? 'system-generated' : ''} ${isLoading?'loader':''}`}
                      isClearable
                      onChange={(data, { action }) => {
                        data &&
                          this.props.onDdlChange({
                            target: {
                              name: "containerType",
                              value: data.allInfos,
                            },
                          });
                        if (action === "clear") {
                          this.onRemovePress(null, {
                            name: "containerType",
                          });
                        }
                        data && this.onSelectFieldChange("containerType",data.allInfos)
                      }}
                      isDisabled={disabled}
                    />
                    <SyncError alert={this.getAlertInfo("containerType")} />
                  </div>
                </div>
                <div className={`form-group col-lg-3 ${this.renderClassesForShowReviewButton("containerOwner")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"containerOwner"} getAlertInfo={this.getAlertInfo} />
                  <label>
                    <ExtraLabel
                      showExtraData={showExtraData}
                      keyName="containerOwnerName"
                      onApply={this.onApplyTracerData}
                      extraData={extraData}
                      label="Owner"
                    />
                  </label>
                  <div className="input-wrapper">
                    <AsyncSelect
                      styles={smallSelectStyle}
                      disabled={disabled}
                      defaultOptions={containerOwners.map((co) => ({
                        label: co.company_name,
                        value: co._id,
                        allInfos: co,
                      }))}
                      cacheOptions
                      value={
                        containerOwner ? {
                          label: containerOwner.company_name,
                        } : ''
                      }
                      key={isLoading+'containerOwner'}
                      className={`containerOwner ${!isLoading && !this.props.selectedLoads?.isUserEnteredcontainerOwner && this.props.selectedLoads?.systemGeneratedOwnerName ? 'system-generated' : ''} ${isLoading?'loader':''}`}
                      isClearable
                      loadOptions={this.getTMSContainerOwner}
                      onChange={(data, { action }) => {
                        if (action === "clear") {
                          this.onRemovePress(null, {
                            name: "containerOwner",
                          });
                        }
                        if (action === "select-option") {
                          this.setState({ ContainerDefaultValue: data });
                          this.props.updateState(
                            "containerOwner",
                            data.allInfos
                          );
                          data && this.onSelectFieldChange("containerOwner",data.allInfos)
                          setTimeout(() => {
                            this.props.onDdlChange({
                              target: {
                                name: "scac",
                                value: data.allInfos.scac
                                  ? data.allInfos.scac
                                  : "",
                              },
                            });
                          this.onSelectFieldChange("scac",data.allInfos.scac ? data.allInfos.scac : "")
                          }, 2000);
                        }
                      }}
                      isDisabled={disabled}
                    />
                    <SyncError alert={this.getAlertInfo("containerOwner")} />
                  </div>
                </div>
              </div>
            
              <div className="form-row ">
                <div className={`form-group col-lg-3 ${this.renderClassesForShowReviewButton("chassisNo")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"chassisNo"} getAlertInfo={this.getAlertInfo} />
                  <label>
                    <ExtraLabel
                      showExtraData={showExtraData}
                      keyName="chassisNo"
                      onApply={this.onApplyTracerData}
                      extraData={extraData}
                      label="Chassis #"
                    />
                  </label>
                  {this.isPreventChassis ? 
                    <AsyncSelect
                    className="chassisNo"
                    styles={smallSelectStyle}
                    loadOptions={this.getChassis.bind(this)}
                    defaultOptions={this.state.chassisOptions?.map((chassis) => ({
                      label: chassis.chassisNo,
                      value: chassis._id,
                      allInfos: chassis,
                      isDisabled: !chassis.isAvailable,
                      drivers: this.props.drivers
                    }))}
                    isLoading={this.state.areOptionsLoading}
                    isClearable
                    value={
                      this.props.customerProfile.chassisNo ? {
                        label: this.props.customerProfile.chassisNo,
                      } : ''
                    }
                    components={{ Option:PreventChassisOptions }}
                    onChange={(value, { action }) => {
                      this.selectChassis(value);
                      if (action === "clear") {
                        this.onRemovePress(null, { name: "chassisNo" }, this.props?.customerProfile?.chassisNo);
                      }
                    }}
                    onMenuOpen={() => {
                      this.setState({chassisOptions:[]})
                      this.loadOptionsForChassis()
                    }}
                    drivers={this.props.drivers}
                    isDisabled={disabled}
                  />
                  :                  
                  <AsyncCreatableSelect
                    className="chassisNo"
                    styles={smallSelectStyle}
                    cacheOptions
                    loadOptions={this.getChassis.bind(this)}
                    defaultOptions={this.isChassisV3 ? this.state.chassisOptionsV3 : chassis?.map((chassis) => ({
                      label: chassis.chassisNo,
                      value: chassis._id,
                      allInfos: chassis,
                      chassisNo: chassis.chassisNo,
                    }))}
                    isClearable
                    formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
                    value={
                      this.props.customerProfile.chassisNo ? {
                        label: this.props.customerProfile.chassisNo,
                      } : ''
                    }
                    onChange={(value, { action }) => {
                      this.selectChassis(value);
                      if (action === "clear") {
                        this.onRemovePress(null, { name: "chassisNo" });
                      }
                    }}
                    onCreateOption={(value) =>
                      {
                        this.onSelectFieldChange("chassisNo", value)
                        this.props.updateState("chassisNo", value)
                      }
                    }
                    isDisabled={disabled}
                  />}
                  <SyncError alert={this.getAlertInfo("chassisNo")} />
                </div>
                <div className={`form-group col-lg-3 ${this.renderClassesForShowReviewButton("chassisSize")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"chassisSize"} getAlertInfo={this.getAlertInfo} />
                  <label>
                    <ExtraLabel
                      showExtraData={showExtraData}
                      keyName="chassisSizeName"
                      onApply={this.onApplyTracerData}
                      extraData={extraData}
                      label="Size"
                    />
                  </label>
                  <div className="input-wrapper">
                    <AsyncSelect
                      className="chassisSize"
                      styles={smallSelectStyle}
                      disabled={disabled}
                      defaultOptions={chassisSizes.map((cs) => ({
                        label: cs.name,
                        value: cs._id,
                        allInfos: cs,
                      }))}
                      isClearable
                      loadOptions={this.getChassisSize}
                      value={chassisSize ? { label: chassisSize.name } : ''}
                      onChange={(data, { action }) => {
                        data &&
                          this.props.onDdlChange({
                            target: {
                              name: "chassisSize",
                              value: data.allInfos,
                            },
                          });
                        if (action === "clear") {
                          this.onRemovePress(null, { name: "chassisSize" });
                        }
                        data && this.onSelectFieldChange("chassisSize",data.allInfos)
                      }}
                      isDisabled={disabled}
                    />
                  <SyncError alert={this.getAlertInfo("chassisSize")} />
                  </div>
                </div>
                <div className={`form-group col-lg-3 ${this.renderClassesForShowReviewButton("chassisType")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"chassisType"} getAlertInfo={this.getAlertInfo} />
                  <label>
                    <ExtraLabel
                      showExtraData={showExtraData}
                      keyName="chassisTypeName"
                      onApply={this.onApplyTracerData}
                      extraData={extraData}
                      label="Type"
                    />
                  </label>
                  <div className="input-wrapper">
                    <AsyncSelect
                      className="chassisType"
                      styles={smallSelectStyle}
                      disabled={disabled}
                      defaultOptions={chassisTypes.map((ct) => ({
                        label: ct.name,
                        value: ct._id,
                        allInfos: ct,
                      }))}
                      isClearable
                      loadOptions={this.getChassisType}
                      value={chassisType ? { label: chassisType.name } : ''}
                      onChange={(data, { action }) => {
                        data &&
                          this.props.onDdlChange({
                            target: {
                              name: "chassisType",
                              value: data.allInfos,
                            },
                          });
                        if (action === "clear") {
                          this.onRemovePress(null, { name: "chassisType" });
                        }
                        data && this.onSelectFieldChange("chassisType",data.allInfos)
                      }}
                      isDisabled={disabled}
                    />
                  <SyncError alert={this.getAlertInfo("chassisType")} />
                  </div>
                </div>
                <div className={`form-group col-lg-3 ${this.renderClassesForShowReviewButton("chassisOwner")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"chassisOwner"} getAlertInfo={this.getAlertInfo} />
                  <label>
                    <ExtraLabel
                      showExtraData={showExtraData}
                      keyName="chassisOwnerName"
                      onApply={this.onApplyTracerData}
                      extraData={extraData}
                      label="Owner"
                    />
                  </label>
                  <div className="input-wrapper">
                    <AsyncSelect
                      className="chassisOwner"
                      styles={smallSelectStyle}
                      disabled={disabled}
                      defaultOptions={chassisOwners.filter((el)=>{ return el.company_name !== null }).map((co) => ({
                        label: co.company_name,
                        value: co._id,
                        allInfos: co,
                      }))}
                      isClearable
                      loadOptions={this.getTMSChassisOwner.bind(this)}
                      value={
                        chassisOwner ? {
                          label: chassisOwner.company_name,
                        } : ''
                      }
                      onChange={(data, { action }) => {
                        data &&
                          this.props.onDdlChange({
                            target: {
                              name: "chassisOwner",
                              value: data.allInfos,
                            },
                          });
                        if (action === "clear") {
                          this.onRemovePress(null, { name: "chassisOwner" });
                        }
                        data && this.onSelectFieldChange("chassisOwner",data.allInfos)
                      }}
                      isDisabled={disabled}
                    />
                    <SyncError alert={this.getAlertInfo("chassisOwner")} />
                  </div>
                </div>
              </div>
        
              <div className="form-row">
                <div className={`form-group col-lg-3 ${this.renderClassesForShowReviewButton("genset")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"genset"} getAlertInfo={this.getAlertInfo} />
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control genset transition-white-field"
                    name="genset"
                    maxlength="12"
                    disabled={disabled}
                    isRemove={true}
                    value={this.props.customerProfile.genset}
                    onRemovePress={this.onRemovePress}
                    label="Genset #"
                    onBlur={this.onBlurChange}
                    alert={this.getAlertInfo("genset")}
                  />
                </div>
                <div className={`form-group col-lg-3 ${this.renderClassesForShowReviewButton("temperature")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"temperature"} getAlertInfo={this.getAlertInfo} />
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control temperature transition-white-field"
                    name="temperature"
                    disabled={disabled}
                    isRemove={true}
                    value={this.props.customerProfile.temperature}
                    onRemovePress={this.onRemovePress}
                    label="Temperature"
                    onBlur={this.onBlurChange}
                    alert={this.getAlertInfo("temperature")}
                  />
                </div>
                <div className={`form-group col-lg-3 ${this.renderClassesForShowReviewButton("routeType")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"routeType"} getAlertInfo={this.getAlertInfo} />
                  <label className="">Route</label>

                  <div className="input-wrapper">
                    <Select
                      className="routeType"
                      styles={smallSelectStyle}
                      disabled={disabled}
                      options={this.state.routeTypes.map((co) => ({
                        label: co,
                        value: co,
                        allInfos: co,
                      }))}
                      value={routeType ? { label: routeType } : ''}
                      onChange={(data, { action }) => {
                        data &&
                          this.props.onDdlChange({
                            target: {
                              name: "routeType",
                              value: data.allInfos,
                            },
                          });
                        if (action === "clear") {
                          this.onRemovePress(null, { name: "routeType" });
                        }
                        data && this.onSelectFieldChange("routeType",data.allInfos)
                      }}
                      isDisabled={disabled}
                      isClearable
                    />
                    <SyncError alert={this.getAlertInfo("routeType")} />
                  </div>
                </div>
                <div className={`form-group col-lg-3 ${this.renderClassesForShowReviewButton("scac")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"scac"} getAlertInfo={this.getAlertInfo} />
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control scac transition-white-field"
                    label="SCAC"
                    name="scac"
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    onBlur={this.onBlurChange}
                    alert={this.getAlertInfo("scac")}
                  />
                </div>
                {isTareWeightEnabled() && (
                <div className={`form-group col-lg-3 ${this.renderClassesForShowReviewButton("tareWeight")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"tareWeight"} getAlertInfo={this.getAlertInfo} />
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control tareWeight transition-white-field"
                    name="tareWeight"
                    maxlength="12"
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    label="Tare Weight"
                    onBlur={this.onBlurChange}
                    alert={this.getAlertInfo("tareWeight")}
                  />
                </div>
                )}
                {isISOTypeEnabled() && this.props.selectedLoads.type_of_load !== 'BILL_ONLY' &&
                  <div className="form-group col-lg-3">
                    <label className="">ISO Code</label>
                    <div className="input-wrapper">
                      <AsyncSelect
                        className="isoContainerType"
                        styles={smallSelectStyle}
                        disabled={disabled}
                        defaultOptions={isoContainerTypes?.length > 0 && isoContainerTypes?.map((cs) => ({
                          label: cs.name,
                          value: cs.name,
                          allInfos: cs,
                        }))}
                        isClearable
                        loadOptions={getISOCodeAsyncSearch}
                        value={isoContainerType && {
                          label: isoContainerType,
                          value: isoContainerType,
                        }}
                        onChange={(data, { action }) => {
                          this.selectISOEquipmentType(data)
                          if (action === "clear") {
                            this.onRemovePress(null, { name: "isoContainerType" });
                          }
                        }}
                        isDisabled={disabled}
                      />
                    </div>
                  </div>
                }
              </div>
              <div className="form-row mt-2">
                <div className={`form-group col-lg-3 mb-0 ${this.renderClassesForShowReviewButton("hazmat")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"hazmat"} getAlertInfo={this.getAlertInfo} />
                  <div className="custom-control custom-checkbox d-flex align-items-center pl-0">                 
                    <input 
                      type="checkbox" 
                      className="form-check-input rounded-3 border-secondary" 
                      id="hazmat" 
                      value="1"
                      checked={this.props.customerProfile?.hazmat}
                      onChange={(e) => {
                        this.onSelectFieldChange("hazmat", e.target.checked)
                        this.props.updateState("hazmat", e.target.checked);
                      }}
                      disabled={disabled || this.getAlertInfo("hazmat")?.message === alertMessageType.PENDING_RECIEVED} 
                      />
                    <label className="m-0 pl-10" htmlFor="hazmat" id="hazmat-label">Hazmat</label>
                  </div>
                    <SyncError alert={this.getAlertInfo("hazmat")} />
                </div>
                <div className={`form-group col-lg-3 mb-0 ${this.renderClassesForShowReviewButton("overWeight")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"overWeight"} getAlertInfo={this.getAlertInfo} />
                    <div className="custom-control custom-checkbox d-flex align-items-center pl-0">
                    <input 
                      type="checkbox" 
                      className="form-check-input rounded-3 border-secondary" 
                      id="overWeight" 
                      value="2"
                      checked={this.props.customerProfile?.overWeight}
                      onChange={(e) => {
                        this.onSelectFieldChange("overWeight", e.target.checked)
                        this.props.updateState("overWeight", e.target.checked);
                      }}
                      disabled={disabled || this.getAlertInfo("overWeight")?.message === alertMessageType.PENDING_RECIEVED} 
                      />
                    <label className="m-0 pl-10" htmlFor="overWeight" id="overWeight-label">Overweight</label>
                  </div>
                  <SyncError alert={this.getAlertInfo("overWeight")} />
                </div>
                
              <div className={`form-group col-lg-3 mb-0 ${this.renderClassesForShowReviewButton("overHeight")}`}>
                <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"overHeight"} getAlertInfo={this.getAlertInfo} />
                <div className="custom-control custom-checkbox d-flex align-items-center pl-0">
                  <input 
                      type="checkbox" 
                      className="form-check-input rounded-3 border-secondary" 
                      id="overHeight" 
                      value="2"
                      checked={this.props.customerProfile?.overHeight}
                      onChange={(e) => {
                        this.onSelectFieldChange("overHeight", e.target.checked)
                        this.props.updateState("overHeight", e.target.checked);
                      }}
                      disabled={disabled || this.getAlertInfo("overHeight")?.message === alertMessageType.PENDING_RECIEVED} 
                    />
                    <label className="m-0 pl-10" htmlFor="overHeight" id="overHeight-label">Overheight</label>
                </div>
                  <SyncError alert={this.getAlertInfo("overHeight")} />
                </div>

              <div className={`form-group col-lg-3 mb-0 ${this.renderClassesForShowReviewButton("liquor")}`}>
                <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"liquor"} getAlertInfo={this.getAlertInfo} />
                <div className="custom-control custom-checkbox d-flex align-items-center pl-0">
                  <input 
                      type="checkbox" 
                      className="form-check-input rounded-3 border-secondary" 
                      id="liquor" 
                      value="2"
                      checked={this.props.customerProfile?.liquor}
                      onChange={(e) => {
                        this.onSelectFieldChange("liquor", e.target.checked)
                        this.props.updateState("liquor", e.target.checked);
                      }}
                      disabled={disabled || this.getAlertInfo("liquor")?.message === alertMessageType.PENDING_RECIEVED} 
                    />
                    <label className="m-0 pl-10" htmlFor="liquor" id="liquor-label">{showForCarrier() ? "Liquid" : "Liquor"}</label>
                </div>
                <SyncError alert={this.getAlertInfo("liquor")} />
                </div>
              <div className={`form-group col-lg-3 mb-0 mt-1 ${this.renderClassesForShowReviewButton("isHot")}`}>
                <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"isHot"} getAlertInfo={this.getAlertInfo} />
                <div className="custom-control custom-checkbox d-flex align-items-center pl-0">
                  <input 
                    type="checkbox" 
                    className="form-check-input rounded-3 border-secondary" 
                    id="hot" 
                    value="2"
                    checked={this.props.customerProfile?.hot}
                    onChange={(e) => {
                      this.onSelectFieldChange("hot", e.target.checked)
                      this.props.updateState("hot", e.target.checked);
                    }}
                    disabled={disabled || this.getAlertInfo("hot")?.message === alertMessageType.PENDING_RECIEVED} 
                  />
                  <label className="m-0 pl-10" htmlFor="hot" id="hot-label">Hot</label>
                </div>
                <SyncError alert={this.getAlertInfo("isHot")} />
                </div>

                <div className={`form-group col-lg-3 mb-0 mt-1 ${this.renderClassesForShowReviewButton("isGenset")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"isGenset"} getAlertInfo={this.getAlertInfo} />
                  <div className="custom-control custom-checkbox d-flex align-items-center pl-0">                 
                    <input 
                      type="checkbox" 
                      className="form-check-input rounded-3 border-secondary" 
                      id="isGenset" 
                      value="1"
                      checked={this.props.customerProfile?.isGenset}
                      onChange={(e) => {
                        this.onSelectFieldChange("isGenset", e.target.checked)
                        this.props.updateState("isGenset", e.target.checked);
                      }}
                      disabled={disabled || this.getAlertInfo("isGenset")?.message === alertMessageType.PENDING_RECIEVED} 
                      />
                    <label className="m-0 pl-10" htmlFor="isGenset" id="isGenset-label">Genset</label>
                  </div>
                  <SyncError alert={this.getAlertInfo("isGenset")} />
                </div>

                {
                  this.isScaleEnabled && (
                    <div className={`form-group col-lg-3 mb-0 mt-1 ${this.renderClassesForShowReviewButton("scale")}`}>
                      <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"scale"} getAlertInfo={this.getAlertInfo} />
                      <div className="custom-control custom-checkbox d-flex align-items-center pl-0">
                        <input
                          type="checkbox"
                          className="form-check-input rounded-3 border-secondary"
                          id="scale"
                          value="1"
                          checked={this.props.customerProfile?.scale}
                          onChange={(e) => {
                            this.onSelectFieldChange("scale", e.target.checked)
                            this.props.updateState("scale", e.target.checked);
                          }}
                          disabled={disabled || this.getAlertInfo("scale")?.message === alertMessageType.PENDING_RECIEVED}
                        />
                        <label className="m-0 pl-10" htmlFor="scale" id="scale-label">Scale</label>
                      </div>
                      <SyncError alert={this.getAlertInfo("scale")} />
                    </div>

                  )
                }

                {isNewEndorsement() && <>
                  <div className={`form-group col-lg-3 mb-0 mt-1`}>
                    <div className="custom-control custom-checkbox d-flex align-items-center pl-0">                 
                      <input 
                        type="checkbox" 
                        className="form-check-input rounded-3 border-secondary" 
                        id="domestic" 
                        value="1"
                        checked={this.props.customerProfile?.domestic}
                        onChange={(e) => {
                          this.onSelectFieldChange("domestic", e.target.checked)
                          this.props.updateState("domestic", e.target.checked);
                        }}
                        disabled={disabled || this.getAlertInfo("domestic")?.message === alertMessageType.PENDING_RECIEVED} 
                        />
                      <label className="m-0 pl-10" htmlFor="domestic" id="domestic-label">Domestic</label>
                    </div>
                  </div>

                  <div className={`form-group col-lg-3 mb-0 mt-1`}>
                    <div className="custom-control custom-checkbox d-flex align-items-center pl-0">                 
                      <input 
                        type="checkbox" 
                        className="form-check-input rounded-3 border-secondary" 
                        id="ev" 
                        value="1"
                        checked={this.props.customerProfile?.ev}
                        onChange={(e) => {
                          this.onSelectFieldChange("ev", e.target.checked)
                          this.props.updateState("ev", e.target.checked);
                        }}
                        disabled={disabled || this.getAlertInfo("ev")?.message === alertMessageType.PENDING_RECIEVED} 
                        />
                      <label className="m-0 pl-10" htmlFor="ev" id="ev-label">EV</label>
                    </div>
                  </div>

                  <div className={`form-group col-lg-3 mb-0 mt-1`}>
                    <div className="custom-control custom-checkbox d-flex align-items-center pl-0">                 
                      <input 
                        type="checkbox" 
                        className="form-check-input rounded-3 border-secondary" 
                        id="waste" 
                        value="1"
                        checked={this.props.customerProfile?.waste}
                        onChange={(e) => {
                          this.onSelectFieldChange("waste", e.target.checked)
                          this.props.updateState("waste", e.target.checked);
                        }}
                        disabled={disabled || this.getAlertInfo("waste")?.message === alertMessageType.PENDING_RECIEVED} 
                        />
                      <label className="m-0 pl-10" htmlFor="waste" id="waste-label">Waste</label>
                    </div>
                  </div>

                  <div className={`form-group col-lg-3 mb-0 mt-1`}>
                    <div className="custom-control custom-checkbox d-flex align-items-center pl-0">                 
                      <input 
                        type="checkbox" 
                        className="form-check-input rounded-3 border-secondary" 
                        id="gdp" 
                        value="1"
                        checked={this.props.customerProfile?.gdp}
                        onChange={(e) => {
                          this.onSelectFieldChange("gdp", e.target.checked)
                          this.props.updateState("gdp", e.target.checked);
                        }}
                        disabled={disabled || this.getAlertInfo("gdp")?.message === alertMessageType.PENDING_RECIEVED} 
                        />
                      <label className="m-0 pl-10" htmlFor="gdp" id="gdp-label">GDP</label>
                    </div>
                  </div>

                  <div className={`form-group col-lg-3 mb-0 mt-1`}>
                    <div className="custom-control custom-checkbox d-flex align-items-center pl-0">                 
                      <input 
                        type="checkbox" 
                        className="form-check-input rounded-3 border-secondary" 
                        id="isRail" 
                        value="1"
                        checked={this.props.customerProfile?.isRail}
                        onChange={(e) => {
                          this.onSelectFieldChange("isRail", e.target.checked)
                          this.props.updateState("isRail", e.target.checked);
                        }}
                        disabled={disabled || this.getAlertInfo("isRail")?.message === alertMessageType.PENDING_RECIEVED} 
                        />
                      <label className="m-0 pl-10" htmlFor="isRail" id="isRail-label">Rail</label>
                    </div>
                  </div>
                </>}

                <div className={`form-group col-lg-3 mb-0 mt-1 ${this.renderClassesForShowReviewButton("isStreetTurn")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"isStreetTurn"} getAlertInfo={this.getAlertInfo} />
                  <div className="custom-control custom-checkbox d-flex align-items-center pl-0">
                    <input
                      type="checkbox"
                      className="form-check-input rounded-3 border-secondary"
                      id="isStreetTurn"
                      value="1"
                      checked={this.props.customerProfile?.isStreetTurn}
                      onChange={(e) => {
                        this.onSelectFieldChange("isStreetTurn", e.target.checked)
                        this.props.updateState("isStreetTurn", e.target.checked);
                      }}
                      disabled={disabled || this.getAlertInfo("isStreetTurn")?.message === alertMessageType.PENDING_RECIEVED}
                    />
                    <label className="m-0 pl-10" htmlFor="isStreetTurn" id="isStreetTurn-label">Street Turn</label>
                  </div>
                  <SyncError alert={this.getAlertInfo("isStreetTurn")} />
                </div>

              </div>
            </div>
            { this.props.customerProfile.isGrayPool &&
              <div className="bg-back-light pl-20 pr-20 pt-20 pb-20">           
                <h4 className="font-16 mb-20 d-flex align-items-center">
                <IconPool /> <span className="ml-10">Gray Pool Empty</span>
                </h4>
                <div className="form-row">
                  <div className="form-group col-lg-3">
                    <Field
                      type="text"
                      className="form-control grayContainerNo transition-white-field"
                      component={InputField}
                      name="grayContainerNo"
                      disabled={disabled}
                      isRemove={true}
                      onRemovePress={this.onRemovePress}
                      label="Gray Container #"
                      onBlur={this.onBlurChange}
                      alert={this.getAlertInfo("grayContainerNo")}
                    />
                  </div>
                  <div className="form-group col-lg-3">
                    <label>
                      <ExtraLabel
                        showExtraData={showExtraData}
                        keyName="grayContainerSizeName"
                        onApply={this.onApplyTracerData}
                        extraData={extraData}
                        label="Size"
                      />
                    </label>
                    <div className="input-wrapper">
                      <AsyncSelect
                        className="grayContainerSize"
                        styles={smallSelectStyle}
                        disabled={disabled}
                        defaultOptions={containerSizes.map((cs) => ({
                          label: cs.name,
                          value: cs._id,
                          allInfos: cs,
                        }))}
                        isClearable
                        loadOptions={getContainerSize}
                        value={
                          grayContainerSize && {
                            label: grayContainerSize.name,
                            value: grayContainerSize._id,
                          }
                        }
                        onChange={(data) => {
                          if(data){
                            this.props.onDdlChange({
                              target: {
                                name: "grayContainerSize",
                                value: data.allInfos,
                              },
                            });
                          }else{
                            this.onRemovePress(null, {
                              name: "grayContainerSize",
                            });
                          }
                          data && this.onSelectFieldChange("grayContainerSize",data.allInfos)
                        }}
                        isDisabled={disabled}
                      />
                    <SyncError alert={this.getAlertInfo("grayContainerSize")} />
                    </div>
                  </div>
                  <div className={`form-group col-lg-3 ${this.renderClassesForShowReviewButton("grayContainerType")}`}>
                    <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"grayContainerType"} getAlertInfo={this.getAlertInfo} />
                    <label>
                      <ExtraLabel
                        showExtraData={showExtraData}
                        keyName="grayContainerTypeName"
                        onApply={this.onApplyTracerData}
                        extraData={extraData}
                        label="Type"
                      />
                    </label>
                    <div className="input-wrapper">
                      <AsyncSelect
                        className="grayContainerType"
                        styles={smallSelectStyle}
                        disabled={disabled}
                        defaultOptions={containerTypes.map((ct) => ({
                          label: ct.name,
                          value: ct._id,
                          allInfos: ct,
                        }))}
                        loadOptions={getContainerType}
                        value={
                          grayContainerType && {
                            label: grayContainerType.name,
                            value: grayContainerType._id,
                          }
                        }
                        isClearable
                        onChange={(data) => {
                          if(data) { 
                            this.props.onDdlChange({
                              target: {
                                name: "grayContainerType",
                                value: data.allInfos,
                              },
                            })
                          }else{
                            this.onRemovePress(null, {
                              name: "grayContainerType",
                            });
                          }
                        data && this.onSelectFieldChange("grayContainerType",data.allInfos)
                        }}
                        isDisabled={disabled}
                      />
                    <SyncError alert={this.getAlertInfo("grayContainerType")} />
                    </div>
                  </div>
                  <div className={`form-group col-lg-3 ${this.renderClassesForShowReviewButton("grayContainerOwner")}`}>
                    <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"grayContainerOwner"} getAlertInfo={this.getAlertInfo} />
                    <label>
                      <ExtraLabel
                        showExtraData={showExtraData}
                        keyName="grayContainerOwnerName"
                        onApply={this.onApplyTracerData}
                        extraData={extraData}
                        label="Owner"
                      />
                    </label>
                    <div className="input-wrapper">
                      <AsyncSelect
                        className="grayContainerOwner"
                        styles={smallSelectStyle}
                        disabled={disabled}
                        defaultOptions={containerOwners.map((co) => ({
                          label: co.company_name,
                          value: co._id,
                          allInfos: co,
                        }))}
                        cacheOptions
                        value={
                          grayContainerOwner && {
                            label: grayContainerOwner.company_name,
                          }
                        }
                        isClearable
                        loadOptions={this.getTMSContainerOwner}
                        onChange={(data) => {
                          if(data){ 
                            this.props.onDdlChange({
                              target: {
                                name: "grayContainerOwner",
                                value: data.allInfos,
                              },
                            })
                          }else{
                            this.onRemovePress(null, {
                              name: "grayContainerOwner",
                            });
                          }
                          data && this.onSelectFieldChange("grayContainerOwner",data.allInfos)
                        }}
                        isDisabled={disabled}
                      />
                      <SyncError alert={this.getAlertInfo("grayContainerOwner")} />
                    </div>
                  </div>
                </div>
            
                <div className="form-row ">
                  <div className={`form-group col-lg-3 ${this.renderClassesForShowReviewButton("grayChassisNo")}`}>
                    <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"grayChassisNo"} getAlertInfo={this.getAlertInfo} />
                    <label>
                      <ExtraLabel
                        showExtraData={showExtraData}
                        keyName="grayChassisNo"
                        onApply={this.onApplyTracerData}
                        extraData={extraData}
                        label="Gray Chassis #"
                      />
                    </label>
                    {this.isPreventChassis ?
                    <AsyncSelect
                    styles={smallSelectStyle}
                    className="grayChassisNo"
                    loadOptions={this.getChassis.bind(this)}
                    defaultOptions={this.state.chassisOptions?.map((chassis) => ({
                      label: chassis.chassisNo,
                      value: chassis._id,
                      allInfos: chassis,
                      isDisabled: !chassis.isAvailable,
                      drivers: this.props.drivers
                    }))}
                    isLoading={this.state.areOptionsLoading}
                    components={{ Option:PreventChassisOptions }}
                    onMenuOpen={() => {
                      this.setState({chassisOptions:[]})
                      this.loadOptionsForChassis()
                    }}
                    isClearable
                    value={
                      this.props.customerProfile.grayChassisNo && {
                        label: this.props.customerProfile.grayChassisNo,
                      }
                    }
                    onChange={(value) => {
                      if(value){
                        this.selectGrayChassis(value);
                      }else {
                        this.onRemovePress(null, { name: "grayChassisNo" });
                      }
                    }}
                    drivers={this.props.drivers}
                    isDisabled={disabled}
                  />
                    :
                    <AsyncCreatableSelect
                      styles={smallSelectStyle}
                      cacheOptions
                      className="grayChassisNo"
                      loadOptions={this.getChassis.bind(this)}
                      defaultOptions={this.isChassisV3 ? this.state.chassisOptionsV3 : chassis?.map((chassis) => ({
                        label: chassis.chassisNo,
                        value: chassis._id,
                        allInfos: chassis,
                        chassisNo: chassis.chassisNo,
                      }))}
                      isClearable
                      formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
                      value={
                        this.props.customerProfile.grayChassisNo && {
                          label: this.props.customerProfile.grayChassisNo,
                        }
                      }
                      onChange={(value) => {
                        if(value){
                          this.selectGrayChassis(value);
                        }else {
                          this.onRemovePress(null, { name: "grayChassisNo" });
                        }
                      }}
                      onCreateOption={(value) =>
                        {
                          this.onSelectFieldChange("grayChassisNo", value)
                          this.props.updateState("grayChassisNo", value)
                        }
                      }
                      isDisabled={disabled}
                    />}
                      <SyncError alert={this.getAlertInfo("grayChassisNo")} />
                  </div>
                    <div className={`form-group col-lg-3 ${this.renderClassesForShowReviewButton("grayChassisSize")}`}>
                      <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"grayChassisSize"} getAlertInfo={this.getAlertInfo} />
                    <label>
                      <ExtraLabel
                        showExtraData={showExtraData}
                        keyName="grayChassisSizeName"
                        onApply={this.onApplyTracerData}
                        extraData={extraData}
                        label="Size"
                      />
                    </label>
                    <div className="input-wrapper">
                      <AsyncSelect
                        className="grayChassisSize"
                        styles={smallSelectStyle}
                        disabled={disabled}
                        defaultOptions={chassisSizes.map((cs) => ({
                          label: cs.name,
                          value: cs._id,
                          allInfos: cs,
                        }))}
                        isClearable
                        loadOptions={this.getChassisSize}
                        value={grayChassisSize && { label: grayChassisSize.name }}
                        onChange={(data) => {
                          if(data){
                            this.props.onDdlChange({
                              target: {
                                name: "grayChassisSize",
                                value: data.allInfos,
                              },
                            });
                          }else{
                            this.onRemovePress(null, {
                              name: "grayChassisSize",
                            });
                          }
                          data && this.onSelectFieldChange("grayChassisSize",data.allInfos)
                        }}
                        isDisabled={disabled}
                      />
                      <SyncError alert={this.getAlertInfo("grayChassisSize")} />
                    </div>
                  </div>
                  <div className={`form-group col-lg-3 ${this.renderClassesForShowReviewButton("grayChassisType")}`}>
                    <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"grayChassisType"} getAlertInfo={this.getAlertInfo} />
                    <label>
                      <ExtraLabel
                        showExtraData={showExtraData}
                        keyName="grayChassisTypeName"
                        onApply={this.onApplyTracerData}
                        extraData={extraData}
                        label="Type"
                      />
                    </label>
                    <div className="input-wrapper">
                      <AsyncSelect
                        className="grayChassisType"
                        styles={smallSelectStyle}
                        disabled={disabled}
                        defaultOptions={chassisTypes.map((ct) => ({
                          label: ct.name,
                          value: ct._id,
                          allInfos: ct,
                        }))}
                        isClearable
                        loadOptions={this.getChassisType}
                        value={grayChassisType && { label: grayChassisType.name }}
                        onChange={(data) => {
                          if(data) { 
                            this.props.onDdlChange({
                              target: {
                                name: "grayChassisType",
                                value: data.allInfos,
                              },
                            })
                          }else{
                            this.onRemovePress(null, {
                              name: "grayChassisType",
                            });
                          }
                          data && this.onSelectFieldChange("grayChassisType",data.allInfos)
                        }}
                        isDisabled={disabled}
                      />
                      <SyncError alert={this.getAlertInfo("grayChassisType")} />
                    </div>
                  </div>
                  <div className={`form-group col-lg-3 ${this.renderClassesForShowReviewButton("grayChassisOwner")}`}>
                    <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"grayChassisOwner"} getAlertInfo={this.getAlertInfo} />
                    <label>
                      <ExtraLabel
                        showExtraData={showExtraData}
                        keyName="grayChassisOwnerName"
                        onApply={this.onApplyTracerData}
                        extraData={extraData}
                        label="Owner"
                      />
                    </label>
                    <div className="input-wrapper">
                      <AsyncSelect
                        className="grayChassisOwner"
                        styles={smallSelectStyle}
                        disabled={disabled}
                        defaultOptions={chassisOwners.filter((el)=>{ return el.company_name !== null }).map((co) => ({
                          label: co.company_name,
                          value: co._id,
                          allInfos: co,
                        }))}
                        isClearable
                        loadOptions={this.getTMSChassisOwner.bind(this)}
                        value={
                          grayChassisOwner && {
                            label: grayChassisOwner.company_name,
                          }
                        }
                        onChange={(data) => {
                          if(data){ 
                            this.props.onDdlChange({
                              target: {
                                name: "grayChassisOwner",
                                value: data.allInfos,
                              },
                            });
                          }else{
                            this.onRemovePress(null, {
                              name: "grayChassisOwner",
                            });
                          }
                          data && this.onSelectFieldChange("grayChassisOwner",data.allInfos)
                        }}
                        isDisabled={disabled}
                      />
                      <SyncError alert={this.getAlertInfo("grayChassisOwner")} />
                    </div>
                  </div>
                </div>              
              </div>
            } 
            </div>
            <div className="card py-30 px-20 mb-20">
              <h4 className="font-16 mb-20">
                Reference Numbers
              </h4>
              <div className="form-row">
                {this.props.loadType === "IMPORT" && <div className={`form-group col-lg-6 ${this.renderClassesForShowReviewButton("callerbillLandingNo")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"callerbillLandingNo"} getAlertInfo={this.getAlertInfo} />
                  <label>Master Bill of Lading</label>
                  <Field
                    type="text"
                    className={`form-control callerbillLandingNo ${!isLoading && this.props.selectedLoads?.systemGeneratedcallerbillLandingNo && !this.props.selectedLoads?.isUserEnteredcallerbillLandingNo ? "bg-primary-100" : 'transition-white-field'} ${isLoading ? 'loader' : ''}`}
                    component={InputField}
                    name="callerbillLandingNo"
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    placeholder={isLoading ? CHECKING_DATA : ""}
                    onBlur={this.onBlurChange}
                    key={isLoading + 'masterbol'}
                    alert={this.getAlertInfo("callerbillLandingNo")}
                  />
                </div>}

                {this.props.loadType === "EXPORT" && <div className={`form-group col-lg-6 ${this.renderClassesForShowReviewButton("doNo")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"doNo"} getAlertInfo={this.getAlertInfo} />
                    <label>House Bill of Lading</label>
                    <Field
                      type="text"
                      className="form-control doNo transition-white-field"
                      component={InputField}
                      name="doNo"
                      placeholder=""
                      disabled={disabled}
                      isRemove={true}
                      onRemovePress={this.onRemovePress}
                      onBlur={this.onBlurChange}
                      alert={this.getAlertInfo("doNo")}
                      />
                  </div>}

                 {this.props.loadType === "IMPORT" && <div className={`form-group col-lg-6 ${this.renderClassesForShowReviewButton("doNo")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"doNo"} getAlertInfo={this.getAlertInfo} />
                    <label>House Bill of Lading</label>
                    <Field
                      type="text"
                      className="form-control doNo transition-white-field"
                      component={InputField}
                      name="doNo"
                      placeholder=""
                      disabled={disabled}
                      isRemove={true}
                      onRemovePress={this.onRemovePress}
                      onBlur={this.onBlurChange}
                      alert={this.getAlertInfo("doNo")}
                      />
                  </div>}
                  {this.props.loadType === "IMPORT" &&
                    <div className={`form-group col-lg-6 ${this.renderClassesForShowReviewButton("sealNo")}`}>
                      <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"sealNo"} getAlertInfo={this.getAlertInfo} />
                      <label>Seal #</label>
                      <Field
                        type="text"
                        className={`form-control sealNo ${!isLoading && !this.props.selectedLoads?.isUserEnteredsealNo && this.props.selectedLoads.systemGeneratedsealNo ? 'bg-primary-100' :'transition-white-field'} ${isLoading ? 'loader' : ''}`}
                        component={InputField}
                        name="sealNo"
                        isRemove={true}
                        onRemovePress={this.onRemovePress}
                        disabled={disabled}
                        placeholder={isLoading ? CHECKING_DATA : ''}
                        key={isLoading + 'sealNo#'}
                        onBlur={this.onBlurChange}
                        alert={this.getAlertInfo("sealNo")}
                      />
                    </div>
                  }
                {this.props.loadType == "EXPORT" && (
                  <div className={`form-group col-lg-6 ${this.renderClassesForShowReviewButton("bookingNo")}`}>
                    <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"bookingNo"} getAlertInfo={this.getAlertInfo} />
                    <label>Booking #</label>
                    <Field
                      type="text"
                      className="form-control bookingNo transition-white-field"
                      component={InputField}
                      name="bookingNo"
                      disabled={disabled}
                      isRemove={true}
                      onRemovePress={this.onRemovePress}
                      placeholder=""
                      onBlur={this.onBlurChange}
                      alert={this.getAlertInfo("bookingNo")}
                    />
                  </div>
                )}
                <div className={`form-group col-lg-6 ${this.renderClassesForShowReviewButton("secondaryReferenceNo")}`}>
                 <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"secondaryReferenceNo"} getAlertInfo={this.getAlertInfo} />
                  <label>Reference #</label>
                  <div>
                  <Field
                    type="text"
                    className="form-control secondaryReferenceNo transition-white-field"
                    component={InputField}
                    name="secondaryReferenceNo"
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    disabled={disabled}
                    placeholder=""  
                    onBlur={this.onBlurChange}
                    alert={this.getAlertInfo("secondaryReferenceNo")}
                  />
                  </div>
                </div>
                <div className={`form-group col-lg-6 ${this.renderClassesForShowReviewButton("deliveryOrderNo")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"deliveryOrderNo"} getAlertInfo={this.getAlertInfo} />
                  <label>Vessel Name</label>
                  <Field
                    type="text"
                    className={`form-control deliveryOrderNo ${!isLoading && this.props.selectedLoads?.systemGenerateddeliveryOrderNo && !this.props.selectedLoads?.isUserEntereddeliveryOrderNo ? 'bg-primary-100' : 'transition-white-field'} ${isLoading ? 'loader' : ''}`}
                    component={InputField}
                    name="deliveryOrderNo"
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    disabled={disabled}
                    placeholder={isLoading ? CHECKING_DATA : ''}
                    onBlur={this.onBlurChange}
                    key={isLoading + 'vesselname'}
                    alert={this.getAlertInfo("deliveryOrderNo")}
                  />
                </div>
                <div className={`form-group col-lg-6 ${this.renderClassesForShowReviewButton("releaseNo")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"releaseNo"} getAlertInfo={this.getAlertInfo} />
                  <label>Voyage</label>
                  <Field
                    component={InputField}
                    type="text"
                    className={`form-control releaseNo ${!isLoading && this.props.selectedLoads?.systemGeneratedreleaseNo && !this.props.selectedLoads?.isUserEnteredreleaseNo ? 'bg-primary-100' : 'transition-white-field'} ${isLoading ? 'loader' : ''}`}
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    name="releaseNo"
                    placeholder={isLoading ? CHECKING_DATA : ""}
                    onBlur={this.onBlurChange}
                    key={isLoading + "voyage"}
                    alert={this.getAlertInfo("releaseNo")}
                  />
                </div>
                <div className={`form-group col-lg-6 ${this.renderClassesForShowReviewButton("purchaseOrderNo")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"purchaseOrderNo"} getAlertInfo={this.getAlertInfo} />
                  <label>Purchase Order #</label>
                  <Field
                    type="text"
                    className="form-control purchaseOrderNo transition-white-field"
                    component={InputField}
                    name="purchaseOrderNo"
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    disabled={disabled}
                    placeholder=""
                    onBlur={this.onBlurChange}
                    alert={this.getAlertInfo("purchaseOrderNo")}
                  />
                </div>
                <div className={`form-group col-lg-6 ${this.renderClassesForShowReviewButton("shipmentNo")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"shipmentNo"} getAlertInfo={this.getAlertInfo} />
                  <label>Shipment #</label>
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control shipmentNo transition-white-field"
                    placeholder=""
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    name="shipmentNo"
                    onBlur={this.onBlurChange}
                    alert={this.getAlertInfo("shipmentNo")}
                  />
                </div>
                <div className={`form-group col-lg-6 ${this.renderClassesForShowReviewButton("callerPONo")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"callerPONo"} getAlertInfo={this.getAlertInfo} />
                  <label>Pick Up #</label>
                  <Field
                    component={InputField}
                    type="text"
                    className="form-control callerPONo transition-white-field"
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    name="callerPONo"
                    placeholder=""
                    onBlur={this.onBlurChange}
                    alert={this.getAlertInfo("callerPONo")}
                  />
                </div>
                <div className={`form-group col-lg-6 ${this.renderClassesForShowReviewButton("appointmentNo")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"appointmentNo"} getAlertInfo={this.getAlertInfo} />
                  <label>Appointment #</label>
                  <Field
                    type="text"
                    className="form-control appointmentNo transition-white-field"
                    component={InputField}
                    name="appointmentNo"
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    disabled={disabled}
                    placeholder=""
                    onBlur={this.onBlurChange}
                    alert={this.getAlertInfo("appointmentNo")}
                  />
                </div>
                <div className={`form-group col-lg-6 ${this.renderClassesForShowReviewButton("returnNo")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"returnNo"} getAlertInfo={this.getAlertInfo} />
                  <label>Return #</label>
                  <Field
                    type="text"
                    className="form-control returnNo transition-white-field"
                    component={InputField}
                    name="returnNo"
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    disabled={disabled}
                    placeholder=""
                    onBlur={this.onBlurChange}
                    alert={this.getAlertInfo("returnNo")}
                  />
                </div>
                <div className={`form-group col-lg-6 ${this.renderClassesForShowReviewButton("reservationNo")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"reservationNo"} getAlertInfo={this.getAlertInfo} />
                  <label>Reservation #</label>
                  <Field
                    type="text"
                    className="form-control reservationNo transition-white-field"
                    component={InputField}
                    name="reservationNo"
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    disabled={disabled}
                    placeholder=""
                    onBlur={this.onBlurChange}
                    alert={this.getAlertInfo("reservationNo")}
                  />
                </div>
                  {this.props.loadType === "EXPORT" && (
                    <div className={`form-group col-lg-6 ${this.renderClassesForShowReviewButton("sealNo")}`}>
                      <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"sealNo"} getAlertInfo={this.getAlertInfo} />
                      <label>Seal #</label>
                      <Field
                        type="text"
                        className="form-control sealNo transition-white-field"
                        component={InputField}
                        name="sealNo"
                        isRemove={true}
                        onRemovePress={this.onRemovePress}
                        disabled={disabled}
                        placeholder=""
                        onBlur={this.onBlurChange}
                        alert={this.getAlertInfo("sealNo")}
                    />
                    </div>
                  )}
                {this.props.loadType === "EXPORT" && <div className={`form-group col-lg-6 ${this.renderClassesForShowReviewButton("callerbillLandingNo")}`}>
                  <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} field={"callerbillLandingNo"} getAlertInfo={this.getAlertInfo} />
                  <label>Master Bill of Lading</label>
                  <Field
                    type="text"
                    className={`form-control callerbillLandingNo ${!isLoading && this.props.selectedLoads?.systemGeneratedcallerbillLandingNo && !this.props.selectedLoads?.isUserEnteredcallerbillLandingNo ? "bg-primary-100" : 'transition-white-field'} ${isLoading ? 'loader' : ''}`}
                    component={InputField}
                    name="callerbillLandingNo"
                    disabled={disabled}
                    isRemove={true}
                    onRemovePress={this.onRemovePress}
                    placeholder={isLoading ? CHECKING_DATA : ""}
                    onBlur={this.onBlurChange}
                    key={isLoading + 'masterbol'}
                    alert={this.getAlertInfo("callerbillLandingNo")}
                  />
                </div>}
                {this.props.customerProfile &&
                    this.props.customerProfile.consignee &&
                    this.props.customerProfile.consignee.length > 0 && 
                    this.isShowDeliveryReferenceField &&
                    this.props.customerProfile.consignee.map((val, i) => (
                      val?.company_name && (
                        <div className="form-group col-lg-6" key={`referenceNoForDeliveryLocations-${i}`}>
                          <label>{`Delivery Reference #`}</label>
                          <label className="label-control d-block"></label>
                          <div className="input-wrapper">
                          <input
                            className={`form-control pl-30 referenceNoForDeliveryLocations-${i} transition-white-field`}
                            style={{transition: "background 0.4s ease-out"}}
                            type="text"
                            name={`referenceNoForDeliveryLocations-${i}`}
                            autoComplete="off"
                            value={this.props.customerProfile?.deliveryReference?.[i]?.referenceNo ?? ""}
                            disabled={disabled}
                            onChange={(e) => {this.props.changeDeliveryReference("referenceNo",e.target.value ?? "",i)}}
                            onBlur={(e) => this._saveDeliveryReferenceNumber(i)}
                          />
                            {<>
                              <div data-tip data-for={`reference-${i}`} className="input-icon left-0 right-inherit pt_6 tooltip-label">
                                <div className="avatar-circle avatar-xs bg-white text-dark font-10 font-weight-normal border-1 border-gray-100">
                                  {i+1}
                                </div>
                                <div className="tooltip tooltip--gray-700 bs-tooltip-top" id={`reference-${i}`} data-placement="top-center">
                                  {val?.company_name}
                                  <span className="arrow"></span>
                                </div>
                              </div>
                              {this.props.customerProfile?.deliveryReference?.[i]?.referenceNo && !disabled && <div className="input-icon">
                              <span
                                onClick={(e) => {
                                  this.props.changeDeliveryReference("referenceNo","",i,true, this._saveDeliveryReferenceNumber);
                                }}
                              >
                                {" "}
                                <IconTimes />{" "}
                              </span>
                            </div>}
                            </>
                            }
                          </div>
                        </div>
                      )
                    ))
                }
                {this.props.customerProfile &&
                    liftoffEventsOfLoad.length > 0 &&
                    this.isShowLiftOffReferenceField &&
                    liftoffEventsOfLoad?.map((item, i) => (
                      item?.company_name && (
                        <div className="form-group col-lg-6" key={`liftOffReferenceNo-${i}`}>
                          <label>{`Lift Off Reference #`}</label>
                          <label className="label-control d-block"></label>
                          <div className="input-wrapper">
                          <input
                            className={`form-control pl-30 liftOffReferenceNo-${i} transition-white-field`}
                            style={{transition: "background 0.4s ease-out"}}
                            type="text"
                            name={`liftOffReferenceNo-${i}`}
                            autoComplete="off"
                            value={this.props.customerProfile?.liftOffReference?.[i]?.referenceNo ?? ""}
                            disabled={disabled}
                            onChange={(e) => {this.props.changeLiftOffReference("referenceNo",e.target.value ?? "",i, liftoffEventsOfLoad)}}
                            onBlur={(e) => this.onLiftoffReferenceNumberChange(i)}
                          />
                            {<>
                              <div data-tip data-for={`liftoff-reference-${i}`} className="input-icon left-0 right-inherit pt_6 tooltip-label">
                                <div className="avatar-circle avatar-xs bg-white text-dark font-10 font-weight-normal border-1 border-gray-100">
                                  {i+1}
                                </div>
                                <div className="tooltip tooltip--gray-700 bs-tooltip-top" id={`liftoff-reference-${i}`} data-placement="top-center">
                                  {item?.company_name}
                                  <span className="arrow"></span>
                                </div>
                              </div>
                              {this.props.customerProfile?.liftOffReference?.[i]?.referenceNo && !disabled && <div className="input-icon">
                              <span
                                onClick={(e) => {
                                  this.props.changeLiftOffReference("referenceNo","",i,liftoffEventsOfLoad, true, this.onLiftoffReferenceNumberChange);
                                }}
                              >
                                {" "}
                                <IconTimes />{" "}
                              </span>
                            </div>}
                            </>
                            }
                          </div>
                        </div>
                      )
                    ))
                }
              </div>
            </div>
          </div>
        </div>
        <div className="card py-20 px-30 mb-0">
          <h4 className="font-16 mb-0">Freight Description</h4>
        </div>
        <div className="table mb-20">
          <table className="table table-card table-card--ls fd-row border-spacing-7 px_2">
            <thead>
              <tr>
                <th scope="col" width="17%">Commodity</th>
                <th scope="col">Description</th>
                <th width="10%" scope="col">
                  Pieces
                </th>
                <th width="10%" scope="col">
                  Weight LBS
                </th>
                <th width="10%" scope="col">
                  Weight KGS
                </th>
                <th width="10%" scope="col">
                  Pallets
                </th>
                <th width="2%" scope="col"></th>
              </tr>
            </thead>
            <tbody>
                
              {this.state.items &&
                this.state.items.length > 0 &&
                this.state.items.map((data, key) => {
                  const isSent = this.shiposOrConnectCarrier ? this.state.sentAddItemRequest?.find((sent) => data.itemId === sent?.items?.commodity?.itemId) : false
                  if(isSent) return <></>
                  return (
                    <tr key={key} className={`select-custom-input fd-row-${key} ${this.state.addCommodity || disabled ? "disabled-pointer-events": ""} ${this.checkRemovedCommodityIndex(data?.itemId) ? `${this.getRemovedCommodityTypeAtIndex(data?.itemId)?.alertMessageType === alertMessageType.PENDING_SENT ? "request-wrapper-items request-wrapper-cancel" : "request-wrapper-items"} outline-2 rounded-5 outline-danger bg-danger-50` : ""}`}  style={this.state.addCommodity ? {opacity: "0.2", transition: "background 0.4s ease-out"}: {transition: "background 0.4s ease-out"}}>
                      <td className={`commodity-${key} px-0 ${this.checkUpdateCommodityIndex(data?.itemId, "commodity") ? `${this.getUpdatedCommodityTypeAtIndex(data?.itemId, "commodity")?.alertMessageType === alertMessageType.PENDING_SENT ? "request-wrapper request-wrapper-cancel" : (this.getUpdatedCommodityTypeAtIndex(data?.itemId, "commodity")?.alertMessageType === alertMessageType.PENDING_RECIEVED && "request-wrapper")} ${this.renderClassesForShowCellOfItems('items', key, null, 'commodity', data?.itemId)}`: ""}`} style={{transition: "background 0.4s ease-out"}}>
                        <div className={`${this.checkRemovedCommodityIndex(data?.itemId) ? "ml-15 position-absolute top-0 left-0 bottom-0 d-flex align-items-center" : ""}`}>
                        {this.checkRemovedCommodityIndex(data?.itemId) && (this.getRemovedCommodityTypeAtIndex(data?.itemId)?.alertMessageType === alertMessageType.PENDING_SENT ? <IconDelete className="text-danger"/> : <IconMinus className="text-danger"/>)}
                        {JSON.parse(getStorage("measureUnits"))
                          ?.isCommodityProfileShow == true ? (
                            <div className={`form-group m-0 ${this.checkRemovedCommodityIndex(data?.itemId) ? "pointer-none" : ''}`}>
                            <Select
                              styles={newSmallSelectStyle}
                              options={this.state.commodityProfiles}
                              value={this.state.commodityProfiles.find(
                                (pt) => pt.value == data.commodity
                              )}
                              onChange={(value) => {
                                const temp = [...this.state.items];
                                temp[key]["commodity"] = value.value;
                                this.setState({
                                  items: [...temp],
                                });
                                this.props.updateState("items", temp);
                                let oldItems = [
                                  ...this.props.originalLoad?.items,
                                ]
                                oldItems[0] && delete oldItems[0]?._id
                                oldItems?.forEach((item)=> {
                                  item && item._id && delete item._id
                                })
                                const noChange = _.isEqual(temp, this.props.originalLoad?.items)
                                if(noChange) return
                                this.onCommodityChanges(temp,key,"commodity")
                              }}
                            />
                          </div>
                        ) : (
                          <input
                            className={`form-control border-0 ${this.checkRemovedCommodityIndex(data?.itemId) ? "pointer-none" : 'py-0'}`}
                            style={{transition: "background 0.4s ease-out"}}
                            type="text"
                            name="commodity"
                            autoComplete="off"
                            value={data.commodity ?? ""}
                            onChange={(e) => this.tblvalchanges(key, e)}
                            onBlur={(e) => this.tblvalchanges(key, e, true)}
                          />
                        )}
                        </div>
                        {this.checkUpdateCommodityIndex(data?.itemId, "commodity") && <SyncError alert={this.getAlertInfo('items', key, null, 'commodity', data?.itemId)} subField={'commodity'}/>}
                        {this.checkUpdateCommodityIndex(data?.itemId, "commodity", true) && <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} getAlertInfo={this.getAlertInfo} typeOfitems={this.getUpdatedCommodityTypeAtIndex(data?.itemId, "commodity")?.alertMessageType} requestID={this.getUpdatedCommodityTypeAtIndex(data?.itemId, "commodity")?.requestID}/>}
                      </td>
                      <td className={`description-${key}  px-0 ${this.checkUpdateCommodityIndex(data?.itemId, "description") ? `${this.getUpdatedCommodityTypeAtIndex(data?.itemId, "description")?.alertMessageType === alertMessageType.PENDING_SENT ? "request-wrapper request-wrapper-cancel" : (this.getUpdatedCommodityTypeAtIndex(data?.itemId, "description")?.alertMessageType === alertMessageType.PENDING_RECIEVED && "request-wrapper")} ${this.renderClassesForShowCellOfItems('items', key, null, 'description', data?.itemId)}`: ""}`} style={{transition: "background 0.4s ease-out"}}>
                        <input
                          className={`form-control ${this.checkRemovedCommodityIndex(data?.itemId) ? "pointer-none" : 'py-0'}`}
                          type="text"
                          name="description"
                          value={(data.description === 0 || data.description) ? data.description : ''}
                          onChange={(e) => this.tblvalchanges(key, e)}
                          onBlur={(e) => this.tblvalchanges(key, e, true)}
                          autoComplete="off"
                        />
                        {this.checkUpdateCommodityIndex(data?.itemId, "description") && <SyncError alert={this.getAlertInfo('items', key, null, 'description', data?.itemId)} subField={'description'}/>}
                        {this.checkUpdateCommodityIndex(data?.itemId, "description", true) && <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} getAlertInfo={this.getAlertInfo} typeOfitems={this.getUpdatedCommodityTypeAtIndex(data?.itemId, "description")?.alertMessageType} requestID={this.getUpdatedCommodityTypeAtIndex(data?.itemId, "description")?.requestID}/>}
                      </td>
                      <td className={`pieces-${key}  px-0 ${this.checkUpdateCommodityIndex(data?.itemId, "pieces") ? `${this.getUpdatedCommodityTypeAtIndex(data?.itemId, "pieces")?.alertMessageType === alertMessageType.PENDING_SENT ? "request-wrapper request-wrapper-cancel" : (this.getUpdatedCommodityTypeAtIndex(data?.itemId, "pieces")?.alertMessageType === alertMessageType.PENDING_RECIEVED && "request-wrapper")} ${this.renderClassesForShowCellOfItems('items', key, null, 'pieces', data?.itemId)}` : ""}`} style={{transition: "background 0.4s ease-out"}}>
                        {!isGlobalizationEnabled() && <input
                          className={`tblrequire form-control ${this.checkRemovedCommodityIndex(data?.itemId) ? "pointer-none" : 'py-0'}`}
                          type="number"
                          name="pieces"
                          value={(data.pieces === 0 || data.pieces) ? data.pieces : ''}
                          onChange={(e) => this.tblvalchanges(key, e)}
                          onBlur={(e) => this.tblvalchanges(key, e, true)}
                          autoComplete="off"
                        />}
                        {isGlobalizationEnabled() && (
                          <NumberFormatterV2
                            selected={true}
                            autoFocus={false}
                            showCurrency={false}
                            className={`tblrequire form-control ${this.checkRemovedCommodityIndex(data?.itemId) ? "pointer-none" : 'py-0'}`}
                            type="number"
                            name="pieces"
                            value={(data.pieces === 0 || data.pieces) ? data.pieces : ''}
                            onChange={(e) => this.tblvalchanges(key, e)}
                            onBlurWithProps={(e) => this.tblvalchanges(key, e, true)}
                            autoComplete="off"
                          />
                        )}
                        {this.checkUpdateCommodityIndex(data?.itemId, "pieces") && <SyncError alert={this.getAlertInfo('items', key, null, 'pieces', data?.itemId)} subField={'pieces'}/>}
                        {this.checkUpdateCommodityIndex(data?.itemId, "pieces", true) && <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} getAlertInfo={this.getAlertInfo} typeOfitems={this.getUpdatedCommodityTypeAtIndex(data?.itemId, "pieces")?.alertMessageType} requestID={this.getUpdatedCommodityTypeAtIndex(data?.itemId, "pieces")?.requestID}/>}
                      </td>
                      <td className={`weight-${key}  px-0 ${this.checkUpdateCommodityIndex(data?.itemId, "weight") ? `${this.getUpdatedCommodityTypeAtIndex(data?.itemId, "weight")?.alertMessageType === alertMessageType.PENDING_SENT ? "request-wrapper request-wrapper-cancel" : (this.getUpdatedCommodityTypeAtIndex(data?.itemId, "weight")?.alertMessageType === alertMessageType.PENDING_RECIEVED && "request-wrapper")} ${this.renderClassesForShowCellOfItems('items', key, null, 'weight', data?.itemId)}` : ""}`} style={{transition: "background 0.4s ease-out"}}>
                        {!isGlobalizationEnabled() && <input
                          className={`tblrequire form-control ${this.checkRemovedCommodityIndex(data?.itemId) ? "pointer-none" : 'py-0'}`}
                          type="number"
                          name="weight"
                          value={(data.weight === 0 || data.weight ) ? data.weight : ''}
                          onChange={(e) => this.tblvalchanges(key, e)}
                          onBlur={(e) => this.tblvalchanges(key, e, true)}
                          autoComplete="off"
                        />}
                        {isGlobalizationEnabled() && (
                          <NumberFormatterV2 
                            selected={true}
                            autoFocus={false}
                            showCurrency={false}
                            className={`tblrequire form-control ${this.checkRemovedCommodityIndex(data?.itemId) ? "pointer-none" : 'py-0'}`}
                            type="number"
                            name="weight"
                            value={(data.weight === 0 || data.weight ) ? data.weight : ''}
                            onChange={(e) => this.tblvalchanges(key, e)}
                            onBlurWithProps={(e) => this.tblvalchanges(key, e, true)}
                            autoComplete="off"
                          />
                        )}
                        {this.checkUpdateCommodityIndex(data?.itemId, "weight") && <SyncError alert={this.getAlertInfo('items', key, null, 'weight', data?.itemId)} subField={'weight'}/>}
                        {this.checkUpdateCommodityIndex(data?.itemId, "weight", true) && <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} getAlertInfo={this.getAlertInfo} typeOfitems={this.getUpdatedCommodityTypeAtIndex(data?.itemId, "weight")?.alertMessageType} requestID={this.getUpdatedCommodityTypeAtIndex(data?.itemId, "weight")?.requestID}/>}
                      </td>
                      <td className={`weightKgs-${key}  px-0 ${this.checkUpdateCommodityIndex(data?.itemId, "weightKgs") ? `${this.getUpdatedCommodityTypeAtIndex(data?.itemId, "weightKgs")?.alertMessageType === alertMessageType.PENDING_SENT ? "request-wrapper request-wrapper-cancel" : (this.getUpdatedCommodityTypeAtIndex(data?.itemId, "weightKgs")?.alertMessageType === alertMessageType.PENDING_RECIEVED && "request-wrapper")} ${this.renderClassesForShowCellOfItems('items', key, null, 'weightKgs', data?.itemId)}` : ""}`} style={{transition: "background 0.4s ease-out"}}>
                        {!isGlobalizationEnabled() && <input
                          className={`tblrequire form-control ${this.checkRemovedCommodityIndex(data?.itemId) ? "pointer-none" : 'py-0'}`}
                          type="number"
                          name="weightKgs"
                          value={(data.weightKgs === 0 || data.weightKgs) ? data.weightKgs : ''}
                          onChange={(e) => this.tblvalchanges(key, e)}
                          onBlur={(e) => this.tblvalchanges(key, e, true)}
                          autoComplete="off"
                        />}
                        {isGlobalizationEnabled() && (
                          <NumberFormatterV2 
                            selected={true}
                            autoFocus={false}
                            showCurrency={false}
                            className={`tblrequire form-control ${this.checkRemovedCommodityIndex(data?.itemId) ? "pointer-none" : 'py-0'}`}
                            type="text"
                            name="weightKgs"
                            value={(data.weightKgs === 0 || data.weightKgs) ? data.weightKgs : ''}
                            onChange={(e) => this.tblvalchanges(key, e)}
                            onBlurWithProps={(e) => this.tblvalchanges(key, e, true)}
                            autoComplete="off"
                          />
                        )}
                        {this.checkUpdateCommodityIndex(data?.itemId, "weightKgs") && <SyncError alert={this.getAlertInfo('items', key, null, 'weightKgs', data?.itemId)} subField={'weightKgs'}/>}
                        {this.checkUpdateCommodityIndex(data?.itemId, "weightKgs", true) && <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} getAlertInfo={this.getAlertInfo} typeOfitems={this.getUpdatedCommodityTypeAtIndex(data?.itemId, "weightKgs")?.alertMessageType} requestID={this.getUpdatedCommodityTypeAtIndex(data?.itemId, "weightKgs")?.requestID}/>}
                      </td>
                      <td className={`pallets-${key}  px-0 ${this.checkUpdateCommodityIndex(data?.itemId, "pallets") ? `${this.getUpdatedCommodityTypeAtIndex(data?.itemId, "pallets")?.alertMessageType === alertMessageType.PENDING_SENT ? "request-wrapper request-wrapper-cancel" : (this.getUpdatedCommodityTypeAtIndex(data?.itemId, "pallets")?.alertMessageType === alertMessageType.PENDING_RECIEVED && "request-wrapper")} ${this.renderClassesForShowCellOfItems('items', key, null, 'pallets', data?.itemId)}` : ""}`} style={{transition: "background 0.4s ease-out"}}>
                        {!isGlobalizationEnabled() && <input
                          type="number"
                          className={`tblrequire form-control ${this.checkRemovedCommodityIndex(data?.itemId) ? "pointer-none" : 'py-0'}`}
                          name="pallets"
                          value={(data.pallets === 0 || data.pallets) ? data.pallets : ''}
                          onChange={(e) => this.tblvalchanges(key, e)}
                          onBlur={(e) => this.tblvalchanges(key, e, true)}
                          autoComplete="off"
                        />}
                        {isGlobalizationEnabled() && (
                          <NumberFormatterV2
                            selected={true}
                            autoFocus={false}
                            showCurrency={false}
                            type="number"
                            className={`tblrequire form-control ${this.checkRemovedCommodityIndex(data?.itemId) ? "pointer-none" : 'py-0'}`}
                            name="pallets"
                            value={(data.pallets === 0 || data.pallets) ? data.pallets : ''}
                            onChange={(e) => this.tblvalchanges(key, e)}
                            onBlurWithProps={(e) => this.tblvalchanges(key, e, true)}
                            autoComplete="off"
                          />
                        )}
                        {this.checkUpdateCommodityIndex(data?.itemId, "pallets") && <SyncError alert={this.getAlertInfo('items', key, null, 'pallets', data?.itemId)} subField={'pallets'}/>}
                        {this.checkUpdateCommodityIndex(data?.itemId, "pallets", true) && <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} getAlertInfo={this.getAlertInfo} typeOfitems={this.getUpdatedCommodityTypeAtIndex(data?.itemId, "pallets")?.alertMessageType} requestID={this.getUpdatedCommodityTypeAtIndex(data?.itemId, "pallets")?.requestID}/>}
                      </td>

                      <td style={disabled ? {cursor: "not-allowed", transition: "background 0.4s ease-out"} : {transition: "background 0.4s ease-out"}} className={`${this.checkUpdateCommodityIndex(data?.itemId, "all", true) ? 'pointer-none' : ""}`}>
                        <div
                          type="button"
                          style={disabled ? {pointerEvents: "none", opacity: 0.5} : {}}
                          onClick={() => this.DeleteItemRow(key, data?.itemId)}
                          className="btn btn-circle btn-xs bg-soft-danger"
                        >
                          <IconMinus />
                        </div>
                      </td>
                      {this.checkRemovedCommodityIndex(data?.itemId) && <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} getAlertInfo={this.getAlertInfo} typeOfitems={this.getRemovedCommodityTypeAtIndex(data?.itemId)?.alertMessageType} requestID={this.getRemovedCommodityTypeAtIndex(data?.itemId)?.requestID}/>}
                    </tr>
                  );
                })}
              {this.state.sentAddItemRequest?.length > 0 && this.state.sentAddItemRequest?.map((request) => {
                const data = request?.items?.commodity
                return (
                  <tr className="outline-primary outline-2 rounded-5 bg-brand-50 request-wrapper-items request-wrapper-cancel">
                    <td width="17%" className='td-commodity px-0 form-group m-0 pl-2'><div className="position-absolute top-0 left-0 bottom-0 d-flex align-items-center ml-15"><IconPlus className="text-primary mr-10"/>{data?.commodity}</div></td>
                    <td className='td-description px-0 pl-2'>{data?.description}</td>
                    <td width="10%" className='td-pieces px-0 pl-2'>{data?.pieces}</td>
                    <td width="10%" className='td-weight px-0 pl-2'>{data?.weight}</td>
                    <td width="10%" className='td-weightKgs px-0 pl-2'>{data?.weightKgs}</td>
                    <td width="10%" className='td-pallets px-0 pl-2'>{data?.pallets}</td>
                    <td width="2%"></td>
                    <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} getAlertInfo={this.getAlertInfo} typeOfitems={alertMessageType.PENDING_SENT} requestID={request?.requestID}/>
                  </tr>
                )
              })}
              {this.state.receivedAddItemRequest?.length > 0 && this.state.receivedAddItemRequest?.map((request) => {
                const data = request?.items?.commodity
                return (
                  <tr className="outline-warning outline-2 rounded-5 bg-warning-50 mx-1 px-2 py-1 request-wrapper-items">
                    <td width="17%" className='td-commodity px-0 form-group m-0 pl-2'><div className="position-absolute top-0 left-0 bottom-0 d-flex align-items-center ml-15"><IconPlus className="text-warning mr-10"/>{data?.commodity}</div></td>
                    <td className='td-description px-0 pl-2'>{data?.description}</td>
                    <td width="10%" className='td-pieces px-0 pl-2'>{data?.pieces}</td>
                    <td width="10%" className='td-weight px-0 pl-2'>{data?.weight}</td>
                    <td width="10%" className='td-weightKgs px-0 pl-2'>{data?.weightKgs}</td>
                    <td width="10%" className='td-pallets px-0 pl-2'>{data?.pallets}</td>
                    <td width="2%"></td>
                    <ReviewButton fetchSyncChanges={this.fetchSyncChanges} load_reference_number={this.props.selectedLoads.reference_number} showReviewModal={this.showReviewModal} getAlertInfo={this.getAlertInfo} typeOfitems={alertMessageType.PENDING_RECIEVED}/>
                  </tr>
                )
              })}
            {this.state.addCommodity && <CommodityComponent commodityProfiles={this.state.commodityProfiles} handleAddCommodity={this.AddItemRows} items={this.state.items} discardRow={()=>this.setState({ addCommodity: false })}/>}
            </tbody>
          </table>
          <span style={disabled ? {cursor: "not-allowed"} : {}}>
          <div
            type="button"
            className="btn btn-white btn-sm text-primary mt-10"
            onClick={() => !disabled && this.setState({ addCommodity: true })}
            style={disabled || this.state.addCommodity ? {pointerEvents: "none", opacity: 0.4} : {}}
          >
            <IconPlus className="mr-2" />
            Add Row
          </div>
          </span>
        </div>

        <Modal show={this.state.showCutOffModal} bsSize="lg">
          <Modal.Header>
            <h4 className="modal-title"> Cuttof Status </h4>
          </Modal.Header>
          <Modal.Body>
            {this.state.bookingInfoLoading ? (
              <div>Getting Infos please wait.... This may take a minute.</div>
            ) : (
              <div>
                {this.state.bookingCutOffInfo &&
                this.state.bookingCutOffInfo.result ? (
                  <div>
                    cut Of Date :{" "}
                    {moment(
                      this.state.bookingCutOffInfo.result.cutOff
                    ).format(DateTimeFormatUtils.verboseDateTimeFormat())}
                  </div>
                ) : (
                  <div>Not any Booking Infos Found</div>
                )}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="col-md-6">
              <button
                className="btn btn-default "
                disabled={!this.state.bookingCutOffInfo}
                onClick={() => {
                  this.updateCutOff();
                }}
              >
                <span> Update </span>
              </button>
            </div>
            <div className="col-md-6">
              <button
                className="btn btn-default col-md-6 "
                onClick={() => {
                  this.setState({ showCutOffModal: false });
                }}
              >
                <span> Cancel </span>
              </button>
            </div>
          </Modal.Footer>
        </Modal>
        <AwaitablePromptAlert content={APPOINTMENT_MESSAGES.CONFIRM_CANCEL_APPOINTMENT} confirmCallback={this.handleApptCancelConfirm}/>
        <Modal show={this.state.showPastDateModal} dialogClassName="modal-sm modal-dialog-scrollable" backdropClassName="z-1050">
          <Modal.Body className="py-50 text-center">         
                  <IconWarning className="mb-30 wh-60px text-warning" />
                  <div className="font-14 line-height-20">You are about to record a date that occurred in the past. Do you want to proceed?</div>
          </Modal.Body>
          <Modal.Footer>
              <button
                className="btn btn-outline-light"
                onClick={(e) =>{
                  this.setState({showPastDateModal: false})
                  {this.onRemovePress(e, { name: "readyToReturnDate" });}
                }}
              >
               No
              </button>
              <button
                className="btn btn-primary"
                onClick={() => {
                  this.setState({allowPastDate: true}, () => {
                    this.onDateSelect("readyToReturnDate", this.props.customerProfile.readyToReturnDate)
                  })
                }}
              >
                Yes 
              </button>
          </Modal.Footer>
        </Modal>

        {isCabotageEnabled() && this.state.isShowViolatedModal &&
          <RemoveDriverModal
            isWarning
            response={this.state.showViolatedModal?.response ?? {}}
            contentBody={this.state.showViolatedModal?.message ?? ''}
            showModal={this.state.isShowViolatedModal}
            setShowModal={(isShowViolatedModal) => this.setState({ isShowViolatedModal })}
            removeDriver={() => {
                this.setState({ isShowViolatedModal: false });
                if (this.state.showViolatedModal?.fromReturnLocation) {
                  this.handleEditLoad(this.state.showViolatedModal);
                } else {
                  this.handleEditReturnLocationLoad(this.state.showViolatedModal);
                }
              }
            }
            confirmText='Continue'
          />
        }
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    formValue: state.form,
    containerSizes: state.containerReducer.containerSize,
    containerTypes: state.containerReducer.containerType,
    containerOwners: state.containerReducer.containerOwner,
    chassis: state.chassisReducer.chassis,
    chassisOwners: state.chassisReducer.chassisOwner,
    chassisSizes: state.chassisReducer.chassisSize,
    chassisTypes: state.chassisReducer.chassisType,
    isEmptyEnabled: state.TmsReducer.isEmptyEnabled,
    drayosTrackRefLoad: state.TmsReducer.drayosTrackRefLoad,
    drayosTrackSuccess: state.TmsReducer.drayosTrackSuccess,
    appSettings: state.TmsReducer.appSettings,
    trackosSynced: state.TmsReducer.trackosSynced,
    standard_ports:state.TmsReducer.standard_ports,
    drivers: state.TmsReducer.drivers,
    isoContainerTypes: state.containerReducer.isoContainerType,
  };
}

const mapDispatchToProps = (dispatch) => ({
  getTMSChassis: bindActionCreators(getTMSChassis, dispatch),
  tmsAction: bindActionCreators(tmsAction, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withCurrentUserSettings(withAwaitablePrompt(NewCommonLoadComponent)));

const StatusButton = ({
  updateState,
  buttonStatus,
  preSetOrderStatus,
  loadStatus,
}) => {
  return (
    <div className="col-md-4">
      <div className="form-check form-group">
        <input
          type="radio"
          disabled
          checked={preSetOrderStatus === buttonStatus.value ? true : false}
          className="form-check-input"
          id="prepull-drop-hook"
          name="pre-order-status"
        />
        <label className="form-check-label" htmlFor="prepull-drop-hook">
          {buttonStatus.label ? buttonStatus.label : "DEFAULT"}
        </label>
      </div>
    </div>
  );
};
const YesOrNo = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];
const HoldOrReleased = [
  { label: "Yes", value: "HOLD" },
  { label: "No", value: "RELEASED" },
];



