import { useEffect, useId, useMemo, useState } from "react";
import moment from "moment-timezone";

import { generateStatusColor } from "../../NewDispatcher/DispatcherTable/Functions";
import { useCurrentUser, useHover, useCurrentUserSettings } from "../../../../hooks";
import { DateTimeFormatUtils } from "../../../../services";
import { APPOINTMENT_STATUSES, MOVE_TYPES } from "../../../../constants";
import { IconInfo } from "../../../../Components/Common/Icons";
import { formatToTime } from "../../services";
import { getAppointment } from "../actionCreator";
import CustomTooltip from "Components/Common/CustomTooltip";

const RequestedAppointmentTimeInfo = ({ label, moveType, load, isAbsolute = false, isPortal = true, isAllowedForDirectBooking = false }) => {
  const id = useId();
  const [hoverRef, isHovering] = useHover();

  const { currentUserTimeZone } = useCurrentUser();
  const { isAppointmentFeatureEnabled } = useCurrentUserSettings() || {};
  const [errorRemarks, setErrorRemarks] = useState({ isLoading: false, isCompleted: false, remarks: null, systemRemarks: null });
  

  // Must have startTime property
  const isValidTime = (timeArgs) => {
    if (!timeArgs?.length) return [];
    return timeArgs?.some((eachTime) => eachTime?.startTime);
  };

  const times = useMemo(() => {
    if (moveType == MOVE_TYPES.PICKUP && load?.pickupAppointmentSystem) return load?.requestedPickupTimes;

    // DROP
    if (moveType == MOVE_TYPES.EMPTY && load?.emptyAppointmentSystem) return load?.requestedReturnTimes;

    return [];
  }, [moveType, load]);

  const status = useMemo(() => {
    if (moveType == MOVE_TYPES.PICKUP && load?.pickupAppointmentSystem && load?.pickUpApptStatus)
      return load?.pickUpApptStatus;

    // DROP
    if (moveType == MOVE_TYPES.EMPTY) {
      if(load?.emptyAppointmentSystem && load?.emptyApptStatus){
        return load?.emptyApptStatus;
      }
      if(load?.dropAutoAppointmentSystem && load?.loadTracks?.emptyAutoApptStatus){
        return load?.loadTracks?.emptyAutoApptStatus;
      }
    }

    return "";
  }, [moveType, load]);

  // Hide ToolTip if there is user entered time
  const isUserEnteredTime = useMemo(() => {
    if (moveType == MOVE_TYPES.PICKUP && ![APPOINTMENT_STATUSES.CANCELLED, APPOINTMENT_STATUSES.DELETED].includes(load?.pickUpApptStatus)) return true;

    // DROP
    if (moveType == MOVE_TYPES.EMPTY && ![APPOINTMENT_STATUSES.CANCELLED, APPOINTMENT_STATUSES.DELETED].includes(load?.emptyApptStatus)) return true;
    return false;
  }, [moveType, load]);

  useEffect(() => {
    if (!isHovering || errorRemarks.isCompleted || status !== APPOINTMENT_STATUSES.ERROR) return;

    const appointmentId =
      (moveType === MOVE_TYPES.PICKUP && load?.pickupAppointmentSystem) ||
      (moveType === MOVE_TYPES.EMPTY && load?.emptyAppointmentSystem);

    if (!appointmentId) return;

    setErrorRemarks((prev) => ({ ...prev, isLoading: true }));

    getAppointment({ appointmentId })
      .then((data) => {
        setErrorRemarks((prev) => ({
          ...prev,
          isLoading: false,
          isCompleted: true,
          remarks: data?.remarks,
          systemRemarks: data?.systemRemarks,
        }));
      })
      .catch(() => {
        setErrorRemarks((prev) => ({
          ...prev,
          isLoading: false,
          isCompleted: true,
          remarks: null,
          systemRemarks: null,
        }));
      });
  }, [isHovering, moveType, load, status, errorRemarks.isCompleted]);

  const CommonToolTip = ({ isOpen, refNo }) => (
    <CustomTooltip isOpen={isOpen} refNo={refNo} color={"gray-700"} className={"max-w-300px max-vh-100 pull-y"} placement={"auto"} offset={[0, 0]}>
      {status === APPOINTMENT_STATUSES.ERROR ? (
        errorRemarks.isLoading ? (
          "Loading..."
        ) : errorRemarks.isCompleted && !errorRemarks.remarks && !errorRemarks.systemRemarks ? (
          "No remarks available for this appointment"
        ) : (
          <div>
            {errorRemarks?.remarks && <div>{errorRemarks.remarks}</div>}
            {errorRemarks?.systemRemarks && (
              <div className={errorRemarks.remarks ? "mt-1" : ""}>System Remarks: {errorRemarks.systemRemarks}</div>
            )}
          </div>
        )
      ) : (
        <>
          <div>Requested {label ?? "Time"}</div>
          {times?.map((eachTime) => {
            return (
              eachTime?.startTime &&
              eachTime?.endTime && (
                <div className="mt-1">
                  {eachTime?.dateType?.includes("TIME") ? (
                    <>
                      {formatToTime(eachTime.startTime)} - {formatToTime(eachTime.endTime)}
                    </>
                  ) : (
                    <>
                      {moment(eachTime?.startTime)
                        .tz(currentUserTimeZone)
                        .format(DateTimeFormatUtils.fullDateTimeFormat())}{" "}
                      -{" "}
                      {moment(eachTime?.endTime)
                        .tz(currentUserTimeZone)
                        .format(DateTimeFormatUtils.fullDateTimeFormat())}
                    </>
                  )}
                </div>
              )
            );
          })}
          {times?.length == 0 && <p>N/A</p>}
        </>
      )}
    </CustomTooltip>
  );
  return isAppointmentFeatureEnabled && status && isUserEnteredTime && times?.length && isValidTime(times) ? (
    <div
      
    >
      <div className="d-inline-flex align-items-center gap-5" ref={hoverRef}>
        <div className={`appointment-time-info_status badge badge-sm badge-${generateStatusColor(status)}`}>{status}</div>
        <div className="appointment-time-info_icon">
          <IconInfo />
        </div>
      </div>

      {isHovering && <CommonToolTip isOpen={isHovering} refNo={hoverRef?.current} /> }
    </div>
  ) : (
    isAppointmentFeatureEnabled && status && (
      <div className={`d-block w-max appointment-time-info_status badge badge-sm badge-${generateStatusColor(status)}`}>{status}</div>
    )
  );
};

export default RequestedAppointmentTimeInfo;
